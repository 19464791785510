import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { makeGet } from '../api/helpers';
import { UPDATE_SERVER_AVAILABLE_SUCCESS } from './updateServerAvailable';

/* Action Types */
export const FETCH_AVAILABLE_SERVER_START = 'FETCH_AVAILABLE_SERVER_START';
export const FETCH_AVAILABLE_SERVER_SUCCESS = 'FETCH_AVAILABLE_SERVER_SUCCESS';
export const FETCH_AVAILABLE_SERVER_ERROR = 'FETCH_AVAILABLE_SERVER_ERROR';

/* reducer */
const DEFAULT_STATE = {
    data: [],
    error: null,
    loading: false,
};

export const reducer = handleActions(
    {
        [FETCH_AVAILABLE_SERVER_ERROR]: (state, action) => ({
            ...state,
            data: [],
            error: action.payload,
            loading: false,
        }),
        [FETCH_AVAILABLE_SERVER_START]: (state) => ({
            ...state,
            data: [],
            loading: true,
        }),
        [FETCH_AVAILABLE_SERVER_SUCCESS]: (state, action) => ({
            ...state,
            data: action.payload,
            loading: false,
        }),
        [UPDATE_SERVER_AVAILABLE_SUCCESS]: (state, action) => {
            const data = [...state.data];
            if (action.meta.isAvailable === 'true') {
                data.push(action.meta.serverID);
            } else {
                data.forEach((server, i) => {
                    if (server === Number(action.meta.serverID)) {
                        data.splice(server[i], 1);
                    }
                    return [...data];
                });
            }
            return { ...state, data };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.availableServers;

export const getServerAvailableData = createSelector(stateSelector, (state) => state.data);

export const getServerAvailableError = createSelector(stateSelector, (state) => state.error);

export const getServerAvailableLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export const fetchAvailableServers = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: FETCH_AVAILABLE_SERVER_START,
        });

        const request = makeGet({
            authToken: authToken,
            path: `https://api-${deployment}.liveauctioneers.com/support/getavailableservers`,
        });

        request.end((err, res) => {
            if (err) {
                dispatch({
                    error: true,
                    payload: err.message,
                    type: FETCH_AVAILABLE_SERVER_ERROR,
                });
            } else if (res && res.errorMessage) {
                dispatch({
                    error: true,
                    payload: res.body.errorMessage,
                    type: FETCH_AVAILABLE_SERVER_ERROR,
                });
            } else {
                dispatch({
                    payload: res.body.payload,
                    type: FETCH_AVAILABLE_SERVER_SUCCESS,
                });
            }
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message || error,
            type: FETCH_AVAILABLE_SERVER_ERROR,
        });
    }
};
