import { CancelForm, RefundForm } from '../../types/Payment';
import { handleResponse, makeGet, makePost } from './helpers';

type GetBidderPaymentsAdminParams = {
    authToken: string;
    deployment: string;
    from: string;
    keyword: string;
    page: number;
    pageSize: number | 'all';
    status: string;
    to: string;
};

export const getBidderPaymentsAdmin = ({
    authToken,
    deployment,
    from,
    keyword,
    page,
    pageSize,
    status,
    to,
}: GetBidderPaymentsAdminParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'admin/payments', authToken, deployment, path: '<PAYMENT-API>' });
        request.query({
            from,
            keyword,
            page,
            pageSize,
            status,
            to,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetDisbursementsAdminParams = {
    authToken: string;
    deployment: string;
    from: string;
    keyword: string;
    page: number;
    pageSize: number | 'all';
    to: string;
};

export const getDisbursementsAdmin = ({
    authToken,
    deployment,
    from,
    keyword,
    page,
    pageSize,
    to,
}: GetDisbursementsAdminParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'admin/disbursements', authToken, deployment, path: '<PAYMENT-API>' });
        request.query({
            from,
            keyword,
            page,
            pageSize,
            to,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetAdminDisbursementRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    to: string;
};

export const getAdminDisbursementRecords = ({ authToken, deployment, from, to }: GetAdminDisbursementRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'admin/disbursementrecords',
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });
        request.query({ from, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetRevenueReportParams = {
    authToken: string;
    deployment: string;
    from: string;
    to: string;
};

export const getRevenueReport = ({ authToken, deployment, from, to }: GetRevenueReportParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'report/revenue',
            authToken,
            deployment,
            path: '<HOUSEREPORT-API>',
        });
        request.query({ from, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetLADisbursementRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    groupBy: string;
    provider: number;
    to: string;
};

export const getLADisbursementRecords = ({
    authToken,
    deployment,
    from,
    groupBy,
    provider,
    to,
}: GetLADisbursementRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'admin/ladisbursementrecords',
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });
        request.query({ from, groupBy, provider, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetAdminPaymentRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    to: string;
};

export const getAdminPaymentRecords = ({ authToken, deployment, from, to }: GetAdminPaymentRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'admin/paymentrecords',
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });
        request.query({ from, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type PostCancelParams = {
    authToken: string;
    cancelForm: CancelForm;
    deployment: string;
    transactionId: string;
};

export const postCancelApi = ({ authToken, cancelForm, deployment, transactionId }: PostCancelParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `payment/${transactionId}/cancel`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send(cancelForm);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

/**
 * @category Payment API
 * @see postResetPaymentSetupForHouse
 */
type PostResetPaymentSetupForHouseParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

/**
 * Resets payrix setup status for given houseId via Post request to Payment API
 * @function postResetPaymentSetupForHouse
 * @category Payment API
 * @param {PostResetPaymentSetupForHouseParams}
 * @see tbd
 */
export const postResetPaymentSetupForHouse = ({
    authToken,
    deployment,
    houseId,
}: PostResetPaymentSetupForHouseParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `house/${houseId}/onboardreset`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send({});
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

/**
 * @category Payment API
 * @see postPaysafeOnboard
 */
type PostPaysafeOnboardParams = {
    authToken: string;
    deployment: string;
    highRiskMerchantId: string;
    houseId: number;
    merchantId: string;
};

/**
 * Links houseId and paysafe merchantId
 * @function postPaysafeOnboard
 * @category Payment API
 * @param {PostPaysafeOnboardParams}
 * @see tbd
 */
export const postPaysafeOnboard = ({
    authToken,
    deployment,
    highRiskMerchantId,
    houseId,
    merchantId,
}: PostPaysafeOnboardParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'paysafe/houseaccount/create',
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send({
            highRiskMerchantId,
            houseId,
            merchantId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostCaptureParams = {
    authToken: string;
    deployment: string;
    transactionId: string;
};

export const postCaptureApi = ({ authToken, deployment, transactionId }: PostCaptureParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `payment/${transactionId}/capture`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send({});
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRefundParams = {
    authToken: string;
    deployment: string;
    refundForm: RefundForm;
    transactionId: string;
};

export const postRefundApi = ({ authToken, deployment, refundForm, transactionId }: PostRefundParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `payment/${transactionId}/refund`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send(refundForm);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetDailyBidderPaymentDepositsParams = {
    authToken: string;
    deployment: string;
    transferId: string;
};

export const getDailyBidderPaymentDeposits = ({
    authToken,
    deployment,
    transferId,
}: GetDailyBidderPaymentDepositsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'report/getdailybidderpaymentdeposits/' + transferId,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetWeeklyAHPayoutsParams = {
    authToken: string;
    deployment: string;
};

export const getWeeklyAHPayoutsParams = ({ authToken, deployment }: GetWeeklyAHPayoutsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'report/getweeklyahpayouts',
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetMonthlyWeaponListingFeeParams = {
    authToken: string;
    deployment: string;
    saleStart: string;
};

export const getMonthlyWeaponListingFee = ({ authToken, deployment, saleStart }: GetMonthlyWeaponListingFeeParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'report/getmonthlyweaponlistingfee/' + saleStart,
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });
type GetMonthEndStripeBalanceParams = {
    authToken: string;
    datePaid: string;
    deployment: string;
    transactionDate: string;
};

export const getMonthEndStripeBalance = ({
    authToken,
    datePaid,
    deployment,
    transactionDate,
}: GetMonthEndStripeBalanceParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `report/getmonthendstripebalance/${datePaid}/transactiondate/${transactionDate}`,
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetDisbursementAuditApiParams = {
    authToken: string;
    deployment: string;
    disbursementId: number;
};

export const getDisbursementAuditApi = ({ authToken, deployment, disbursementId }: GetDisbursementAuditApiParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `admin/merchantdisbursementaudit/${disbursementId}`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
