import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    FetchBidderProfileParams,
    FetchBidderProfileResponse,
    PostUpdateBidderProfileParams,
    UpdateBidderProfileResponse,
} from './bidderProfile.types';
import { getAuthToken } from '../user';
import { getBidderProfile, postUpdateBidderProfile } from './bidderProfile.api';
import { getDeployment } from '../config';

export const fetchBidderProfile = createAsyncThunk<
    FetchBidderProfileResponse['payload'],
    FetchBidderProfileParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/bidderProfile/loadBidderProfile', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    try {
        const { error, payload } = await getBidderProfile({ authToken, deployment, params });

        if (error) {
            throw error;
        }

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in fetching bidder profile'));
    }
});

export const updateBidderProfile = createAsyncThunk<
    UpdateBidderProfileResponse['payload'],
    PostUpdateBidderProfileParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/bidderProfile/updateBidderProfile', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    try {
        const { error, payload } = await postUpdateBidderProfile({
            authToken,

            deployment,
            params,
        });

        if (error) {
            throw error;
        }

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error Updating Bidder Profile'));
    }
});
