import { ShippingLabelOption, ShippingLabelOwner } from '@/redux/modules/shippingLabel/shippingLabel.types';
import caterwaulIntlEn from '@liveauctioneers/caterwaul-components/lib/Intl/intl/en';

/*
Organize your translations by area, and do them in camelCase
*/

export default {
    add: 'Add',
    auctionHouseSettings: {
        shipping: {
            atgShip: {
                defaultLabel: 'Default',
                labelOptions: {
                    [ShippingLabelOption.Fedex]: 'FedEx',
                    [ShippingLabelOption.UnitedParcelService]: 'UPS',
                    [ShippingLabelOption.UnitedStatesPostalService]: 'USPS',
                },
                labelOwners: {
                    [ShippingLabelOwner.House]: 'House',
                    [ShippingLabelOwner.LA]: 'LiveAuctioneers',
                    [ShippingLabelOwner.Partner]: 'Shipping Partner',
                },
                setDefault: 'Set as default',
                title: 'atgShip Label',
            },
            incentives: {
                defaultLiveShipping: {
                    maxPercentage:
                        "The quote is less than or equal to {input} of the item's hammer price + buyer's premium",
                    maxPrice: 'The quote is less than or equal to {input}',
                    minDistance:
                        "Buyer's default address is more than or equal to {input} from auction house's address",
                    title: 'Default Shipping Method',
                    toggle: {
                        additionalInfo: 'If autopay is on, LiveShipping fee will be included in the payment',
                        label: 'Set LiveShipping as the default shipping method when all of the following criteria are met',
                    },
                },
                save: 'Save Changes',
                saveFailure: {
                    close: 'Close',
                    description:
                        "We can't complete your request at this moment. Please try again later or contact product support team.",
                    title: 'System Error',
                },
                saveSuccess: {
                    close: 'Close',
                    description: 'You have successfully updated the Default Shipping Method setting for the seller.',
                    title: 'Success!',
                },
                type: {
                    fixedMargin: {
                        additionalInfo:
                            'e.g. if a quote is $100 and margin is $5, the buyer will pay $105 and the seller will receive $5',
                        label: 'Seller Incentive Amount',
                    },
                    noMargin: {
                        label: 'No Seller Shipping Margin',
                    },
                    percentageMargin: {
                        additionalInfo:
                            'e.g. ifa quote is $100 and margin is 10%, the buyer will pay $110 and the seller will receive $10',
                        label: 'Seller Incentive Percentage',
                    },
                    title: 'LiveShipping Incentives',
                },
            },
            title: 'Shipping',
        },
    },
    betaChecklist: {
        shippingSaint: {
            addId: 'Add Shipping Saint House ID',
            cancel: 'Cancel',
            deleteConfirmationModal: {
                body: 'Are you sure you want to remove the Shipping Saints house ID from this auction house?',
                cancel: 'Cancel',
                confirm: 'Yes',
                title: 'Remove Shipping Saints House ID',
            },
            edit: 'Edit',
            errors: {
                duplicate: 'This ID has been used by another auction house.',
                system: 'System error. Please try again.',
            },
            placeholder: 'Enter Shipping Saint house ID',
            populated: 'ID: {shippingSaintId}',
            remove: 'Remove',
            save: 'Save',
            successModal: {
                body: 'You have successfully saved a Shipping Saints house ID to this auction house!',
                close: 'Close',
                title: 'Success!',
            },
        },
    },
    bidder: {
        pageTitles: {
            details: 'Details',
            notes: 'Notes',
            payments: 'Payments',
            saveSearch: 'Save Search',
            security: 'Two-Step Authentication',
        },
        twoStepAuthentication: {
            activationModal: {
                confirm: 'Yes, activate',
                description: 'Are you sure you want to activate Two-Step Authentication for this bidder?',
            },
            deactivationModal: {
                confirm: 'Yes, deactivate',
                description: 'Are you sure you want to deactivate Two-Step Authentication for this bidder?',
            },
            label: 'Two-Step Authentication Status:',
        },
    },
    bidderProfileForm: {
        formErrors: {
            emailInvalid: 'Enter a valid email address.',
            firstNameEmpty: 'Enter a first name.',
            lastNameEmpty: 'Enter a last name.',
            mobileNumberEmpty: 'Enter a mobile phone number.',
            mobileNumberInvalid: 'Enter a valid phone number.',
            nameEmpty: 'Enter a name.',
            none: '',
        },
        formLabels: {
            email: 'Email',
            firstName: 'First name',
            lastName: 'Last name',
            mobileNumber: 'Mobile Phone Number',
        },
        formPlaceholder: {
            email: 'Enter the new email address.',
        },
    },
    buyNow: {
        buyItNowTitle: 'Buy It Now (BIN)',
        itemForm: {
            addBINLots: 'Add BIN Lots',
            correctLotIds: 'Are {itemIds} the correct Lot Ids?',
            error: 'There was an error setting buy now item data: {error}',
            lotAdded: 'Buy Now flag added for lot(s) {updateMessage}',
            lotRemoved: 'Buy Now flag removed for lot(s) {updateMessage}',
            removeBINLots: 'Remove BIN Lots',
        },
        manageCatalogLotsTitle: 'Add or remove BIN items from a catalog',
        manageHouseLotsTitle: 'Update BIN house settings',
        manageItemLotsTitle: 'Add or remove lots from BIN (comma-separated)',
        manageListingAgentLotsTitle: 'Remove BIN Items from a listing agent',
    },
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    edit: 'Edit',
    errorModal: {
        body: 'We can’t complete your request at this moment. Please try again later or contact product support team.',
        title: 'System Error',
    },
    featuredAuctioneers: {
        adStatus: 'Ad Status',
        areYouSure: 'Are you sure you want to delete this ad unit?',
        cancel: 'Cancel',
        delete: 'Delete',
        destination: 'Destination Path URL',
        display: 'Where will ad display?',
        displayOn: 'Display On',
        edit: 'Edit',
        endDate: 'End Date',
        faAddUnits: 'Featured Auctioneer Ad Units',
        featuredAuctioneers: 'Featured Auctioneers',
        houseId: 'House Id',
        houseName: 'House',
        imageURL: 'Image URL',
        number: '#',
        onceDeleted: 'Once deleted, the unit will be removed from the queue.',
        position: 'Position',
        range: 'Date Range (PT)',
        schedule: 'Schedule Featured Auctioneer Ad',
        startDate: 'Start Date',
        template: 'Use the Featured Auctioneers template below as a reference when selecting the position of this ad.',
    },
    globalATGId: {
        mapper: {
            auctionHouseId: 'Auction House ID: {id}',
            error: 'System Error. Please try again.',
            globalHouseId: 'Global ATG ID:',
            inputPlaceholder: 'Enter global ATG ID',
        },
        modal: {
            removeBody: 'Are you sure you want to remove the global ATG ID from this auction house?',
            removeTitle: 'Remove Global ATG ID',
            saveBody: 'You have successfully assigned a Global ATG ID to this auction house!',
            saveTitle: 'Success!',
        },
        page: {
            title: 'Global ATG ID',
        },
    },
    houseFinder: {
        label: 'Auction House ID or Name',
        noOptions: 'No houses found',
        placeholder: 'House ID or Name',
        search: 'Search',
    },
    loading: 'loading...',
    remove: 'Remove',
    save: 'Save',
    search: 'Search',
    sellerIncentive: {
        mapper: {
            auctionHouseId: 'Auction House ID: {id}',
            error: 'System Error. Please try again.',
        },
        modal: {
            saveBody: 'You have successfully assigned a LiveShipping Seller Margin to this auction house!',
            saveTitle: 'Success!',
        },
        page: {
            title: 'LiveShipping Seller Margin',
        },
    },
    successModal: {
        body: 'Your changes have been saved.',
        title: 'Success!',
    },
    yes: 'Yes',
    ...caterwaulIntlEn,
};
