import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const REMOVE_FROM_SHIPPING_START = 'REMOVE_FROM_SHIPPING_START';
export const REMOVE_FROM_SHIPPING_SUCCESS = 'REMOVE_FROM_SHIPPING_SUCCESS';
export const REMOVE_FROM_SHIPPING_ERROR = 'REMOVE_FROM_SHIPPING_ERROR';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case REMOVE_FROM_SHIPPING_START:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case REMOVE_FROM_SHIPPING_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case REMOVE_FROM_SHIPPING_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.removeFromShipping;

export const getRemoveFromShippingError = createSelector(stateSelector, (state) => state.error);

export const getRemoveFromShippingLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
type RemoveFromShippingParams = { itemId: number };
export const removeFromShipping =
    ({ itemId }: RemoveFromShippingParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: itemId,
                type: REMOVE_FROM_SHIPPING_START,
            });

            const response = await api.postRemoveJ52Item({
                authToken,
                deployment,
                itemId,
            });
            dispatch({
                meta: { itemId },
                payload: response.payload,
                type: REMOVE_FROM_SHIPPING_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: REMOVE_FROM_SHIPPING_ERROR,
            });
        }
    };
