import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import { getShippingLabel, postShippingLabel } from './shippingLabel.api';
import { getShouldFetchShippingLabel } from './shippingLabel.selectors';
import { ShippingLabel } from './shippingLabel.types';

export const fetchShippingLabel = createAsyncThunk<ShippingLabel, number>(
    'la/ui/shippingLabel/fetchShippingLabel',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await getShippingLabel({
            authToken,
            deployment,
            houseId,
        });
        return payload;
    }
);

export const fetchShippingLabelIfNeeded = createAsyncThunk<void, number>(
    'la/ui/shippingLabel/fetchShippingLabelSettingsIfNeeded',
    async (houseId, { dispatch, getState }) => {
        const state = getState();
        const shouldFetch = getShouldFetchShippingLabel(state, houseId);
        if (shouldFetch) {
            await dispatch(fetchShippingLabel(houseId));
        }
    }
);

type SaveParams = {
    body: ShippingLabel;
    houseId: number;
};

export const saveShippingLabel = createAsyncThunk<void, SaveParams>(
    'la/ui/shippingLabel/saveShippingLabelSettings',
    async ({ body, houseId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        await postShippingLabel({
            authToken,
            body,
            deployment,
            houseId,
        });
    }
);
