import {
    addTrainingItemsByIds,
    getPotentialTrainingItems,
    getSelectedTrainingItems,
    getSynonymsAndExclusionKeywords,
    removeTrainingItemsByIds,
} from './trainingCollection.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import {
    LoadPotentialTrainingItemsPayload,
    SynonymsAndExclusionsParams,
    SynonymsAndExclusionsResponse,
    TrainingCollectionState,
    UpdateItemPayload,
} from './trainingCollection.types';
import { loadTrainingData } from '@/redux/modules/training';
import { TrainingItem, TrainingLotsPageInfo } from '@/pages/Categorization/CategoryTrainingCollection';

export const trainingCollectionDefaultState: TrainingCollectionState = {
    error: false,
    exclusions: null,
    isLoading: false,
    isUpdating: false,
    potentialTrainingItems: [],
    selectedTrainingItems: [],
    synonyms: null,
    totalItemCount: 0,
};

export const loadSynonymsAndExclusions = createAsyncThunk(
    'la/domain/training/loadSynonymsAndExclusions',
    async (
        { categoryId, facetId }: SynonymsAndExclusionsParams,
        { getState }
    ): Promise<SynonymsAndExclusionsResponse> => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        return await getSynonymsAndExclusionKeywords({
            authToken,
            categoryId,
            deployment,
            facetId,
        });
    }
);
export const loadPotentialTrainingItems = createAsyncThunk(
    'la/domain/training/loadPotentialTrainingItems',
    async (
        { categoryId, houseId, pageNumber, searchTerm }: LoadPotentialTrainingItemsPayload,
        { getState }
    ): Promise<TrainingLotsPageInfo> => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        return (
            await getPotentialTrainingItems({
                authToken,
                categoryId,
                deployment,
                houseId,
                pageNumber,
                searchTerm,
            })
        ).payload;
    }
);

export const loadSelectedTrainingItems = createAsyncThunk(
    'la/domain/training/loadSelectedTrainingItems',
    async (categoryId: number, { getState }): Promise<TrainingItem[]> => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        return (
            await getSelectedTrainingItems({
                authToken,
                categoryId,
                deployment,
            })
        ).payload;
    }
);

export const addTrainingItems = createAsyncThunk(
    'la/domain/training/addTrainingItems',
    async (
        { categoryId, facetId, houseId, lotIds, pageNumber, searchTerm }: UpdateItemPayload,
        { dispatch, getState }
    ): Promise<void> => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        await addTrainingItemsByIds({
            authToken,
            categoryId,
            deployment,
            lotIds,
        });
        await dispatch(
            loadPotentialTrainingItems({
                categoryId,
                houseId,
                pageNumber,
                searchTerm,
            })
        );
        await dispatch(loadTrainingData(facetId));
    }
);

export const removeTrainingItems = createAsyncThunk(
    'la/domain/training/removeTrainingItems',
    async (
        { categoryId, facetId, houseId, lotIds, pageNumber, searchTerm }: UpdateItemPayload,
        { dispatch, getState }
    ): Promise<void> => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        await removeTrainingItemsByIds({
            authToken,
            categoryId,
            deployment,
            lotIds,
        });
        await dispatch(
            loadPotentialTrainingItems({
                categoryId,
                houseId,
                pageNumber,
                searchTerm,
            })
        );
        await dispatch(loadTrainingData(facetId));
        await dispatch(loadSelectedTrainingItems(categoryId));
    }
);

const trainingCollectionSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(
                loadPotentialTrainingItems.pending,
                (state): TrainingCollectionState => ({
                    ...state,
                    isLoading: true,
                })
            )
            .addCase(
                loadPotentialTrainingItems.fulfilled,
                (state, { payload }): TrainingCollectionState => ({
                    ...state,
                    isLoading: false,
                    potentialTrainingItems: payload.currentPageTrainingItems,
                    totalItemCount: payload.totalRecords,
                })
            )
            .addCase(
                loadPotentialTrainingItems.rejected,
                (state): TrainingCollectionState => ({
                    ...state,
                    isLoading: false,
                    potentialTrainingItems: trainingCollectionDefaultState.potentialTrainingItems,
                    totalItemCount: trainingCollectionDefaultState.totalItemCount,
                })
            )
            .addCase(
                loadSelectedTrainingItems.pending,
                (state): TrainingCollectionState => ({
                    ...state,
                    isLoading: true,
                })
            )
            .addCase(
                loadSelectedTrainingItems.fulfilled,
                (state, { payload }): TrainingCollectionState => ({
                    ...state,
                    isLoading: false,
                    selectedTrainingItems: payload,
                })
            )
            .addCase(
                loadSelectedTrainingItems.rejected,
                (state): TrainingCollectionState => ({
                    ...state,
                    isLoading: false,
                    selectedTrainingItems: trainingCollectionDefaultState.selectedTrainingItems,
                })
            )
            .addCase(
                addTrainingItems.pending,
                (state): TrainingCollectionState => ({
                    ...state,
                    isUpdating: true,
                })
            )
            .addCase(
                addTrainingItems.fulfilled,
                (state): TrainingCollectionState => ({
                    ...state,
                    error: false,
                    isUpdating: false,
                })
            )
            .addCase(
                addTrainingItems.rejected,
                (state): TrainingCollectionState => ({
                    ...state,
                    error: true,
                    isUpdating: false,
                })
            )
            .addCase(
                removeTrainingItems.pending,
                (state): TrainingCollectionState => ({
                    ...state,
                    isUpdating: true,
                })
            )
            .addCase(
                removeTrainingItems.fulfilled,
                (state): TrainingCollectionState => ({
                    ...state,
                    error: false,
                    isUpdating: false,
                })
            )
            .addCase(
                removeTrainingItems.rejected,
                (state): TrainingCollectionState => ({
                    ...state,
                    error: true,
                    isUpdating: false,
                })
            )
            .addCase(
                loadSynonymsAndExclusions.pending,
                (state): TrainingCollectionState => ({
                    ...state,
                    exclusions: null,
                    isLoading: true,
                    synonyms: null,
                })
            )
            .addCase(
                loadSynonymsAndExclusions.fulfilled,
                (state, { payload }): TrainingCollectionState => ({
                    ...state,
                    exclusions: payload?.payload?.exclusionKeywords?.split(', '),
                    isLoading: false,
                    synonyms: payload?.payload?.synonyms?.split(', '),
                })
            )
            .addCase(
                loadSynonymsAndExclusions.rejected,
                (state): TrainingCollectionState => ({
                    ...state,
                    exclusions: trainingCollectionDefaultState.exclusions,
                    isLoading: false,
                    synonyms: trainingCollectionDefaultState.synonyms,
                })
            );
    },
    initialState: trainingCollectionDefaultState,
    name: 'trainingCollection',
    reducers: {
        clearPotentialTrainingItems: (state) => ({
            ...state,
            potentialTrainingItems: trainingCollectionDefaultState.potentialTrainingItems,
            totalItemCount: trainingCollectionDefaultState.totalItemCount,
        }),
    },
});

export const { reducer: trainingCollectionReducer } = trainingCollectionSlice;
const { actions } = trainingCollectionSlice;
export const { clearPotentialTrainingItems } = actions;
