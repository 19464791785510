import { createSellerIncentive, fetchSellerIncentive } from './sellerIncentive.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultSellerIncentiveSlice } from './sellerIncentive.types';

const sellerIncentiveSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchSellerIncentive.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
            slice.success = false;
        });
        builder.addCase(fetchSellerIncentive.fulfilled, (slice, { payload }) => {
            slice.loading = false;
            slice.loaded = new Date();
            slice.error = false;
            slice.success = true;
            slice.amount = payload.amount;
            slice.houseId = payload.houseId;
            slice.percentage = payload.percentage;
            slice.type = payload.type;
        });
        builder.addCase(fetchSellerIncentive.rejected, (slice, { error }) => {
            slice.loading = false;
            slice.error = true;
            slice.success = false;
            slice.message = error.message;
        });
        builder.addCase(createSellerIncentive.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
            slice.success = false;
        });
        builder.addCase(createSellerIncentive.fulfilled, (slice, { payload }) => {
            slice.loading = false;
            slice.loaded = new Date();
            slice.error = false;
            slice.success = true;
            slice.amount = payload.amount;
            slice.houseId = payload.houseId;
            slice.percentage = payload.percentage;
            slice.type = payload.type;
            slice.marketplaceCode = payload.marketplaceCode;
            slice.active = payload.active;
        });
        builder.addCase(createSellerIncentive.rejected, (slice, { error }) => {
            slice.loading = false;
            slice.error = true;
            slice.message = error.message;
            slice.success = false;
        });
    },
    initialState: defaultSellerIncentiveSlice,
    name: 'sellerIncentiveSlice',
    reducers: {},
});

export const { reducer: sellerIncentiveReducer } = sellerIncentiveSlice;
