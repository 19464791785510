import {
    FetchTwoStepAuthenticationParams,
    FetchTwoStepAuthenticationResponse,
    UpdateTwoStepAuthenticationActivationParams,
    UpdateTwoStepAuthenticationActivationResponse,
} from './twoStepAuthentication.types';
import { handleResponseV2, makeGet, makePost } from '@/redux/api/helpers';

export const getTwoStepAuthenticationActivationStatus = ({
    authToken,
    bidderId,
    deployment,
}: FetchTwoStepAuthenticationParams) => {
    return new Promise<FetchTwoStepAuthenticationResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `mfa/bidders/${bidderId}/mfa/status`,
            authToken,
            deployment,
            path: '<AUTHENTICATION-API>',
        });
        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
};

export const postActivateTwoStepAuthentication = ({
    authToken,
    bidderId,
    deployment,
}: UpdateTwoStepAuthenticationActivationParams) => {
    return new Promise<UpdateTwoStepAuthenticationActivationResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `mfa/bidders/${bidderId}/enable-mfa`,
            authToken,
            deployment,
            path: '<AUTHENTICATION-API>',
        });
        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
};

export const postDeactivateTwoStepAuthentication = ({
    authToken,
    bidderId,
    deployment,
}: UpdateTwoStepAuthenticationActivationParams) => {
    return new Promise<UpdateTwoStepAuthenticationActivationResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `mfa/bidders/${bidderId}/disable-mfa`,
            authToken,
            deployment,
            path: '<AUTHENTICATION-API>',
        });
        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
};
