import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '../user';
import { getDeployment } from '../config';
import { getSellerIncentive, postSellerIncentive } from './sellerIncentive.api';
import {
    GetSellerIncentiveParams,
    GetSellerIncentiveResponse,
    PostSellerIncentiveParams,
    PostSellerIncentiveResponse,
} from './sellerIncentive.types';
import { openSellerIncentiveModal } from '../modal';
import { shouldFetchSellerIncentive } from './sellerIncentive.selectors';

export const createSellerIncentive = createAsyncThunk<
    PostSellerIncentiveResponse['payload'],
    PostSellerIncentiveParams,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/sellerIncentive/createSellerIncentive',
    async ({ active, amount, houseId, marketplaceCode, percentage, type }, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await postSellerIncentive({
            authToken,
            deployment,
            params: {
                active,
                amount,
                houseId,
                marketplaceCode,
                percentage,
                type,
            },
        });

        if (error) {
            return rejectWithValue(new Error(`Error adding incentive for house ${houseId}`));
        }

        await dispatch(fetchSellerIncentiveIfNeeded(Number(houseId)));
        dispatch(openSellerIncentiveModal());

        return payload;
    }
);

export const fetchSellerIncentive = createAsyncThunk<
    GetSellerIncentiveResponse['payload'],
    GetSellerIncentiveParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/sellerIncentive/fetchSellerIncentive', async ({ houseId }, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    const { error, payload } = await getSellerIncentive({ authToken, deployment, params: { houseId } });

    if (error) {
        return rejectWithValue(new Error(`Error fetching seller incentive for house ${houseId}}`));
    }

    return payload;
});

export const fetchSellerIncentiveIfNeeded = createAsyncThunk<void, number>(
    'la/ui/sellerIncentive/fetchSellerIncentiveIfNeeded',
    async (houseId, { dispatch, getState }) => {
        if (!Boolean(houseId) || isNaN(houseId)) {
            return;
        }

        const houseIdString = houseId.toString();

        const state = getState();

        if (shouldFetchSellerIncentive(state, houseId)) {
            await dispatch(fetchSellerIncentive({ houseId: houseIdString }));
        }
    }
);
