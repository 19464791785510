import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { loadBatchEnd, loadBatchStart } from './fetchBatch';
import api from '../api/item';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_ITEMS_FAIL = 'la/domain/items/LOAD_FAIL';
export const LOAD_ITEMS_REQUEST = 'la/domain/items/LOAD_REQUEST';
export const LOAD_ITEMS_SUCCESS = 'la/domain/items/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');
const FETCH_SET_SIZE = 128;

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_ITEMS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.itemIds),
            };
        case LOAD_ITEMS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_ITEMS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.items) {
                action.payload.items.forEach((item) => {
                    existing[item.itemId] = { ...item };
                    loaded[item.itemId] = time;
                    loading = difference(loading, [item.itemId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.item;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getItem = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

export const getLoadTimeForItem = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const isItemLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchItem = (state, itemId) => {
    if (!itemId) {
        return false;
    }
    const item = getItem(state, itemId);
    if (item) {
        const loaded = getLoadTimeForItem(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isItemLoading(state, itemId);
    return !loading;
};

const whichItemsNeeded = (state, itemIds) => itemIds.filter((itemId) => shouldFetchItem(state, itemId));

/* ACTION CREATORS */
const loadItems = (itemIds, loadingId, lastRound) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            payload: itemIds,
            type: LOAD_ITEMS_REQUEST,
        });
        const response = await api.fetchItemsByIds({ authToken, deployment, itemIds });
        dispatch({
            meta: { actionTime: Date.now(), itemIds },
            payload: response.data,
            type: LOAD_ITEMS_SUCCESS,
        });
        if (lastRound) {
            dispatch(loadBatchEnd(loadingId));
        }
    } catch (error) {
        dispatch({
            error: true,
            meta: { itemIds },
            payload: error,
            type: LOAD_ITEMS_FAIL,
        });
        dispatch(loadBatchEnd(loadingId));
    }
};

const loadItemsInSets = (ids, setSize, loadingId) => async (dispatch) => {
    if (ids.length > setSize) {
        const newSize = Math.min(8192, setSize * 2);
        return dispatch(loadItems(ids.slice(0, setSize), loadingId, false)).then(() => {
            dispatch(loadItemsInSets(ids.slice(setSize), newSize, loadingId));
        });
    }
    return dispatch(loadItems(ids, loadingId, true));
};

export const fetchItemsIfNeeded =
    (itemIds: number[], loadingId: string = 'none') =>
    async (dispatch: Function, getState: Function) => {
        const needed = whichItemsNeeded(getState(), itemIds);
        if (needed.length) {
            dispatch(loadBatchStart(loadingId));
            return dispatch(loadItemsInSets(needed, FETCH_SET_SIZE, loadingId));
        }
        return Promise.resolve();
    };
