import { BidderPaymentData } from '../../types/BidderPayment';
import { createSelector } from 'reselect';
import {
    FETCH_BIDDER_PAYMENT_DATA_FAILURE,
    FETCH_BIDDER_PAYMENT_DATA_FAILURE_ACTION,
    FETCH_BIDDER_PAYMENT_DATA_REQUEST,
    FETCH_BIDDER_PAYMENT_DATA_REQUEST_ACTION,
    FETCH_BIDDER_PAYMENT_DATA_SUCCESS,
    FETCH_BIDDER_PAYMENT_DATA_SUCCESS_ACTION,
} from './actions';
import { fetchBidderTransactionCaseStatus, getTransactionCaseStatuses } from './caseStatus';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { loadBidderPaymentData } from '../api/bidderPaymentData';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    error: {},
    loaded: {},
    loading: [],
};

type State = typeof DEFAULT_STATE & {
    byId: {
        [key: number]: BidderPaymentData;
    };
    error: {
        [key: number]: string;
    };
    loaded: {
        [key: number]: number;
    };
    loading: number[];
};

export const reducer = handleActions(
    {
        [FETCH_BIDDER_PAYMENT_DATA_FAILURE]: (state: State, action: FETCH_BIDDER_PAYMENT_DATA_FAILURE_ACTION) => ({
            ...state,
            loading: difference(state.loading, [action.meta.bidderId]),
        }),
        [FETCH_BIDDER_PAYMENT_DATA_REQUEST]: (state: State, action: FETCH_BIDDER_PAYMENT_DATA_REQUEST_ACTION) => ({
            ...state,
            loading: union(state.loading, [action.payload.bidderId]),
        }),
        [FETCH_BIDDER_PAYMENT_DATA_SUCCESS]: (state: State, action: FETCH_BIDDER_PAYMENT_DATA_SUCCESS_ACTION) => {
            const paymentData = action.payload;
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            existing[paymentData.bidderId] = { ...paymentData };
            loaded[paymentData.bidderId] = time;
            loading = difference(loading, [paymentData.bidderId]);

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.bidderPayment;
const idSelector = (state, id) => id;
const byIdSelector = createSelector(stateSelector, (state) => state.byId);
const errorSelector = createSelector(stateSelector, (state) => state.error);
const loadingSelector = createSelector(stateSelector, (state) => state.loading);
const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

export const getBidderPaymentData = createSelector(
    [byIdSelector, idSelector],
    (byId, id) =>
        byId[id] || {
            bankAccounts: [],
            bidderId: id,
            cards: [],
            customers: [],
            transactions: [],
        }
);

export const getBidderTransactionIds = createSelector([getBidderPaymentData], (paymentData) =>
    paymentData.transactions.map((t) => t.id)
);

export const getBidderPaymentError = createSelector([errorSelector, idSelector], (error, id) => error[id]);
export const getBidderPaymentLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.contains(id)
);
export const getBidderPaymentLoaded = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id]);
export const getBidderTransactionWithCaseStatus = createSelector(
    [getBidderPaymentData, getTransactionCaseStatuses],
    (bidderPaymentData, transactionCaseStatuses) => {
        const { transactions } = bidderPaymentData;
        let transactionsWithCaseStatus = transactions.map((obj) => {
            let data = transactionCaseStatuses.find((item) => item.transactionId === obj.id);
            if (!data) {
                data = { caseId: 0, caseStatus: 'NONE', transactionId: obj.id };
            }
            return { ...obj, caseId: data.caseId, caseStatus: data.caseStatus };
        });

        return transactionsWithCaseStatus;
    }
);

/* ACTION CREATORS */
type FetchBidderPaymentDataParams = {
    bidderId: number;
};
export const fetchBidderPaymentData =
    ({ bidderId }: FetchBidderPaymentDataParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            const requestAction: FETCH_BIDDER_PAYMENT_DATA_REQUEST_ACTION = {
                error: false,
                meta: null,
                payload: { bidderId },
                type: FETCH_BIDDER_PAYMENT_DATA_REQUEST,
            };
            dispatch(requestAction);

            const response = await loadBidderPaymentData({
                authToken,
                bidderId,
                deployment,
            });

            const successAction: FETCH_BIDDER_PAYMENT_DATA_SUCCESS_ACTION = {
                error: false,
                meta: { actionTime: Date.now(), bidderId },
                payload: response.payload,
                type: FETCH_BIDDER_PAYMENT_DATA_SUCCESS,
            };
            dispatch(successAction);
        } catch (error) {
            const failAction: FETCH_BIDDER_PAYMENT_DATA_FAILURE_ACTION = {
                error: true,
                meta: { bidderId },
                payload: error,
                type: FETCH_BIDDER_PAYMENT_DATA_FAILURE,
            };
            dispatch(failAction);
        }
    };

export const fetchBidderPaymentDataAndTransCaseStatus =
    ({ bidderId }: FetchBidderPaymentDataParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            await dispatch(fetchBidderPaymentData({ bidderId }));
            let transactionIds = getBidderTransactionIds(getState(), bidderId);
            let strBidderId = `${bidderId}`;
            await dispatch(fetchBidderTransactionCaseStatus({ bidderId: strBidderId, transactionIds }));
        } catch (error) {
            const failAction: FETCH_BIDDER_PAYMENT_DATA_FAILURE_ACTION = {
                error: true,
                meta: { bidderId },
                payload: error,
                type: FETCH_BIDDER_PAYMENT_DATA_FAILURE,
            };
            dispatch(failAction);
        }
    };
