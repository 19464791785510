import pickBy from 'lodash/pickBy';

export const getCategoryIdsFromPathname = (pathname: string = '') => {
    if (pathname === '/catalog/search') {
        return '0';
    }
    const categoryIds = pathname
        .split('/')
        .filter((x) => Number(x))
        .join(',');
    return categoryIds;
};

export const getIdFromPath = (path: string) => {
    if (!path) {
        return 0;
    }

    const pathString = `${path}`;
    const cleaned = pathString.replace(/^\/?(catalog(_gallery)?|item|auctioneer|catinfot|console|checkout)\/?/, '');
    let urlFrags = cleaned.split('_')[0];

    if (urlFrags.includes('/page')) {
        urlFrags = urlFrags.split('/')[0];
    }

    const idMatches = urlFrags.match(/^(\d+)\/?$/);
    return idMatches ? Number(idMatches[1]) || 0 : 0;
};

// https://stackoverflow.com/a/1714899/148256
// Returns a string from the object
export const toQueryString = (obj: any) => {
    const arr = [];

    /* eslint-disable-next-line no-unused-vars */
    for (const p in obj) {
        /* eslint-disable-next-line no-prototype-builtins */
        if (obj.hasOwnProperty(p)) {
            arr.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    }
    arr.sort();
    return arr.join('&');
};

export const sellerIdFromPath = (path: string) => {
    if (!path) {
        return 0;
    }
    let sellerId = Number(path);

    if (!sellerId) {
        sellerId = Number(path.split('-')[0]);
    }
    return sellerId;
};

export const logoUrl = 'https://p1.liveauctioneers.com/dist/images/logo_og.jpg?quality=50';
export const signupLadySmallUrl = 'https://p1.liveauctioneers.com/dist/images/SignupLadySmall.png?quality=50';

export const urlSafeTitle = (title = '') => {
    const safe = title
        .trim() // & to and
        .replace(/&/g, 'and') // comma to hyphen
        .replace(/,+/g, '-') // * to hyphen
        .replace(/\*+/g, '-') // ; to hyphen
        .replace(/;+/g, '-') // : to hyphen
        .replace(/:+/g, '-') // ' to hyphen
        .replace(/'+/g, '-') // " to hyphen
        .replace(/"+/g, '-')
        .replace(/-*\s+-*/g, '-') // non letter/digit/& to nothing
        .replace(/[^a-zA-Z0-9-_&]/g, '') // many hyphen to one
        .replace(/--+/g, '-')
        .toLowerCase()
        .replace(/-+$/, '');
    return safe;
};

export const setQueryParams = (base: string, params: any = {}) => {
    let cleanedBase = base.replace(/\?.*/, '');
    const queryString = toQueryString(params);
    return queryString === '' ? cleanedBase : `${cleanedBase}?${queryString}`;
};

const buildSearchUrl = (base: string, params?: any) => {
    const queryString = toQueryString(params);
    return queryString === '' ? base : `${base}?${queryString}`;
};

export const getTodayUrl = () => '/trending/';

const getCatalogSearchBaseUrl = () => '/catalog/search/';

export const getCatalogSearchUrlWithParams = (params?: any) => {
    const base = getCatalogSearchBaseUrl();
    return buildSearchUrl(base, params);
};

const getSearchBaseUrl = () => '/search/';
export const getSearchUrlWithParams = (params: any) => {
    const base = getSearchBaseUrl();
    return buildSearchUrl(base, params);
};

export const getCategoryUrlWithParams = (base: string, params: any) => {
    return buildSearchUrl(base, params);
};

export const getCategoryFacetLink = (base: string, params: any) => {
    base = base === '' || base === undefined ? '/c/' : `/c/${base}/`;
    return buildSearchUrl(base, params);
};

export const getAuctionResultsUrl = () => '/auction-results';
export const getAuctioneerDirectoryUrl = () => '/auctioneer/directory/';

export const getCatalogAdminUrl = (deployment: string, id: number) => {
    const subdomain = deployment === 'prod' ? 'classic' : `mainhost-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/auctioneers/C${id}.html`;
};

export const getBidderConsoleUrl = (deployment: string, id: number) => {
    const subdomain = deployment === 'prod' ? 'www' : `bidder-react-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/console/${id}`;
};

export const getHtmlClerkUrl = (deployment: string, id: number) => {
    const subdomain = deployment === 'prod' ? 'partners' : `hardboiled-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/clerk-console/${id}`;
};

export const getInvoiceRefundUrl = (deployment: string, houseId: number, invoiceId: number) => {
    const subdomain = deployment === 'prod' ? 'partners' : `hardboiled-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/house/${houseId}/invoice/${invoiceId}`;
};

export const getForgotPassword = (deployment: string) => {
    const subdomain = deployment === 'prod' ? 'partners' : `hardboiled-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/forgot`;
};

export const getCatalogConsoleUrl = (baseUrl: string, id: number) => `${baseUrl}/bidapplet${id}`;

export const getCatalogUrl = (deployment: string, id: number, title: string) => {
    const subdomain = deployment === 'prod' ? 'www' : `${deployment}-test`;
    return `https://${subdomain}.liveauctioneers.com/catalog/${id}_${urlSafeTitle(title)}/`;
};

export const getCategoryLandingPageBaseUrl = () => '/c/';
export const getCheckoutConfirmationUrl = (sellerId: number, itemId: number) => `/confirmation/${sellerId}/${itemId}`;
export const getConsignmentsUrl = () => '/consignments';
export const getCookiePolicyUrl = () => '/cookie-policy';
export const getFollowedSellersUrl = () => '/dashboard/followed-auctioneers';
export const getHomeUrl = () => '/';
export const getIntellectualPropertyUrl = () => '/intellectualproperty';

export const getItemUrl = (deployment: string, id: number, title: string) => {
    const subdomain = deployment === 'prod' ? 'www' : `${deployment}-test`;
    return `https://${subdomain}.liveauctioneers.com/item/${id}_${urlSafeTitle(title)}/`;
};

export const getLiveBiddingUrl = (catalogId: number) => `/console/${catalogId}`;
export const getMobileUrl = () => '/mobile';
export const getMyAuctionsUrl = () => '/dashboard/my-auctions';
export const getMyBidsUrl = () => '/dashboard/my-bids';
export const getNearMeUrl = () => '/find-auctions-near-me';
export const getNewCatalogConsoleUrl = (id: number, title: string) => `/console/${id}_${urlSafeTitle(title)}/`;
export const getNewsUrl = (baseUrl: string) => `${baseUrl}/news/`;
export const getNotFoundUrl = () => '/not-found';
export const getPriceGuideBaseUrl = () => '/price-guide/';
export const getPriceGuideUrl = (name: string, id: number) => `${getPriceGuideBaseUrl()}${name}/${id}/`;
export const getPrivacyUrl = () => '/privacy';
export const getProfileUrl = () => '/dashboard/profile';
export const getSavedItemsUrl = () => '/dashboard/saved-items';
export const getSavedSearchesUrl = (append: string | null | undefined) =>
    `/dashboard/search-and-alerts/${append ? `${append}/` : ''}`;
export const getSellPageUrl = (baseUrl: string) => `${baseUrl}/pages/sell/`;

export const getSellerAdminUrl = (deployment: string, id: number) => {
    const subdomain = deployment === 'prod' ? 'classic' : `mainhost-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/auctioneers/home-${id}.html`;
};

export const getSellerUrl = (deployment: string, id: number, title: string) => {
    const subdomain = deployment === 'prod' ? 'www' : `${deployment}-test`;
    return `https://${subdomain}.liveauctioneers.com/auctioneer/${id}/${urlSafeTitle(title)}/`;
};

export const getTerminologyUrl = () => '/terminology';
export const getTermsAndConditionsUrl = () => '/termsandconditions';
export const getWonItemsPageUrl = () => '/dashboard/won-items';

export const addImageQueryParams = (url: string, width: number) => {
    const correctImageUrl = url.replace('http://', 'https://');
    return `${correctImageUrl}?width=${width}`;
};

export const getAuctioneerLogoUrl = (auctioneer: string, width: number = 110) => {
    return `https://images.liveauctioneers.com/houses/logos/lg/${auctioneer}_large.jpg?width=${width}&quality=100`;
};

export const getAllUpcomingAuctionsUrl = () => getCatalogSearchUrlWithParams();

const generateImageUrl = (
    sellerId,
    catalogId,
    itemId,
    imgNumber,
    imageVersion,
    size,
    limitQuality = true,
    cropImage: string = ''
) => {
    if (!sellerId || !catalogId || !itemId || !imgNumber) {
        return '';
    }

    const quality = limitQuality ? '&quality=50' : '';
    const crop = cropImage ? `&crop=${cropImage}` : '';

    return `https://p1.liveauctioneers.com/${sellerId}/${catalogId}/${itemId}_${imgNumber}_x.jpg?version=${imageVersion}${
        size ? `&width=${size}` : ''
    }${quality}${crop}`;
};

const generateThumbnailImageUrl = (
    sellerId,
    catalogId,
    itemId,
    imgNumber,
    imageVersion,
    size,
    limitQuality = true,
    quality = 50,
    cropImage: string = ''
) => {
    if (!sellerId || !catalogId || !itemId || !imgNumber) {
        return '';
    }

    const imageQuality = limitQuality ? `&quality=${quality}` : '';
    const crop = cropImage ? `&crop=${cropImage}` : '';

    return `https://p1.liveauctioneers.com/${sellerId}/${catalogId}/${itemId}_${imgNumber}_x.jpg?version=${imageVersion}${
        size ? `&width=${size}` : ''
    }${imageQuality}${crop}`;
};

export const toQString = (obj: any) => {
    const arr = [];
    for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            arr.push(`${encodeURIComponent(p)}=${obj[p]}`);
        }
    }
    arr.sort();
    const joined = arr.join('&');
    return joined ? `?${joined}` : '';
};

export const getImageUrl = ({
    blur,
    catalogId,
    crop,
    height,
    imageNumber = 1,
    imageVersion = 1,
    itemId,
    quality,
    sellerId,
    width,
}: any) => {
    if (!sellerId || !catalogId || !itemId) {
        return '';
    }

    const query = pickBy({
        blur,
        crop,
        height,
        quality,
        width,
    });

    return `https://p1.liveauctioneers.com/${sellerId}/${catalogId}/${itemId}_${imageNumber}_x.jpg?version=${imageVersion}&${toQString(
        query
    )}`;
};

export const getThumbnailItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1,
    width: number = 128
) => generateThumbnailImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, width, true, 25);

export const getPlaceholderItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateThumbnailImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 612, true, 10);

export const getMediumItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 512);

export const getLargeItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 768);

export const getXLargeItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, false, true);

export const getItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1,
    width: number,
    limitQuality: boolean = true,
    crop: string = ''
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, width, limitQuality, crop);

export const getCoverLotSecondaryImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1,
    featured: boolean = false
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, featured ? 170 : 130);

export const getCoverLotSecondaryImageUrlLarge = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 202);

export const getCoverLotSecondaryImageUrlSmall = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 105);

export const getCuratorImageUrl = (baseUrl: string, curatorId: number, curatorImageFileName: string) =>
    `${baseUrl}/${curatorId}/${curatorImageFileName}`;

export const getItemCardImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 280);

export const getCoverLotPrimaryImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1,
    featured: boolean = false
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, featured ? 340 : 250);

export const getCoverLotPrimaryImageUrlLarge = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 410);

export const getCoverLotPrimaryImageUrlSmall = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 210);

export const getMegaNavImageUrlLarge = (category: string) =>
    `https://p1.liveauctioneers.com/dist/images/desktop-${category}.jpg?height=382&quality=50`;

export const getMegaNavImageUrlSmall = (category: string) =>
    `https://p1.liveauctioneers.com/dist/images/tablet-${category}.jpg?height=288&quality=50`;

export const getDeskSupportEmail = (name: string, email: string) => {
    const fields = [];
    name && fields.push(`interaction[name]=${encodeURIComponent(name)}`);
    email && fields.push(`interaction[email]=${email}`);

    return `https://liveauctioneers.desk.com/customer/portal/emails/new${fields.length ? `?${fields.join('&')}` : ''}`;
};

export const getAdQuestionUrl = () => 'https://www.surveymonkey.com/r/KFCCVSX';

export const getSearchPageMostPopular = () => getSearchUrlWithParams({ page: 1, sort: '-popularity' });
export const getSearchPageMostBids = () => getSearchUrlWithParams({ page: 1, sort: '-bidCount' });

const categoryLandingIds = {
    art: 1,
    asian: 158,
    collectibles: 2,
    fashion: 3,
    furniture: 4,
    'home-garden': 5,
    jewelry: 6,
};

export const getCategoryLandingPageUrlFromCategoryName = (category: string) => {
    const categoryId = categoryLandingIds[category];
    return categoryId
        ? `${getCategoryLandingPageBaseUrl()}${category}/${categoryId}/`
        : getCategoryLandingPageBaseUrl();
};

export const getCategoryPageUrlFromObject = (category: any) => {
    let url = getCategoryLandingPageBaseUrl();
    if (!category || !category.l1CategoryId) {
        return url;
    }
    url += `${category.l1CategoryUrl}/`;
    if (category?.l2CategoryId) {
        url += `${category.l2CategoryUrl}/${category.l1CategoryId}/${category.l2CategoryId}/`;
    } else {
        url += `${category.l1CategoryId}/`;
    }
    return url;
};

export const separateURL = (url: string) => {
    const match = url.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    if (match === null) {
        return {
            host: '',
            path: '',
        };
    }
    return {
        host: match[2],
        path: match[5],
    };
};

export const getSearchAuctioneerUrl = (sellerId: number) =>
    getSearchUrlWithParams({ house_id: `${sellerId}`, page: 1, sort: '-popularity' });

export const getSearchAuctioneerSoldUrl = (sellerId: number) =>
    getSearchUrlWithParams({ house_id: `${sellerId}`, page: 1, status: 'archive' });

export const getSimilarItemsUrl = (title: string = '', sellerId: number = 0) => {
    const keyword = title
        .split(/\s+/g)
        .map((word) => `"${word}"`)
        .join(' OR ')
        .replace(/"/g, '\\"');
    const params: any = { keyword, status: ['online', 'live', 'upcoming'] };
    if (sellerId) {
        params.house_id = sellerId.toString();
    }
    return getSearchUrlWithParams(params);
};

//Finance dashboard, these are in tab order left to right, for my sanity
export const getFinanceDashboardUrl = () => '/finance-dashboard';
export const getFinanceDashboardPaymentsUrl = () => `${getFinanceDashboardUrl()}/payments`;
export const getFinanceDashboardDisbursementsUrl = () => `${getFinanceDashboardUrl()}/disbursements`;
export const getFinanceDashboardHouseBillingUrl = () => `${getFinanceDashboardUrl()}/billing`;
export const getFinanceDashboardHouseDisbursementsUrl = () => `${getFinanceDashboardUrl()}/billing-payrix`;
export const getFinanceDashboardHouseAutopayUrl = () => `${getFinanceDashboardUrl()}/house-autopay`;
export const getFinanceDashboardReportingUrl = () => `${getFinanceDashboardUrl()}/reporting`;
export const getFinanceDashboardChargebackUrl = () => `${getFinanceDashboardUrl()}/chargeback`;
export const getFinanceDashboardInvoicingUrl = () => `${getFinanceDashboardUrl()}/invoicing`;
export const getFinanceDashboardToolsUrl = () => `${getFinanceDashboardUrl()}/tools`;
export const getFinanceDashboardRefundsUrl = () => `${getFinanceDashboardUrl()}/refunds`;

export const getHouseSettingsUrl = () => '/house-settings';

export const getHouseBetaUrl = () => `${getHouseSettingsUrl()}/house-beta`;
export const getHouseBetaUrlWithHouseId = (houseId: number) => `${getHouseBetaUrl()}/${houseId}`;

export const getGlobalATGIdUrl = () => `${getHouseSettingsUrl()}/global-atg-id`;
export const getGlobalATGIdUrlWithHouseId = (houseId: number) => `${getGlobalATGIdUrl()}/${houseId}`;

export const getShippingUrl = () => `${getHouseSettingsUrl()}/shipping`;
export const getShippingUrlWithHouseId = (houseId: number) => `${getShippingUrl()}/${houseId}`;

export const getWhiteLabelUrl = () => '/white-label';
export const getWhiteLabelUrlWithHouseId = (houseId: number) => `${getWhiteLabelUrl()}/${houseId}`;

export const getATGShipUrl = () => '/atg-ship';
export const getATGShipUrlWithItemId = (itemId: number) => `${getATGShipUrl()}/${itemId}`;

const getCategoryTaxonomyUrl = () => '/categorization/category-taxonomy';
export const getCategoryEditUrl = (currentCategoryId: number) =>
    `${getCategoryTaxonomyUrl()}/edit/${currentCategoryId}`;
export const getFullCategoryEditUrl = (deployment: string, categoryId: number) => {
    const subdomain = deployment === 'prod' ? 'support-tools' : `redesigned-winner-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com${getCategoryEditUrl(categoryId)}`;
};
export const getAddCategoryUrl = (currentCategoryId: number) => `${getCategoryTaxonomyUrl()}/add/${currentCategoryId}`;
export const getSuggestedCategoryUrl = (currentCategoryId: number) =>
    `${getCategoryTaxonomyUrl()}/suggested/${currentCategoryId}`;
export const getRelatedCategoryUrl = (currentCategoryId: number) =>
    `${getCategoryTaxonomyUrl()}/related/${currentCategoryId}`;
export const getDeleteCategoryUrl = (currentCategoryId: number) =>
    `${getCategoryTaxonomyUrl()}/delete/${currentCategoryId}`;
export const getMergeCategoryUrl = (currentCategoryId: number) =>
    `${getCategoryTaxonomyUrl()}/merge/${currentCategoryId}`;
export const getCombinationsCategoryUrl = (currentCategoryId: number) =>
    `${getCategoryTaxonomyUrl()}/combinations/${currentCategoryId}`;
export const changeCategoryUrl = (currentUrl: string, newCategoryId: number) =>
    currentUrl.replace(/\d+\/?$/, `${newCategoryId}`);

const getComboCategoryTaxonomyUrl = () => '/categorization/combination-category';
export const getComboCategoryEditUrl = (concatedFacetIDs: string) =>
    `${getComboCategoryTaxonomyUrl()}/edit/${concatedFacetIDs}`;
export const getComboSuggestedCategoryUrl = (concatedFacetIDs: string) =>
    `${getComboCategoryTaxonomyUrl()}/suggested/${concatedFacetIDs}`;
export const getComboRelatedCategoryUrl = (concatedFacetIDs: string) =>
    `${getComboCategoryTaxonomyUrl()}/related/${concatedFacetIDs}`;

export const getCoverLotUrls = (
    catalogId: number,
    coverLots: any,
    sellerId: number,
    firstImageHeight: number,
    secondImageHeight: number,
    thirdImageHeight: number
) => {
    const { results } = coverLots || {};
    const [item1 = {}, item2 = {}, item3 = {}] = results;

    const coverLotImgAlt1 = item1.title;
    const coverLotImgAlt2 = item2.title;
    const coverLotImgAlt3 = item3.title;

    const coverLotImgSrc1 = getImageUrl({
        catalogId,
        height: firstImageHeight,
        imageNumber: item1.imageNumber || 1,
        imageVersion: item1.imageVersion,
        itemId: item1.itemId,
        sellerId,
    });

    const coverLotImgSrc2 = getImageUrl({
        catalogId,
        height: secondImageHeight,
        imageNumber: item2.imageNumber || 1,
        imageVersion: item2.imageVersion,
        itemId: item2.itemId,
        sellerId,
    });

    const coverLotImgSrc3 = getImageUrl({
        catalogId,
        height: thirdImageHeight,
        imageNumber: item3.imageNumber || 1,
        imageVersion: item3.imageVersion,
        itemId: item3.itemId,
        sellerId,
    });

    return {
        primary: {
            imageAlt: coverLotImgAlt1,
            imageSrc: coverLotImgSrc1,
        },
        secondary1: {
            imageAlt: coverLotImgAlt2,
            imageSrc: coverLotImgSrc2,
        },
        secondary2: {
            imageAlt: coverLotImgAlt3,
            imageSrc: coverLotImgSrc3,
        },
    };
};
