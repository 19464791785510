import { CategorySuggestion } from '../../types/CategorySuggestion';
import { handleResponse, makeGet, makePost } from './helpers';

type GetCombinationRelatedSearchesByCatIDParams = {
    authToken: string;
    concatedIDs: string;
    deployment: string;
};

const getCombinationRelatedSearchesByConcatIDs = ({
    authToken,
    concatedIDs,
    deployment,
}: GetCombinationRelatedSearchesByCatIDParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationrelatedsearchesbyid',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ concatedFacetIds: concatedIDs });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostCombinationRelatedSearchParams = {
    authToken: string;
    deployment: string;
    relatedSearch: CategorySuggestion;
};

type DeleteCombinationRelatedSearchParams = {
    authToken: string;
    deployment: string;
    idToDelete: number;
};

const postCombinationRelatedSearch = ({ authToken, deployment, relatedSearch }: PostCombinationRelatedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'addcombinationrelatedsearch',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ ...relatedSearch });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const updateCombinationRelatedSearch = ({ authToken, deployment, relatedSearch }: PostCombinationRelatedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updatecombinationrelatedsearch',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ ...relatedSearch });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const deleteCombinationRelatedSearch = ({ authToken, deployment, idToDelete }: DeleteCombinationRelatedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletecombinationrelatedsearch',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ idToDelete });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    deleteCombinationRelatedSearch,
    getCombinationRelatedSearchesByConcatIDs,
    postCombinationRelatedSearch,
    updateCombinationRelatedSearch,
};
