import { AppDispatch, GlobalState } from '@/redux/store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

/**
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-usedispatch-hook
 * Use instead of `useAppDispatch`
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();
/**
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-usedispatch-hook
 * Use instead of `useAppSelector`
 */
export const useAppSelector: TypedUseSelectorHook<GlobalState> = useSelector;
