import ms from 'ms';

export const SELLER_INCENTIVE_SLICE_CACHE_TIME = ms('20s');

export type SellerIncentiveSlice = {
    active: boolean;
    amount: number;
    error: boolean;
    houseId: string;
    loaded?: Date;
    loading: boolean;
    marketplaceCode: string;
    message: string;
    percentage: number;
    success: boolean;
    type: SellerIncentiveType;
};

export enum SellerIncentiveType {
    Amount = 'amount',
    None = 'none',
    Percentage = 'percentage',
}

export const defaultSellerIncentiveSlice: SellerIncentiveSlice = {
    active: false,
    amount: 0,
    error: false,
    houseId: '0',
    loading: false,
    marketplaceCode: 'LA',
    message: '',
    percentage: 0,
    success: false,
    type: SellerIncentiveType.Amount,
};

export type PostSellerIncentiveParams = {
    active: boolean;
    amount: number;
    houseId: string;
    marketplaceCode: string;
    percentage: number;
    type: SellerIncentiveType;
};

export type PostSellerIncentiveResponse = {
    error: boolean;
    payload: {
        active: boolean;
        amount: number;
        houseId: string;
        marketplaceCode: string;
        percentage: number;
        type: SellerIncentiveType;
    };
};

export type GetSellerIncentiveParams = {
    houseId: string;
};

export type GetSellerIncentiveResponse = {
    error: boolean;
    payload: {
        amount: number;
        houseId: string;
        percentage: number;
        type: SellerIncentiveType;
    };
};
