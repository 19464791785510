/* eslint-disable import/prefer-default-export */
import * as api from '../api/support';
import { ActionWithPayload } from '@/types/redux';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/store';
import { handleActions } from 'redux-actions';
import { SET_CATALOG_ONLY_FAILURE, SET_CATALOG_ONLY_REQUEST, SET_CATALOG_ONLY_SUCCESS } from './actions';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    submitted: false,
    success: false,
};

type State = typeof DEFAULT_STATE & {
    error: string | null | undefined;
};

export const reducer = handleActions(
    {
        [SET_CATALOG_ONLY_FAILURE]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            error: action.payload,
            submitted: false,
            success: false,
        }),
        [SET_CATALOG_ONLY_REQUEST]: (state: State) => ({
            ...state,
            error: null,
            submitted: true,
            success: false,
        }),
        [SET_CATALOG_ONLY_SUCCESS]: (state: State) => ({
            ...state,
            error: null,
            submitted: false,
            success: true,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
// @ts-expect-error circular dependencies because everything's in one file
const stateSelector = (state: GlobalState): State => state.catalogOnly;

export const getCatalogOnlyState = createSelector(stateSelector, (state) => state);

export const getCatalogOnlySuccess = createSelector([stateSelector], (state) => state.success);

/* ACTION CREATORS */
type SubmitSetCatalogOnlyParams = { catalogId: number };
export const submitSetCatalogOnly =
    ({ catalogId }: SubmitSetCatalogOnlyParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: catalogId,
                type: SET_CATALOG_ONLY_REQUEST,
            });

            const response = await api.postSetAuctionType({
                authToken,
                catalogId: Number(catalogId),
                catalogType: 4,
                deployment,
            });
            dispatch({
                payload: response.payload,
                type: SET_CATALOG_ONLY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SET_CATALOG_ONLY_FAILURE,
            });
        }
    };
