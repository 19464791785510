import { annotateFacetExclusions, getUserExcludedFacets } from './searchExclusions';
import { createSelector } from 'reselect';
import { DEFAULT_ARCHIVE_STATUSES, DEFAULT_LIVE_STATUSES } from '../../utils/searchUtils';
import { getDeployment } from './config';
import api from '../api/search';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';

export const LOAD_FACETS_FAIL = 'LOAD_FACETS_FAIL';
export const LOAD_FACETS_REQUEST = 'LOAD_FACETS_REQUEST';
export const LOAD_UPCOMING_FACETS_SUCCESS = 'LOAD_UPCOMING_FACETS_SUCCESS';
export const LOAD_ARCHIVED_FACETS_SUCCESS = 'LOAD_ARCHIVED_FACETS_SUCCESS';

// reducer
const DEFAULT_STATE = {
    error: false,
    facets: {
        archived: [],
        upcoming: [],
    },
    submitted: false,
    success: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case LOAD_FACETS_REQUEST:
            return {
                ...state,
                error: false,
                submitted: true,
                success: false,
            };
        case LOAD_UPCOMING_FACETS_SUCCESS:
            return {
                ...state,
                facets: {
                    ...state.facets,
                    upcoming: action.payload.facets,
                },
                submitted: false,
                success: true,
            };
        case LOAD_ARCHIVED_FACETS_SUCCESS:
            return {
                ...state,
                facets: {
                    ...state.facets,
                    archived: action.payload.facets,
                },
                submitted: false,
                success: true,
            };
        case LOAD_FACETS_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.searchFacets;
const searchFacetsSelector = createSelector(stateSelector, (state) => state.facets);

export const getRawUpcomingSearchFacets = createSelector(
    searchFacetsSelector,
    getUserExcludedFacets,
    (facets, exclusions) => annotateFacetExclusions(facets.upcoming, exclusions)
);

export const getRawArchivedSearchFacets = createSelector(
    searchFacetsSelector,
    getUserExcludedFacets,
    (facets, exclusions) => annotateFacetExclusions(facets.archived, exclusions)
);

export const getRawSearchFacets = (state: any, archivedSearch: boolean) => {
    return archivedSearch ? getRawArchivedSearchFacets(state) : getRawUpcomingSearchFacets(state);
};

export function getArchivedAndUpcomingFacetValues(state: any, facetId: any) {
    function auctionHouseFilter(facet) {
        return facet.id === facetId;
    }
    let facets = [];
    let doneFacets = [];

    if (getRawUpcomingSearchFacets(state).length) {
        const filtered = getRawUpcomingSearchFacets(state).filter(auctionHouseFilter);
        if (filtered.length) {
            facets = getRawUpcomingSearchFacets(state).filter(auctionHouseFilter)[0].options;
        }
    }
    if (getRawArchivedSearchFacets(state).length) {
        const filtered = getRawArchivedSearchFacets(state).filter(auctionHouseFilter);
        if (filtered.length) {
            doneFacets = getRawArchivedSearchFacets(state).filter(auctionHouseFilter)[0].options;
        }
    }

    const uniq = uniqBy([...facets, ...doneFacets], (facet) => facet.id);
    return uniq.length ? cloneDeep(uniq) : [];
}

export function getSearchAuctionHouseFacetValues(state: any) {
    return getArchivedAndUpcomingFacetValues(state, 'auctionHouse');
}

export function getSearchCountryFacetValues(state: any) {
    return getArchivedAndUpcomingFacetValues(state, 'countryCode');
}

export const getFetchFacetsSubmitted = (state: any) => state.searchFacets.submitted;

const shouldFetchUpcomingFacets = (state) =>
    state.searchFacets.error ||
    (state.searchFacets.facets &&
        state.searchFacets.facets.upcoming &&
        state.searchFacets.facets.upcoming.length === 0);

const shouldFetchArchivedFacets = (state) =>
    state.searchFacets.error ||
    (state.searchFacets.facets &&
        state.searchFacets.facets.archived &&
        state.searchFacets.facets.archived.length === 0);

/* ACTION CREATORS */
const loadFacetsResultsFail = (error) => ({
    error: true,
    payload: error,
    type: LOAD_FACETS_FAIL,
});

const loadFacetsResultsRequest = () => ({
    type: LOAD_FACETS_REQUEST,
});

const loadUpcomingFacetsResultsSuccess = (results) => ({
    payload: results,
    type: LOAD_UPCOMING_FACETS_SUCCESS,
});

const loadArchivedFacetsResultsSuccess = (results) => ({
    payload: results,
    type: LOAD_ARCHIVED_FACETS_SUCCESS,
});

/* UTILITY FUNCTIONS */
const loadFacets = (archivedSearch) => {
    return (dispatch, getState) => {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch(loadFacetsResultsRequest());
        const searchStatus = archivedSearch ? DEFAULT_ARCHIVE_STATUSES : DEFAULT_LIVE_STATUSES;
        const searchQuery = {
            analyticsTags: ['redesigned-winner'],
            options: {
                status: searchStatus,
            },
        };

        return api
            .fetchSearchFacetsResults({ deployment, searchQuery })
            .then((results) => {
                if (results.error) {
                    dispatch(loadFacetsResultsFail(results.payload));
                } else {
                    const successResult = archivedSearch
                        ? loadArchivedFacetsResultsSuccess(results.payload)
                        : loadUpcomingFacetsResultsSuccess(results.payload);

                    // In a manual check, it works, I think we just need to type
                    // everything better to have this naturally go away.
                    // eslint-disable-next-line @liveauctioneers/la-react/dispatch-invoke
                    dispatch(successResult);
                }
            })
            .catch((error) => {
                dispatch(loadFacetsResultsFail(error));
            });
    };
};

export const fetchSearchFacetsIfNeeded = (archivedSearch: boolean) => {
    return (dispatch: Function, getState: Function) => {
        const state = getState();
        const shouldLoadFacets = archivedSearch ? shouldFetchArchivedFacets(state) : shouldFetchUpcomingFacets(state);
        if (shouldLoadFacets) {
            return dispatch(loadFacets(archivedSearch));
        }
        return Promise.resolve();
    };
};
