/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
    DELETE_COMBINATION_RELATED_SEARCH_SUCCESS,
    FETCH_COMBINATION_RELATED_SEARCHES_FAIL,
    FETCH_COMBINATION_RELATED_SEARCHES_START,
    FETCH_COMBINATION_RELATED_SEARCHES_SUCCESS,
    FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL,
    FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_START,
    FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS,
    SAVE_COMBINATION_RELATED_SEARCH_FAIL,
    SAVE_COMBINATION_RELATED_SEARCH_REQUEST,
    SAVE_COMBINATION_RELATED_SEARCH_SUCCESS,
} from './actions';
import { dismissModal } from './modal';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/combinationRelatedSearch';
import suggestedApi from '../api/suggestedCategory';

/* reducer */
const DEFAULT_STATE = {
    combinationRelatedSearches: [],
    fetchCombinationRelatedSearchError: '',
    fetchCombinationRelatedSearchLoading: false,
    saveCombinationRelatedSearchError: '',
    saveCombinationRelatedSearchLoading: false,
    saveCombinationRelatedSearchsuccess: false,
    taxonomyChildren: [],
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_COMBINATION_RELATED_SEARCH_FAIL:
            return {
                ...state,
                saveCombinationRelatedSearchError: action.payload,
                saveCombinationRelatedSearchLoading: false,
                saveCombinationRelatedSearchsuccess: false,
            };
        case SAVE_COMBINATION_RELATED_SEARCH_REQUEST:
            return {
                ...state,
                combinationRelatedSearches: [...state.combinationRelatedSearches, action.payload],
                saveCombinationRelatedSearchError: null,
                saveCombinationRelatedSearchLoading: true,
                saveCombinationRelatedSearchsuccess: false,
            };
        case SAVE_COMBINATION_RELATED_SEARCH_SUCCESS:
            return {
                ...state,
                combinationRelatedSearches: action.payload || [],
                saveCombinationRelatedSearchError: null,
                saveCombinationRelatedSearchLoading: false,
                saveCombinationRelatedSearchsuccess: true,
            };
        case FETCH_COMBINATION_RELATED_SEARCHES_START:
            return {
                ...state,
                combinationRelatedSearches: [],
                fetchCombinationRelatedSearchError: null,
                fetchCombinationRelatedSearchLoading: true,
            };
        case FETCH_COMBINATION_RELATED_SEARCHES_FAIL:
            return {
                ...state,
                combinationRelatedSearches: [],
                fetchCombinationRelatedSearchError: action.payload,
                fetchCombinationRelatedSearchLoading: false,
            };
        case FETCH_COMBINATION_RELATED_SEARCHES_SUCCESS:
            return {
                ...state,
                combinationRelatedSearches: action.payload || [],
                fetchCombinationRelatedSearchLoading: false,
            };
        case FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_START:
            return {
                ...state,
                fetchTaxonomyChildrenError: null,
                fetchTaxonomyChildrenLoading: true,
                taxonomyChildren: [],
            };
        case FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL:
            return {
                ...state,
                fetchTaxonomyChildrenError: action.payload,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: [],
            };
        case FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS:
            return {
                ...state,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: action.payload || [],
            };
        case DELETE_COMBINATION_RELATED_SEARCH_SUCCESS:
            return {
                ...state,
                combinationRelatedSearches: state.combinationRelatedSearches.length
                    ? state.combinationRelatedSearches.filter(
                          (related) => related.combinationRelatedSearchId !== action.payload
                      )
                    : [],
                fetchCombinationRelatedSearchLoading: false,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.combinationRelatedSearch;

export const combinationRelatedSearchesSelector = createSelector(
    stateSelector,
    (state) => state.combinationRelatedSearches
);
export const combinationRelatedSearchesTaxonomyChildrenOptionsSelector = createSelector(
    stateSelector,
    (state) => state.taxonomyChildren
);
export const combinationRelatedSearchErrorSelector = createSelector(
    stateSelector,
    (state) => state.saveCombinationRelatedSearchError
);
export const combinationRelatedSearchCaptureLoadingSelector = createSelector(
    stateSelector,
    (state) => state.saveCombinationRelatedSearchLoading
);

/* ACTION CREATORS */

export const getCombinationRelatedSearches =
    (concatedIDs: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: concatedIDs,
                type: FETCH_COMBINATION_RELATED_SEARCHES_START,
            });

            const response = await api.getCombinationRelatedSearchesByConcatIDs({
                authToken,
                concatedIDs,
                deployment,
            });

            dispatch({
                payload: response.payload,
                type: FETCH_COMBINATION_RELATED_SEARCHES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_COMBINATION_RELATED_SEARCHES_FAIL,
            });
        }
    };

export const saveCombinationRelatedSearch = (relatedSearch) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: relatedSearch,
            type: SAVE_COMBINATION_RELATED_SEARCH_REQUEST,
        });

        let response;
        if (Boolean(relatedSearch.combinationRelatedSearchId) || Boolean(relatedSearch.relatedSearchId)) {
            response = await api.updateCombinationRelatedSearch({
                authToken,
                deployment,
                relatedSearch,
            });
        } else {
            response = await api.postCombinationRelatedSearch({
                authToken,
                deployment,
                relatedSearch,
            });
        }

        dispatch({
            payload: response.payload,
            type: SAVE_COMBINATION_RELATED_SEARCH_SUCCESS,
        });
        dispatch(dismissModal());
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_COMBINATION_RELATED_SEARCH_FAIL,
        });
    }
};

export const deleteCombinationRelatedSearch =
    (combinationRelatedSearchId) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            await api.deleteCombinationRelatedSearch({
                authToken,
                deployment,
                idToDelete: combinationRelatedSearchId,
            });

            dispatch({
                payload: combinationRelatedSearchId,
                type: DELETE_COMBINATION_RELATED_SEARCH_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SAVE_COMBINATION_RELATED_SEARCH_FAIL,
            });
        }
    };

export const getCategoriesTaxonomyChildren = (searchTerm: string) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: -1,
            type: FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_START,
        });

        const response = await suggestedApi.getTaxonomyCategoryChildrenByString({
            authToken,
            deployment,
            searchTerm,
        });

        const taxonomyOptions = response.payload.map((option) => {
            return {
                label: `${option.categoryId} - ${option.categoryName}`,
                value: `${option.categoryId} - ${option.categoryName}`,
            };
        });

        dispatch({
            payload: taxonomyOptions,
            type: FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL,
        });
    }
};
