import { createSelector } from 'reselect';
import { TrainingInfo } from '@/pages/Categorization/CategoryTrainingDashboard';
import { TrainingState } from './training.types';

// TODO: Type state param as global state type when type type is created
const stateSelector = (state): TrainingState => state.training;

export const existingTrainingDataSelector = createSelector(stateSelector, (state) => state.existingTrainingData);

export const getTrainingDataSelectorByCategoryName = (categoryName: string) =>
    createSelector(existingTrainingDataSelector, (existingTrainingData): TrainingInfo | undefined =>
        existingTrainingData.find((data) => data.name.category === categoryName)
    );
