import { createSelector } from 'reselect';
import { getDeployment } from './config';
import { postSpaAuctioneerLogin } from '../api/auth';
import { push } from 'connected-react-router';

/* Action Types */
export const LOG_OUT = 'LOG_OUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    error: false,
    errorMessage: '',
    loading: false,
    success: false,
};

type State = typeof DEFAULT_STATE;

export default function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case LOGIN_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                loading: false,
                success: false,
            };
        case LOGIN_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: '',
                loading: true,
                success: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                error: false,
                errorMessage: '',
                loading: false,
                success: true,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.login;

export const getLoginState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
export const submitLogout = () => ({
    type: LOG_OUT,
});

const submitLoginFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: LOGIN_FAIL,
});

export const submitLogin =
    (username: string, password: string, referrer?: any) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            dispatch({
                payload: username,
                type: LOGIN_REQUEST,
            });
            const response = await postSpaAuctioneerLogin({
                deployment,
                password,
                username,
            });

            if (response && response.data && response.data.type !== 'admin') {
                dispatch(submitLoginFail('Unauthorized'));
            } else {
                dispatch({
                    payload: response.data,
                    type: LOGIN_SUCCESS,
                });
                dispatch(push(referrer || { pathname: '/' }));
            }
        } catch (error) {
            if (error === 'Failed to get the login.') {
                dispatch(submitLoginFail('Invalid username or password.'));
            } else {
                dispatch(submitLoginFail(error));
            }
        }
    };
