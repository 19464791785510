import { handleResponse, makeGet, makePost } from './helpers';

type LoadAuctionServerStatusParams = {
    authToken: string;
    deployment: string;
};

export const loadAuctionServerStatus = ({ authToken, deployment }: LoadAuctionServerStatusParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'auctionserverstatus', authToken, deployment, path: '<SUPPORT-API>' });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type LoadBidderNotesParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

export const loadBidderNotes = ({ authToken, bidderId, deployment }: LoadBidderNotesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'getbiddernotes', authToken, deployment, path: '<SUPPORT-API>' });
        request.query({ id: bidderId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type LoadCategoryItemsParams = {
    authToken: string;
    deployment: string;
};

export const loadCategoryItems = ({ authToken, deployment }: LoadCategoryItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'categorymodule', authToken, deployment, path: '<SUPPORT-API>' });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type LoadCustomSectionParams = {
    authToken: string;
    customSectionId: number;
    deployment: string;
};

export const loadCustomSection = ({ authToken, customSectionId, deployment }: LoadCustomSectionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'getcustomsection', authToken, deployment, path: '<SUPPORT-API>' });
        request.query({ customSectionId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostCategoryItemParams = {
    authToken: string;
    deployment: string;
    endDate: string;
    id: number;
    imageUrl: string;
    label: string;
    link: string;
    sequence: number;
    startDate: string;
    title: string;
};

export const postCategoryItem = ({
    authToken,
    deployment,
    endDate,
    id,
    imageUrl,
    label,
    link,
    sequence,
    startDate,
    title,
}: PostCategoryItemParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'categorymodule', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({
            endDate,
            id,
            imageUrl,
            label,
            link,
            sequence,
            startDate,
            title,
        });

        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostChangeCatalogServerParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    serverId: number;
};

export const postChangeCatalogServer = ({
    authToken,
    catalogId,
    deployment,
    serverId,
}: PostChangeCatalogServerParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'changecatalogserver', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({ catalogId, serverId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostDeleteCategoryItemParams = {
    authToken: string;
    categoryId: number;
    deployment: string;
};

export const postDeleteCategoryItem = ({ authToken, categoryId, deployment }: PostDeleteCategoryItemParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'deletecategory', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({ categoryId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostMarkJ52ItemPaidParams = {
    authToken: string;
    deployment: string;
    itemId: number;
};

export const postMarkJ52ItemPaid = ({ authToken, deployment, itemId }: PostMarkJ52ItemPaidParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'markj52itemaspaid', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({ id: itemId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRemoveBINItemsParams = {
    authToken: string;
    deployment: string;
    itemIds: number[];
};

export const postRemoveBINItems = ({ authToken, deployment, itemIds }: PostRemoveBINItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'lots/remove', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ lotIds: itemIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostAddBINItemsParams = {
    authToken: string;
    deployment: string;
    itemIds: number[];
};

export const postAddBINItems = ({ authToken, deployment, itemIds }: PostAddBINItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'lots/add', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ lotIds: itemIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostAddCatalogBINParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const postAddCatalogBIN = ({ authToken, catalogId, deployment }: PostAddCatalogBINParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'catalog/add', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ catalogId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRemoveCatalogBINParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const postRemoveCatalogBIN = ({ authToken, catalogId, deployment }: PostRemoveCatalogBINParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'catalog/remove', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ catalogId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetBINHouseParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export const getBINHouse = ({ authToken, deployment, houseId }: GetBINHouseParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'house/get', authToken, deployment, path: '<BUYNOW-API>' });
        request.query({ houseId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSetBINHouseParams = {
    authToken: string;
    defaultListingDays: number;
    deployment: string;
    houseId: number;
};

export const postSetBINHouse = ({ authToken, defaultListingDays, deployment, houseId }: PostSetBINHouseParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'house/set', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ defaultListingDays, houseId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRemoveBINHouseParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export const postRemoveBINHouse = ({ authToken, deployment, houseId }: PostRemoveBINHouseParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'house/remove', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ houseId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRemoveJ52ItemParams = {
    authToken: string;
    deployment: string;
    itemId: number;
};

export const postRemoveJ52Item = ({ authToken, deployment, itemId }: PostRemoveJ52ItemParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'removej52item', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({ id: itemId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRemoveListingAgentBINItemsParams = {
    authToken: string;
    deployment: string;
    listingAgentId: number;
};

export const postRemoveListingAgentBINItems = ({
    authToken,
    deployment,
    listingAgentId,
}: PostRemoveListingAgentBINItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'listingagent/remove', authToken, deployment, path: '<BUYNOW-API>' });
        request.send({ agentId: listingAgentId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRenumberLotsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const postRenumberLots = ({ authToken, catalogId, deployment }: PostRenumberLotsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'renumberlots', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({ catalogId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSetAuctionTypeParams = {
    authToken: string;
    catalogId: number;
    catalogType: number;
    deployment: string;
};

export const postSetAuctionType = ({ authToken, catalogId, catalogType, deployment }: PostSetAuctionTypeParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'setauctiontype', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({ catalogId, catalogType });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSetBidderNotesParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    note: string;
    username: string;
};

export const postSetBidderNotes = ({ authToken, bidderId, deployment, note, username }: PostSetBidderNotesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'setbiddernotes', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({
            bidderId,
            comment: note,
            commenter: username,
            parentCommentId: 0,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSetCatalogTimedParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    isTimed: boolean;
    serverId: number;
};

export const postSetCatalogTimed = ({
    authToken,
    catalogId,
    deployment,
    isTimed,
    serverId,
}: PostSetCatalogTimedParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'setcatalogtimed', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({
            catalogId,
            isTimed,
            serverId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostUpdateCustomSectionParams = {
    authToken: string;
    customSectionId: number;
    customText: string;
    deployment: string;
};

export const postUpdateCustomSection = ({
    authToken,
    customSectionId,
    customText,
    deployment,
}: PostUpdateCustomSectionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'updatecustomsection', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({
            customSectionId,
            customText,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostResetSaleParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    saleStartDate: string;
};

export const postResetSale = ({ authToken, catalogId, deployment, saleStartDate }: PostResetSaleParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'resetauction', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({
            catalogId,
            startDate: saleStartDate,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const postMoveLiveSaleToFutureDate = ({
    authToken,
    catalogId,
    deployment,
    saleStartDate,
}: PostResetSaleParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'setliveauctionstart', authToken, deployment, path: '<SUPPORT-API>' });
        request.send({
            catalogId,
            startDate: saleStartDate,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
