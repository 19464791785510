import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const GET_BIN_HOUSE = 'GET_BIN_HOUSE';
export const GET_BIN_HOUSE_SUCCESS = 'GET_BIN_HOUSE_SUCCESS';
export const GET_BIN_HOUSE_ERROR = 'GET_BIN_HOUSE_ERROR';
export const SET_BIN_HOUSE = 'SET_BIN_HOUSE';
export const SET_BIN_HOUSE_SUCCESS = 'SET_BIN_HOUSE_SUCCESS';
export const SET_BIN_HOUSE_ERROR = 'SET_BIN_HOUSE_ERROR';
export const REMOVE_BIN_HOUSE = 'REMOVE_BIN_HOUSE';
export const REMOVE_BIN_HOUSE_SUCCESS = 'REMOVE_BIN_HOUSE_SUCCESS';
export const REMOVE_BIN_HOUSE_ERROR = 'REMOVE_BIN_HOUSE_ERROR';
export const SET_BIN_HOUSE_INPUT_VALUES = 'SET_BIN_HOUSE_INPUT_VALUES';

/* reducer */
const DEFAULT_STATE = {
    defaultListingDays: 0,
    getError: null,
    getLoading: false,
    houseId: 0,
    isBINHouse: false,
    removeSuccess: false,
    setError: null,
    setLoading: false,
    setSuccess: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case GET_BIN_HOUSE:
            return {
                ...DEFAULT_STATE,
                getLoading: true,
            };

        case GET_BIN_HOUSE_SUCCESS:
            return {
                ...DEFAULT_STATE,
                defaultListingDays: action.payload.defaultListingDays || 0,
                houseId: action.payload.houseId || 0,
                isBINHouse: action.payload.isBINHouse,
            };

        case GET_BIN_HOUSE_ERROR:
            return {
                ...DEFAULT_STATE,
                getError: action.payload,
            };

        case SET_BIN_HOUSE:
            return {
                ...state,
                removeSuccess: false,
                setError: null,
                setLoading: true,
                setSuccess: false,
            };

        case SET_BIN_HOUSE_INPUT_VALUES:
            return {
                ...state,
                defaultListingDays: action.payload.defaultListingDays,
                houseId: action.payload.houseId,
            };

        case SET_BIN_HOUSE_SUCCESS:
            return {
                ...state,
                removeSuccess: false,
                setError: null,
                setLoading: false,
                setSuccess: true,
            };

        case SET_BIN_HOUSE_ERROR:
            return {
                ...state,
                removeSuccess: false,
                setError: action.payload,
                setLoading: false,
                setSuccess: false,
            };

        case REMOVE_BIN_HOUSE:
            return {
                ...state,
                removeSuccess: false,
                setError: null,
                setLoading: true,
                setSuccess: false,
            };

        case REMOVE_BIN_HOUSE_SUCCESS:
            return {
                ...state,
                removeSuccess: true,
                setError: null,
                setLoading: false,
                setSuccess: false,
            };

        case REMOVE_BIN_HOUSE_ERROR:
            return {
                ...state,
                removeSuccess: false,
                setError: action.payload,
                setLoading: false,
                setSuccess: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.binHouse;

export const getSetBINHouseError = createSelector(stateSelector, (state) => state.setError);
export const getBINHouseError = createSelector(stateSelector, (state) => state.getError);

export const getSetBINHouseLoading = createSelector(stateSelector, (state) => state.setLoading);
export const getBINHouseLoading = createSelector(stateSelector, (state) => state.getLoading);

export const getSetSucces = createSelector(stateSelector, (state) => state.setSuccess);
export const getRemoveSuccess = createSelector(stateSelector, (state) => state.removeSuccess);

export const getBINHouseId = createSelector(stateSelector, (state) => state.houseId);
export const getBINHouseDefaultListingDays = createSelector(stateSelector, (state) => state.defaultListingDays);
export const getIsBINHouse = createSelector(stateSelector, (state) => state.isBINHouse);

/* ACTION CREATORS */
export const setBINHouseInputValues = (houseId: number, defaultListingDays: number) => async (dispatch: Function) => {
    dispatch({
        payload: { defaultListingDays, houseId },
        type: SET_BIN_HOUSE_INPUT_VALUES,
    });
};

export const getBINHouse = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: houseId,
            type: GET_BIN_HOUSE,
        });

        const response = await api.getBINHouse({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            meta: { houseId },
            payload: response.payload,
            type: GET_BIN_HOUSE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: GET_BIN_HOUSE_ERROR,
        });
    }
};

export const setBINHouse =
    (houseId: number, defaultListingDays: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: { defaultListingDays, houseId },
                type: SET_BIN_HOUSE,
            });

            const response = await api.postSetBINHouse({
                authToken,
                defaultListingDays,
                deployment,
                houseId,
            });
            dispatch({
                meta: { defaultListingDays, houseId },
                payload: response.payload,
                type: SET_BIN_HOUSE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: SET_BIN_HOUSE_ERROR,
            });
        }
    };

export const removeBINHouse = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: { houseId },
            type: REMOVE_BIN_HOUSE,
        });

        const response = await api.postRemoveBINHouse({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            meta: { houseId },
            payload: response.payload,
            type: REMOVE_BIN_HOUSE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: REMOVE_BIN_HOUSE_ERROR,
        });
    }
};
