import { handleResponse, makeGet } from './helpers';

type GetAdminChargebackRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    keyword: string;
    page: number;
    pageSize: number | 'all';
    to: string;
};

export const getAdminChargebackRecords = ({
    authToken,
    deployment,
    from,
    keyword,
    page,
    pageSize,
    to,
}: GetAdminChargebackRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: 'admin/chargebackrecords', authToken, deployment, path: '<PAYMENT-API>' });
        request.query({
            from,
            keyword,
            page,
            pageSize,
            to,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
