export const LOAD_FEATURED_AUCTIONEERS_FAILURE = 'LOAD_FEATURED_AUCTIONEERS_FAILURE';
export const LOAD_FEATURED_AUCTIONEERS_REQUEST = 'LOAD_FEATURED_AUCTIONEERS_REQUEST';
export const LOAD_FEATURED_AUCTIONEERS_SUCCESS = 'LOAD_FEATURED_AUCTIONEERS_SUCCESS';

export const POST_FEATURED_AUCTIONEERS_FAILURE = 'POST_FEATURED_AUCTIONEERS_FAILURE';
export const POST_FEATURED_AUCTIONEERS_REQUEST = 'POST_FEATURED_AUCTIONEERS_REQUEST';
export const POST_FEATURED_AUCTIONEERS_SUCCESS = 'POST_FEATURED_AUCTIONEERS_SUCCESS';

export const DELETE_FEATURED_AUCTIONEERS_FAILURE = 'DELETE_FEATURED_AUCTIONEERS_FAILURE';
export const DELETE_FEATURED_AUCTIONEERS_REQUEST = 'DELETE_FEATURED_AUCTIONEERS_REQUEST';
export const DELETE_FEATURED_AUCTIONEERS_SUCCESS = 'DELETE_FEATURED_AUCTIONEERS_SUCCESS';

export const EDIT_FEATURED_AUCTIONEERS_FAILURE = 'EDIT_FEATURED_AUCTIONEERS_FAILURE';
export const EDIT_FEATURED_AUCTIONEERS_REQUEST = 'EDIT_FEATURED_AUCTIONEERS_REQUEST';
export const EDIT_FEATURED_AUCTIONEERS_SUCCESS = 'EDIT_FEATURED_AUCTIONEERS_SUCCESS';
