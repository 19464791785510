import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import { postDeleteBidderCards } from './bidderCards.api';
import { PostDeleteBidderCardsParams, PostDeleteBidderCardsResponse } from './bidderCards.types';

export const deleteBidderCards = createAsyncThunk<
    PostDeleteBidderCardsResponse['payload'],
    PostDeleteBidderCardsParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/bidderCards/deleteBidderCards', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    try {
        const { error, payload } = await postDeleteBidderCards({ authToken, deployment, params });

        if (error) {
            throw error;
        }
        return payload;
    } catch (error) {
        return rejectWithValue(new Error('There was an error deleting the selected card/cards'));
    }
});
