import { handleResponse, makeGet, makePost } from './helpers';
import { WhiteLabelStyles } from '@/types/WhiteLabel';

type FetchWhiteLabelStylesByHouseIdParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type FetchWhiteLabelStylesResponse = {
    error: boolean;
    payload: WhiteLabelStyles;
};

export const fetchWhiteLabelStylesByHouseId = ({
    authToken,
    deployment,
    houseId,
}: FetchWhiteLabelStylesByHouseIdParams) =>
    new Promise<FetchWhiteLabelStylesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'styles',
            authToken,
            deployment,
            path: '<WHITE-LABEL-API>',
        });
        request.query({ houseId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type UpdateWhiteLabelStylesParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    whiteLabelStyles: WhiteLabelStyles;
};

export const updateWhiteLabelStyles = ({
    authToken,
    deployment,
    houseId,
    whiteLabelStyles,
}: UpdateWhiteLabelStylesParams) =>
    new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'styles',
            authToken,
            deployment,
            path: '<WHITE-LABEL-API>',
        });
        request.query({ houseId });
        request.send(whiteLabelStyles);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
