export type PostDeleteBidderCardsParams = {
    bidderId: number;
    cardIds: number[];
};

export type PostDeleteBidderCardsResponse = {
    error: boolean;
    payload: string;
};

export type BidderCardsState = {
    error: boolean;
    loading: boolean;
    submitted: boolean;
};

export const defaultBidderCardState: BidderCardsState = {
    error: false,
    loading: false,
    submitted: false,
};
