import { getAuthToken } from './user';
import { getDeployment } from './config';
import { getSavedSearchId, submitIsSavedSearchCheckAdmin } from './saveSearchCheckAdmin';
import api from '../api/saveSearch';

/* Action Types */
export const SAVE_SEARCH_ADMIN_FAIL = 'la/ui/saveSearchAdmin/FAIL';
export const SAVE_SEARCH_ADMIN_REQUEST = 'la/ui/saveSearchAdmin/REQUEST';
export const SAVE_SEARCH_ADMIN_SUCCESS = 'la/ui/saveSearchAdmin/SUCCESS';

export const DELETE_SAVED_SEARCH_ADMIN_FAIL = 'la/ui/deleteSavedSearchAdmin/FAIL';
export const DELETE_SAVED_SEARCH_ADMIN_REQUEST = 'la/ui/deleteSavedSearchAdmin/REQUEST';
export const DELETE_SAVED_SEARCH_ADMIN_SUCCESS = 'la/ui/deleteSavedSearchAdmin/SUCCESS';

// reducer
const DEFAULT_STATE = {
    error: false,
    submitted: false,
    success: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_SEARCH_ADMIN_REQUEST:
        case DELETE_SAVED_SEARCH_ADMIN_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SAVE_SEARCH_ADMIN_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case DELETE_SAVED_SEARCH_ADMIN_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case SAVE_SEARCH_ADMIN_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
export const getSaveSearchAdminState = (state: any) => state.saveSearchAdmin;

/* ACTION CREATORS */
export const deleteSavedSearchAdmin =
    (ssParams: any, bidderId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const savedSearchId = getSavedSearchId(state);
            dispatch({
                type: DELETE_SAVED_SEARCH_ADMIN_REQUEST,
            });
            const response = await api.postDeleteSavedSearchAdmin({ authToken, bidderId, deployment, savedSearchId });
            dispatch({ payload: response.payload, type: DELETE_SAVED_SEARCH_ADMIN_SUCCESS });
            dispatch(submitIsSavedSearchCheckAdmin(ssParams, bidderId));
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: DELETE_SAVED_SEARCH_ADMIN_FAIL,
            });
            dispatch(submitIsSavedSearchCheckAdmin(ssParams, bidderId));
        }
    };

export const submitSaveSearchAdmin =
    (ssParams: any, sendAlert: boolean, bidderId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: SAVE_SEARCH_ADMIN_REQUEST,
            });

            const response = await api.postSaveSearchAdmin({ authToken, bidderId, deployment, sendAlert, ssParams });
            dispatch({
                meta: { bidderId },
                payload: response,
                type: SAVE_SEARCH_ADMIN_SUCCESS,
            });
            dispatch(submitIsSavedSearchCheckAdmin(ssParams, bidderId));
        } catch (error) {
            dispatch({
                error: true,
                meta: { bidderId },
                payload: error,
                type: SAVE_SEARCH_ADMIN_FAIL,
            });
            dispatch(submitIsSavedSearchCheckAdmin(ssParams, bidderId));
        }
    };
