import cloneDeep from 'lodash/cloneDeep';

/* Action Types */
export const SET_FACET_EXCLUSION_FAIL = 'la/domain/bidderData/SET_FACET_EXCLUSION_FAIL';
export const SET_FACET_EXCLUSION_REQUEST = 'la/domain/bidderData/SET_FACET_EXCLUSION_REQUEST';
export const SET_FACET_EXCLUSION_SUCCESS = 'la/domain/bidderData/SET_FACET_EXCLUSION_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    excludedFacets: {
        auctionHouse: [],
    },
    loaded: 0,
    loading: false,
};

export const annotateFacetExclusions = (facets: any = [], exclusions: any = {}) =>
    facets.map((facet) =>
        !exclusions[facet.id]
            ? facet
            : {
                  ...facet,
                  excludable: true,
                  options: facet.options.map((option) => ({
                      ...option,
                      excluded: exclusions[facet.id].includes(option.id),
                  })),
              }
    );

export const getUserExcludedFacets = () => cloneDeep(DEFAULT_STATE.excludedFacets);
