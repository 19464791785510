import { createSelector } from 'reselect';
import {
    emptyShippingQuote,
    SHIPPING_QUOTE_SLICE_CACHE_TIME,
    ShippingQuoteError,
    ShippingQuoteSlice,
} from './shippingQuote.types';
import { GlobalState } from '@/redux/store';

const getShippingQuoteSlice = ({ shippingQuote }: GlobalState): ShippingQuoteSlice => shippingQuote;
const getItemId = (_: GlobalState, itemId: number) => itemId;

const getIsShippingQuoteLoading = createSelector(
    [getShippingQuoteSlice, getItemId],
    ({ loading }, itemId) => loading.indexOf(itemId) >= 0
);

const getShippingQuoteLoadTime = createSelector(
    [getShippingQuoteSlice, getItemId],
    ({ loaded }, itemId) => loaded[itemId] ?? 0
);

export const getShippingQuoteByItemId = createSelector(
    [getShippingQuoteSlice, getItemId],
    ({ byId }, itemId) => byId[itemId] || emptyShippingQuote
);

export const getShouldFetchShippingQuote = createSelector(
    [getIsShippingQuoteLoading, getShippingQuoteLoadTime],
    (loading, loaded) => {
        if (loading) {
            return false;
        }
        const timeSinceLastLoad = Date.now() - loaded;
        return !loaded || timeSinceLastLoad > SHIPPING_QUOTE_SLICE_CACHE_TIME;
    }
);

export const getShippingQuoteSuccess = createSelector([getShippingQuoteSlice], ({ result }) => result === 'success');

export const getShippingQuoteFailure = createSelector([getShippingQuoteSlice], ({ result }) => result === 'failure');

export const getShippingQuoteError = createSelector([getShippingQuoteSlice], ({ error }) => error);

export const getShowQuoteError = createSelector(
    [getShippingQuoteError],
    (error) => error === ShippingQuoteError.MissingDimensionsOrOversized || error === ShippingQuoteError.PartnerError
);
