/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
    DELETE_RELATED_SEARCH_SUCCESS,
    FETCH_RELATED_SEARCHES_FAIL,
    FETCH_RELATED_SEARCHES_START,
    FETCH_RELATED_SEARCHES_SUCCESS,
    FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL,
    FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_START,
    FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS,
    SAVE_RELATED_SEARCH_FAIL,
    SAVE_RELATED_SEARCH_REQUEST,
    SAVE_RELATED_SEARCH_SUCCESS,
} from './actions';
import { dismissModal } from './modal';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/relatedSearch';
import suggestedApi from '../api/suggestedCategory';

/* reducer */
const DEFAULT_STATE = {
    fetchRelatedSearchError: '',
    fetchRelatedSearchLoading: false,
    relatedSearches: [],
    saveRelatedSearchError: '',
    saveRelatedSearchLoading: false,
    saveRelatedSearchsuccess: false,
    taxonomyChildren: [],
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_RELATED_SEARCH_FAIL:
            return {
                ...state,
                saveRelatedSearchError: action.payload,
                saveRelatedSearchLoading: false,
                saveRelatedSearchsuccess: false,
            };
        case SAVE_RELATED_SEARCH_REQUEST:
            return {
                ...state,
                relatedSearches: [...state.relatedSearches, action.payload],
                saveRelatedSearchError: null,
                saveRelatedSearchLoading: true,
                saveRelatedSearchsuccess: false,
            };
        case SAVE_RELATED_SEARCH_SUCCESS:
            return {
                ...state,
                relatedSearches: action.payload || [],
                saveRelatedSearchError: null,
                saveRelatedSearchLoading: false,
                saveRelatedSearchsuccess: true,
            };
        case FETCH_RELATED_SEARCHES_START:
            return {
                ...state,
                fetchRelatedSearchError: null,
                fetchRelatedSearchLoading: true,
                relatedSearches: [],
            };
        case FETCH_RELATED_SEARCHES_FAIL:
            return {
                ...state,
                fetchRelatedSearchError: action.payload,
                fetchRelatedSearchLoading: false,
                relatedSearches: [],
            };
        case FETCH_RELATED_SEARCHES_SUCCESS:
            return {
                ...state,
                fetchRelatedSearchLoading: false,
                relatedSearches: action.payload || [],
            };
        case FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_START:
            return {
                ...state,
                fetchTaxonomyChildrenError: null,
                fetchTaxonomyChildrenLoading: true,
                taxonomyChildren: [],
            };
        case FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL:
            return {
                ...state,
                fetchTaxonomyChildrenError: action.payload,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: [],
            };
        case FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS:
            return {
                ...state,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: action.payload || [],
            };
        case DELETE_RELATED_SEARCH_SUCCESS:
            return {
                ...state,
                fetchRelatedSearchLoading: false,
                relatedSearches: state.relatedSearches.length
                    ? state.relatedSearches.filter((related) => related.relatedSearchId !== action.payload)
                    : [],
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.relatedSearch;

export const relatedSearchesSelector = createSelector(stateSelector, (state) => state.relatedSearches);
export const relatedSearchesTaxonomyChildrenOptionsSelector = createSelector(
    stateSelector,
    (state) => state.taxonomyChildren
);
export const relatedSearchErrorSelector = createSelector(stateSelector, (state) => state.saveRelatedSearchError);
export const relatedSearchCaptureLoadingSelector = createSelector(
    stateSelector,
    (state) => state.saveRelatedSearchLoading
);

/* ACTION CREATORS */

export const getRelatedSearches = (categoryId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: categoryId,
            type: FETCH_RELATED_SEARCHES_START,
        });

        const response = await api.getRelatedSearchesByCatID({
            authToken,
            categoryId,
            deployment,
        });

        const suggestedCatsInPositionOrder = response.payload.sort((a, b) => {
            return a.position - b.position;
        });

        dispatch({
            payload: suggestedCatsInPositionOrder,
            type: FETCH_RELATED_SEARCHES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_RELATED_SEARCHES_FAIL,
        });
    }
};

export const saveRelatedSearch = (relatedSearch) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: relatedSearch,
            type: SAVE_RELATED_SEARCH_REQUEST,
        });

        let response;
        if (!relatedSearch.relatedSearchId) {
            response = await api.postRelatedSearch({
                authToken,
                deployment,
                suggestion: relatedSearch,
            });
        } else {
            response = await api.updateRelatedSearch({
                authToken,
                deployment,
                suggestion: relatedSearch,
            });
        }

        dispatch({
            payload: response.payload,
            type: SAVE_RELATED_SEARCH_SUCCESS,
        });
        dispatch(dismissModal());
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_RELATED_SEARCH_FAIL,
        });
    }
};

export const deleteRelatedSearch = (relatedSearchId) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        await api.deleteRelatedSearch({
            authToken,
            deployment,
            idToDelete: relatedSearchId,
        });

        dispatch({
            payload: relatedSearchId,
            type: DELETE_RELATED_SEARCH_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_RELATED_SEARCH_FAIL,
        });
    }
};

export const getCategoriesTaxonomyChildren = (searchTerm: string) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: -1,
            type: FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_START,
        });

        const response = await suggestedApi.getTaxonomyCategoryChildrenByString({
            authToken,
            deployment,
            searchTerm,
        });

        const taxonomyOptions = response.payload.map((option) => {
            return {
                label: `${option.categoryId} - ${option.categoryName}`,
                value: `${option.categoryId} - ${option.categoryName}`,
            };
        });

        dispatch({
            payload: taxonomyOptions,
            type: FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS,
        });
        return taxonomyOptions;
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL,
        });
    }
};
