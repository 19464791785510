import { ActionWithPayload } from '../../types/redux';
import { CoverLots } from '../../types/CoverLot';
import { createSelector } from 'reselect';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_COVER_LOTS_FAIL, LOAD_COVER_LOTS_REQUEST, LOAD_COVER_LOTS_SUCCESS } from './actions';
import api from '../api/coverLots';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_COVER_LOTS_FAIL]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: difference(state.loading, action.payload),
        }),
        [LOAD_COVER_LOTS_REQUEST]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [LOAD_COVER_LOTS_SUCCESS]: (state: State, action: ActionWithPayload<CoverLots[], { actionTime: number }>) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            action.payload.forEach((item) => {
                existing[item.catalogId] = { ...item };
                loaded[item.catalogId] = time;
                loading = difference(loading, [item.catalogId]);
            });
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.coverLots;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getCoverLots = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || { catalogId: 0, results: [{}, {}, {}] }
);

export const getCoverLotItemIds = createSelector([byIdSelector, idSelector], (byId, id) =>
    Boolean(byId[id]) ? { [id]: byId[id].results.map((i) => i.itemId) } : { [id]: [0, 0, 0] }
);

export const getLoadTimeForCoverLots = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const isCoverLotsLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchCoverLots = (state, catalogId) => {
    if (!catalogId) {
        return false;
    }
    const item = getCoverLots(state, catalogId);
    if (item) {
        const loaded = getLoadTimeForCoverLots(state, catalogId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isCoverLotsLoading(state, catalogId);
    return !loading;
};

const coverLotsToFetch = (state, catalogIds) => {
    return catalogIds.filter((id) => shouldFetchCoverLots(state, id));
};

/* ACTION CREATORS */
const loadCoverLots = (catalogIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            payload: catalogIds,
            type: LOAD_COVER_LOTS_REQUEST,
        });

        const response = await api.fetchCoverLots({ catalogIds, deployment });
        return dispatch({
            meta: { actionTime: Date.now(), catalogIds },
            payload: response.data,
            type: LOAD_COVER_LOTS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { catalogIds },
            payload: error,
            type: LOAD_COVER_LOTS_FAIL,
        });
    }
};

export const fetchCoverLotsIfNeeded = (catalogIds: number[]) => async (dispatch: Function, getState: Function) => {
    const coverLotsToLoad = coverLotsToFetch(getState(), catalogIds);
    if (coverLotsToLoad.length) {
        return dispatch(loadCoverLots(coverLotsToLoad));
    }
    return Promise.resolve();
};
