/* eslint-disable import/prefer-default-export */
import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const MOVE_CATALOG_TO_SERVER_START = 'MOVE_CATALOG_TO_SERVER_START';
export const MOVE_CATALOG_TO_SERVER_ERROR = 'MOVE_CATALOG_TO_SERVER_ERROR';
export const MOVE_CATALOG_TO_SERVER_SUCCESS = 'MOVE_CATALOG_TO_SERVER_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    moveServerError: null,
    updateLotNumbersError: null,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case MOVE_CATALOG_TO_SERVER_ERROR:
            return {
                ...state,
                moveServerError: action.payload,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.moveServer;

export const getMoveServerState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
type SubmitMoveServerParams = { catalogId: number; serverId: number };
export const submitMoveServer =
    ({ catalogId, serverId }: SubmitMoveServerParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: MOVE_CATALOG_TO_SERVER_START,
            });

            await api.postChangeCatalogServer({
                authToken,
                catalogId: Number(catalogId),
                deployment,
                serverId: Number(serverId),
            });
            dispatch({
                type: MOVE_CATALOG_TO_SERVER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: MOVE_CATALOG_TO_SERVER_ERROR,
            });
        }
    };
