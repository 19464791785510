import { TrainingItem } from '@/pages/Categorization/CategoryTrainingCollection';

export type TrainingCollectionState = {
    error: boolean;
    exclusions?: string[];
    isLoading: boolean;
    isUpdating: boolean;
    potentialTrainingItems?: TrainingItem[];
    selectedTrainingItems: TrainingItem[];
    synonyms?: string[];
    totalItemCount: number;
};

export type GetSelectedTrainingItemsParams = {
    authToken: string;
    categoryId: number;
    deployment: string;
};

export type GetTrainingItemsParams = GetSelectedTrainingItemsParams & {
    houseId?: number;
    pageNumber: number;
    searchTerm?: string;
};

export type LoadPotentialTrainingItemsPayload = {
    categoryId: number;
    houseId?: number;
    pageNumber: number;
    searchTerm?: string;
};

export type ApiTrainingItemPageStatus = {
    potentialTrainingLots: {
        alreadyCollected: boolean;
        lotId: number;
    }[];
    total: number;
};

export type ApiPotentialTrainingLot = {
    catalogId: number;
    houseId: number;
    imageUrl: string;
    lotDescription: string;
    lotId: number;
    lotIndex: number;
    lotNumber: string;
    lotTitle: string;
    reservePrice: number;
    startPrice: number;
};

export type ApiTrainingLot = {
    categoryId: number;
    description: string;
    imageUrl: string;
    lotId: number;
    title: string;
};

export type UpdateItemPayload = LoadPotentialTrainingItemsPayload & {
    facetId: number;
    lotIds: number[];
};

export type SynonymsAndExclusionsParams = {
    categoryId: number;
    facetId: number;
};

export type SynonymsAndExclusionsResponse = {
    error: boolean;
    payload: {
        exclusionKeywords: string;
        synonyms: string;
    };
};

export enum TrainingCollectionTab {
    ADD = 'add-items-tab',
    EDIT = 'edit-items-tab',
}
