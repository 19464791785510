import { ActionWithPayload } from '@/types/redux';
import { CancelForm, Disbursement, Payment, RefundForm } from '@/types/Payment';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
    DISBURSEMENTS_FILTERS_CHANGE,
    DISMISS_MODAL,
    FILTERS_CHANGE_FAIL,
    LOAD_BIDDER_PAYMENTS_ADMIN_FAIL,
    LOAD_BIDDER_PAYMENTS_ADMIN_REQUEST,
    LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS,
    LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS_ACTION,
    LOAD_DISBURSEMENTS_ADMIN_FAIL,
    LOAD_DISBURSEMENTS_ADMIN_REQUEST,
    LOAD_DISBURSEMENTS_ADMIN_SUCCESS,
    LOAD_DISBURSEMENTS_ADMIN_SUCCESS_ACTION,
    PAYMENTS_FILTERS_CHANGE,
    POST_PAYMENT_CANCEL_FAIL,
    POST_PAYMENT_CANCEL_REQUEST,
    POST_PAYMENT_CANCEL_SUCCESS,
    POST_PAYMENT_CAPTURE_FAIL,
    POST_PAYMENT_CAPTURE_REQUEST,
    POST_PAYMENT_CAPTURE_SUCCESS,
    POST_PAYMENT_REFUND_FAIL,
    POST_PAYMENT_REFUND_REQUEST,
    POST_PAYMENT_REFUND_SUCCESS,
    REPORTS_FILTERS_CHANGE,
} from './actions';
import { dismissModal } from './modal';
import {
    getAdminDisbursementRecords,
    getAdminPaymentRecords,
    getBidderPaymentsAdmin,
    getDailyBidderPaymentDeposits,
    getDisbursementsAdmin,
    getLADisbursementRecords,
    getMonthEndStripeBalance,
    getMonthlyWeaponListingFee,
    getRevenueReport,
    getWeeklyAHPayoutsParams,
    postCancelApi,
    postCaptureApi,
    postRefundApi,
} from '../api/payment';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/store';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import moment from 'moment';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

type State = {
    disbursements: Disbursement[];
    disbursementsFilters: PaginationFilter;
    disbursementsLoaded?: Date;
    disbursementsLoading: boolean;
    error?: any;
    paymentCancelLoading: boolean;
    paymentCaptureLoading: boolean;
    paymentRefundLoading: boolean;
    payments: Payment[];
    paymentsFilters: PaginationFilter;
    paymentsLoaded?: Date;
    paymentsLoading: boolean;
    reportsFilters: PaginationFilter;
};

const DEFAULT_STATE: State = {
    disbursements: [],
    disbursementsFilters: {
        from: moment().subtract(90, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: moment().toDate(),
        totalRecords: 0,
    },
    disbursementsLoaded: undefined,
    disbursementsLoading: false,
    error: undefined,
    paymentCancelLoading: false,
    paymentCaptureLoading: false,
    paymentRefundLoading: false,
    payments: [],
    paymentsFilters: {
        from: moment().subtract(21, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        status: '',
        to: moment().toDate(),
        totalRecords: 0,
    },
    paymentsLoaded: undefined,
    paymentsLoading: false,
    reportsFilters: {
        from: moment().subtract(30, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: moment().toDate(),
        totalRecords: 0,
    },
};

/* reducer */
export default handleActions(
    {
        [combineActions(
            FILTERS_CHANGE_FAIL,
            LOAD_BIDDER_PAYMENTS_ADMIN_FAIL,
            LOAD_DISBURSEMENTS_ADMIN_FAIL,
            POST_PAYMENT_CANCEL_FAIL,
            POST_PAYMENT_CAPTURE_FAIL,
            POST_PAYMENT_REFUND_FAIL
        )]: (state: State, action: ActionWithPayload<any>) => ({
            ...state,
            disbursementsLoading: false,
            error: action.payload,
            paymentCancelLoading: false,
            paymentCaptureLoading: false,
            paymentRefundLoading: false,
            paymentsLoading: false,
        }),
        [DISBURSEMENTS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                disbursementsFilters: {
                    ...state.disbursementsFilters,
                    ...action.payload,
                },
                disbursementsLoaded: undefined,
            };
            return newState;
        },
        [DISMISS_MODAL]: (state: State) => ({
            ...state,
            error: undefined,
        }),
        [LOAD_BIDDER_PAYMENTS_ADMIN_REQUEST]: (state: State) => ({
            ...state,
            paymentsLoading: true,
        }),
        [LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS]: (state: State, action: LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS_ACTION) => ({
            ...state,
            error: undefined,
            payments: action.payload.payments,
            paymentsFilters: {
                ...state.paymentsFilters,
                totalRecords: action.payload.totalRecords,
            },
            paymentsLoaded: new Date(),
            paymentsLoading: false,
        }),
        [LOAD_DISBURSEMENTS_ADMIN_REQUEST]: (state: State) => ({
            ...state,
            disbursementsLoading: true,
        }),
        [LOAD_DISBURSEMENTS_ADMIN_SUCCESS]: (state: State, action: LOAD_DISBURSEMENTS_ADMIN_SUCCESS_ACTION) => ({
            ...state,
            disbursements: action.payload.disbursements,
            disbursementsFilters: {
                ...state.disbursementsFilters,
                totalRecords: action.payload.totalRecords,
            },
            disbursementsLoaded: new Date(),
            disbursementsLoading: false,
            error: undefined,
        }),
        [PAYMENTS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                paymentsFilters: {
                    ...state.paymentsFilters,
                    ...action.payload,
                },
                paymentsLoaded: undefined,
            };
            return newState;
        },
        [POST_PAYMENT_CANCEL_REQUEST]: (state: State) => ({
            ...state,
            paymentCancelLoading: true,
        }),
        [POST_PAYMENT_CANCEL_SUCCESS]: (state: State) => ({
            ...state,
            error: undefined,
            paymentCancelLoading: false,
            payments: [],
            paymentsLoaded: undefined,
        }),
        [POST_PAYMENT_CAPTURE_REQUEST]: (state: State) => ({
            ...state,
            paymentCaptureLoading: true,
        }),
        [POST_PAYMENT_CAPTURE_SUCCESS]: (state: State) => ({
            ...state,
            error: undefined,
            paymentCaptureLoading: false,
            payments: [],
            paymentsLoaded: undefined,
        }),
        [POST_PAYMENT_REFUND_REQUEST]: (state: State) => ({
            ...state,
            paymentRefundLoading: true,
        }),
        [POST_PAYMENT_REFUND_SUCCESS]: (state: State) => ({
            ...state,
            error: undefined,
            paymentRefundLoading: false,
            payments: [],
            paymentsLoaded: undefined,
        }),
        [REPORTS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                reportsFilters: {
                    ...state.reportsFilters,
                    ...action.payload,
                },
            };
            return newState;
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
// @ts-expect-error Probably circular dependencies, given that everything is in this one file for the payment slice.
const stateSelector = (state: GlobalState): State => state.payments;

export const paymentsErrorSelector = createSelector(stateSelector, (state) => {
    const stateError = state.error;
    if (stateError) {
        return stateError;
    }
    return undefined;
});

export const disbursementsFiltersSelector = createSelector(stateSelector, (state) => state.disbursementsFilters);

export const paymentsFiltersSelector = createSelector(stateSelector, (state) => state.paymentsFilters);

export const reportsFiltersSelector = createSelector(stateSelector, (state) => state.reportsFilters);

export const paymentsSelector = createSelector(stateSelector, (state) => state.payments);

export const paymentsLoadedSelector = createSelector(stateSelector, (state) => state.paymentsLoaded);

export const paymentsLoadingSelector = createSelector(stateSelector, (state) => state.paymentsLoading);

export const paymentCancelLoadingSelector = createSelector(stateSelector, (state) => state.paymentCancelLoading);

export const paymentCaptureLoadingSelector = createSelector(stateSelector, (state) => state.paymentCaptureLoading);

export const paymentRefundLoadingSelector = createSelector(stateSelector, (state) => state.paymentRefundLoading);

export const disbursementsSelector = createSelector(stateSelector, (state) => state.disbursements);

export const disbursementsLoadedSelector = createSelector(stateSelector, (state) => state.disbursementsLoaded);

export const disbursementsLoadingSelector = createSelector(stateSelector, (state) => state.disbursementsLoading);

export const shouldFetchDisbursements = createSelector(
    disbursementsLoadedSelector,
    disbursementsLoadingSelector,
    (disbursementsLoaded, disbursementsLoading) => {
        if (disbursementsLoading) {
            return false;
        }
        const time = Date.now();
        const diff = time - (disbursementsLoaded?.valueOf() ?? 0);
        return !disbursementsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const shouldFetchPayments = createSelector(
    paymentsLoadedSelector,
    paymentsLoadingSelector,
    (paymentsLoaded, paymentsLoading) => {
        if (paymentsLoading) {
            return false;
        }
        const time = Date.now();
        const diff = time - (paymentsLoaded?.valueOf() ?? 0);
        return !paymentsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

/* ACTION CREATORS */
export const onDisbursementsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: DISBURSEMENTS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: FILTERS_CHANGE_FAIL,
        });
    }
};

export const onPaymentsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: PAYMENTS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: FILTERS_CHANGE_FAIL,
        });
    }
};

export const onReportsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: REPORTS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: FILTERS_CHANGE_FAIL,
        });
    }
};

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadAdminDisbursementRecords =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getAdminDisbursementRecords({
                authToken,
                deployment,
                from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadRevenueReport = (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await getRevenueReport({
            authToken,
            deployment,
            from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
            to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadLADisbursementRecords =
    (filters: PaginationFilter, groupBy: string = 'catalog', provider: number = 0) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getLADisbursementRecords({
                authToken,
                deployment,
                from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                groupBy,
                provider,
                to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadAdminPaymentRecords =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getAdminPaymentRecords({
                authToken,
                deployment,
                from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

export const fetchAdminHouseDisbursementsIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchDisbursements(state)) {
            try {
                dispatch({
                    type: LOAD_DISBURSEMENTS_ADMIN_REQUEST,
                });
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);
                const response = await getDisbursementsAdmin({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                return dispatch({
                    payload: {
                        disbursements: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_DISBURSEMENTS_ADMIN_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    meta: { error },
                    payload: 'Error loading disbursements',
                    type: LOAD_DISBURSEMENTS_ADMIN_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };

export const fetchAdminBidderPaymentsIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchPayments(state)) {
            try {
                dispatch({
                    type: LOAD_BIDDER_PAYMENTS_ADMIN_REQUEST,
                });

                const state = getState();
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);
                const response = await getBidderPaymentsAdmin({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    status: filters.status || '',
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                return dispatch({
                    payload: {
                        payments: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    meta: { error },
                    payload: 'Error loading payments',
                    type: LOAD_BIDDER_PAYMENTS_ADMIN_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };

export const postCancel =
    (transactionId: string, cancelForm: CancelForm) => async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: POST_PAYMENT_CANCEL_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await postCancelApi({
                authToken,
                cancelForm,
                deployment,
                transactionId,
            });
            return dispatch({
                payload: response.payload,
                type: POST_PAYMENT_CANCEL_SUCCESS,
            });
        } catch (error) {
            let errToDispatch = {
                error: true,
                meta: { error },
                payload: 'Error posting cancel',
                type: POST_PAYMENT_CANCEL_FAIL,
            };
            if (error === 'INVALID_CANCEL') {
                errToDispatch.payload = 'Bank will not allow cancel';
            }
            dispatch(errToDispatch);
        }
    };

export const postCapture = (transactionId: string) => async (dispatch: Function, getState: Function) => {
    try {
        dispatch({
            type: POST_PAYMENT_CAPTURE_REQUEST,
        });

        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await postCaptureApi({
            authToken,
            deployment,
            transactionId,
        });
        dispatch(dismissModal());
        return dispatch({
            payload: response.payload,
            type: POST_PAYMENT_CAPTURE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error posting capture',
            type: POST_PAYMENT_CAPTURE_FAIL,
        });
        return Promise.reject();
    }
};

export const postRefund =
    (transactionId: string, refundForm: RefundForm) => async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: POST_PAYMENT_REFUND_REQUEST,
            });

            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await postRefundApi({
                authToken,
                deployment,
                refundForm,
                transactionId,
            });
            return dispatch({
                payload: response.payload,
                type: POST_PAYMENT_REFUND_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { error },
                payload: error.payload || 'Error posting refund',
                type: POST_PAYMENT_REFUND_FAIL,
            });
            return Promise.reject();
        }
    };

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadDailyBidderPaymentDepositsRecords =
    (transferId: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getDailyBidderPaymentDeposits({
                authToken,
                deployment,
                transferId,
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadWeeklyAHPayoutsRecords = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await getWeeklyAHPayoutsParams({
            authToken,
            deployment,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadMonthlyWeaponListingFee = (saleStart: Date) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await getMonthlyWeaponListingFee({
            authToken,
            deployment,
            saleStart: moment(saleStart.setHours(0, 0, 0)).format(),
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// Goal is not to dispatch actions, it is to return a blob of csv data
export const loadMonthEndStripeBalance =
    (datePaid: Date, transactionDate: Date) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getMonthEndStripeBalance({
                authToken,
                datePaid: moment(datePaid.setHours(0, 0, 0)).format(),
                deployment,
                transactionDate: moment(transactionDate.setHours(23, 59, 59)).format(),
            });
            return response;
        } catch (error) {
            throw error;
        }
    };
