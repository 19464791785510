import {
    ApiPotentialTrainingLot,
    ApiTrainingItemPageStatus,
    ApiTrainingLot,
    GetSelectedTrainingItemsParams,
    GetTrainingItemsParams,
    SynonymsAndExclusionsResponse,
} from './trainingCollection.types';
import {
    CATEGORY_TRAINING_COLLECTION_PAGE_SIZE,
    TrainingItem,
    TrainingLotsPageInfo,
} from '@/pages/Categorization/CategoryTrainingCollection';
import { handleResponse, makeGet, makePost } from '@/redux/api/helpers';

export const getPotentialTrainingItems = async ({
    authToken,
    categoryId,
    deployment,
    houseId,
    pageNumber,
    searchTerm,
}: GetTrainingItemsParams): Promise<{
    error: boolean;
    payload: TrainingLotsPageInfo;
}> => {
    try {
        const pageStatusResponse: { error: boolean; payload: ApiTrainingItemPageStatus } = await new Promise(
            (resolve, reject) => {
                const request = makeGet({
                    apiPath: 'training/getpotentiallots',
                    authToken,
                    deployment,
                    path: '<CRANNY-CATEGORIZER-API>',
                    queryParams: {
                        categoryId,
                        page: pageNumber,
                        pageSize: CATEGORY_TRAINING_COLLECTION_PAGE_SIZE,
                        searchTerm: `"${searchTerm}"` || '""',
                    },
                });
                if (houseId) {
                    request.query({ houseId });
                }
                request.end((err, response) => handleResponse({ err, reject, resolve, response }));
            }
        );
        const { payload: pageStatus } = pageStatusResponse;
        const lotInformationResponse: { error: boolean; payload: ApiPotentialTrainingLot[] } = await new Promise(
            (resolve, reject) => {
                const { potentialTrainingLots } = pageStatus;
                const potentialLotIds = potentialTrainingLots.map((lot) => lot.lotId);
                const request = makePost({
                    apiPath: `house/${houseId || 0}/catalog/0/lots`,
                    authToken,
                    deployment,
                    path: '<CMS-API>',
                });
                request.send({ lotIds: potentialLotIds });
                request.end((err, response) => handleResponse({ err, reject, resolve, response }));
            }
        );
        const { payload: lotInformation } = lotInformationResponse;
        const pageInfo: TrainingLotsPageInfo = {
            currentPageTrainingItems: pageStatus.potentialTrainingLots.map((lot) => {
                const fullLotInfo = lotInformation.find((info) => info.lotId === lot.lotId);
                return {
                    collected: lot.alreadyCollected,
                    id: lot.lotId,
                    imageUrl: fullLotInfo?.imageUrl || '',
                    name: fullLotInfo?.lotTitle || '',
                };
            }),
            totalRecords: pageStatus.total,
        };
        return {
            error: false,
            payload: pageInfo,
        };
    } catch (error) {
        return {
            error: true,
            payload: error.message,
        };
    }
};

export const getSelectedTrainingItems = async ({
    authToken,
    categoryId,
    deployment,
}: GetSelectedTrainingItemsParams): Promise<{ error: boolean; payload: TrainingItem[] }> => {
    try {
        const trainingItems: TrainingItem[] = await new Promise((resolve, reject) => {
            const request = makeGet({
                apiPath: 'training/gettraininglots',
                authToken,
                deployment,
                path: '<CRANNY-CATEGORIZER-API>',
                queryParams: { categoryId },
            });
            const transform = ({ payload }: { payload: ApiTrainingLot[] }): TrainingItem[] => {
                return payload.map((lot) => ({
                    collected: true,
                    id: lot.lotId,
                    imageUrl: lot?.imageUrl || '',
                    name: lot?.title || '',
                }));
            };
            request.end((err, response) =>
                handleResponse<{ payload: ApiTrainingLot[] }, TrainingItem[]>({
                    err,
                    reject,
                    resolve,
                    response,
                    transform,
                })
            );
        });
        return {
            error: false,
            payload: trainingItems,
        };
    } catch (error) {
        return {
            error: true,
            payload: error.message,
        };
    }
};

type UpdateTrainingItemsParams = {
    authToken: string;
    categoryId: number;
    deployment: string;
    lotIds: number[];
};

export const addTrainingItemsByIds = ({
    authToken,
    categoryId,
    deployment,
    lotIds,
}: UpdateTrainingItemsParams): Promise<void> => {
    return new Promise((resolve, reject) => {
        const request = makePost({
            apiPath: 'training/addlots',
            authToken,
            deployment,
            path: '<CRANNY-CATEGORIZER-API>',
            queryParams: {
                categoryId,
            },
        });
        request.send({ lotIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
};

export const removeTrainingItemsByIds = ({
    authToken,
    categoryId,
    deployment,
    lotIds,
}: UpdateTrainingItemsParams): Promise<void> => {
    return new Promise((resolve, reject) => {
        const request = makePost({
            apiPath: 'training/removelots',
            authToken,
            deployment,
            path: '<CRANNY-CATEGORIZER-API>',
            queryParams: {
                categoryId,
            },
        });
        request.send({ lotIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
};

export const getSynonymsAndExclusionKeywords = ({
    authToken,
    categoryId,
    deployment,
    facetId,
}): Promise<SynonymsAndExclusionsResponse> => {
    return new Promise((resolve, reject) => {
        const request = makeGet({
            apiPath: 'category',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
            queryParams: {
                categoryId,
                facetId,
            },
        });
        request.end((err, response) => {
            handleResponse({ err, reject, resolve, response });
        });
    });
};
