import { getPriceGuideBaseUrl } from './urls';
import find from 'lodash/find';
import some from 'lodash/some';

export const DEFAULT_ARCHIVE_STATUSES = ['sold', 'passed', 'done'];
export const DEFAULT_LIVE_STATUSES = ['upcoming', 'live', 'online'];
export const DEFAULT_SORT_ORDER = '-relevance';

/* Action Types */
export const LOAD_SEARCH_RESULTS_FAIL = 'la/domain/searchResults/LOAD_FAIL';
export const LOAD_SEARCH_RESULTS_REQUEST = 'la/domain/searchResults/LOAD_REQUEST';
export const LOAD_SEARCH_RESULTS_SUCCESS = 'la/domain/searchResults/LOAD_SUCCESS';

const getParentPathForQuery = (category) => {
    let categoryToQuery = '';
    if (category.selected && category.parents && category.parents.length > 0) {
        let parentsToQuery = '';
        category.parents.forEach((parent) => {
            parentsToQuery += `${parent.id}/`;
        });
        categoryToQuery = `${parentsToQuery}${categoryToQuery}`;
    }
    return categoryToQuery;
};

export const concatenatePath = (category: any, catId: number) => {
    if (!category) {
        return catId;
    }
    if (category.id === catId) {
        let categoryToQuery = category.id;
        categoryToQuery = `${getParentPathForQuery(category)}${categoryToQuery}`;

        return categoryToQuery;
    }
    const nextCategory = findCategoryContainingSomeCategoryId(catId, category.categories);

    return `${category.id}/${concatenatePath(nextCategory, catId)}`;
};

export const findCategoryContainingSomeCategoryId = (categoryId: any, categories: any) => {
    if (!categories) {
        return false;
    }
    const trees = categories;
    if (some(trees, (c) => c.id === categoryId)) {
        return find(trees, (c) => c.id === categoryId);
    }
    return find(trees, (c) => findCategoryContainingSomeCategoryId(categoryId, c.categories));
};

export const translateSortForOlderUrls = (sortOption: string, defaultSortOrder: string) => {
    let translatedSort = sortOption || defaultSortOrder;

    if (translatedSort.indexOf('_') > 0) {
        const oldUrl = sortOption.split('_');
        const newOption = oldUrl[0] + oldUrl[1].charAt(0).toUpperCase() + oldUrl[1].slice(1);
        translatedSort = newOption;
    }

    return translatedSort;
};

export const translateStatus = (queryParams: any, pathname: string) => {
    let status = DEFAULT_LIVE_STATUSES;

    if (queryParams.status === 'archive' || (pathname && pathname.includes(getPriceGuideBaseUrl()))) {
        status = DEFAULT_ARCHIVE_STATUSES;
    }

    return status;
};
