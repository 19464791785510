import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const ADD_CATALOG_BIN = 'ADD_CATALOG_BIN';
export const ADD_CATALOG_BIN_SUCCESS = 'ADD_CATALOG_BIN_SUCCESS';
export const ADD_CATALOG_BIN_ERROR = 'ADD_CATALOG_BIN_ERROR';
export const REMOVE_CATALOG_BIN = 'REMOVE_CATALOG_BIN';
export const REMOVE_CATALOG_BIN_SUCCESS = 'REMOVE_CATALOG_BIN_SUCCESS';
export const REMOVE_CATALOG_BIN_ERROR = 'REMOVE_CATALOG_BIN_ERROR';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case ADD_CATALOG_BIN:
        case REMOVE_CATALOG_BIN:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case ADD_CATALOG_BIN_SUCCESS:
        case REMOVE_CATALOG_BIN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case ADD_CATALOG_BIN_ERROR:
        case REMOVE_CATALOG_BIN_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.catalogBIN;

export const getAddCatalogBINError = createSelector(stateSelector, (state) => state.error);

export const getAddCatalogBINLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export const addCatalogBIN = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: catalogId,
            type: ADD_CATALOG_BIN,
        });

        const response = await api.postAddCatalogBIN({
            authToken,
            catalogId,
            deployment,
        });
        dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: ADD_CATALOG_BIN_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: ADD_CATALOG_BIN_ERROR,
        });
    }
};

export const removeCatalogBIN = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: catalogId,
            type: REMOVE_CATALOG_BIN,
        });

        const response = await api.postRemoveCatalogBIN({
            authToken,
            catalogId,
            deployment,
        });
        dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: REMOVE_CATALOG_BIN_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: REMOVE_CATALOG_BIN_ERROR,
        });
    }
};
