/* eslint-disable import/prefer-default-export */
import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const UPDATE_LOT_NUMBERS_FAIL = 'UPDATE_LOT_NUMBERS_FAIL';
export const UPDATE_LOT_NUMBERS_REQUEST = 'UPDATE_LOT_NUMBERS_REQUEST';
export const UPDATE_LOT_NUMBERS_SUCCESS = 'UPDATE_LOT_NUMBERS_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    submitted: false,
    success: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case UPDATE_LOT_NUMBERS_FAIL:
            return {
                ...state,
                error: action.payload,
                submitted: false,
                success: false,
            };
        case UPDATE_LOT_NUMBERS_REQUEST:
            return {
                ...state,
                error: null,
                submitted: true,
                success: false,
            };
        case UPDATE_LOT_NUMBERS_SUCCESS:
            return {
                ...state,
                error: null,
                submitted: false,
                success: true,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.updateLotNumbers;

export const getUpdateLotNumbersState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
type SubmitUpdateLotNumbersParams = { catalogId: number };

export const submitUpdateLotNumbers =
    ({ catalogId }: SubmitUpdateLotNumbersParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: catalogId,
                type: UPDATE_LOT_NUMBERS_REQUEST,
            });

            const response = await api.postRenumberLots({
                authToken,
                catalogId: Number(catalogId),
                deployment,
            });
            dispatch({
                payload: response.payload,
                type: UPDATE_LOT_NUMBERS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: UPDATE_LOT_NUMBERS_FAIL,
            });
        }
    };
