import { ActionWithPayload } from '@/types/redux';
import { Chargeback } from '@/types/Chargeback';
import {
    CHARGEBACKS_FILTERS_CHANGE,
    FILTERS_CHANGE_FAIL,
    LOAD_CHARGEBACKS_ADMIN_FAIL,
    LOAD_CHARGEBACKS_ADMIN_REQUEST,
    LOAD_CHARGEBACKS_ADMIN_SUCCESS,
    LOAD_CHARGEBACKS_ADMIN_SUCCESS_ACTION,
} from './actions';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAdminChargebackRecords } from '../api/chargeback';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/store';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import moment from 'moment';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

type State = {
    chargebacks: Chargeback[];
    chargebacksFilters: PaginationFilter;
    chargebacksLoaded?: Date;
    chargebacksLoading: boolean;
    error?: any;
};

const DEFAULT_STATE: State = {
    chargebacks: [],
    chargebacksFilters: {
        from: moment().subtract(60, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: moment().toDate(),
        totalRecords: 0,
    },
    chargebacksLoaded: undefined,
    chargebacksLoading: false,
    error: undefined,
};

// /* reducer */
export default handleActions(
    {
        [combineActions(FILTERS_CHANGE_FAIL, LOAD_CHARGEBACKS_ADMIN_FAIL)]: (
            state: State,
            action: ActionWithPayload<any>
        ) => ({
            ...state,
            chargebacksLoading: false,
            error: action.payload,
        }),
        [CHARGEBACKS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                chargebacksFilters: {
                    ...state.chargebacksFilters,
                    ...action.payload,
                },
                chargebacksLoaded: undefined,
            };
            return newState;
        },
        [LOAD_CHARGEBACKS_ADMIN_REQUEST]: (state: State) => ({
            ...state,
            chargebacksLoading: true,
        }),
        [LOAD_CHARGEBACKS_ADMIN_SUCCESS]: (state: State, action: LOAD_CHARGEBACKS_ADMIN_SUCCESS_ACTION) => ({
            ...state,
            chargebacks: action.payload.chargebacks,
            chargebacksFilters: {
                ...state.chargebacksFilters,
                totalRecords: action.payload.totalRecords,
            },
            chargebacksLoaded: new Date(),
            chargebacksLoading: false,
            error: undefined,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
// @ts-expect-error circular dependencies
const stateSelector = (state: GlobalState): State => state.chargebacks;

export const chargebacksFiltersSelector = createSelector(stateSelector, (state) => state.chargebacksFilters);

export const chargebacksSelector = createSelector(stateSelector, (state) => state.chargebacks);

export const chargebacksLoadedSelector = createSelector(stateSelector, (state) => state.chargebacksLoaded);

export const chargebacksLoadingSelector = createSelector(stateSelector, (state) => state.chargebacksLoading);

export const filteredChargebacksSelector = createSelector(
    chargebacksFiltersSelector,
    chargebacksSelector,
    (filters, chargebacks) => {
        return filterByDate(filters, chargebacks);
    }
);

const filterByDate = (filters, activity: Array<Chargeback>) => {
    if (activity) {
        let fromDate = moment(filters.from);
        let toDate = moment(filters.to);
        let result = activity.filter((act) => {
            let activityDate = moment(act.created);
            return activityDate.isBetween(fromDate, toDate);
        });
        return result;
    }
    return [];
};

export const filteredDateSortedChargebacksSelector = createSelector(filteredChargebacksSelector, (chargebacks) =>
    sortByDate(chargebacks)
);

const sortByDate = (recentActivity: Array<Chargeback>) => {
    return recentActivity.sort((activityA, activityB) => {
        return activityA.created > activityB.created ? -1 : activityA.created < activityB.created ? 1 : 0;
    });
};

export const shouldFetchChargebacks = createSelector(
    chargebacksLoadedSelector,
    chargebacksLoadingSelector,
    (chargebacksLoaded, chargebacksLoading) => {
        if (chargebacksLoading) {
            return false;
        }
        const time = Date.now();
        const diff = time - (chargebacksLoaded?.valueOf() ?? 0);
        return !chargebacksLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

/* ACTION CREATORS */
export const onChargebacksFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: CHARGEBACKS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: FILTERS_CHANGE_FAIL,
        });
    }
};

export const fetchAdminChargebacksIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchChargebacks(state)) {
            try {
                dispatch({
                    type: LOAD_CHARGEBACKS_ADMIN_REQUEST,
                });
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);
                const response = await getAdminChargebackRecords({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                return dispatch({
                    payload: {
                        chargebacks: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_CHARGEBACKS_ADMIN_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    meta: { error },
                    payload: 'Error loading chargeback',
                    type: LOAD_CHARGEBACKS_ADMIN_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };
