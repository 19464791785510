import { BidderPaymentData } from '../../../types/BidderPayment';
import { TypedActionWithPayload } from '../../../types/redux';

export const FETCH_BIDDER_PAYMENT_DATA_FAILURE = 'FETCH_BIDDER_PAYMENT_DATA_FAILURE';
export type FETCH_BIDDER_PAYMENT_DATA_FAILURE_ACTION = TypedActionWithPayload<
    typeof FETCH_BIDDER_PAYMENT_DATA_FAILURE,
    string,
    { bidderId: number }
>;

export const FETCH_BIDDER_PAYMENT_DATA_REQUEST = 'FETCH_BIDDER_PAYMENT_DATA_REQUEST';
export type FETCH_BIDDER_PAYMENT_DATA_REQUEST_ACTION = TypedActionWithPayload<
    typeof FETCH_BIDDER_PAYMENT_DATA_REQUEST,
    { bidderId: number }
>;

export const FETCH_BIDDER_PAYMENT_DATA_SUCCESS = 'FETCH_BIDDER_PAYMENT_DATA_SUCCESS';
export type FETCH_BIDDER_PAYMENT_DATA_SUCCESS_ACTION = TypedActionWithPayload<
    typeof FETCH_BIDDER_PAYMENT_DATA_SUCCESS,
    BidderPaymentData,
    { actionTime: number; bidderId: number }
>;
