export const FETCH_BID_PRECENT_DATA_FAILURE = 'FETCH_BID_PRECENT_DATA_FAILURE';
export const FETCH_BID_PRECENT_DATA_REQUEST = 'FETCH_BID_PRECENT_DATA_REQUEST';
export const FETCH_BID_PRECENT_DATA_SUCCESS = 'FETCH_BID_PRECENT_DATA_SUCCESS';

export const FETCH_SERVER_STATUS_FAILURE = 'FETCH_SERVER_STATUS_FAILURE';
export const FETCH_SERVER_STATUS_REQUEST = 'FETCH_SERVER_STATUS_REQUEST';
export const FETCH_SERVER_STATUS_SUCCESS = 'FETCH_SERVER_STATUS_SUCCESS';

export const SET_CATALOG_ONLY_FAILURE = 'SET_CATALOG_ONLY_FAILURE';
export const SET_CATALOG_ONLY_REQUEST = 'SET_CATALOG_ONLY_REQUEST';
export const SET_CATALOG_ONLY_SUCCESS = 'SET_CATALOG_ONLY_SUCCESS';

export const SUBMIT_SEND_GLOBAL_NOTIFICATION_FAILURE = 'SUBMIT_SEND_GLOBAL_NOTIFICATION_FAILURE';
export const SUBMIT_SEND_GLOBAL_NOTIFICATION_REQUEST = 'SUBMIT_SEND_GLOBAL_NOTIFICATION_REQUEST';
export const SUBMIT_SEND_GLOBAL_NOTIFICATION_SUCCESS = 'SUBMIT_SEND_GLOBAL_NOTIFICATION_SUCCESS';
