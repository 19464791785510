/* Action Types */
export const FETCH_SUGGESTED_CATEGORIES_FAIL = 'FETCH_SUGGESTED_CATEGORIES_FAIL';
export const FETCH_SUGGESTED_CATEGORIES_START = 'FETCH_SUGGESTED_CATEGORIES_START';
export const FETCH_SUGGESTED_CATEGORIES_SUCCESS = 'FETCH_SUGGESTED_CATEGORIES_SUCCESS';
export const FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL = 'FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL';
export const FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START = 'FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START';
export const FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS =
    'FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS';
export const DELETE_SUGGESTED_CATEGORY_SUCCESS = 'DELETE_SUGGESTED_CATEGORY_SUCCESS';
export const SAVE_SUGGESTED_CATEGORY_FAIL = 'SAVE_SUGGESTED_CATEGORY_FAIL';
export const SAVE_SUGGESTED_CATEGORY_REQUEST = 'SAVE_SUGGESTED_CATEGORY_REQUEST';
export const SAVE_SUGGESTED_CATEGORY_SUCCESS = 'SAVE_SUGGESTED_CATEGORY_SUCCESS';

export const FETCH_COMBINATION_SUGGESTED_CATEGORIES_FAIL = 'FETCH_COMBINATION_SUGGESTED_CATEGORIES_FAIL';
export const FETCH_COMBINATION_SUGGESTED_CATEGORIES_START = 'FETCH_COMBINATION_SUGGESTED_CATEGORIES_START';
export const FETCH_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS = 'FETCH_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS';
export const FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL =
    'FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL';
export const FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START =
    'FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START';
export const FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS =
    'FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS';
export const DELETE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS = 'DELETE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS';
export const SAVE_COMBINATION_SUGGESTED_CATEGORY_FAIL = 'SAVE_COMBINATION_SUGGESTED_CATEGORY_FAIL';
export const SAVE_COMBINATION_SUGGESTED_CATEGORY_REQUEST = 'SAVE_COMBINATION_SUGGESTED_CATEGORY_REQUEST';
export const SAVE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS = 'SAVE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS';
