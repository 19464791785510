import { createSelector } from 'reselect';
import { getDeployment } from './config';
import { LOG_OUT, LOGIN_FAIL, LOGIN_SUCCESS } from './login';
import { makeGet } from '../api/helpers';

/* Action Types */
export const USER_DATA_FAIL = 'USER_DATA_FAIL';
export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    token: null,
    type: null,
    userData: {},
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case LOG_OUT:
        case LOGIN_FAIL:
        case USER_DATA_FAIL:
            return {
                ...state,
                token: null,
                type: null,
                userData: {},
            };
        case LOGIN_SUCCESS:
        case USER_DATA_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                type: action.payload.type,
                userData: { ...action.payload.userData },
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.user;

export const getAuthToken = createSelector(stateSelector, (state) => state.token);
export const isAuthenticated = createSelector(getAuthToken, (token) => token !== null && token !== '');
export const getUserData = createSelector(stateSelector, (state) => state.userData);
export const getUsername = createSelector(getUserData, (userData) => userData.username);

/* ACTION CREATORS */
const fetchUserDataFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: USER_DATA_FAIL,
});

export const fetchUserData = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: USER_DATA_REQUEST,
        });

        const request = makeGet({
            apiPath: 'auth/spauser',
            authToken: authToken,
            deployment,
            path: '<ITEM-API>',
        });

        // @ts-ignore
        request.end((err, { body } = {}) => {
            if (err || (body && body.errorMessage) || ('success' in body && !body.success)) {
                dispatch(fetchUserDataFail(err || body.message));
            } else if (body && body.data && body.data.type !== 'admin') {
                dispatch(fetchUserDataFail('Unauthorized'));
            } else {
                dispatch({
                    payload: {
                        ...body.data,
                        token: authToken,
                    },
                    type: USER_DATA_SUCCESS,
                });
            }
        });
    } catch (error) {
        dispatch(fetchUserDataFail(error.message));
    }
};
