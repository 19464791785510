import { ActionWithPayload } from '../../types/redux';
import { createSelector } from 'reselect';
import {
    GET_DISBURSEMENT_AUDIT_FAIL,
    GET_DISBURSEMENT_AUDIT_REQUEST,
    GET_DISBURSEMENT_AUDIT_SUCCESS,
    POST_PAYMENT_SETUP_RESET_FAIL,
    POST_PAYMENT_SETUP_RESET_REQUEST,
    POST_PAYMENT_SETUP_RESET_SUCCESS,
} from './actions';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { getDisbursementAuditApi, postResetPaymentSetupForHouse } from '../api/payment';
import { handleActions } from 'redux-actions';

type State = {
    disbursementAuditError?: string;
    disbursementAuditLoading: boolean;
    disbursementAuditResult?: string;
    paymentsReset?: Date;
    paymentsResetError?: boolean;
    paymentsResetting: boolean;
};

const DEFAULT_STATE: State = {
    disbursementAuditError: undefined,
    disbursementAuditLoading: false,
    disbursementAuditResult: undefined,
    paymentsReset: undefined,
    paymentsResetError: undefined,
    paymentsResetting: false,
};

/* reducer */
export default handleActions(
    {
        [GET_DISBURSEMENT_AUDIT_FAIL]: (state: State, action: any) => ({
            ...state,
            disbursementAuditError: action.payload,
            disbursementAuditLoading: false,
            disbursementAuditResult: undefined,
        }),
        [GET_DISBURSEMENT_AUDIT_REQUEST]: (state: State) => ({
            ...state,
            disbursementAuditError: undefined,
            disbursementAuditLoading: true,
            disbursementAuditResult: undefined,
        }),
        [GET_DISBURSEMENT_AUDIT_SUCCESS]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            disbursementAuditError: undefined,
            disbursementAuditLoading: false,
            disbursementAuditResult: action.payload,
        }),
        [POST_PAYMENT_SETUP_RESET_FAIL]: (state: State, action: any) => ({
            ...state,
            paymentsReset: undefined,
            paymentsResetError: action.payload,
            paymentsResetting: false,
        }),
        [POST_PAYMENT_SETUP_RESET_REQUEST]: (state: State) => ({
            ...state,
            paymentsReset: undefined,
            paymentsResetError: undefined,
            paymentsResetting: true,
        }),
        [POST_PAYMENT_SETUP_RESET_SUCCESS]: (state: State) => ({
            ...state,
            paymentsReset: new Date(),
            paymentsResetError: undefined,
            paymentsResetting: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.paymentTools;

export const getDisbursementAuditResult = createSelector(stateSelector, (state) => state.disbursementAuditResult);

export const getDisbursementAuditError = createSelector(stateSelector, (state) => state.disbursementAuditError);

export const getDisbursementAuditLoading = createSelector(stateSelector, (state) => state.disbursementAuditLoading);

export const paymentsResetSelector = createSelector(stateSelector, (state) => state.paymentsReset);

export const paymentsResetErrorSelector = createSelector(stateSelector, (state) => state.paymentsResetError);

export const paymentsResettingSelector = createSelector(stateSelector, (state) => state.paymentsResetting);

export const getDisbursementAudit = (disbursementId: number) => async (dispatch: Function, getState: Function) => {
    try {
        dispatch({
            type: GET_DISBURSEMENT_AUDIT_REQUEST,
        });

        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await getDisbursementAuditApi({
            authToken,
            deployment,
            disbursementId,
        });
        return dispatch({
            payload: response.payload,
            type: GET_DISBURSEMENT_AUDIT_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { error },
            payload: error.payload,
            type: GET_DISBURSEMENT_AUDIT_FAIL,
        });
    }
};

export const postResetSetup = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        dispatch({
            type: POST_PAYMENT_SETUP_RESET_REQUEST,
        });

        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await postResetPaymentSetupForHouse({
            authToken,
            deployment,
            houseId,
        });
        return dispatch({
            payload: response.payload,
            type: POST_PAYMENT_SETUP_RESET_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { error },
            payload: error.payload,
            type: POST_PAYMENT_SETUP_RESET_FAIL,
        });
    }
};
