export default {
    AUCTION_HOUSE_ID: 'auctionHouse',
    BUYERS_PREMIUM_ID: 'hasBuyersPremium',
    CATEGORY_ID: 'F1',
    COUNTRY_CODE_ID: 'countryCode',
    CREATOR_ID: 'creator',
    CURRENCY_ID: 'currency',
    CURRENT_BID_ID: 'currentBid',
    DISTANCE_ID: 'distance',
    ESTIMATED_PRICE_ID: 'estimate',
    HAMMER_PRICE_ID: 'hammerPrice',
    MATERIALS_AND_TECHNIQUES_ID: 'materialsAndTechniques',
    ORIGIN_ID: 'F2',
    SALE_TYPE_ID: 'sale_type',
    STYLE_PERIOD_ID: 'stylePeriod',
};
