import { handleResponse, makePost } from './helpers';

type FetchCatalogsByIdsParams = {
    authToken?: string;
    catalogIds: number[];
    deployment: string;
    forceNoCache?: boolean;
};

export default {
    fetchCatalogsByIds: ({ authToken, catalogIds, deployment, forceNoCache }: FetchCatalogsByIdsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({ authToken, deployment, path: '<ITEM-API>spa/small/catalogs' });
            if (forceNoCache) {
                request.query({ forceNoCache });
            }
            request.send({ ids: catalogIds });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
