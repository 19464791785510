import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Auth from '@/components/Auth/Auth';
import Layout from '@/components/Layout/Layout';
import Modals from '@/components/Modals/Modals';
import PrivateRoute from '@/components/PrivateRoute/PrivateRoute';
import ReviewsPage from '../Reviews/ReviewsPage';
import styled from 'styled-components';
import ToastMessage from '@liveauctioneers/caterwaul-components/lib/ToastMessage/ToastMessage';
import WhiteLabelPage from '../WhiteLabel/WhiteLabelPage';

const HouseSettingsPage = lazy(() => import('../HouseSettings/HouseSettingsPageContainer'));
const BidderPage = lazy(() => import('../Bidder/BidderPage'));
const BidPercentPage = lazy(() => import('../BidPercent/BidPercentPageContainer'));
const BINPage = lazy(() => import('../BuyItNow/BINPage'));
const CatalogPage = lazy(() => import('../Catalog/CatalogPage'));
const CategorizationPage = lazy(() => import('../Categorization/CategorizationPageContainer'));
const FinanceDashboardPage = lazy(() => import('../FinanceDashboard/FinanceDashboardPage'));
const HealthPage = lazy(() => import('../Health/HealthPageContainer'));
const J52Page = lazy(() => import('../J52/J52Page'));
const LoginPage = lazy(() => import('../Login/LoginPageContainer'));
const MarketingPage = lazy(() => import('../Marketing/MarketingPageContainer'));
const NotFoundPage = lazy(() => import('../NotFound/NotFoundPageContainer'));
const StatusPage = lazy(() => import('../Status/StatusPage'));
const AtgShipPage = lazy(() => import('../AtgShip/AtgShipPage'));

const App = () => {
    const { pathname } = useLocation();

    return (
        <div>
            <Auth />
            <main>
                <Layout>
                    <Suspense fallback={<StyledPageContainer>Loading...</StyledPageContainer>}>
                        <Switch>
                            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                            <Route component={LoginPage} exact path="/" />
                            <Route component={LoginPage} exact path="/login" />
                            <PrivateRoute component={HealthPage} exact path="/health" />
                            <PrivateRoute component={J52Page} exact path="/j52" />
                            <PrivateRoute component={BidderPage} path="/bidder/:bidderId?" />
                            <PrivateRoute component={BidPercentPage} path="/bidpercent/:catalogId" />
                            <PrivateRoute component={BINPage} path="/bin" />
                            <PrivateRoute component={CatalogPage} path="/catalog" />
                            <PrivateRoute component={CategorizationPage} path="/categorization" />
                            <PrivateRoute component={MarketingPage} path="/marketing" />
                            <PrivateRoute component={StatusPage} path="/status" />
                            <PrivateRoute component={FinanceDashboardPage} path="/finance-dashboard" />
                            <PrivateRoute component={HouseSettingsPage} path="/house-settings" />
                            <PrivateRoute component={WhiteLabelPage} path="/white-label/:houseId?" />
                            <PrivateRoute component={ReviewsPage} path="/reviews" />
                            <PrivateRoute component={AtgShipPage} path="/atg-ship/:itemId?" />
                            <Route component={NotFoundPage} />
                        </Switch>
                    </Suspense>
                </Layout>
            </main>
            <Modals />
            <ToastMessage />
        </div>
    );
};

export default App;

const StyledPageContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 20px 0;
`;
