import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { deleteGlobalATGId, postGlobalATGId } from './globalATGId.api';
import {
    DeleteGlobalATGIdParams,
    DeleteGlobalATGIdResponse,
    DisplayOptions,
    PostGlobalATGIdParams,
    PostGlobalATGIdResponse,
} from './globalATGId.types';
import { dismissModal, openGlobalATGIdModal } from '../modal';
import { fetchSellersIfNeeded } from '@/redux/modules/seller';
import { getAuthToken } from '../user';
import { getDeployment } from '../config';

export const updateHouseGlobalATGId = createAsyncThunk<
    PostGlobalATGIdResponse['payload'],
    PostGlobalATGIdParams,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/globalATGId/updateHouseGlobalATGId',
    async ({ atgAuctionHouseId, houseId }, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await postGlobalATGId({
            authToken,
            deployment,
            params: {
                atgAuctionHouseId,
                houseId,
            },
        });

        if (error) {
            return rejectWithValue(new Error(`Error adding global ID for house ${houseId}`));
        }

        await dispatch(fetchSellersIfNeeded([houseId], 'none', false));
        dispatch(openGlobalATGIdModal({ displayOption: DisplayOptions.save }));

        return payload;
    }
);

export const removeHouseGlobalATGId = createAsyncThunk<
    DeleteGlobalATGIdResponse['payload'],
    DeleteGlobalATGIdParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/globalATGId/removeHouseGlobalATGId', async ({ houseId }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    const { error, payload } = await deleteGlobalATGId({ authToken, deployment, params: { houseId } });

    if (error) {
        return rejectWithValue(new Error(`Error removing global ID for house ${houseId}}`));
    }

    await dispatch(fetchSellersIfNeeded([houseId], 'none', false));
    dispatch(dismissModal());

    return payload;
});
