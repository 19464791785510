import { ActionWithPayload } from '../../types/redux';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import {
    SUBMIT_DISCOVER_PAGE_TITLES,
    SUBMIT_DISCOVER_PAGE_TITLES_ERROR,
    SUBMIT_DISCOVER_PAGE_TITLES_SUCCESS,
} from './actions';
import api from '../api/search';

/* reducer */
const DEFAULT_STATE = {
    discoverPageTitlesError: null,
    discoverPageTitlesSuccess: false,
    submitDiscoverPageTitlesLoading: false,
};

type State = typeof DEFAULT_STATE;

// @ts-ignore
export default function reducer(state: State = DEFAULT_STATE, action: ActionWithPayload<number[]> = {}) {
    switch (action.type) {
        case SUBMIT_DISCOVER_PAGE_TITLES:
            return {
                ...state,
                discoverPageTitlesSuccess: false,
                submitDiscoverPageTitlesLoading: true,
            };
        case SUBMIT_DISCOVER_PAGE_TITLES_ERROR:
            return {
                ...state,
                discoverPageTitlesError: action.payload,
                submitDiscoverPageTitlesLoading: false,
            };
        case SUBMIT_DISCOVER_PAGE_TITLES_SUCCESS:
            return {
                ...state,
                discoverPageTitlesSuccess: true,
                submitDiscoverPageTitlesLoading: false,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.discoverPages;

export const discoverPageTitlesErrorSelector = createSelector(stateSelector, (state) => state.discoverPageTitlesError);
export const discoverPageTitlesSuccessSelector = createSelector(
    stateSelector,
    (state) => state.discoverPageTitlesSuccess
);

/* ACTION CREATORS */
export const submitDiscoverPageTitles =
    (discoverPageTitles: string[]) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: { discoverPageTitles },
                type: SUBMIT_DISCOVER_PAGE_TITLES,
            });

            await api.postDiscoverPageTitles({
                authToken,
                deployment,
                discoverPageTitles,
            });
            dispatch({
                error: false,
                type: SUBMIT_DISCOVER_PAGE_TITLES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_DISCOVER_PAGE_TITLES_ERROR,
            });
        }
    };
