import { SerializedError } from '@reduxjs/toolkit';
import ms from 'ms';

export type HouseBeta = {
    enrolled: boolean;
    id: HouseBetas;
    name?: string;
};

export const HOUSE_BETA_SLICE_CACHE_TIME = ms('20s');

export type HouseBetaSlice = {
    betas: HouseBeta[];
    error?: Error | SerializedError;
    houseId?: number;
    loaded?: Date;
    loading: boolean;
    shippingSaintHouseId?: string;
    shippingSaintHouseIdError?: string;
    successfulShippingSaintHouseIdSave?: boolean;
    successfullyModified?: boolean;
};

export const defaultHouseBetaSlice: HouseBetaSlice = {
    betas: [],
    houseId: undefined,
    loading: false,
    shippingSaintHouseId: '',
};

export type UpdateHouseBetaParams = {
    beta: HouseBeta;
    houseId: number;
};

/**
 * Enum of available betas. The numeric value must match the id from the database.
 */
export enum HouseBetas {
    LivePayments = 1,
    BidLimits = 2,
    SquarePayments = 3,
    GuaranteePremiumBidders = 4,
    HtmlClerkConsole = 5,
    Autopay = 6,
    PayrixPayments = 7,
    Sfs2020 = 8,
    ArtaShipping = 9,
    Sfs2020Reopen = 10,
    HouseLivePayments = 11,
    AvaTax = 12,
    AchPayments = 13,
    PaysafePayments = 14,
    BidderApprovalSuggestions = 15,
    CatalogPayments = 16,
    Ekata = 17,
    HouseDuesAutoPay = 18, //houses paying their dues automatically
    SameDayPayrixPayments = 19,
    HighRiskPaysafe = 20,
    NewInvoiceApi = 21,
    DedicatedFollowers = 22,
    PaypalPayments = 23,
    NewInvoiceUi = 24,
    InstantAutopay = 25,
    AutoGenerateInvoices = 28,
    BidderApprovalMigration = 29,
    LiveShipping = 30,
    HighRiskPayrix = 34,
    SelfServeRefund = 35,
    PackengersShipping = 36,
    NlsShipping = 37,
    FirstTimeBiddersSkipCof = 38,
    LiveShippingInternational = 39,
    ShippingSaintShipping = 49,
    GlobalMinimumBidLimit = 51,
}

/* eslint-disable sort-keys/sort-keys-fix */
/**
 * Use the HouseBetas enum to get the display name for the beta.
 * @example betaNamesById[HouseBetas.LiveShipping]; // returns 'LiveShipping'
 * @see HouseBetas
 */
export const betaNamesById: { [betaId in HouseBetas]: string } = {
    1: 'Live Payments',
    2: 'Bidder Payments - Bid Limits',
    3: 'Bidder Payments - Square',
    4: 'Guarantee Premium Bidders',
    5: 'HTML Clerk Console',
    6: 'Bidder Payments - Autopay',
    7: 'Bidder Payments - Payrix',
    8: 'SFS2020',
    9: 'Arta Shipping',
    10: 'SFS2020 - Reopen Lot (Clerk Console)',
    11: 'House Billing - Manual payment',
    12: 'AvaTax',
    13: 'Bidder Payments - ACH',
    14: 'Bidder Payments - Paysafe non-High Risk',
    15: 'Approval ML',
    16: 'House Billing - Catalog payment',
    17: 'Ekata',
    18: 'House Billing - Autopay',
    19: 'Payrix Same Day Payouts',
    20: 'Bidder Payments - Paysafe High Risk',
    21: 'Billing API - New invoicing using the legacy database',
    22: 'Marketing - Dedicated Followers - A new promotion during catalog setup',
    23: 'Bidder Payments - Paypal Payments - Allows the house to enable paypal',
    24: 'Billing UI - The frontend beta for the new invoicing', //billingUILegacy. The apis might change behind this but I don't think the UI will ever get a new one.
    25: 'Bidder Payments - Instant Autopay',
    28: 'Auto Generate Invoices',
    29: 'Bidder Approval Migration',
    30: 'LiveShipping',
    34: 'Payrix High Risk',
    35: 'Self Serve Refund',
    36: 'LiveShipping via ThePackengers',
    37: 'LiveShipping via NLS',
    38: 'Skip CoF for first-time bidders',
    39: 'LiveShipping International Shipments',
    49: 'LiveShipping via Shipping Saint',
    51: 'Global Minimum Bid Limit',
};
/* eslint-enable sort-keys/sort-keys-fix */

export type UpdateShippingSaintHouseIdParams = {
    houseId: number;
    shippingSaintHouseId: string;
};
