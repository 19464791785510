import { CATEGORY_TRAINING_COLLECTION_PAGE_SIZE } from '../CategoryTrainingCollection.constants';
import { formatNumber } from '@/utils/format';
import { ReactElement } from 'react';
import { TRAINING_LOTS_MINIMUM_FOR_USE } from '../../CategoryTrainingDashboard/CategoryTrainingDashboardConstants';
import { TrainingItem } from '../CategoryTrainingCollection.types';
import { useIntl } from 'react-intl';
import Button from '@liveauctioneers/caterwaul-components/lib/Button/Button';
import Checkbox from '@liveauctioneers/caterwaul-components/lib/Checkbox/Checkbox';
import SearchInput from '@liveauctioneers/caterwaul-components/lib/Input/SearchInput/SearchInput';
import styled from 'styled-components';

export type CategoryTrainingCollectionHeaderProps = {
    isAdding: boolean;
    lotIdsToEdit: number[];
    numberOfCurrentItems: number;
    numberOfItemsChecked: number;
    onFilterByHouse: (newHouseId: number) => void;
    onSearch: (newSearchTerm: string) => void;
    setLotIdsToUpdate: (lotIds: number[]) => void;
    trainingItems: TrainingItem[];
    updateSelectedTrainingItems: () => void;
};

const CategoryTrainingCollectionHeader = ({
    isAdding,
    lotIdsToEdit,
    numberOfCurrentItems,
    numberOfItemsChecked,
    onFilterByHouse,
    onSearch,
    setLotIdsToUpdate,
    trainingItems,
    updateSelectedTrainingItems,
}: CategoryTrainingCollectionHeaderProps): ReactElement => {
    const intl = useIntl();
    const toggleCheckbox = () => {
        const pageTrainingIds = trainingItems.map((item) => item.id);
        // removes all selected lots on the page, but not any other pages
        const filteredLotIds = lotIdsToEdit.filter((id) => !pageTrainingIds.includes(id));
        if (numberOfItemsChecked > 0) {
            setLotIdsToUpdate(filteredLotIds);
        } else {
            // filtered lot ids is used to prevent duplicates in the array when adding new ids
            setLotIdsToUpdate([...filteredLotIds, ...pageTrainingIds]);
        }
    };

    return (
        <StyledCollectionHeader>
            <h4>
                {formatNumber(numberOfCurrentItems, intl)} of {formatNumber(TRAINING_LOTS_MINIMUM_FOR_USE, intl)}{' '}
                Training Items
            </h4>
            {isAdding ? (
                <>
                    <SearchInput
                        aria-label="Search Items"
                        onSubmit={(newSearchTerm) => {
                            onSearch(newSearchTerm);
                        }}
                        placeholder="Search Items"
                        small
                    />
                    <SearchInput
                        aria-label="Search Filter"
                        isNumber
                        label="FILTER:"
                        labelLeft
                        onSubmit={(newHouseId) => {
                            onFilterByHouse(Number(newHouseId));
                        }}
                        placeholder="Enter House ID"
                        small
                    />
                </>
            ) : (
                <>
                    <Divider />
                    <Divider />
                </>
            )}
            <Divider />
            <Checkbox
                checked={numberOfItemsChecked > 0}
                indeterminate={
                    numberOfItemsChecked > 0 &&
                    numberOfItemsChecked < CATEGORY_TRAINING_COLLECTION_PAGE_SIZE &&
                    numberOfItemsChecked < numberOfCurrentItems
                }
                label={`Selected (${formatNumber(numberOfItemsChecked, intl)})`}
                name="toggle-all-items-on-page"
                onClick={toggleCheckbox}
            />
            <StyledUpdateItemsButton disabled={numberOfItemsChecked === 0} onClick={updateSelectedTrainingItems} small>
                {isAdding ? 'Add Items' : 'Remove Items'}
            </StyledUpdateItemsButton>
        </StyledCollectionHeader>
    );
};

export default CategoryTrainingCollectionHeader;

const StyledCollectionHeader = styled.nav`
    position: sticky;
    top: 93px;
    z-index: 1;
    padding: 15px 0 0 0;
    display: flex;
    justify-content: space-around;
    background: ${({ theme }) => theme.colors.white};
`;

const Divider = styled.span``;

const StyledUpdateItemsButton = styled(Button)`
    min-width: 125px;
`;
