import { createSelector } from 'reselect';
import { GlobalState } from '@/redux/store';
import {
    SHIPPING_INCENTIVES_SLICE_CACHE_TIME,
    ShippingIncentivesSlice,
} from '@/redux/modules/shippingIncentives/shippingIncentives.types';

const getShippingIncentivesSlice = ({ shippingIncentives }: GlobalState): ShippingIncentivesSlice => shippingIncentives;
const getHouseId = (_: GlobalState, houseId: number) => houseId;

const getIsShippingIncentivesSettingsLoading = createSelector(
    [getShippingIncentivesSlice, getHouseId],
    ({ loading }, houseId) => loading.indexOf(houseId) >= 0
);

const getShippingIncentivesSettingsLoadTime = createSelector(
    [getShippingIncentivesSlice, getHouseId],
    ({ loaded }, houseId) => loaded[houseId] ?? 0
);

export const getShippingIncentiveSettingsByHouseId = createSelector(
    [getShippingIncentivesSlice, getHouseId],
    ({ byId }, houseId) => byId[houseId]
);

export const getShouldFetchShippingIncentiveSettings = createSelector(
    [getIsShippingIncentivesSettingsLoading, getShippingIncentivesSettingsLoadTime],
    (loading, loaded) => {
        if (loading) {
            return false;
        }
        const timeSinceLastLoad = Date.now() - loaded;
        return !loaded || timeSinceLastLoad > SHIPPING_INCENTIVES_SLICE_CACHE_TIME;
    }
);

export const getSaveShippingIncentivesSuccess = createSelector(
    [getShippingIncentivesSlice],
    ({ result }) => result === 'success'
);

export const getSaveShippingIncentivesError = createSelector(
    [getShippingIncentivesSlice],
    ({ result }) => result === 'failure'
);
