import { createSlice } from '@reduxjs/toolkit';
import { defaultBidderCardState } from './bidderCards.types';
import { deleteBidderCards } from './bidderCards.actions';

const bidderCardsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(deleteBidderCards.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
            slice.submitted = true;
        });
        builder.addCase(deleteBidderCards.fulfilled, (slice) => {
            slice.loading = false;
            slice.error = false;
            slice.submitted = true;
        });
        builder.addCase(deleteBidderCards.rejected, (slice) => {
            slice.loading = false;
            slice.error = true;
            slice.submitted = true;
        });
    },
    initialState: defaultBidderCardState,
    name: 'bidderCardSlice',
    reducers: {},
});

export const { reducer: bidderCardsReducer } = bidderCardsSlice;
