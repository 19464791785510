import { handleResponse, makePost } from './helpers';

type FetchItemsByIdsParams = {
    authToken: string;
    deployment: string;
    itemIds: number[];
};
export default {
    fetchItemsByIds: ({ authToken, deployment, itemIds }: FetchItemsByIdsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({ authToken, deployment, path: '<ITEM-API>spa/small/items' });
            request.send({ ids: itemIds });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
