import * as React from 'react';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import './Layout.css';

type Props = {
    children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
    return (
        <>
            <Header />
            <Navigation />
            <div className="content">{children}</div>
        </>
    );
};

export default Layout;
