import { handleResponseV2, makeGet } from '@/redux/api/helpers';
import { ShippingQuote, ShippingQuoteError } from './shippingQuote.types';

/**
 * @category Shipping API
 * @see getItemShippingQuote
 */
type FetchItemShippingQuote = {
    authToken: string;
    countryCode: string;
    deployment: string;
    itemId: number;
    postalCode: string;
};

export type ItemShippingQuoteResponse = {
    error: boolean;
    payload: ShippingQuote;
};

/**
 * Fetches a shipping estimate via Get from Shipping API
 * @function fetchItemShippingQuote
 * @category Shipping API
 * @param {FetchItemShippingQuote}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/shipping/estimate
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/2sprwrrwd6/resources/tztdqt/methods/GET
 * @see https://github.com/LIVEauctioneers/shipping-api/tree/master/functions/GetQuote-shipping-api
 */
export const getItemShippingQuote = ({
    authToken,
    countryCode,
    deployment,
    itemId,
    postalCode,
}: FetchItemShippingQuote) =>
    new Promise<ItemShippingQuoteResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'estimate',
            authToken,
            deployment,
            path: '<SHIPPING-API>',
            queryParams: { countryCode, itemId, postalCode, visibilityBypass: true },
        });
        request.end((err, response) => {
            if (response.status === 204) {
                // if response has no content then the item is not on ATG ship
                return reject(new Error(ShippingQuoteError.ItemNotOnShip));
            } else if (response.status === 400) {
                // if the request is bad then the item is either missing dimensions or is too large
                return reject(new Error(ShippingQuoteError.MissingDimensionsOrOversized));
            } else if (response.status === 500) {
                // if there is a server error then there was an issue with on the partners side
                return reject(new Error(ShippingQuoteError.PartnerError));
            }

            return handleResponseV2({
                err,
                reject,
                resolve,
                response,
            });
        });
    });
