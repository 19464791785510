import ms from 'ms';

export const SHIPPING_QUOTE_SLICE_CACHE_TIME = ms('30m');

export enum ShippingQuoteError {
    ItemNotOnShip = 'Item is not on ATG Ship',
    MissingDimensionsOrOversized = 'Not available - The item is missing dimensions or oversized',
    PartnerError = 'Not available - the partner returned uncertified quote or error',
}

export type ShippingQuote = {
    city: string;
    country: string;
    postalCode: string;
    shippingProvider: number;
    state: string;
    total: number;
};

export type ShippingQuoteSlice = {
    byId: {
        [itemId: number]: ShippingQuote;
    };
    error: ShippingQuoteError;
    loaded: {
        [itemId: number]: number;
    };
    loading: number[];
    result: 'success' | 'failure';
};

export const emptyShippingQuote: ShippingQuote = {
    city: '',
    country: '',
    postalCode: '',
    shippingProvider: undefined,
    state: '',
    total: 0,
};

export const defaultShippingQuoteSlice: ShippingQuoteSlice = {
    byId: {},
    error: undefined,
    loaded: {},
    loading: [],
    result: undefined,
};
