import { ActionWithPayload } from '../../types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
    DISBURSEMENT_FILTERS_CHANGE,
    DISBURSEMENT_FILTERS_CHANGE_FAIL,
    LOAD_HOUSE_DISBURSEMENTS_FAIL,
    LOAD_HOUSE_DISBURSEMENTS_REQUEST,
    LOAD_HOUSE_DISBURSEMENTS_SUCCESS,
} from './actions';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import api from '../api/houseDisbursements';
import moment from 'moment';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

const DEFAULT_STATE = {
    disbursementFilters: {
        from: moment().subtract(90, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: moment().toDate(),
        totalRecords: 0,
    },
    disbursements: [],
    error: undefined,
    loaded: undefined,
    loading: false,
};

type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [combineActions(DISBURSEMENT_FILTERS_CHANGE_FAIL, LOAD_HOUSE_DISBURSEMENTS_FAIL)]: (
            state: State,
            action: ActionWithPayload<{ error: string }>
        ) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [DISBURSEMENT_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                disbursementFilters: {
                    ...state.disbursementFilters,
                    ...action.payload,
                },
                loaded: undefined,
            };
            return newState;
        },
        [LOAD_HOUSE_DISBURSEMENTS_REQUEST]: (state: State) => ({
            ...state,
            loading: true,
        }),
        [LOAD_HOUSE_DISBURSEMENTS_SUCCESS]: (state: State, action: ActionWithPayload<any>) => ({
            ...state,
            disbursementFilters: {
                ...state.disbursementFilters,
                totalRecords: action.payload.totalRecords,
            },
            disbursements: action.payload.disbursements,
            loaded: new Date(),
            loading: false,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state) => state.houseDisbursements;

export const getHouseDisbursementsLoading = createSelector(stateSelector, (state) => state.loading);

const getHouseDisbursementsLoaded = createSelector(stateSelector, (state) => state.loaded);

export const getHouseDisbursements = createSelector(stateSelector, (state) => state.disbursements);

export const getHouseDisbursementsFilters = createSelector(stateSelector, (state) => state.disbursementFilters);

const shouldFetchDisbursements = createSelector(
    getHouseDisbursementsLoaded,
    getHouseDisbursementsLoading,
    getHouseDisbursementsFilters,
    (disbursementsLoaded, disbursementsLoading) => {
        if (disbursementsLoading) {
            return false;
        }
        const time = Date.now();
        const diff = time - disbursementsLoaded;
        return !disbursementsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const onDisbursementsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: DISBURSEMENT_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: DISBURSEMENT_FILTERS_CHANGE_FAIL,
        });
    }
};

export const fetchHouseDisbursementsIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchDisbursements(state)) {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                dispatch({ type: LOAD_HOUSE_DISBURSEMENTS_REQUEST });

                const response = await api.getHouseDisbursements({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                dispatch({
                    payload: {
                        disbursements: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_HOUSE_DISBURSEMENTS_SUCCESS,
                });
            } catch (error) {
                dispatch({ error: true, payload: error, type: LOAD_HOUSE_DISBURSEMENTS_FAIL });
            }
        }
    };

export const getHouseDisbursementCsv = (disbursementid: string) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await api.getHouseDisbursementCsv({
            authToken,
            deployment,
            disbursementid,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
