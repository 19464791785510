import * as api from '../api/reviews';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const FETCH_REVIEWS_START = 'FETCH_REVIEWS_START';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAIL = 'FETCH_REVIEWS_FAIL';
export const UPDATE_REVIEW_START = 'UPDATE_REVIEWS_START';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEWS_SUCCESS';
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEWS_FAIL';
export const DELETE_REVIEW_START = 'DELETE_REVIEWS_START';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEWS_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEWS_FAIL';
export const DELETE_COMMENT_START = 'DELETE_COMMENTS_START';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENTS_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENTS_FAIL';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
    reviews: [],
};

export function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case FETCH_REVIEWS_START:
            return {
                ...state,
                loading: true,
            };

        case FETCH_REVIEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: action.payload || [],
            };

        case FETCH_REVIEWS_FAIL:
            return {
                ...state,
                error: action.payload || [],
                loading: false,
            };

        default:
            return state;
    }
}

/* selectors */
const stateSelector = (state) => state.reviews;
const idSelector = (state, id) => id;
const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getReviewById = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]);

export const getReviews = (state: any) => state.reviews.reviews;

/* action creators */
type FetchReviewDataParams = {
    bidderId?: number;
    houseId?: number;
    page?: number;
    pageSize?: number;
};
export const fetchReviews =
    ({ bidderId, houseId, page, pageSize }: FetchReviewDataParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: FETCH_REVIEWS_START,
            });

            const response = await api.getReviews({
                authToken,
                bidderId,
                deployment,
                houseId,
                page,
                pageSize,
            });

            dispatch({
                payload: response.payload,
                type: FETCH_REVIEWS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_REVIEWS_FAIL,
            });
        }
    };

type UpdateReviewDataParams = {
    bidderId?: number;
    houseId?: number;
    page?: number;
    pageSize?: number;
    review: api.ReviewItem;
};
export const updateReview =
    ({ bidderId, houseId, page, pageSize, review }: UpdateReviewDataParams) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: UPDATE_REVIEW_START,
            });

            const response = await api.updateReview({
                authToken,
                deployment,
                review,
            });

            dispatch({
                payload: response.payload,
                type: UPDATE_REVIEW_SUCCESS,
            });
            dispatch(fetchReviews({ bidderId, houseId, page, pageSize }));
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: UPDATE_REVIEW_FAIL,
            });
        }
    };

type DeleteReviewDataParams = {
    bidderId?: number;
    houseId?: number;
    page?: number;
    pageSize?: number;
    reviewId: number;
};
export const deleteReview =
    ({ bidderId, houseId, page, pageSize, reviewId }: DeleteReviewDataParams) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: DELETE_REVIEW_START,
            });

            const response = await api.deleteReview({
                authToken,
                deployment,
                reviewId,
            });

            dispatch({
                payload: response.payload,
                type: DELETE_REVIEW_SUCCESS,
            });
            dispatch(fetchReviews({ bidderId, houseId, page, pageSize }));
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: DELETE_REVIEW_FAIL,
            });
        }
    };

type DeleteCommentDataParams = {
    bidderId?: number;
    commentId: number;
    houseId?: number;
    page?: number;
    pageSize?: number;
};
export const deleteComment =
    ({ bidderId, commentId, houseId, page, pageSize }: DeleteCommentDataParams) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: DELETE_COMMENT_START,
            });

            const response = await api.deleteComment({
                authToken,
                commentId,
                deployment,
            });
            dispatch({
                payload: response.payload,
                type: DELETE_COMMENT_SUCCESS,
            });

            dispatch(fetchReviews({ bidderId, houseId, page, pageSize }));
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: DELETE_COMMENT_FAIL,
            });
        }
    };
