import { CategorySuggestion, CombinationCategorySuggestion } from '../../types/CategorySuggestion';
import { CombinationCategory } from '@/types/CombinationCategory';
import { CombinationRelatedSearch, RelatedSearch } from '../../types/ReleatedSearch';
import { createSelector } from 'reselect';
import { DISMISS_MODAL, OPEN_MODAL, OPEN_MODAL_ACTION } from './actions';
import { GlobalATGIdModalData } from './globalATGId/globalATGId.types';
import { handleActions } from 'redux-actions';
import { HousePayment, Payment } from '../../types/Payment';
import { modalTypes } from '../../enums/modalTypes';

// reducer
const DEFAULT_STATE = {
    modalData: undefined,
    openModal: modalTypes.NONE,
};

type State = typeof DEFAULT_STATE;

export default handleActions(
    {
        [DISMISS_MODAL]: (state: State) => ({
            ...state,
            modalData: undefined,
            openModal: modalTypes.NONE,
        }),
        [OPEN_MODAL]: (state: State, action: OPEN_MODAL_ACTION) => ({
            ...state,
            modalData: action.payload.data,
            openModal: action.payload.modalType,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const modalSelector = (state) => state.modal;

export const openModalSelector = createSelector(modalSelector, (modal) => modal.openModal);
export const getModalData = createSelector(modalSelector, (modal) => modal.modalData);

/* ACTION CREATORS */
export const dismissModal = () => ({
    type: DISMISS_MODAL,
});

export const openSuggestedCategoryModal = (suggestion: CategorySuggestion) => ({
    payload: { data: suggestion, modalType: modalTypes.SUGGESTED_CATEGORY_MODAL },
    type: OPEN_MODAL,
});

export const openCombinationSuggestedCategoryModal = (suggestion: CombinationCategorySuggestion) => ({
    payload: { data: suggestion, modalType: modalTypes.COMBINATION_SUGGESTED_CATEGORY_MODAL },
    type: OPEN_MODAL,
});

export const openRelatedSearchModal = (suggestion: RelatedSearch) => ({
    payload: { data: suggestion, modalType: modalTypes.RELATED_SEARCH_MODAL },
    type: OPEN_MODAL,
});

export const openCombinationRelatedSearchModal = (suggestion: CombinationRelatedSearch) => ({
    payload: { data: suggestion, modalType: modalTypes.COMBINATION_RELATED_SEARCH_MODAL },
    type: OPEN_MODAL,
});

export const openCombinationCategoryModal = (combinationCategory: CombinationCategory) => ({
    payload: { data: combinationCategory, modalType: modalTypes.COMBINATION_CATEGORY_MODAL },
    type: OPEN_MODAL,
});

export const openPaymentsCancelModal = (payment: Payment) => ({
    payload: { data: payment, modalType: modalTypes.PAYMENTS_CANCEL_MODAL },
    type: OPEN_MODAL,
});

export const openPaymentsCaptureModal = (payment: Payment) => ({
    payload: { data: payment, modalType: modalTypes.PAYMENTS_CAPTURE_MODAL },
    type: OPEN_MODAL,
});

export const openPaymentsRefundModal = (payment: Payment | HousePayment, isHouseRefund?: boolean) => ({
    payload: { data: { isHouseRefund: isHouseRefund, payment: payment }, modalType: modalTypes.PAYMENTS_REFUND_MODAL },
    type: OPEN_MODAL,
});

export const openFeaturedAuctioneersModal = (featuredAuctioneersID: number) => ({
    payload: { data: featuredAuctioneersID, modalType: modalTypes.FEATURED_AUCTIONEER_MODAL },
    type: OPEN_MODAL,
});

export const openGlobalATGIdModal = (data: GlobalATGIdModalData) => ({
    payload: { data, modalType: modalTypes.GLOBAL_ATG_ID_MODAL },
    type: OPEN_MODAL,
});

export const openSellerIncentiveModal = () => ({
    payload: { modalType: modalTypes.SELLER_INCENTIVE_MODAL },
    type: OPEN_MODAL,
});

export const openErrorModal = () => ({
    payload: { data: {}, modalType: modalTypes.ERROR_MODAL },
    type: OPEN_MODAL,
});

export const openSuccessModal = () => ({
    payload: { data: {}, modalType: modalTypes.SUCCESS_MODAL },
    type: OPEN_MODAL,
});
