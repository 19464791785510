import * as React from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from '@/redux/modules/user';
import { Redirect, Route } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

type Props = {
    component: any;
    exact?: boolean;
    isAuthenticated: boolean;
    path: string;
};

class PrivateRoute extends React.Component<Props> {
    render() {
        const { component: Component, isAuthenticated, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props) => {
                    return isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }}
            />
        );
    }
}

export default connect(mapStateToProps)(PrivateRoute);
