import { $Values } from 'utility-types';

import { modalTypes } from '../../../enums/modalTypes';
import { TypedActionWithPayload } from '../../../types/redux';

export const DISMISS_MODAL = 'DISMISS_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export type OPEN_MODAL_ACTION = TypedActionWithPayload<
    typeof OPEN_MODAL,
    { data: any; modalType: $Values<typeof modalTypes> }
>;
