import { handleResponse, makeGet, makePost } from './helpers';
import moment from 'moment';
import shuffle from 'lodash/shuffle';

const transform = ({ facets, itemIDs, landingPageMetaData, sort, startIndex, totalFound }) => {
    return {
        facets,
        itemIDs: shuffle(itemIDs),
        landingPageMetaData,
        sort,
        startIndex,
        totalFound,
    };
};

// this is search ... need to adjust to use the category landing page endpoint
type FetchCategoryResultsParams = {
    deployment: string;
    ids: number[];
    searchQuery: any;
};
const fetchCategoryResults = ({ deployment, ids, searchQuery }: FetchCategoryResultsParams) =>
    new Promise<any>((resolve, reject) => {
        const encodedIds = encodeURIComponent(JSON.stringify(ids));

        let searchString = `<SEARCH-API>?ids=${encodedIds}`;

        const encodedParams = encodeURIComponent(JSON.stringify(searchQuery));
        searchString = `${searchString}&parameters=${encodedParams}`;

        const request = makeGet({ apiPath: 'categoryweb', deployment, path: searchString });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type FetchPromotedSearchResultsParams = {
    deployment: string;
    ids: number[];
    searchQuery: any;
};
const fetchPromotedSearchResults = ({ deployment, ids, searchQuery }: FetchPromotedSearchResultsParams) =>
    new Promise<any>((resolve, reject) => {
        let searchString = `<SEARCH-API>?promoted=1&parameters=${encodeURIComponent(JSON.stringify(searchQuery))}`;
        let apiPath = 'web';
        if (ids && ids.length) {
            searchString = `${searchString}&ids=${encodeURIComponent(JSON.stringify(ids))}`;
            apiPath = 'categoryweb';
        } else if (searchQuery.campaignId && searchQuery.campaignId > 0) {
            searchString = `${searchString}&ids=${encodeURIComponent('[]')}`;
            apiPath = 'categoryweb';
        }
        const request = makeGet({ apiPath, deployment, path: searchString });
        request.end((err, response) => handleResponse({ err, reject, resolve, response, transform }));
    });

type FetchSearchFacetsResultsParams = {
    deployment: string;
    searchQuery: any;
};
const fetchSearchFacetsResults = ({ deployment, searchQuery }: FetchSearchFacetsResultsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'faceted',
            deployment,
            path: `<SEARCH-API>?parameters=${encodeURIComponent(JSON.stringify(searchQuery))}`,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type FetchSearchResultsParams = {
    deployment: string;
    searchQuery: any;
};
const fetchSearchResults = ({ deployment, searchQuery }: FetchSearchResultsParams) =>
    new Promise<any>((resolve, reject) => {
        let buyNow = '';
        if (searchQuery.buyNow) {
            buyNow = `&buyNow=${encodeURIComponent(JSON.stringify(searchQuery.buyNow))}`;
            delete searchQuery.buyNow;
        }

        let buyNowDate = '';
        if (searchQuery.buyNowDate) {
            const buyNowDateMoment = moment(searchQuery.buyNowDate);

            if (buyNowDateMoment.isValid()) {
                const valueInSeconds = buyNowDateMoment.valueOf() / 1000;
                buyNowDate = `&buyNowDate=${encodeURIComponent(`${valueInSeconds}`)}`;
            }
            delete searchQuery.buyNowDate;
        }

        let searchString = `<SEARCH-API>?parameters=${encodeURIComponent(
            JSON.stringify(searchQuery)
        )}${buyNow}${buyNowDate}`;

        const request = makeGet({ apiPath: 'web', deployment, path: searchString, useCacheKey: false });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostDiscoverPageTitles = {
    authToken: string;
    deployment: string;
    discoverPageTitles: string[];
};

const postDiscoverPageTitles = ({ authToken, deployment, discoverPageTitles }: PostDiscoverPageTitles) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'adddiscoverpagetitles', authToken, deployment, path: '<SEARCH-API>' });
        request.send(discoverPageTitles);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    fetchCategoryResults,
    fetchPromotedSearchResults,
    fetchSearchFacetsResults,
    fetchSearchResults,
    postDiscoverPageTitles,
};
