export const LOAD_CATEGORY_FAIL = 'LOAD_CATEGORY_FAIL';
export const LOAD_CATEGORY_REQUEST = 'LOAD_CATEGORY_REQUEST';
export const LOAD_CATEGORY_SUCCESS = 'LOAD_CATEGORY_SUCCESS';

export const LOAD_PARENT_FAIL = 'LOAD_PARENT_FAIL';
export const LOAD_PARENT_REQUEST = 'LOAD_PARENT_REQUEST';
export const LOAD_PARENT_SUCCESS = 'LOAD_PARENT_SUCCESS';

export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';
export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';

export const EDIT_CATEGORY_FAIL = 'EDIT_CATEGORY_FAIL';
export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';

export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

export const MERGE_CATEGORY_FAIL = 'MERGE_CATEGORY_FAIL';
export const MERGE_CATEGORY_REQUEST = 'MERGE_CATEGORY_REQUEST';
export const MERGE_CATEGORY_SUCCESS = 'MERGE_CATEGORY_SUCCESS';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const SELECT_FACET = 'SELECT_FACET';

export const RESET_CATEGORY_ERROR_STATE = 'RESET_CATEGORY_ERROR_STATE';
