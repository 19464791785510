import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/saveSearch';

/* Action Types */
export const SAVE_SEARCH_CHECK_ADMIN_FAIL = 'la/ui/saveSearchCheckAdmin/FAIL';
export const SAVE_SEARCH_CHECK_ADMIN_REQUEST = 'la/ui/saveSearchCheckAdmin/REQUEST';
export const SAVE_SEARCH_CHECK_ADMIN_SUCCESS = 'la/ui/saveSearchCheckAdmin/SUCCESS';

// reducer
const DEFAULT_STATE = {
    error: false,
    isSavedSearch: false,
    savedSearchId: 0,
    success: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_SEARCH_CHECK_ADMIN_FAIL:
            return {
                ...state,
                error: true,
                isSavedSearch: false,
                savedSearchId: 0,
                success: false,
            };
        case SAVE_SEARCH_CHECK_ADMIN_REQUEST:
            return {
                ...state,
                success: false,
            };
        case SAVE_SEARCH_CHECK_ADMIN_SUCCESS:
            const savedSearchId = action.payload || 0;
            const isSavedSearch = savedSearchId > 0;
            return {
                ...state,
                isSavedSearch,
                savedSearchId,
                success: true,
            };
        default:
            return state;
    }
}

/* SELECTORS */
export const getSaveSearchCheckAdminState = (state: any) => state.saveSearchCheckAdmin;

export const getSavedSearchId = (state: any) => state.saveSearchCheckAdmin.savedSearchId;

/* ACTION CREATORS */
export const submitIsSavedSearchCheckAdmin = (ssParams: any, bidderId: number) => {
    return (dispatch: Function, getState: Function) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: SAVE_SEARCH_CHECK_ADMIN_REQUEST,
        });

        return api
            .postIsSavedSearchAdmin({ authToken, bidderId, deployment, ssParams })
            .then((response) => {
                dispatch({
                    payload: response.payload,
                    type: SAVE_SEARCH_CHECK_ADMIN_SUCCESS,
                });
            })
            .catch(() => {
                dispatch({
                    type: SAVE_SEARCH_CHECK_ADMIN_FAIL,
                });
            });
    };
};
