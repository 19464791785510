import { ActionWithPayload } from '@/types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/store';
import {
    HOUSE_AUTOPAY_FILTERS_CHANGE,
    HOUSE_AUTOPAY_FILTERS_CHANGE_FAIL,
    LOAD_HOUSE_AUTOPAY_BALANCES_FAILURE,
    LOAD_HOUSE_AUTOPAY_BALANCES_REQUEST,
    LOAD_HOUSE_AUTOPAY_BALANCES_SUCCESS,
    POST_HOUSE_AUTOPAY_NOTES_FAILURE,
    POST_HOUSE_AUTOPAY_NOTES_REQUEST,
    POST_HOUSE_AUTOPAY_NOTES_SUCCESS,
} from './actions';
import { MODIFY_HOUSE_BETA_SUCCESS } from '@/redux/modules/houseBeta/houseBeta.actions';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import { Reducer } from 'redux';
import api from '../api/houseAutopay';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

type HouseAutopaySlice = {
    autopayBalances: any[];
    autopayFilters: {
        keyword: string;
        page: number;
        pageSize: number | 'all';
        sortedASC: boolean;
        sortedId: string;
        totalRecords: number;
    };
    error: boolean;
    loaded: Date;
    loading: boolean;
};

export const defaultHouseAutopaySlice: HouseAutopaySlice = {
    autopayBalances: [],
    autopayFilters: {
        keyword: '',
        page: 1,
        pageSize: 24,
        sortedASC: true,
        sortedId: 'houseId',
        totalRecords: 0,
    },
    error: undefined,
    loaded: undefined,
    loading: false,
};

export const reducer: Reducer<HouseAutopaySlice> = handleActions(
    {
        [combineActions(HOUSE_AUTOPAY_FILTERS_CHANGE_FAIL, LOAD_HOUSE_AUTOPAY_BALANCES_FAILURE)]: (
            state: HouseAutopaySlice,
            action: ActionWithPayload<{ error: string }>
        ): HouseAutopaySlice => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [HOUSE_AUTOPAY_FILTERS_CHANGE]: (
            state: HouseAutopaySlice,
            action: ActionWithPayload<PaginationFilter>
        ): HouseAutopaySlice => {
            return {
                ...state,
                autopayFilters: {
                    ...state.autopayFilters,
                    ...action.payload,
                },
                loaded: undefined,
            };
        },
        [LOAD_HOUSE_AUTOPAY_BALANCES_REQUEST]: (state: HouseAutopaySlice): HouseAutopaySlice => ({
            ...state,
            loading: true,
        }),
        [LOAD_HOUSE_AUTOPAY_BALANCES_SUCCESS]: (
            state: HouseAutopaySlice,
            action: ActionWithPayload<any>
        ): HouseAutopaySlice => ({
            ...state,
            autopayBalances: [...action.payload?.data],
            autopayFilters: {
                ...state.autopayFilters,
                totalRecords: action.payload.totalRecords,
            },
            loaded: new Date(),
            loading: false,
        }),
        [MODIFY_HOUSE_BETA_SUCCESS]: (state: HouseAutopaySlice): HouseAutopaySlice => ({
            ...state,
            loaded: undefined,
        }),
    },
    defaultHouseAutopaySlice
);

const stateSelector = (state: GlobalState) => state.houseAutopay;

export const getHouseAutopayBalances = createSelector(stateSelector, (state) => state.autopayBalances);

export const getHouseAutopayLoading = createSelector(stateSelector, (state) => state.loading);

export const getHouseAutopayLoaded = createSelector(stateSelector, (state) => state.loaded);

export const getHouseAutopayFilters = createSelector(stateSelector, (state) => state.autopayFilters);

export const shouldFetchAutopayItems = createSelector(
    getHouseAutopayLoaded,
    getHouseAutopayLoading,
    (autopayLoaded, autopayLoading) => {
        if (autopayLoading) {
            return false;
        }
        const time = Date.now();
        const diff = time - (autopayLoaded?.valueOf() || 0);
        return !autopayLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const fetchHouseAutopayBalancesIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchAutopayItems(state)) {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);
                dispatch({ type: LOAD_HOUSE_AUTOPAY_BALANCES_REQUEST });

                const response = await api.getHouseAutopayBalances({
                    authToken,
                    deployment,
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    sortedASC: filters.sortedASC,
                    sortedId: filters.sortedId,
                });

                return dispatch({
                    payload: response.payload,
                    type: LOAD_HOUSE_AUTOPAY_BALANCES_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    payload: error.message,
                    type: LOAD_HOUSE_AUTOPAY_BALANCES_FAILURE,
                });
            }
        }
    };

export const onAutopayFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: HOUSE_AUTOPAY_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: HOUSE_AUTOPAY_FILTERS_CHANGE_FAIL,
        });
    }
};

export const postHouseAutopayNotes =
    (houseId: number, note: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({ type: POST_HOUSE_AUTOPAY_NOTES_REQUEST });

            await api.postHouseAutopayNotes({
                authToken,
                deployment,
                houseId,
                note,
            });

            return dispatch({
                payload: { houseId, note },
                type: POST_HOUSE_AUTOPAY_NOTES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message,
                type: POST_HOUSE_AUTOPAY_NOTES_FAILURE,
            });
            return Promise.reject(error);
        }
    };
