export const LOAD_HOUSE_BILLING_HISTORY_FAIL = 'LOAD_HOUSE_BILLING_HISTORY_FAIL';
export const LOAD_HOUSE_BILLING_HISTORY_REQUEST = 'LOAD_HOUSE_BILLING_HISTORY_REQUEST';
export const LOAD_HOUSE_BILLING_HISTORY_SUCCESS = 'LOAD_HOUSE_BILLING_HISTORY_SUCCESS';

export const BILLING_FILTERS_CHANGE = 'BILLING_FILTERS_CHANGE';
export const BILLING_FILTERS_CHANGE_FAIL = 'FILTERS_CHANGE_FAIL';

export const DISBURSEMENT_FILTERS_CHANGE = 'DISBURSEMENT_FILTERS_CHANGE';
export const DISBURSEMENT_FILTERS_CHANGE_FAIL = 'DISBURSEMENT_CHANGE_FAIL';

export const LOAD_HOUSE_BILLING_DISBURSEMENTS_FAIL = 'LOAD_HOUSE_BILLING_DISBURSEMENTS_FAIL';
export const LOAD_HOUSE_BILLING_DISBURSEMENTS_REQUEST = 'LOAD_HOUSE_BILLING_DISBURSEMENTS_REQUEST';
export const LOAD_HOUSE_BILLING_DISBURSEMENTS_SUCCESS = 'LOAD_HOUSE_BILLING_DISBURSEMENTS_SUCCESS';

export const POST_HOUSE_PAYMENT_REFUND_FAIL = 'POST_HOUSE_PAYMENT_REFUND_FAIL';
export const POST_HOUSE_PAYMENT_REFUND_REQUEST = 'POST_HOUSE_PAYMENT_REFUND_REQUEST';
export const POST_HOUSE_PAYMENT_REFUND_SUCCESS = 'POST_HOUSE_PAYMENT_REFUND_SUCCESS';
