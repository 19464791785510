import { AnyAction, Dispatch, ThunkAction } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createStore } from '@/redux/create';
import { getInitialState } from '@/redux/initialState';
import { ThunkDispatch } from 'redux-thunk';

let initialData = getInitialState();

// Create client-side browser history for React-Router
// Don't use these exports. Exclusively for `src/client/index.ts`
export const history = createBrowserHistory({ basename: '' });
// Create Redux store / return rootReducer so we can export the RootState
const { reducer, store } = createStore({ history, initialData });
// Don't use these exports. Exclusively for `src/client/index.ts`
export { reducer, store };

export type RootState = ReturnType<typeof reducer>;
export type GlobalState = RootState;

export type AppDispatch = ThunkDispatch<GlobalState, null, AnyAction> & Dispatch<AnyAction>;
export type AppGetState = () => GlobalState;
export type AppThunk<ReturnType = any> = ThunkAction<ReturnType, GlobalState, unknown, AnyAction>;
