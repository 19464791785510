import './polyfills';
// eslint-disable-next-line
import { ConnectedRouter } from 'connected-react-router';
import { createRoot } from 'react-dom/client';
import { HammerUIProvider } from '@liveauctioneers/hammer-ui-core/providers';
import { HelmetProvider } from 'react-helmet-async';
import { history, store } from '@/redux/store';
import { IntlProvider } from 'react-intl';
import { mergedCaterwaulAndHammerUIThemes } from '@/theme/mergedCaterwaulAndHammerUIThemes';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './pages/App/App';
import en from './intl/en';
import ErrorBoundary from '@liveauctioneers/caterwaul-components/lib/ErrorBoundary/ErrorBoundary';
import flattenMessages from '@liveauctioneers/caterwaul-components/lib/utils/flattenMessages';
import React from 'react';
import './index.css';

const THREE_HOURS = 3 * 60 * 60 * 1000;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            initialDataUpdatedAt: Date.now() / 1000,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: THREE_HOURS,
        },
    },
});

const renderTheApp = () => {
    const target = document.querySelector('#root');
    const root = createRoot(target);

    if (target) {
        root.render(
            <HammerUIProvider theme={mergedCaterwaulAndHammerUIThemes}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <ErrorBoundary onError={(e) => console.error(e)}>
                        <HelmetProvider>
                            <Provider store={store}>
                                <IntlProvider locale="en" messages={flattenMessages(en)}>
                                    <React.StrictMode>
                                        {/*@ts-ignore*/}
                                        <ConnectedRouter history={history}>
                                            <App />
                                        </ConnectedRouter>
                                    </React.StrictMode>
                                </IntlProvider>
                            </Provider>
                        </HelmetProvider>
                    </ErrorBoundary>
                </QueryClientProvider>
            </HammerUIProvider>
        );
    }
};

renderTheApp();
