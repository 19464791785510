import ms from 'ms';

export const SHIPPING_LABEL_SLICE_CACHE_TIME = ms('30m');

export enum ShippingLabelOwner {
    House = 'house',
    LA = 'la',
    Partner = 'partner',
}

export type LabelSettings = {
    isEnabled: boolean;
    owner: ShippingLabelOwner;
};

export enum ShippingLabelOption {
    Empty = '',
    Fedex = 'fedex',
    UnitedParcelService = 'ups',
    UnitedStatesPostalService = 'usps',
}

export type ShippingLabel = {
    isDefault: ShippingLabelOption;
    [ShippingLabelOption.Fedex]: LabelSettings;
    [ShippingLabelOption.UnitedParcelService]: LabelSettings;
    [ShippingLabelOption.UnitedStatesPostalService]: LabelSettings;
};

export type ShippingLabelSlice = {
    byId: {
        [houseId: number]: ShippingLabel;
    };
    loaded: {
        [houseId: number]: number;
    };
    loading: number[];
    result: 'success' | 'failure';
};

const emptyLabelSettings = {
    isEnabled: false,
    owner: ShippingLabelOwner.House,
};

export const emptyShippingLabel: ShippingLabel = {
    isDefault: ShippingLabelOption.Empty,
    [ShippingLabelOption.Fedex]: emptyLabelSettings,
    [ShippingLabelOption.UnitedParcelService]: emptyLabelSettings,
    [ShippingLabelOption.UnitedStatesPostalService]: emptyLabelSettings,
};

export const defaultShippingLabelSlice: ShippingLabelSlice = {
    byId: {},
    loaded: {},
    loading: [],
    result: undefined,
};
