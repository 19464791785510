/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { makePost } from '../api/helpers';

/* Action Types */
export const UPDATE_SERVER_AVAILABLE_FAIL = 'UPDATE_SERVER_AVAILABLE_FAIL';
export const UPDATE_SERVER_AVAILABLE_REQUEST = 'UPDATE_SERVER_AVAILABLE_REQUEST';
export const UPDATE_SERVER_AVAILABLE_SUCCESS = 'UPDATE_SERVER_AVAILABLE_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    submitted: false,
    success: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case UPDATE_SERVER_AVAILABLE_FAIL:
            return {
                ...state,
                error: action.payload,
                submitted: false,
                success: false,
            };
        case UPDATE_SERVER_AVAILABLE_REQUEST:
            return {
                ...state,
                error: null,
                submitted: true,
                success: false,
            };
        case UPDATE_SERVER_AVAILABLE_SUCCESS:
            return {
                ...state,
                error: null,
                submitted: false,
                success: true,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.updateServerAvailable;

export const getUpdateServerAvailableState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
const updateServerAvailableFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: UPDATE_SERVER_AVAILABLE_FAIL,
});

type SubmitUpdateServerAvailableParams = { isAvailable: any; serverID: number };
export const submitUpdateServerAvailable =
    ({ isAvailable, serverID }: SubmitUpdateServerAvailableParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: serverID,
                type: UPDATE_SERVER_AVAILABLE_REQUEST,
            });

            const request = makePost({
                apiPath: 'setserveravailable',
                authToken: authToken,
                deployment,
                path: '<SUPPORT-API>',
            });
            request.send({ isAvailable: isAvailable === 'true' ? true : false, serverID: Number(serverID) });

            // @ts-ignore
            request.end((err, { body } = {}) => {
                if (err) {
                    dispatch(updateServerAvailableFail(err.message));
                } else if (body && body.errorMessage) {
                    dispatch(updateServerAvailableFail(body.errorMessage));
                } else {
                    dispatch({
                        meta: { isAvailable, serverID },
                        payload: body,
                        type: UPDATE_SERVER_AVAILABLE_SUCCESS,
                    });
                }
            });
        } catch (error) {
            dispatch(updateServerAvailableFail(error.message));
        }
    };
