import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

type CreateStoreParams = {
    history: History<unknown>;
    initialData: any;
};
export const createStore = ({ history, initialData }: CreateStoreParams) => {
    const middleware = [thunk];
    const enhancers = [];
    const isProductionEnvironment = process.env.NODE_ENV === 'production';

    if (!isProductionEnvironment) {
        // @ts-expect-error
        const devToolsExtension = window.devToolsExtension;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    const reducer = createRootReducer(history);
    const options: ConfigureStoreOptions = {
        devTools: !isProductionEnvironment,
        enhancers,
        middleware: (
            getDefaultMiddleware // Add the guaranteed history, then add the middleware that's dynamically generated.
        ) =>
            // It's currently all disabled because our redux is incredibly broken as of now.
            // A prepend then concat is placed like this to maintain the order of previous middleware.
            // TODO: tl;dr we want to remove these two flags, as it will add performance gains
            getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
                .prepend(routerMiddleware(history))
                .concat(middleware)
                .concat(thunk),
        preloadedState: initialData,
        reducer,
    };
    return { reducer, store: configureStore(options) };
};
