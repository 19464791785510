import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import LoginContainer from '../Login/LoginContainer';
import styled from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';

const Header = () => {
    return (
        <StyledHeaderContainerDiv id="page-header">
            <StyledHeaderDiv>
                <Link route to="/">
                    <StyledLogo alt="LiveAuctioneers" src="https://p1.liveauctioneers.com/dist/images/logo.svg" />
                </Link>
                <StyledTitleText h1>Support Tools</StyledTitleText>
                <LoginContainer />
            </StyledHeaderDiv>
        </StyledHeaderContainerDiv>
    );
};

const StyledHeaderContainerDiv = styled.div`
    background: ${({ theme }) => theme.colors.grey500};
`;

const StyledHeaderDiv = styled.div`
    margin: 0 auto;
    padding: 20px;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > * {
        width: calc(${({ theme }) => theme.breakpoints.pageWidth} / 3);
    }
`;

const StyledLogo = styled.img`
    width: 180px;
`;

const StyledTitleText = styled(Text)`
    display: flex;
    justify-content: center;
`;

export default Header;
