import { createSelector } from 'reselect';
import {
    DELETE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS,
    FETCH_COMBINATION_SUGGESTED_CATEGORIES_FAIL,
    FETCH_COMBINATION_SUGGESTED_CATEGORIES_START,
    FETCH_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS,
    FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL,
    FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START,
    FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS,
    SAVE_COMBINATION_SUGGESTED_CATEGORY_FAIL,
    SAVE_COMBINATION_SUGGESTED_CATEGORY_REQUEST,
    SAVE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS,
} from './actions';
import { dismissModal } from './modal';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/combinationSuggestedCategory';

/* reducer */
const DEFAULT_STATE = {
    combinationSuggestedCategories: [],
    fetchCombinationSuggestedCategoryError: '',
    fetchCombinationSuggestedCategoryLoading: false,
    saveCombinationSuggestedCategoryError: '',
    saveCombinationSuggestedCategoryLoading: false,
    saveCombinationSuggestedCategorysuccess: false,
    taxonomyChildren: [],
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_COMBINATION_SUGGESTED_CATEGORY_FAIL:
            return {
                ...state,
                saveCombinationSuggestedCategoryError: action.payload,
                saveCombinationSuggestedCategoryLoading: false,
                saveCombinationSuggestedCategorysuccess: false,
            };
        case SAVE_COMBINATION_SUGGESTED_CATEGORY_REQUEST:
            return {
                ...state,
                combinationSuggestedCategories: [...state.combinationSuggestedCategories, action.payload].sort(
                    (a, b) => {
                        return a.position - b.position;
                    }
                ),
                saveCombinationSuggestedCategoryError: null,
                saveCombinationSuggestedCategoryLoading: true,
                saveCombinationSuggestedCategorysuccess: false,
            };
        case SAVE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS:
            return {
                ...state,
                combinationSuggestedCategories: action.payload || [],
                saveCombinationSuggestedCategoryError: null,
                saveCombinationSuggestedCategoryLoading: false,
                saveCombinationSuggestedCategorysuccess: true,
            };
        case FETCH_COMBINATION_SUGGESTED_CATEGORIES_START:
            return {
                ...state,
                combinationSuggestedCategories: [],
                fetchCombinationSuggestedCategoryError: null,
                fetchCombinationSuggestedCategoryLoading: true,
            };
        case FETCH_COMBINATION_SUGGESTED_CATEGORIES_FAIL:
            return {
                ...state,
                combinationSuggestedCategories: [],
                fetchCombinationSuggestedCategoryError: action.payload,
                fetchCombinationSuggestedCategoryLoading: false,
            };
        case FETCH_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS:
            return {
                ...state,
                combinationSuggestedCategories: action.payload || [],
                fetchCombinationSuggestedCategoryLoading: false,
            };
        case FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START:
            return {
                ...state,
                fetchTaxonomyChildrenError: null,
                fetchTaxonomyChildrenLoading: true,
                taxonomyChildren: [],
            };
        case FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL:
            return {
                ...state,
                fetchTaxonomyChildrenError: action.payload,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: [],
            };
        case FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS:
            return {
                ...state,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: action.payload || [],
            };
        case DELETE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS:
            return {
                ...state,
                combinationSuggestedCategories: state.combinationSuggestedCategories.length
                    ? state.combinationSuggestedCategories.filter((cat) => cat.suggestedCategoryId !== action.payload)
                    : [],
                fetchCombinationSuggestedCategoryLoading: false,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.combinationSuggestedCategory;

export const combinationSuggestedCategoriesSelector = createSelector(
    stateSelector,
    (state) => state.combinationSuggestedCategories
);
export const combinationSuggestedCategoriesTaxonomyChildrenOptionsSelector = createSelector(
    stateSelector,
    (state) => state.taxonomyChildren
);
export const suggestedCategoryErrorSelector = createSelector(
    stateSelector,
    (state) => state.saveCombinationSuggestedCategoryError
);
export const suggestedCategoryCaptureLoadingSelector = createSelector(
    stateSelector,
    (state) => state.saveCombinationSuggestedCategoryLoading
);

/* ACTION CREATORS */

export const getCombinationSuggestedCategories =
    (concatedIDs: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: concatedIDs,
                type: FETCH_COMBINATION_SUGGESTED_CATEGORIES_START,
            });

            const response = await api.getCombinationSuggestedCategoriesByConcatIDs({
                authToken,
                concatedIDs,
                deployment,
            });

            const suggestedCatsInPositionOrder = response.payload.sort((a, b) => {
                return a.position - b.position;
            });

            dispatch({
                payload: suggestedCatsInPositionOrder,
                type: FETCH_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_COMBINATION_SUGGESTED_CATEGORIES_FAIL,
            });
        }
    };

export const saveCombinationSuggestedCategory =
    (suggestedCategory) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: suggestedCategory,
                type: SAVE_COMBINATION_SUGGESTED_CATEGORY_REQUEST,
            });

            let response;
            if (
                Boolean(suggestedCategory.combinationSuggestedCategoryId) ||
                Boolean(suggestedCategory.suggestedCategoryId)
            ) {
                response = await api.updateCombinationSuggestedCategory({
                    authToken,
                    deployment,
                    suggestion: suggestedCategory,
                });
            } else {
                response = await api.postCombinationSuggestedCategory({
                    authToken,
                    deployment,
                    suggestion: suggestedCategory,
                });
            }

            const suggestedCatsInPositionOrder = response.payload.sort((a, b) => {
                return a.position - b.position;
            });

            dispatch({
                payload: suggestedCatsInPositionOrder,
                type: SAVE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS,
            });
            dispatch(dismissModal());
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SAVE_COMBINATION_SUGGESTED_CATEGORY_FAIL,
            });
        }
    };

export const deleteCombinationSuggestedCategory =
    (suggestedCategoryId) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            await api.deleteCombinationSuggestedCategory({
                authToken,
                deployment,
                idToDelete: suggestedCategoryId,
            });

            dispatch({
                payload: suggestedCategoryId,
                type: DELETE_COMBINATION_SUGGESTED_CATEGORY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SAVE_COMBINATION_SUGGESTED_CATEGORY_FAIL,
            });
        }
    };

export const getCategoriesTaxonomyChildren = (searchTerm: string) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: -1,
            type: FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START,
        });

        const response = await api.getTaxonomyCategoryChildrenByString({
            authToken,
            deployment,
            searchTerm,
        });

        const taxonomyOptions = response.payload.map((option) => {
            return {
                label: `${option.categoryId} - ${option.categoryName}`,
                value: option.categoryId,
            };
        });

        dispatch({
            payload: taxonomyOptions,
            type: FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS,
        });
        return taxonomyOptions;
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_COMBINATION_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL,
        });
    }
};
