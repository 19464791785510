import { BidderPaymentData } from '../../types/BidderPayment';
import { handleResponse, makeGet } from './helpers';

type LoadBidderPaymentDataParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

type LoadBidderPaymentDataResponse = {
    error: boolean;
    payload: BidderPaymentData;
};

export const loadBidderPaymentData = ({ authToken, bidderId, deployment }: LoadBidderPaymentDataParams) =>
    new Promise<LoadBidderPaymentDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/${bidderId}/details`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
