let deployment = process.env.REACT_APP_DEPLOYMENT || 'stage';
const buildNumber = process.env.BUILD;
const cachekey = 20170802; // change to break cache.

const hostname = window && window.location && window.location.hostname;

if (hostname.includes('barako')) {
    deployment = 'barako';
} else if (hostname.includes('stage')) {
    deployment = 'stage';
} else if (hostname.includes('preprod')) {
    deployment = 'preprod';
} else if (hostname.includes('prod')) {
    deployment = 'prod';
} else if (hostname.includes('support-tools')) {
    deployment = 'prod';
}

module.exports = {
    buildNumber,
    cachekey,
    deployment,
};
