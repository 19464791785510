import * as api from '../api/support';
import { ADD_BIDDER_NOTE_SUCCESS } from './addBidderNote';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const FETCH_BIDDER_NOTES_START = 'FETCH_BIDDER_NOTES_START';
export const FETCH_BIDDER_NOTES_SUCCESS = 'FETCH_BIDDER_NOTES_SUCCESS';
export const FETCH_BIDDER_NOTES_ERROR = 'FETCH_BIDDER_NOTES_ERROR';

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case FETCH_BIDDER_NOTES_START:
            return {
                ...state,
                loading: true,
            };

        case ADD_BIDDER_NOTE_SUCCESS:
        case FETCH_BIDDER_NOTES_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.bidderId]: action.payload,
                },
                loading: false,
            };

        case FETCH_BIDDER_NOTES_ERROR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.bidderId]: [],
                },
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.bidderNotes;
const idSelector = (state, id) => id;
const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getBidderNotes = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]);

export const getBidderNotesError = createSelector(stateSelector, (state) => state.error);

export const getBidderNotesLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
type FetchBidderNotesParams = {
    bidderId: number;
};
export const fetchBidderNotes =
    ({ bidderId }: FetchBidderNotesParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                meta: { bidderId },
                type: FETCH_BIDDER_NOTES_START,
            });

            const response = await api.loadBidderNotes({
                authToken,
                bidderId,
                deployment,
            });
            dispatch({
                meta: { bidderId },
                payload: response.payload,
                type: FETCH_BIDDER_NOTES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { bidderId },
                payload: error,
                type: FETCH_BIDDER_NOTES_ERROR,
            });
        }
    };
