export type TwoStepAuthenticationResponsePayload = {
    mfaEnabled: boolean;
    success: boolean;
};

export type FetchTwoStepAuthenticationParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

export type FetchTwoStepAuthenticationResponse = {
    error: boolean;
    payload: TwoStepAuthenticationResponsePayload | string;
};

export type UpdateTwoStepAuthenticationActivationParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

export type UpdateTwoStepAuthenticationActivationResponse = {
    error: boolean;
    payload: TwoStepAuthenticationResponsePayload | string;
};

export type TwoStepAuthenticationSlice = {
    activated?: boolean;
    loading: boolean;
};

export const defaultTwoStepAuthenticationSlice: TwoStepAuthenticationSlice = {
    activated: undefined,
    loading: false,
};
