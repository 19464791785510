import { AppDispatch, AppGetState } from '@/redux/store';
import { createSelector } from 'reselect';
import {
    FETCH_HOUSE_STYLES_ERROR,
    FETCH_HOUSE_STYLES_START,
    FETCH_HOUSE_STYLES_SUCCESS,
    MODIFY_HOUSE_STYLES_ERROR,
    MODIFY_HOUSE_STYLES_REQUEST,
    MODIFY_HOUSE_STYLES_SUCCESS,
} from './actions/whitelabel';
import { fetchWhiteLabelStylesByHouseId, updateWhiteLabelStyles } from '../api/whiteLabel';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { WhiteLabelStyles } from '../../types/WhiteLabel';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

type State = {
    error?: any;
    houseId?: number;
    loaded?: Date;
    loading: boolean;
    successfullyModified?: boolean;
    whiteLabelStyles: WhiteLabelStyles;
};

const DEFAULT_STATE: State = {
    error: undefined,
    houseId: undefined,
    loaded: undefined,
    loading: false,
    successfullyModified: undefined,
    whiteLabelStyles: [],
};

/* Reducer */
export default function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case MODIFY_HOUSE_STYLES_REQUEST:
            return {
                ...state,
                loading: true,
                successfullyModified: false,
            };
        case FETCH_HOUSE_STYLES_START:
            return {
                ...state,
                loaded: undefined,
                loading: true,
                successfullyModified: false,
            };
        case FETCH_HOUSE_STYLES_SUCCESS:
            return {
                error: undefined,
                houseId: action.payload.houseId,
                loaded: new Date(),
                loading: false,
                whiteLabelStyles: action.payload.styles,
            };
        case MODIFY_HOUSE_STYLES_SUCCESS:
            let copiedWhiteLabelStyles = [...state.whiteLabelStyles];
            let changedProperties = action.payload.map((whiteLabelStyle) => whiteLabelStyle.property);
            let whiteLabelCleanStyles = copiedWhiteLabelStyles.filter((whiteLabelStyle) => {
                return !changedProperties.includes(whiteLabelStyle.property);
            });
            let newWhiteLabelStyles = [...whiteLabelCleanStyles, ...action.payload];
            return {
                ...state,
                error: undefined,
                loading: false,
                successfullyModified: true,
                whiteLabelStyles: newWhiteLabelStyles,
            };
        case MODIFY_HOUSE_STYLES_ERROR:
        case FETCH_HOUSE_STYLES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                successfullyModified: false,
            };
        default: {
            return { ...state };
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.whiteLabelStyles;
const passThroughSelector = (state, passThroughValue) => passThroughValue;

export const getHouseStylesError = createSelector(stateSelector, (state) => state.error);
export const getHouseStylesLoaded = createSelector(stateSelector, (state) => state.loaded);
export const getHouseStylesLoading = createSelector(stateSelector, (state) => state.loading);
export const getHouseStylesSuccess = createSelector(stateSelector, (state) => state.successfullyModified);
export const getHouseStyles = createSelector(stateSelector, (state) => state.whiteLabelStyles);
export const getHouseId = createSelector(stateSelector, (state) => state.houseId);

const shouldFetchHouseStyles = createSelector(
    getHouseStylesLoaded,
    getHouseStylesLoading,
    getHouseId,
    passThroughSelector,
    (loaded, loading, storedHouseId, houseIdToFetch) => {
        if (!Boolean(houseIdToFetch) || isNaN(houseIdToFetch)) {
            return;
        }

        if (loading) {
            return false;
        }
        if (storedHouseId !== houseIdToFetch) {
            return true;
        }
        const time = Date.now();
        const diff = time - loaded;
        return !loaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

/* Action Creators */
export const fetchHouseHouseStylesIfNeeded = (houseId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    if (shouldFetchHouseStyles(state, houseId)) {
        dispatch({ type: FETCH_HOUSE_STYLES_START });
        try {
            const res = await fetchWhiteLabelStylesByHouseId({ authToken, deployment, houseId });
            dispatch({
                payload: { houseId, styles: res.payload },
                type: FETCH_HOUSE_STYLES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: FETCH_HOUSE_STYLES_ERROR,
            });
        }
    } else {
        return Promise.resolve();
    }
};

export const updateHouseStyles =
    (whiteLabelStyles: WhiteLabelStyles, houseId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: MODIFY_HOUSE_STYLES_REQUEST });
        try {
            await updateWhiteLabelStyles({ authToken, deployment, houseId, whiteLabelStyles });

            return dispatch({
                payload: whiteLabelStyles,
                type: MODIFY_HOUSE_STYLES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: MODIFY_HOUSE_STYLES_ERROR,
            });
            return Promise.reject(error);
        }
    };
