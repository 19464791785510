import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserData, getAuthToken, getUserData } from '@/redux/modules/user';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

type OwnProps = {};

const mapStateToProps = (state) => {
    return {
        authToken: getAuthToken(state),
        userData: getUserData(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUserData,
        },
        dispatch
    );

type ReduxProps = {
    authToken: string;
    userData: Object;
};
type ReduxActions = {
    fetchUserData: Function;
};

type Props = OwnProps & ReduxProps & ReduxActions;

class Auth extends Component<Props> {
    componentDidMount() {
        if (this.props.authToken) {
            this.props.fetchUserData(this.props.authToken);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { authToken, userData } = this.props;

        if ((!prevProps.authToken && authToken) || (Boolean(authToken) && prevProps.authToken !== authToken)) {
            const expires = this.getExp(authToken);

            this.setAuthCookie(authToken, expires);
        }

        // @ts-ignore
        if (prevProps.userData.username && !userData.username) {
            this.unsetCookies();
        }
    }

    getExp = (token = '') => {
        const splitToken = token.split('.');
        if (splitToken.length < 2) {
            console.warn('Failed to decode JWT expiration');
            return;
        }
        try {
            const payload = JSON.parse(atob(splitToken[1]));
            return new Date(payload.exp * 1000);
        } catch (e) {
            console.warn('Failed to decode JWT expiration', e);
        }
    };

    setAuthCookie = (authToken, expires) => {
        cookie.set('auctioneer-auth', authToken, {
            domain: '.liveauctioneers.com',
            expires,
            path: '/',
        });
    };

    unsetCookies = () => {
        cookie.remove('auctioneer-auth', { domain: '.liveauctioneers.com', path: '/' });
    };

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
