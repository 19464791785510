import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import {
    getTwoStepAuthenticationActivationStatus,
    postActivateTwoStepAuthentication,
    postDeactivateTwoStepAuthentication,
} from './twoStepAuthentication.api';
import { TwoStepAuthenticationResponsePayload } from './twoStepAuthentication.types';

export const fetchTwoStepAuthenticationActivationStatus = createAsyncThunk<
    boolean,
    number,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/twoStepAuthentication/getTwoStepAuthenticationActivationStatus',
    async (bidderId, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await getTwoStepAuthenticationActivationStatus({
            authToken,
            bidderId,
            deployment,
        });
        if (error) {
            return rejectWithValue(new Error(payload as string));
        }
        return (payload as TwoStepAuthenticationResponsePayload).mfaEnabled;
    }
);

export const activateTwoStepAuthentication = createAsyncThunk<boolean, number, AsyncThunkConfigWithRejectValue>(
    'la/ui/twoStepAuthentication/activateTwoStepAuthentication',
    async (bidderId, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await postActivateTwoStepAuthentication({ authToken, bidderId, deployment });
        if (error) {
            return rejectWithValue(new Error(payload as string));
        }
        return (payload as TwoStepAuthenticationResponsePayload).mfaEnabled;
    }
);

export const deactivateTwoStepAuthentication = createAsyncThunk<boolean, number, AsyncThunkConfigWithRejectValue>(
    'la/ui/twoStepAuthentication/deactivateTwoStepAuthentication',
    async (bidderId, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await postDeactivateTwoStepAuthentication({ authToken, bidderId, deployment });
        if (error) {
            return rejectWithValue(new Error(payload as string));
        }
        return (payload as TwoStepAuthenticationResponsePayload).mfaEnabled;
    }
);
