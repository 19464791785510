import { connect } from 'react-redux';
import { modalTypes } from '@/enums/modalTypes';
import { openModalSelector } from '@/redux/modules/modal';
import React, { Component, lazy, Suspense } from 'react';
import SellerIncentiveModal from './SellerIncentiveModal/SellerIncentiveModal';

const CombinationCategoryModal = lazy(() => import('../../pages/CategoryTaxonomy/CombinationCategoryModal'));
const SuggestedCategoryModal = lazy(() => import('../../pages/CategoryTaxonomy/SuggestedCategoryModal'));
const CombinationSuggestedCategoryModal = lazy(
    () => import('../../pages/CombinationCategory/CombinationSuggestedCategoryModal')
);
const RelatedSearchModal = lazy(() => import('../../pages/CategoryTaxonomy/RelatedSearchModal'));
const CombinationRelatedSearchModal = lazy(
    () => import('../../pages/CombinationCategory/CombinationRelatedSearchModal')
);
const PaymentsCancelModal = lazy(() => import('./PaymentsCancelModal/PaymentsCancelModalContainer'));
const PaymentsCaptureModal = lazy(() => import('./PaymentsCaptureModal/PaymentsCaptureModalContainer'));
const PaymentsRefundModal = lazy(() => import('./PaymentsRefundModal/PaymentsRefundModalContainer'));
const FeaturedAuctioneersModal = lazy(() => import('./FeaturedAuctioneerModal/FeaturedAuctioneerModal'));
const GlobalATGIdModal = lazy(() => import('./GlobalATGIdModal/GlobalATGIdModal'));
const ErrorModal = lazy(() => import('./ErrorModal/ErrorModal'));
const SuccessModal = lazy(() => import('./SuccessModal/SuccessModal'));

const mapStateToProps = (state) => {
    return {
        openModal: openModalSelector(state),
    };
};

type Props = {
    openModal: string;
};

class Modals extends Component<Props> {
    render() {
        const { openModal } = this.props;
        return (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    {openModal === modalTypes.SUGGESTED_CATEGORY_MODAL && <SuggestedCategoryModal />}
                    {openModal === modalTypes.COMBINATION_SUGGESTED_CATEGORY_MODAL && (
                        <CombinationSuggestedCategoryModal />
                    )}
                    {openModal === modalTypes.COMBINATION_CATEGORY_MODAL && <CombinationCategoryModal />}
                    {openModal === modalTypes.RELATED_SEARCH_MODAL && <RelatedSearchModal />}
                    {openModal === modalTypes.COMBINATION_RELATED_SEARCH_MODAL && <CombinationRelatedSearchModal />}
                    {openModal === modalTypes.PAYMENTS_CANCEL_MODAL && <PaymentsCancelModal />}
                    {openModal === modalTypes.PAYMENTS_CAPTURE_MODAL && <PaymentsCaptureModal />}
                    {openModal === modalTypes.PAYMENTS_REFUND_MODAL && <PaymentsRefundModal />}
                    {openModal === modalTypes.FEATURED_AUCTIONEER_MODAL && <FeaturedAuctioneersModal />}
                    {openModal === modalTypes.GLOBAL_ATG_ID_MODAL && <GlobalATGIdModal />}
                    {openModal === modalTypes.SELLER_INCENTIVE_MODAL && <SellerIncentiveModal />}
                    {openModal === modalTypes.ERROR_MODAL && <ErrorModal />}
                    {openModal === modalTypes.SUCCESS_MODAL && <SuccessModal />}
                </Suspense>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Modals);
