import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getUsername } from '../../redux/modules/user';
import { submitLogout } from '../../redux/modules/login';
import ActionText from '@liveauctioneers/caterwaul-components/lib/ActionText/ActionText';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';

type OwnProps = {};

const mapStateToProps = (state) => {
    return {
        username: getUsername(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            submitLogout,
        },
        dispatch
    );

type ReduxProps = {
    username: string;
};
type ReduxActions = {
    submitLogout: Function;
};

type Props = OwnProps & ReduxProps & ReduxActions;

class BidderPageContainer extends Component<Props> {
    render() {
        const { username } = this.props;
        return (
            <StyledLoginContainerDiv>
                {username ? (
                    <div>
                        <StyledTextUsername>{username}</StyledTextUsername>
                        {/* @ts-ignore */}
                        <StyledActionTextLogout onClick={this.props.submitLogout}>
                            <StyledTextLogout>Log out</StyledTextLogout>
                        </StyledActionTextLogout>
                    </div>
                ) : (
                    <StyledLinkLogin route to="/login">
                        <Text>Login</Text>
                    </StyledLinkLogin>
                )}
            </StyledLoginContainerDiv>
        );
    }
}

const StyledLoginContainerDiv = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledTextUsername = styled(Text)`
    padding-right: 15px;
    border-right: 1px solid ${({ theme }) => theme.colors.grey800};
`;

const StyledActionTextLogout = styled(ActionText)`
    padding-left: 15px;
`;

const StyledTextLogout = styled(Text)`
    :hover {
        color: ${({ theme }) => theme.colors.blue200};
        text-decoration: none;
    }
`;

const StyledLinkLogin = styled(Link)`
    text-decoration: none;
`;

export default connect(mapStateToProps, mapDispatchToProps)(BidderPageContainer);
