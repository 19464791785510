import { getReviews } from '@/redux/modules/reviews';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { useAppSelector } from '@/redux/hooks';
import Review from './Review';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReviewList = ({ bidderId, houseId }) => {
    const reviews = useAppSelector(getReviews);

    const reviewItems = reviews.map((review) => {
        return <Review bidderId={bidderId} data={review} houseId={houseId} key={review.reviewId} />;
    });

    return (
        <>
            <ReviewListTitle>
                <H2>Reviews Found: {reviews.length}</H2>
            </ReviewListTitle>
            <div>{reviewItems}</div>
        </>
    );
};

export default ReviewList;

const ReviewListTitle = styled.div`
    margin-bottom: 45px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
`;
