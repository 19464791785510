import { handleResponseV2, makePost } from './helpers';

type SendGlobalNotificationApiParams = {
    authToken: string;
    deployment: string;
    destinations: ('bidder-web' | 'bidder-mobile' | 'seller-web')[];
    forceReload: 'yes' | 'no';
    isProd: boolean;
    message: string;
    notificationType: 'reloadNotification' | 'textNotification';
    reloadVersion: string;
};

export const sendGlobalNotificationApi = ({
    authToken,
    deployment,
    destinations,
    forceReload,
    isProd,
    message,
    notificationType,
    reloadVersion,
}: SendGlobalNotificationApiParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'sendGlobalAppNotification',
            authToken,
            deployment,
            path: '<NOTIFICATIONS-API>',
        });
        request.send({
            destinations,
            forceReload,
            isProd,
            message,
            notificationType,
            reloadVersion,
        });
        request.end((err, response) => handleResponseV2({ err, reject, resolve, response }));
    });
