import { IntlShape } from 'react-intl';

export const formatCurrencyWithIntl = (
    value: number,
    currency: string,
    intl: any,
    minimumFractionDigits: number = 0,
    maximumFractionDigits: number = 0,
    isCents: boolean = false
) => {
    // @ts-ignore
    if (isNaN(parseFloat(value)) || !intl) {
        return value;
    }
    const valueToUse = isCents ? value / 100 : value;
    // TODO: do we want to default to showing usd if the currency is null?
    const currencyToUse = currency ? currency : 'usd';

    return intl.formatNumber(valueToUse, {
        currency: currencyToUse,
        maximumFractionDigits,
        minimumFractionDigits,
        style: 'currency',
    });
};

export const formatNumber = (
    value: number,
    intl: IntlShape,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    style?: 'decimal' | 'percent' | 'currency' | 'unit'
): string => intl.formatNumber(value, { maximumFractionDigits, minimumFractionDigits, style });
