import { handleResponse, makeGet, makePost } from './helpers';

type GetHouseAutopayBalancesParams = {
    authToken: string;
    deployment: string;
    keyword: string;
    page: number;
    pageSize: number | 'all';
    sortedASC: boolean;
    sortedId: string;
};

type PostHouseAutopayNotesParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    note: string;
};

export default {
    getHouseAutopayBalances: ({
        authToken,
        deployment,
        keyword,
        page,
        pageSize,
        sortedASC,
        sortedId,
    }: GetHouseAutopayBalancesParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'houses/billing/balancesforautopay',
                authToken,
                deployment,
                path: '<FINANCE-API>',
            });
            request.query({ keyword, page, pageSize, sortedASC, sortedId });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postHouseAutopayNotes: ({ authToken, deployment, houseId, note }: PostHouseAutopayNotesParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                apiPath: `house/${houseId}/notes`,
                authToken,
                deployment,
                path: '<FINANCE-API>',
            });
            request.send({ note });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
