import { createSelector } from 'reselect';
import { extractKeywords } from '../../pages/Marketing/AdUnits/AdUnitUtils';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { makeGet, makePost } from '../api/helpers';
import moment from 'moment';

/* Action Types */
export const SELECT_AD_UNIT = 'SELECT_AD_UNIT';

export const SUBMIT_AD_UNIT_START = 'SUBMIT_AD_UNIT_START';
export const SUBMIT_AD_UNIT_SUCCESS = 'SUBMIT_AD_UNIT_SUCCESS';
export const SUBMIT_AD_UNIT_ERROR = 'SUBMIT_AD_UNIT_ERROR';

export const UPDATE_AD_UNIT_START = 'UPDATE_AD_UNIT_START';
export const UPDATE_AD_UNIT_SUCCESS = 'UPDATE_AD_UNIT_SUCCESS';
export const UPDATE_AD_UNIT_ERROR = 'UPDATE_AD_UNIT_ERROR';

export const FETCH_AD_UNITS_START = 'FETCH_AD_UNITS_START';
export const FETCH_AD_UNITS_SUCCESS = 'FETCH_AD_UNITS_SUCCESS';
export const FETCH_AD_UNITS_ERROR = 'FETCH_AD_UNITS_ERROR';

export const DELETE_AD_UNIT_START = 'DELETE_AD_UNIT_START';
export const DELETE_AD_UNIT_SUCCESS = 'DELETE_AD_UNIT_SUCCESS';
export const DELETE_AD_UNIT_ERROR = 'DELETE_AD_UNIT_FAIL';

export const CHANGE_ADD_TABLE_VALUE = 'CHANGE_ADD_TABLE_VALUE';

/* reducer */
const DEFAULT_STATE = {
    adTypes: [],
    adUnits: [],
    adUnitsError: null,
    adUnitsLoading: false,
    catalogId: '',
    counts: {},
    houseId: '',
    houseName: '',
    selectedAdUnitId: null,
    status: '',
    submitAdUnitError: null,
    submitAdUnitLoading: false,
    type: '',
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SELECT_AD_UNIT:
            return {
                ...state,
                selectedAdUnitId: action.payload,
            };
        case SUBMIT_AD_UNIT_START:
        case UPDATE_AD_UNIT_START:
            return {
                ...state,
                submitAdUnitError: null,
                submitAdUnitLoading: true,
            };
        case SUBMIT_AD_UNIT_ERROR:
        case UPDATE_AD_UNIT_ERROR:
            return {
                ...state,
                submitAdUnitError: action.payload,
                submitAdUnitLoading: false,
            };
        case SUBMIT_AD_UNIT_SUCCESS:
        case UPDATE_AD_UNIT_SUCCESS:
            return {
                ...state,
                selectedAdUnitId: null,
                submitAdUnitLoading: false,
            };
        case FETCH_AD_UNITS_START:
            return {
                ...state,
                adTypes: {},
                adUnits: [],
                adUnitsError: null,
                adUnitsLoading: true,
                counts: {},
            };
        case FETCH_AD_UNITS_ERROR:
            return {
                ...state,
                adTypes: {},
                adUnits: [],
                adUnitsError: action.payload,
                adUnitsLoading: false,
                counts: {},
            };
        case FETCH_AD_UNITS_SUCCESS:
            return {
                ...state,
                adTypes: Object.keys(action.payload.adTypes).map((alertKey) => [
                    alertKey,
                    action.payload.adTypes[alertKey],
                ]),
                adUnits: Object.keys(action.payload.adUnits).map((key) => action.payload.adUnits[key]),
                adUnitsLoading: false,
                counts: action.payload.counts,
            };
        case CHANGE_ADD_TABLE_VALUE:
            return {
                ...state,
                [action.payload.name]: action.payload.val,
            };
        default: {
            return { ...state };
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.adUnit;

export const getSubmitAdUnitError = createSelector(stateSelector, (state) => state.submitAdUnitError);

export const getSubmitAdUnitLoading = createSelector(stateSelector, (state) => state.submitAdUnitLoading);

export const getAdUnits = createSelector(stateSelector, (state) => {
    // const now = new Date();
    return state.adUnits
        .filter((au) => {
            return !au.consignment; // && new Date(au.endDate) > now;
        })
        .sort((a, b) => a.adUnitId - b.adUnitId);
});

export const getConsignmentAdUnits = createSelector(stateSelector, (state) => {
    //const now = new Date();
    return state.adUnits
        .filter((au) => {
            return au.consignment; // && new Date(au.endDate) > now;
        })
        .sort((a, b) => a.adUnitId - b.adUnitId);
});

const getSelectedAdUnitId = createSelector(stateSelector, (state) => state.selectedAdUnitId);

export const getSelectedAdUnit = createSelector(
    [getAdUnits, getConsignmentAdUnits, getSelectedAdUnitId],
    (adUnits, consignmentAdUnits, selectedAdUnitId) =>
        [...adUnits, ...consignmentAdUnits].find((x) => x.adUnitId === selectedAdUnitId)
);

export const getAdUnitsErrors = createSelector(stateSelector, (state) => state.adUnitsError);
export const getAdTypes = createSelector(stateSelector, (state) => state.adTypes);
export const getCounts = createSelector(stateSelector, (state) => state.counts);
export const getStatus = createSelector(stateSelector, (state) => state.status);
export const getType = createSelector(stateSelector, (state) => state.type);
export const getCatalogId = createSelector(stateSelector, (state) => state.catalogId);
export const getHouseId = createSelector(stateSelector, (state) => state.houseId);
export const getHouseName = createSelector(stateSelector, (state) => state.houseName);
export const getAdUnitsLoading = createSelector(stateSelector, (state) => state.adUnitsLoading);

/* ACTION CREATORS */
const submitAdUnitFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: SUBMIT_AD_UNIT_ERROR,
});

const submitAdUnitSuccess = (result) => ({
    error: false,
    payload: result,
    type: SUBMIT_AD_UNIT_SUCCESS,
});

const updateAdUnitFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: UPDATE_AD_UNIT_ERROR,
});

const updateAdUnitSuccess = (result) => ({
    error: false,
    payload: result,
    type: UPDATE_AD_UNIT_SUCCESS,
});

const fetchAdUnitsFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: FETCH_AD_UNITS_ERROR,
});

const fetchAdUnitsSuccess = (items) => ({
    error: false,
    payload: items,
    type: FETCH_AD_UNITS_SUCCESS,
});

const deleteAdUnitFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: DELETE_AD_UNIT_ERROR,
});

const deleteAdUnitSuccess = (items) => ({
    error: false,
    payload: items,
    type: DELETE_AD_UNIT_SUCCESS,
});

const changeAdTableValue = (newVal: any) => ({
    error: false,
    payload: newVal,
    type: CHANGE_ADD_TABLE_VALUE,
});

export const updateAdTable = (newVal: any, isConsignmentPage: boolean) => async (dispatch: Function) => {
    await dispatch(changeAdTableValue(newVal));
    dispatch(fetchAdUnits(isConsignmentPage));
};

export const deleteAdUnit =
    (adUnitId: number, isConsignmentPage: boolean) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: DELETE_AD_UNIT_START });
            const request = makePost({
                apiPath: 'ad-unit/delete',
                authToken: authToken,
                deployment,
                path: '<ITEM-API>',
            });
            request.send({ ids: [adUnitId] });
            // @ts-ignore
            request.end((err, { body } = {}) => {
                if (err) {
                    dispatch(deleteAdUnitFail(err.message));
                } else if (body && body.errorMessage) {
                    dispatch(deleteAdUnitFail(body.errorMessage));
                } else {
                    dispatch(deleteAdUnitSuccess(body.data));
                    dispatch(fetchAdUnits(isConsignmentPage));
                }
            });
        } catch (error) {
            dispatch(deleteAdUnitFail(error.message));
        }
    };

export const fetchAdUnits = (isConsignmentPage) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: FETCH_AD_UNITS_START });

        const request = makeGet({
            apiPath: 'ad-unit',
            authToken: authToken,
            deployment,
            path: '<ITEM-API>',
        });
        request.query({ status: state.adUnit.status });
        request.query({ type: state.adUnit.type });
        request.query({ catalogId: state.adUnit.catalogId });
        request.query({ houseId: state.adUnit.houseId });
        request.query({ houseName: state.adUnit.houseName });
        request.query({ consignment: isConsignmentPage });

        // @ts-ignore
        request.set('Authorization', `Bearer ${authToken}`).end((err, { body } = {}) => {
            if (err || !body.success) {
                const msg = (err && err.message) || (body && body.message) || '';
                dispatch(fetchAdUnitsFail(msg));
            } else if (body && body.errorMessage) {
                dispatch(fetchAdUnitsFail(body.errorMessage));
            } else {
                dispatch(fetchAdUnitsSuccess(body.data));
            }
        });
    } catch (error) {
        dispatch(fetchAdUnitsFail(error.message));
    }
};

export const selectAdUnit = (adUnitId: number) => async (dispatch: Function) => {
    dispatch({
        payload: adUnitId,
        type: SELECT_AD_UNIT,
    });
};

export const submitAdUnit =
    (item: any, isConsignmentPage: boolean) => async (dispatch: Function, getState: Function) => {
        try {
            if (item.adUnitId) {
                return dispatch(updateAdUnit(item, isConsignmentPage));
            }
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: item,
                type: SUBMIT_AD_UNIT_START,
            });

            const request = makePost({
                apiPath: 'ad-unit/create',
                authToken: authToken,
                deployment,
                path: '<ITEM-API>',
            });
            const requestObj = {
                catalogId: Number(item.catalogId),
                categories: item.categoryIds.length ? item.categoryIds.split(',').map((x) => Number(x)) : [],
                destination: item.destination,
                endDate: moment(item.endDate).format(),
                imageUrl1: item.imageUrl1,
                imageUrl2: item.imageUrl2,
                imageUrl3: item.imageUrl3,
                itemId1: Number(item.coverLotItemId1) || null,
                itemId2: Number(item.coverLotItemId2) || null,
                itemId3: Number(item.coverLotItemId3) || null,
                keywords: item.keywords ? extractKeywords(item.keywords) : [],
                sellerId: item.sellerId,
                showOnCalendar: Boolean(item.showOnCalendar) || false,
                showOnCategoryBanner: Boolean(item.showOnCategoryBanner) || false,
                showOnCategoryNavigation: Boolean(item.showOnCategoryNavigation) || false,
                showOnInAppAuctionCalendar: Boolean(item.showOnInAppAuctionCalendar) || false,
                showOnInAppDiscoverBottom: Boolean(item.showOnInAppDiscoverBottom) || false,
                showOnInAppDiscoverTop: Boolean(item.showOnInAppDiscoverTop) || false,
                showOnItemAlert: Boolean(item.showOnItemAlert) || false,
                showOnLeaderBoardAuctionDirectory: Boolean(item.showOnLeaderBoardAuctionDirectory) || false,
                showOnLeaderBoardAuctionsNearMe: Boolean(item.showOnLeaderBoardAuctionsNearMe) || false,
                showOnLeaderBoardFavoritesPage: Boolean(item.showOnLeaderBoardFavoritesPage) || false,
                showOnLeaderBoardMessages: Boolean(item.showOnLeaderBoardMessages) || false,
                showOnLeaderBoardMyBids: Boolean(item.showOnLeaderBoardMyBids) || false,
                showOnLeaderBoardWonItems: Boolean(item.showOnLeaderBoardWonItems) || false,
                showOnPriceGuidePage: Boolean(item.showOnPriceGuidePage) || false,
                showOnSearchAlert: Boolean(item.showOnSearchAlert) || false,
                showOnSoldSearch: Boolean(item.showOnSoldSearch) || false,
                showOnUpcomingCategoryPage: Boolean(item.showOnUpcomingCategoryPage) || false,
                showOnUpcomingSearch: Boolean(item.showOnUpcomingSearch) || false,
                startDate: moment(item.startDate).format(),
            };

            request
                .send(requestObj)
                .set('Authorization', `Bearer ${authToken}`)
                // @ts-ignore
                .end((err, { body } = {}) => {
                    if (err) {
                        return dispatch(submitAdUnitFail(err.message));
                    } else if (body && (!body.success || body.errorMessage || body.error)) {
                        const error = body.errorMessage || body.error;
                        return dispatch(submitAdUnitFail(error));
                    } else {
                        const result = { id: body.data, ...item };
                        dispatch(submitAdUnitSuccess(result));
                        return dispatch(fetchAdUnits(isConsignmentPage));
                    }
                });
        } catch (error) {
            return dispatch(submitAdUnitFail(error.message));
        }
    };

export const updateAdUnit = (item: any, isConsignmentPage) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: item,
            type: UPDATE_AD_UNIT_START,
        });

        const request = makePost({
            apiPath: 'ad-unit/update',
            authToken: authToken,
            deployment,
            path: '<ITEM-API>',
        });
        const requestObj = {
            adUnitId: Number(item.adUnitId),
            catalogId: Number(item.catalogId),
            categories: item.categoryIds.length ? item.categoryIds.split(',').map((x) => Number(x)) : [],
            destination: item.destination,
            endDate: moment(item.endDate).format(),
            imageUrl1: item.imageUrl1,
            imageUrl2: item.imageUrl2,
            imageUrl3: item.imageUrl3,
            itemId1: Number(item.coverLotItemId1) || null,
            itemId2: Number(item.coverLotItemId2) || null,
            itemId3: Number(item.coverLotItemId3) || null,
            keywords: item.keywords ? extractKeywords(item.keywords) : [],
            sellerId: item.sellerId,
            showOnCalendar: Boolean(item.showOnCalendar) || false,
            showOnCategoryBanner: Boolean(item.showOnCategoryBanner) || false,
            showOnCategoryNavigation: Boolean(item.showOnCategoryNavigation) || false,
            showOnInAppAuctionCalendar: Boolean(item.showOnInAppAuctionCalendar) || false,
            showOnInAppDiscoverBottom: Boolean(item.showOnInAppDiscoverBottom) || false,
            showOnInAppDiscoverTop: Boolean(item.showOnInAppDiscoverTop) || false,
            showOnItemAlert: Boolean(item.showOnItemAlert) || false,
            showOnLeaderBoardAuctionDirectory: Boolean(item.showOnLeaderBoardAuctionDirectory) || false,
            showOnLeaderBoardAuctionsNearMe: Boolean(item.showOnLeaderBoardAuctionsNearMe) || false,
            showOnLeaderBoardFavoritesPage: Boolean(item.showOnLeaderBoardFavoritesPage) || false,
            showOnLeaderBoardMessages: Boolean(item.showOnLeaderBoardMessages) || false,
            showOnLeaderBoardMyBids: Boolean(item.showOnLeaderBoardMyBids) || false,
            showOnLeaderBoardWonItems: Boolean(item.showOnLeaderBoardWonItems) || false,
            showOnPriceGuidePage: Boolean(item.showOnPriceGuidePage) || false,
            showOnSearchAlert: Boolean(item.showOnSearchAlert) || false,
            showOnSoldSearch: Boolean(item.showOnSoldSearch) || false,
            showOnUpcomingCategoryPage: Boolean(item.showOnUpcomingCategoryPage) || false,
            showOnUpcomingSearch: Boolean(item.showOnUpcomingSearch) || false,
            startDate: moment(item.startDate).format(),
        };

        request
            .send(requestObj)
            .set('Authorization', `Bearer ${authToken}`)
            // @ts-ignore
            .end((err, { body } = {}) => {
                if (err) {
                    dispatch(updateAdUnitFail(err.message));
                } else if (body && body.errorMessage) {
                    dispatch(updateAdUnitFail(body.errorMessage));
                } else {
                    const result = { id: body.data, ...item };
                    dispatch(updateAdUnitSuccess(result));
                    dispatch(fetchAdUnits(isConsignmentPage));
                }
            });
    } catch (error) {
        dispatch(updateAdUnitFail(error.message));
    }
};
