import { handleResponse, makeGet } from './helpers';

type GetHouseDisbursementsParams = {
    authToken: string;
    deployment: string;
    from: string;
    keyword: string;
    page: number;
    pageSize: number | 'all';
    to: string;
};

type GetHouseDisbursementCsvParams = {
    authToken: string;
    deployment: string;
    disbursementid: string;
};

export default {
    getHouseDisbursementCsv: ({ authToken, deployment, disbursementid }: GetHouseDisbursementCsvParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'sellers/disbursementrecords',
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.query({ disbursementid });
            request.responseType('blob');
            request.end((err, response) => {
                if (err) {
                    return reject(err);
                }
                return resolve(response);
            });
        }),
    getHouseDisbursements: ({
        authToken,
        deployment,
        from,
        keyword,
        page,
        pageSize,
        to,
    }: GetHouseDisbursementsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'sellers/disbursements',
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.query({ from, keyword, page, pageSize, to });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
