import { fetchHouseHouseStylesIfNeeded, getHouseStylesLoading } from '@/redux/modules/whitelabel';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import Throbber from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';
import WhiteLabelHouseFinder from './WhiteLabelHouseFinder';
import WhiteLabelStyles from './WhiteLabelStyles';

const WhiteLabel = () => {
    const dispatch = useAppDispatch();

    const { params } = useRouteMatch<{ houseId?: string }>();
    const houseId: number = parseInt(params.houseId);

    const loading = useAppSelector(getHouseStylesLoading);
    const [showHouseStyles, setShowHouseStyles] = useState(Boolean(houseId));

    useEffect(() => {
        dispatch(fetchHouseHouseStylesIfNeeded(houseId));
    }, [dispatch, houseId]);

    return (
        <StyledPageContainer>
            <StyledPageTitle h1>White Label</StyledPageTitle>
            <StyledPageContent>
                <WhiteLabelHouseFinder setShowHouseStyles={setShowHouseStyles} />
                {showHouseStyles && (
                    <>
                        {loading && <Throbber />}
                        <StyledWhiteLabelStyles $loading={loading} houseId={houseId} />
                    </>
                )}
            </StyledPageContent>
        </StyledPageContainer>
    );
};

export default WhiteLabel;

const StyledPageContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 20px auto;
    padding: 0 20px;
`;

const StyledPageTitle = styled(Text)`
    margin: 30px 0;
    text-align: center;
`;

const StyledPageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledWhiteLabelStyles = styled(WhiteLabelStyles)<{ $loading: boolean }>`
    ${({ $loading }) =>
        $loading
            ? css`
                  opacity: 0;
              `
            : css`
                  opacity: 1;
              `}
`;
