import { ColorResult } from 'react-color';
import { getHouseStyles, updateHouseStyles } from '@/redux/modules/whitelabel';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';
import { WhiteLabelStyle, WhiteLabelStyles as WhiteLabelStylesType } from '../../types/WhiteLabel';
import appTheme from '@liveauctioneers/caterwaul-components/lib/theme/theme';
import Button from '@liveauctioneers/caterwaul-components/lib/Button/Button';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import styled from 'styled-components';

type Props = {
    houseId: number;
};

const WhiteLabelStyles = ({ houseId }: Props) => {
    const dispatch = useAppDispatch();

    const whiteLabelStyles = useAppSelector(getHouseStyles);

    const [dirtyStyles, setDirtyStyles] = useState<WhiteLabelStylesType>([]);

    const updateDirtyStyles = (newStyle: WhiteLabelStyle) => {
        let dirtyProperties = dirtyStyles.map((dirtyStyle) => dirtyStyle.property);
        if (dirtyProperties.includes(newStyle.property)) {
            let newDirtyStyles = dirtyStyles.map((dirtyStyle) => {
                if (dirtyStyle.property === newStyle.property) {
                    return newStyle;
                } else {
                    return dirtyStyle;
                }
            });
            setDirtyStyles(newDirtyStyles);
        } else {
            setDirtyStyles([...dirtyStyles, newStyle]);
        }
    };

    const [primaryColor, setPrimaryColor] = useState({
        property: 'primaryColor',
        value: appTheme.colors.blue100.toString(),
    });

    const updatePrimaryColor = (colorResult: ColorResult) => {
        let newPrimaryColor = { ...primaryColor, value: colorResult.hex.toString() };
        setPrimaryColor(newPrimaryColor);
        updateDirtyStyles(newPrimaryColor);
    };

    const saveStyles = () => {
        dispatch(updateHouseStyles(dirtyStyles, houseId));
        setDirtyStyles([]);
    };

    useEffect(() => {
        let primaryColor = whiteLabelStyles.filter(({ property }: WhiteLabelStyle) => {
            return property === 'primaryColor';
        })[0];

        if (primaryColor) {
            setPrimaryColor(primaryColor);
        } else {
            setPrimaryColor({ property: 'primaryColor', value: appTheme.colors.blue100 });
        }
    }, [whiteLabelStyles]);

    return (
        <StyledWhiteLabelStyles>
            <ColorPicker color={primaryColor.value} label="Primary Color" setColor={updatePrimaryColor} />
            {dirtyStyles.length !== 0 && (
                <StyledWhiteLabelButton blue onClick={saveStyles} small>
                    Save
                </StyledWhiteLabelButton>
            )}
        </StyledWhiteLabelStyles>
    );
};

const StyledWhiteLabelStyles = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const StyledWhiteLabelButton = styled(Button)`
    margin-top: 20px;
`;

export default WhiteLabelStyles;
