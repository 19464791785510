import { handleResponse, makePost } from './helpers';

type PostDeleteSavedSearchParams = {
    authToken: string;
    deployment: string;
    savedSearchId: number;
};
const postDeleteSavedSearch = ({ authToken, deployment, savedSearchId }: PostDeleteSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletesavedsearch',
            authToken,
            deployment,
            path: '<SAVED-SEARCH-API>',
            useCacheKey: false,
        });
        request.send({ savedSearchId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostDeleteSavedSearchAdminParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    savedSearchId: number;
};
const postDeleteSavedSearchAdmin = ({
    authToken,
    bidderId,
    deployment,
    savedSearchId,
}: PostDeleteSavedSearchAdminParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletesavedsearchadmin',
            authToken,
            deployment,
            path: '<SAVED-SEARCH-API>',
            useCacheKey: false,
        });
        request.send({ bidderId, savedSearchId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostIsSavedSearchParams = {
    authToken: string;
    deployment: string;
    ssParams: any;
};
const postIsSavedSearch = ({ authToken, deployment, ssParams }: PostIsSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getsearchsaved',
            authToken,
            deployment,
            path: '<SAVED-SEARCH-API>',
            useCacheKey: false,
        });
        request.send({ facets: ssParams });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostIsSavedSearchAdminParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    ssParams: any;
};
const postIsSavedSearchAdmin = ({ authToken, bidderId, deployment, ssParams }: PostIsSavedSearchAdminParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getsearchsavedadmin',
            authToken,
            deployment,
            path: '<SAVED-SEARCH-API>',
            useCacheKey: false,
        });
        request.send({ bidderId, facets: ssParams });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSaveSearchParams = {
    authToken: string;
    deployment: string;
    sendAlert: boolean;
    ssParams: any;
};
const postSaveSearch = ({ authToken, deployment, sendAlert, ssParams }: PostSaveSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'savesearch',
            authToken,
            deployment,
            path: '<SAVED-SEARCH-API>',
            useCacheKey: false,
        });
        request.send({ facets: ssParams, sendAlert });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSaveSearchAdminParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    sendAlert: boolean;
    ssParams: any;
};
const postSaveSearchAdmin = ({ authToken, bidderId, deployment, sendAlert, ssParams }: PostSaveSearchAdminParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'savesearchadmin',
            authToken,
            deployment,
            path: '<SAVED-SEARCH-API>',
            useCacheKey: false,
        });
        request.send({ bidderId, facets: ssParams, sendAlert });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    postDeleteSavedSearch,
    postDeleteSavedSearchAdmin,
    postIsSavedSearch,
    postIsSavedSearchAdmin,
    postSaveSearch,
    postSaveSearchAdmin,
};
