/* reducer */
const DEFAULT_STATE = {};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        default:
            return state;
    }
}

/* SELECTORS */
export const getLotsCols = () => [
    { name: 'lotID', title: 'Lot Id' },
    { name: 'lot_num', title: 'Lot Number' },
    { name: 'catID', title: 'Catalog Id' },
    { name: 'title', title: 'Title' },
    { name: 'listing_agent_id', title: 'Listing agent' },
];

export const getLotsRows = () => [
    {
        catID: 108012,
        listing_agent_id: 39279,
        lot_num: '0001',
        lotID: 55405896,
        title: 'Sterling Silver Cubic Zirconia Engagement Ring, 2.5ct',
    },
    {
        catID: 108012,
        listing_agent_id: 12987,
        lot_num: '0002',
        lotID: 55405897,
        title: 'Ladies 14k Yellow Gold Diamond Ring, .325ctw',
    },
    {
        catID: 108012,
        listing_agent_id: 34322,
        lot_num: '0003',
        lotID: 55405898,
        title: 'Loose Stone 5.35 ct Aquamarine',
    },
];
