import { CombinationCategory } from '../../types/CombinationCategory';
import { handleResponse, makeGet, makePost } from './helpers';

type GetCombinationCategoriesByCatIDParams = {
    authToken: string;
    categoryId: number;
    deployment: string;
};

const getCombinationCategoriesByCatID = ({
    authToken,
    categoryId,
    deployment,
}: GetCombinationCategoriesByCatIDParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationcategoriesbyid',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ categoryId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetCombinationCategoryByConcatIDsParams = {
    authToken: string;
    concatedIds: string;
    deployment: string;
};

const getCombinationCategoryByConcatIDs = ({
    authToken,
    concatedIds,
    deployment,
}: GetCombinationCategoryByConcatIDsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationcategorybyconcatids',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ concatedIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetAllCombinationCategoriesParams = {
    authToken: string;
    deployment: string;
};

const getAllCombinationCategories = ({ authToken, deployment }: GetAllCombinationCategoriesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getallcombinationcategories',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostCombinationCategoryParams = {
    authToken: string;
    combinationCategory: CombinationCategory;
    deployment: string;
};

const postCombinationCategory = ({ authToken, combinationCategory, deployment }: PostCombinationCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'addcombinationcategory', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ ...combinationCategory });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const updateCombinationCategory = ({ authToken, combinationCategory, deployment }: PostCombinationCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updatecombinationcategory',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ ...combinationCategory });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type DeleteCombinationCategoryParams = {
    authToken: string;
    deployment: string;
    idToDelete: number;
};

const deleteCombinationCategory = ({ authToken, deployment, idToDelete }: DeleteCombinationCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletecombinationcategory',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ idToDelete });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetTaxonomyCategoryChildrenByStringParams = {
    authToken: string;
    deployment: string;
    searchTerm: string;
};

const getTaxonomyCategoryChildrenByString = ({
    authToken,
    deployment,
    searchTerm,
}: GetTaxonomyCategoryChildrenByStringParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'gettaxonomiesall',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ facetId: '-1', searchTerm });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    deleteCombinationCategory,
    getAllCombinationCategories,
    getCombinationCategoriesByCatID,
    getCombinationCategoryByConcatIDs,
    getTaxonomyCategoryChildrenByString,
    postCombinationCategory,
    updateCombinationCategory,
};
