import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const SUBMIT_CUSTOM_SECTION = 'SUBMIT_CUSTOM_SECTION';
export const SUBMIT_CUSTOM_SECTION_SUCCESS = 'SUBMIT_CUSTOM_SECTION_SUCCESS';
export const SUBMIT_CUSTOM_SECTION_ERROR = 'SUBMIT_CUSTOM_SECTION_ERROR';

export const FETCH_CUSTOM_SECTION = 'FETCH_CUSTOM_SECTION';
export const FETCH_CUSTOM_SECTION_SUCCESS = 'FETCH_CUSTOM_SECTION_SUCCESS';
export const FETCH_CUSTOM_SECTION_ERROR = 'FETCH_CUSTOM_SECTION_ERROR';

/* reducer */
const DEFAULT_STATE = {
    customSectionError: null,
    customSectionLoading: false,
    customSections: {},
    submitCustomSectionLoading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SUBMIT_CUSTOM_SECTION:
            return {
                ...state,
                submitCustomSectionLoading: true,
            };
        case SUBMIT_CUSTOM_SECTION_ERROR:
            return {
                ...state,
                customSectionError: action.payload,
                submitCustomSectionLoading: false,
            };
        case SUBMIT_CUSTOM_SECTION_SUCCESS:
            return {
                ...state,
                customSections: {
                    ...state.customSections,
                    [action.payload.customSectionId]: action.payload,
                },
                selectedCustomSection: null,
                submitCustomSectionLoading: false,
            };
        case FETCH_CUSTOM_SECTION:
            return {
                ...state,
                customSectionError: null,
                customSectionLoading: true,
            };
        case FETCH_CUSTOM_SECTION_ERROR:
            return {
                ...state,
                customSectionError: action.payload,
                customSectionLoading: false,
            };
        case FETCH_CUSTOM_SECTION_SUCCESS:
            return {
                ...state,
                customSectionLoading: false,
                customSections: {
                    ...state.customSections,
                    [action.payload.customSectionId]: action.payload,
                },
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.customSection;

export const submitCustomSectionLoadingSelector = createSelector(
    stateSelector,
    (state) => state.submitCustomSectionLoading
);

export const customSectionErrorSelector = createSelector(stateSelector, (state) => state.customSectionError);

export const customSectionLoadingSelector = createSelector(stateSelector, (state) => state.customSectionLoading);

export const customSectionsSelector = createSelector(stateSelector, (state) => state.customSections);

export const customSectionSelector = (customSectionId: number) => {
    return createSelector(customSectionsSelector, (customSections) => customSections[customSectionId]);
};

export const featuredCategoryCustomSectionSelector = createSelector(
    customSectionSelector(1),
    (featuredCategoryCustomSection) => featuredCategoryCustomSection
);

/* ACTION CREATORS */
export const fetchCustomSection = (customSectionId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: FETCH_CUSTOM_SECTION });

        const response = await api.loadCustomSection({
            authToken,
            customSectionId,
            deployment,
        });
        dispatch({
            payload: response.payload,
            type: FETCH_CUSTOM_SECTION_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_CUSTOM_SECTION_ERROR,
        });
    }
};

type SubmitCustomSectionParams = { customSectionId: number; customText: string };
export const submitCustomSection =
    ({ customSectionId, customText }: SubmitCustomSectionParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: { customSectionId, customText },
                type: SUBMIT_CUSTOM_SECTION,
            });

            const response = await api.postUpdateCustomSection({
                authToken,
                customSectionId,
                customText,
                deployment,
            });
            dispatch({
                error: false,
                payload: { customSectionId: response.payload.Id, customText },
                type: SUBMIT_CUSTOM_SECTION_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_CUSTOM_SECTION_ERROR,
            });
        }
    };
