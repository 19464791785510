import * as sellerApi from '../api/seller';
import { ActionWithPayload } from '../../types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { Item } from '../../types/Item';
import { LOAD_CATALOGS_SUCCESS } from './actions';
import { loadBatchEnd, loadBatchStart } from './fetchBatch';
import { removeHouseGlobalATGIdFulfilled, updateHouseGlobalATGIdFulfilled } from './globalATGId/globalATGId.types';
import { Seller } from '../../types/Seller';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

export const LOAD_SELLERS_FAIL = 'la/domain/sellers/LOAD_FAIL';
export const LOAD_SELLERS_REQUEST = 'la/domain/sellers/LOAD_REQUEST';
export const LOAD_SELLERS_SUCCESS = 'la/domain/sellers/LOAD_SUCCESS';

/* Action Types */

const REDUX_STORE_TIME = ms('30m');
const FETCH_SET_SIZE = 120;

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loadedSellers: false,
    loading: [],
    loadingSellers: false,
};

type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_SELLERS_FAIL]: (state: State, action: ActionWithPayload<{}, { sellerIds: number[] }>) => ({
            ...state,
            loading: difference(state.loading, action.meta.sellerIds),
        }),
        [LOAD_SELLERS_REQUEST]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [combineActions(LOAD_SELLERS_SUCCESS, LOAD_CATALOGS_SUCCESS)]: (
            state: State,
            action: ActionWithPayload<{ items: Item[]; sellers: Seller[] }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.sellers) {
                action.payload.sellers.forEach((item) => {
                    existing[item.sellerId] = { ...item };
                    loaded[item.sellerId] = time;
                    loading = difference(loading, [item.sellerId]);
                });
            }
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
        [removeHouseGlobalATGIdFulfilled]: (state: State, action: ActionWithPayload<{ houseId: number }>) => {
            const existing = cloneDeep(state.byId);

            if (action.payload.houseId) {
                existing[action.payload.houseId].globalATGId = null;
            }
            return {
                ...state,
                byId: existing,
            };
        },
        [updateHouseGlobalATGIdFulfilled]: (
            state: State,
            action: ActionWithPayload<{ atgAuctionHouseId: string; houseId: number }>
        ) => {
            const existing = cloneDeep(state.byId);

            if (action.payload.houseId) {
                existing[action.payload.houseId].globalATGId = action.payload.atgAuctionHouseId;
            }
            return {
                ...state,
                byId: existing,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.seller;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

const getLoadTimeForSeller = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const getIsSellerLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const getSeller = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

export const shouldFetchSeller = (state: any, sellerId: number) => {
    if (!sellerId) {
        return false;
    }
    const item = getSeller(state, sellerId);
    if (item) {
        const loaded = getLoadTimeForSeller(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = getIsSellerLoading(state, sellerId);
    return !loading;
};

const whichSellersNeeded = (state, sellerIds) => sellerIds.filter((sellerId) => shouldFetchSeller(state, sellerId));

/* ACTION CREATORS */

// seller
const loadSellers = (sellerIds, loadingId, isFinalFetch, useCacheKey?: boolean) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            payload: sellerIds,
            type: LOAD_SELLERS_REQUEST,
        });
        const response = await sellerApi.fetchSellersByIds({ authToken, deployment, sellerIds, useCacheKey });
        dispatch({
            meta: { actionTime: Date.now(), sellerIds },
            payload: response.data,
            type: LOAD_SELLERS_SUCCESS,
        });
        if (isFinalFetch) {
            dispatch(loadBatchEnd(loadingId));
        }
    } catch (error) {
        dispatch({
            error: true,
            meta: { sellerIds },
            payload: error,
            type: LOAD_SELLERS_FAIL,
        });
        dispatch(loadBatchEnd(loadingId));
    }
};

const loadSellersInSets = (ids, setSize, loadingId, useCacheKey?: boolean) => {
    return (dispatch) => {
        if (ids.length > setSize) {
            return dispatch(loadSellers(ids.slice(0, setSize), loadingId, false)).then(() => {
                dispatch(loadSellersInSets(ids.slice(setSize), setSize, loadingId));
            });
        }
        return dispatch(loadSellers(ids, loadingId, true, useCacheKey));
    };
};

export const fetchSellersIfNeeded = (sellerIds: number[], loadingId: string = 'none', useCacheKey?: boolean) => {
    return (dispatch: Function, getState: Function) => {
        const neededSellers = whichSellersNeeded(getState(), sellerIds);
        // const neededCatalogCounts = whichSellerCatalogCountsNeeded(getState(), sellerIds);
        // const needed = union(neededSellers, neededCatalogCounts);
        if (neededSellers.length) {
            dispatch(loadBatchStart(loadingId));
            return dispatch(loadSellersInSets(neededSellers, FETCH_SET_SIZE, loadingId, useCacheKey));
        }
        return Promise.resolve();
    };
};
