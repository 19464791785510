import { FeaturedAuctioneer } from '@/types/FeaturedAuctioneer';
import { handleResponse, makeDelete, makeGet, makePost, makePut } from './helpers';

type FetchFeaturedAuctioneersParams = {
    authToken: string;
    deployment: string;
};

type FetchFeaturedAuctioneersPayload = {
    error: boolean;
    payload: FeaturedAuctioneer[];
};

type PostFeaturedAuctioneersParams = {
    authToken: string;
    deployment: string;
    destinationURL: string;
    endTime: string;
    houseId: number;
    imageURL: string;
    positionColumn: number;
    startTime: string;
};

type EditFeaturedAuctioneersParams = {
    authToken: string;
    deployment: string;
    destinationURL: string;
    endTime: string;
    featuredAuctioneerId: number;
    houseId: number;
    imageURL: string;
    positionColumn: number;
    startTime: string;
};

type PostFeaturedAuctioneersPayload = {
    error: boolean;
    payload: string;
};

type DeleteFeaturedAuctioneersParams = {
    authToken: string;
    deployment: string;
    featuredAuctioneerID: number;
};

export default {
    deleteFeaturedAuctioner: ({ authToken, deployment, featuredAuctioneerID }: DeleteFeaturedAuctioneersParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeDelete({
                apiPath: `featuredauctioneer/${featuredAuctioneerID}`,
                authToken,
                deployment,
                path: '<PROMOTION-API-TWO>',
            });
            request.end((err: boolean, response: PostFeaturedAuctioneersPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),

    editFeaturedAuctioneers: ({
        authToken,
        deployment,
        destinationURL,
        endTime,
        featuredAuctioneerId,
        houseId,
        imageURL,
        positionColumn,
        startTime,
    }: EditFeaturedAuctioneersParams) =>
        new Promise<PostFeaturedAuctioneersPayload>((resolve, reject) => {
            const request = makePut({
                apiPath: 'featuredauctioneer',
                authToken,
                deployment,
                path: '<PROMOTION-API-TWO>',
            });
            request.send({
                destinationURL,
                endTime,
                featuredAuctioneerId,
                houseId,
                imageURL,
                positionColumn,
                startTime,
            });
            request.end((err: boolean, response: PostFeaturedAuctioneersPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),

    fetchFeaturedAuctioneers: ({ authToken, deployment }: FetchFeaturedAuctioneersParams) =>
        new Promise<FetchFeaturedAuctioneersPayload>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'featuredauctioneer',
                authToken,
                deployment,
                path: '<PROMOTION-API-TWO>',
            });
            request.end((err: boolean, response: FetchFeaturedAuctioneersPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),

    postFeaturedAuctioneers: ({
        authToken,
        deployment,
        destinationURL,
        endTime,
        houseId,
        imageURL,
        positionColumn,
        startTime,
    }: PostFeaturedAuctioneersParams) =>
        new Promise<PostFeaturedAuctioneersPayload>((resolve, reject) => {
            const request = makePost({
                apiPath: 'featuredauctioneer',
                authToken,
                deployment,
                path: '<PROMOTION-API-TWO>',
            });
            request.send({ destinationURL, endTime, houseId, imageURL, positionColumn, startTime });
            request.end((err: boolean, response: PostFeaturedAuctioneersPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
