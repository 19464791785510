import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const REMOVE_LISTINGAGENT_BIN_ITEMS = 'REMOVE_LISTINGAGENT_BIN_ITEMS';
export const REMOVE_LISTINGAGENT_BIN_ITEMS_SUCCESS = 'REMOVE_LISTINGAGENT_BIN_ITEMS_SUCCESS';
export const REMOVE_LISTINGAGENT_BIN_ITEMS_ERROR = 'REMOVE_LISTINGAGENT_BIN_ITEMS_ERROR';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case REMOVE_LISTINGAGENT_BIN_ITEMS:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case REMOVE_LISTINGAGENT_BIN_ITEMS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case REMOVE_LISTINGAGENT_BIN_ITEMS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.listingAgentBIN;

export const getRemoveListingAgentBINItemsError = createSelector(stateSelector, (state) => state.error);

export const getRemoveListingAgentBINItemsLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export const removeListingAgentBINItems =
    (listingAgentId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: listingAgentId,
                type: REMOVE_LISTINGAGENT_BIN_ITEMS,
            });

            const response = await api.postRemoveListingAgentBINItems({
                authToken,
                deployment,
                listingAgentId,
            });
            dispatch({
                meta: { listingAgentId },
                payload: response.payload,
                type: REMOVE_LISTINGAGENT_BIN_ITEMS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: REMOVE_LISTINGAGENT_BIN_ITEMS_ERROR,
            });
        }
    };
