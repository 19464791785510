import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import { getItemShippingQuote } from './shippingQuote.api';
import { getShouldFetchShippingQuote } from './shippingQuote.selectors';
import { ShippingQuote } from './shippingQuote.types';

type ShippingQuoteParams = {
    countryCode: string;
    itemId: number;
    postalCode: string;
};

export const fetchShippingQuote = createAsyncThunk<ShippingQuote, ShippingQuoteParams>(
    'la/ui/shippingQuote/fetchShippingQuote',
    async ({ countryCode, itemId, postalCode }, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            const { payload } = await getItemShippingQuote({
                authToken,
                countryCode,
                deployment,
                itemId,
                postalCode,
            });

            return payload;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchShippingQuoteIfNeeded = createAsyncThunk<void, ShippingQuoteParams>(
    'la/ui/shippingQuote/fetchShippingQuoteSettingsIfNeeded',
    async ({ countryCode, itemId, postalCode }, { dispatch, getState }) => {
        const state = getState();
        const shouldFetch = getShouldFetchShippingQuote(state, itemId);
        if (shouldFetch) {
            await dispatch(fetchShippingQuote({ countryCode, itemId, postalCode }));
        }
    }
);
