import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken, getUsername } from './user';
import { getDeployment } from './config';

/* Action Types */
export const ADD_BIDDER_NOTE_START = 'ADD_BIDDER_NOTE_START';
export const ADD_BIDDER_NOTE_SUCCESS = 'ADD_BIDDER_NOTE_SUCCESS';
export const ADD_BIDDER_NOTE_ERROR = 'ADD_BIDDER_NOTE_ERROR';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case ADD_BIDDER_NOTE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ADD_BIDDER_NOTE_START:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case ADD_BIDDER_NOTE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.addBidderNote;

export const getAddBidderNoteState = createSelector(stateSelector, (state) => state);

export const getAddBidderNoteError = createSelector(stateSelector, (state) => state.error);

export const getAddBidderNoteLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
type AddBidderNoteParams = { bidderId: number; note: string };
export const addBidderNote =
    ({ bidderId, note }: AddBidderNoteParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const username = getUsername(state);
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: ADD_BIDDER_NOTE_START,
            });
            const response = await api.postSetBidderNotes({
                authToken,
                bidderId,
                deployment,
                note,
                username,
            });
            dispatch({
                meta: { bidderId },
                payload: response.payload,
                type: ADD_BIDDER_NOTE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: ADD_BIDDER_NOTE_ERROR,
            });
        }
    };
