import { ActionWithPayload } from '../../types/redux';
import { BidPercentItem } from '../../types/BidPercent';
import { createSelector } from 'reselect';
import {
    FETCH_BID_PRECENT_DATA_FAILURE,
    FETCH_BID_PRECENT_DATA_REQUEST,
    FETCH_BID_PRECENT_DATA_SUCCESS,
} from './actions';
import { getAuthToken } from './user';
import { getBidPercentData as getBidPercentDataApi } from '../api/analytics';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

type State = typeof DEFAULT_STATE;

type PercentData = {
    catalogId: number;
    created: number;
    houseId: number;
    itemId: number;
    pageUrl: string;
    percentage: number;
    sessionId: string;
    userId: number;
};

type PlacedData = {
    amount: number;
    catalogId: number;
    created: number;
    houseId: number;
    itemId: number;
    pageUrl: string;
    sessionId: string;
    userId: number;
};

export const transformBidPercentData = (percentData: PercentData[], placedData: PlacedData[]): BidPercentItem[] => {
    percentData.sort((a, b) => a.created - b.created);
    placedData.sort((a, b) => a.created - b.created);

    const getData = [];

    for (let percentCursor = 0; percentCursor < percentData.length; percentCursor++) {
        const currentPercent = percentData[percentCursor];

        const getItemToAdd: BidPercentItem = {
            bidCast: false,
            bidCastTime: null,
            itemId: currentPercent.itemId,
            pass: currentPercent.percentage === 0 ? 'Pass' : '',
            percent: currentPercent.percentage,
            percentTime: currentPercent.created,
            seconds: 0,
        };

        let nextPercent = percentData[percentCursor + 1];
        let nextPercentTime = nextPercent ? nextPercent.created : Infinity;

        for (let placedCursor = 0; placedCursor < placedData.length; placedCursor++) {
            const currentPlaced = placedData[placedCursor];
            // if this placed data is newer than the current percent data, and is less than the next percent data
            if (currentPlaced.created > currentPercent.created && currentPlaced.created <= nextPercentTime) {
                // and the itemid matches and we have not stored a bid yet
                if (currentPlaced.itemId === currentPercent.itemId && !getItemToAdd.bidCast) {
                    getItemToAdd.bidCast = true;
                    getItemToAdd.bidCastTime = currentPlaced.created;
                    getItemToAdd.seconds = currentPlaced.created - currentPercent.created;
                }
            }
        }

        if (getItemToAdd.percent > 50 && getItemToAdd.seconds < 5) {
            getItemToAdd.pass = 'Pass';
        }
        if (getItemToAdd.percent <= 50 && getItemToAdd.seconds > 5) {
            getItemToAdd.pass = 'Pass';
        }
        if (getItemToAdd.percent >= 50 && getItemToAdd.seconds === 0) {
            getItemToAdd.pass = 'Overstated';
        }
        if (getItemToAdd.percent <= 30 && getItemToAdd.seconds > 0) {
            getItemToAdd.pass = 'Understated';
        }
        if (getItemToAdd.percent >= 1 && getItemToAdd.seconds > 5) {
            getItemToAdd.pass = 'Slow Bid';
        }

        getData.push(getItemToAdd);
    }
    return getData;
};

export const reducer = handleActions(
    {
        [FETCH_BID_PRECENT_DATA_FAILURE]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.catalogId]),
        }),
        [FETCH_BID_PRECENT_DATA_REQUEST]: (state: State, action: ActionWithPayload<number>) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [FETCH_BID_PRECENT_DATA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                { percentData: PercentData[]; placedData: PlacedData[] },
                { actionTime: number; catalogId: number }
            >
        ) => {
            const existing = cloneDeep(state.byId);
            const time = action.meta.actionTime;

            existing[action.meta.catalogId] = transformBidPercentData(
                action.payload.percentData,
                action.payload.placedData
            );

            const loaded = {
                ...state.loaded,
                [action.meta.catalogId]: time,
            };
            const loading = difference(state.loading, [action.meta.catalogId]);

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.bidPercentData;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getBidPercentData = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

/* ACTION CREATORS */
export const fetchBidPercentData =
    ({ catalogId }: { catalogId: number }) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: catalogId,
                type: FETCH_BID_PRECENT_DATA_REQUEST,
            });

            const response = await getBidPercentDataApi({ authToken, catalogId, deployment });

            dispatch({
                meta: { actionTime: Date.now(), catalogId },
                payload: response.payload,
                type: FETCH_BID_PRECENT_DATA_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { catalogId },
                payload: error,
                type: FETCH_BID_PRECENT_DATA_FAILURE,
            });
        }
    };
