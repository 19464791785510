import { reducer as availableServers } from './modules/availableServers';
import { bidderCardsReducer } from './modules/bidderCards/bidderCards.reducer';
import { reducer as bidderPayment } from './modules/bidderPayment';
import { bidderProfileSliceReducer } from './modules/bidderProfile/bidderProfile.reducer';
import { reducer as bidPercentData } from './modules/bidPercentData';
import { reducer as catalog } from './modules/catalog';
import { reducer as catalogOnly } from './modules/catalogOnly';
import { reducer as categoryTaxonomy } from './modules/categoryTaxonomy';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as coverLots } from './modules/coverLots';
import { reducer as featuredAuctioneers } from './modules/featuredAuctioneers';
import { globalATGIdReducer } from './modules/globalATGId/globalATGId.reducer';
import { reducer as globalNotification } from './modules/globalNotification';
import { reducer as houseAutopay } from './modules/houseAutopay';
import { houseBetaReducer } from '@/redux/modules/houseBeta/houseBeta.reducer';
import { reducer as houseBilling } from './modules/houseBilling';
import { reducer as houseDisbursements } from './modules/houseDisbursements';
import { reducer as reviews } from './modules/reviews';
import { reducer as searchSuggestions } from './modules/searchSuggestions';
import { reducer as seller } from './modules/seller';
import { sellerIncentiveReducer } from './modules/sellerIncentive/sellerIncentive.reducer';
import { reducer as serverStatus } from './modules/serverStatus';
import { shippingIncentivesReducer } from '@/redux/modules/shippingIncentives/shippingIncentives.reducer';
import { shippingLabelReducer } from './modules/shippingLabel/shippingLabel.reducer';
import { shippingQuoteReducer } from './modules/shippingQuote/shippingQuote.reducer';
import { trainingCollectionReducer } from './modules/trainingCollection';
import { trainingReducer } from './modules/training';
import { twoStepAuthenticationReducer } from '@/redux/modules/twoStepAuthentication/twoStepAuthentication.reducer';
import addBidderNote from './modules/addBidderNote';
import adUnit from './modules/adUnit';
import bidderNotes from './modules/bidderNotes';
import bidderTransCaseStatus from './modules/caseStatus';
import binHouse from './modules/binHouse';
import catalogBIN from './modules/catalogBIN';
import category from './modules/category';
import changeSaleType from './modules/changeSaleType';
import chargebacks from './modules/chargeback';
import combinationCategory from './modules/combinationCategory';
import combinationRelatedSearch from './modules/combinationRelatedSearch';
import combinationSuggestedCategory from './modules/combinationSuggestedCategory';
import config from './modules/config';
import customSection from './modules/customSection';
import discoverPages from './modules/discoverPages';
import fetchBatch from './modules/fetchBatch';
import health from './modules/health';
import item from './modules/item';
import listingAgentBIN from './modules/listingAgentBIN';
import login from './modules/login';
import lots from './modules/lots';
import lotsSold from './modules/lotsSold';
import markAsPaid from './modules/markAsPaid';
import modal from './modules/modal';
import moveServer from './modules/moveServer';
import payments from './modules/payment';
import paymentTools from './modules/paymentTools';
import paysafeOnboard from './modules/paysafeOnboard';
import refund from './modules/refund';
import relatedSearch from './modules/relatedSearch';
import removeBINLots from './modules/itemBINLots';
import removeFromShipping from './modules/removeFromShipping';
import resetSale from './modules/resetSale';
import saveSearchAdmin from './modules/saveSearchAdmin';
import saveSearchCheckAdmin from './modules/saveSearchCheckAdmin';
import search from './modules/search';
import searchFacets from './modules/searchFacets';
import sellerInvoice from './modules/sellerInvoice';
import suggestedCategory from './modules/suggestedCategory';
import updateLotNumbers from './modules/updateLotNumbers';
import updateServerAvailable from './modules/updateServerAvailable';
import uploadCatalog from './modules/uploadCatalog';
import user from './modules/user';
import whiteLabelStyles from './modules/whitelabel';

export default (history: any) =>
    combineReducers({
        addBidderNote,
        adUnit,
        availableServers,
        bidderCards: bidderCardsReducer,
        bidderDetails: bidderProfileSliceReducer,
        bidderNotes,
        bidderPayment,
        bidderTransCaseStatus,
        bidPercentData,
        binHouse,
        catalog,
        catalogBIN,
        catalogOnly,
        category,
        categoryTaxonomy,
        changeSaleType,
        chargebacks,
        combinationCategory,
        combinationRelatedSearch,
        combinationSuggestedCategory,
        config,
        coverLots,
        customSection,
        discoverPages,
        featuredAuctioneers,
        fetchBatch,
        globalATGId: globalATGIdReducer,
        globalNotification,
        health,
        houseAutopay,
        houseBetas: houseBetaReducer,
        houseBilling,
        houseDisbursements,
        item,
        listingAgentBIN,
        login,
        lots,
        lotsSold,
        markAsPaid,
        modal,
        moveServer,
        payments,
        paymentTools,
        paysafeOnboard,
        refund,
        relatedSearch,
        removeBINLots,
        removeFromShipping,
        resetSale,
        reviews,
        router: connectRouter(history),
        saveSearchAdmin,
        saveSearchCheckAdmin,
        search,
        searchFacets,
        searchSuggestions,
        seller,
        sellerIncentive: sellerIncentiveReducer,
        sellerInvoice,
        serverStatus,
        shippingIncentives: shippingIncentivesReducer,
        shippingLabel: shippingLabelReducer,
        shippingQuote: shippingQuoteReducer,
        suggestedCategory,
        training: trainingReducer,
        trainingCollection: trainingCollectionReducer,
        twoStepAuthentication: twoStepAuthenticationReducer,
        updateLotNumbers,
        updateServerAvailable,
        uploadCatalog,
        user,
        whiteLabelStyles,
    });
