import { ActionWithPayload } from '../../types/redux';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { postPaysafeOnboard } from '../api/payment';
import { SUBMIT_PAYSAFE_ONBOARD_FAIL, SUBMIT_PAYSAFE_ONBOARD_REQUEST, SUBMIT_PAYSAFE_ONBOARD_SUCCESS } from './actions';

type State = {
    onboardPaysafeError?: string;
    onboardPaysafeResult?: string;
    onboardPaysafeSubmitted: boolean;
};

const DEFAULT_STATE: State = {
    onboardPaysafeError: undefined,
    onboardPaysafeResult: undefined,
    onboardPaysafeSubmitted: false,
};

/* reducer */
export default handleActions(
    {
        [SUBMIT_PAYSAFE_ONBOARD_FAIL]: (state: State, action: any) => ({
            ...state,
            onboardPaysafeError: action.payload,
            onboardPaysafeResult: undefined,
            onboardPaysafeSubmitted: false,
        }),
        [SUBMIT_PAYSAFE_ONBOARD_REQUEST]: (state: State) => ({
            ...state,
            onboardPaysafeError: undefined,
            onboardPaysafeResult: undefined,
            onboardPaysafeSubmitted: true,
        }),
        [SUBMIT_PAYSAFE_ONBOARD_SUCCESS]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            onboardPaysafeError: undefined,
            onboardPaysafeResult: action.payload,
            onboardPaysafeSubmitted: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.paysafeOnboard;

export const getPaysafeOnboardResult = createSelector(stateSelector, (state) => state.onboardPaysafeResult);

export const getPaysafeOnboardError = createSelector(stateSelector, (state) => state.onboardPaysafeError);

export const getPaysafeOnboardSubmitted = createSelector(stateSelector, (state) => state.onboardPaysafeSubmitted);

export const submitPaysafeOnboard =
    (houseId: number, merchantId: string, highRiskMerchantId: string) =>
    async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: SUBMIT_PAYSAFE_ONBOARD_REQUEST,
            });
            if (!Boolean(houseId) || !Boolean(merchantId.trim())) {
                return dispatch({
                    error: true,
                    payload: 'fields are required for on-boarding',
                    type: SUBMIT_PAYSAFE_ONBOARD_FAIL,
                });
            }
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await postPaysafeOnboard({
                authToken,
                deployment,
                highRiskMerchantId,
                houseId,
                merchantId,
            });
            return dispatch({
                payload: response.payload,
                type: SUBMIT_PAYSAFE_ONBOARD_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { error },
                payload: error.payload,
                type: SUBMIT_PAYSAFE_ONBOARD_FAIL,
            });
        }
    };
