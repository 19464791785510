import { handleResponse, makeGet } from './helpers';

type FetchSuggestionsParams = {
    deployment: string;
    term: any;
};

type FetchHouseSearchSuggestionsPayload = {
    error: boolean;
    payload: {
        houseId: number;
        label: string;
        value: string;
    }[];
};

export const fetchSuggestions = ({ deployment, term }: FetchSuggestionsParams) => {
    return new Promise<FetchHouseSearchSuggestionsPayload>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'suggestions',
            deployment,
            path: '<SEARCH-API>',
            queryParams: {
                auctionHouseQueryCount: 5,
                itemQueryCount: 0,
                term,
            },
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
};
