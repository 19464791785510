export const modalTypes = {
    COMBINATION_CATEGORY_MODAL: 'COMBINATION_CATEGORY_MODAL',
    COMBINATION_RELATED_SEARCH_MODAL: 'COMBINATION_RELATED_SEARCH_MODAL',
    COMBINATION_SUGGESTED_CATEGORY_MODAL: 'COMBINATION_SUGGESTED_CATEGORY_MODAL',
    ERROR_MODAL: 'ERROR_MODAL',
    FEATURED_AUCTIONEER_MODAL: 'FEATURED_AUCTIONEER_MODAL',
    GLOBAL_ATG_ID_MODAL: 'GLOBAL_ATG_ID_MODAL',
    NONE: 'NONE',
    PAYMENTS_CANCEL_MODAL: 'PAYMENTS_CANCEL_MODAL',
    PAYMENTS_CAPTURE_MODAL: 'PAYMENTS_CAPTURE_MODAL',
    PAYMENTS_REFUND_MODAL: 'PAYMENTS_REFUND_MODAL',
    RELATED_SEARCH_MODAL: 'RELATED_SEARCH_MODAL',
    SELLER_INCENTIVE_MODAL: 'SELLER_INCENTIVE_MODAL',
    SUCCESS_MODAL: 'SUCCESS_MODAL',
    SUGGESTED_CATEGORY_MODAL: 'SUGGESTED_CATEGORY_MODAL',
};
