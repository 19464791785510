import { ChromePicker, ColorChangeHandler } from 'react-color';
import { useState } from 'react';
import styled from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';

type Props = {
    color: string;
    label: string;
    setColor: ColorChangeHandler;
};

const ColorPicker = ({ color, label, setColor }: Props) => {
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

    return (
        <StyledColorPickerInput>
            <StyledColorPickerLabel>{label}</StyledColorPickerLabel>
            <StyledColorPickerSwatch
                data-testid="color-picker-swatch"
                onClick={() => setShowColorPicker(!showColorPicker)}
            >
                <StyledColorPickerColor $pickerColor={color} data-testid="color-picker-color" />
            </StyledColorPickerSwatch>
            {showColorPicker && (
                <StyledColorPickerPopover data-testid="color-picker-popover">
                    <StyledColorPickerCover onClick={() => setShowColorPicker(false)} />
                    <ChromePicker color={color} onChangeComplete={setColor} />
                </StyledColorPickerPopover>
            )}
        </StyledColorPickerInput>
    );
};

const StyledColorPickerInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;

const StyledColorPickerLabel = styled(Text)`
    margin-right: 10px;
`;

const StyledColorPickerSwatch = styled.div`
    padding: 5px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 1px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.shadow400};
    display: inline-block;
    cursor: pointer;
`;

const StyledColorPickerColor = styled.div<{ $pickerColor: string }>`
    width: 36px;
    height: 14px;
    border-radius: 2px;
    background: ${({ $pickerColor }) => $pickerColor};
`;

const StyledColorPickerPopover = styled.div`
    position: absolute;
    z-index: 2;
    top: 25px;
    right: -180px;
`;

const StyledColorPickerCover = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export default ColorPicker;
