import {
    DeleteGlobalATGIdParams,
    DeleteGlobalATGIdResponse,
    PostGlobalATGIdParams,
    PostGlobalATGIdResponse,
} from './globalATGId.types';
import { handleResponseWithNon200Errors, makeDelete, makePost } from '../../api/helpers';

type PostGlobalATGIdAPIParams = {
    authToken: string;
    deployment: string;
    params: PostGlobalATGIdParams;
};

export const postGlobalATGId = ({
    authToken,
    deployment,
    params: { atgAuctionHouseId, houseId },
}: PostGlobalATGIdAPIParams) =>
    new Promise<PostGlobalATGIdResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'mapatghouseid',
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.send({ atgAuctionHouseId, houseId });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });

type DeleteGlobalATGIdAPIParams = {
    authToken: string;
    deployment: string;
    params: DeleteGlobalATGIdParams;
};

export const deleteGlobalATGId = ({ authToken, deployment, params: { houseId } }: DeleteGlobalATGIdAPIParams) =>
    new Promise<DeleteGlobalATGIdResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: 'deleteatghouseid',
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.send({ houseId });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
