import {
    addTrainingItems,
    clearPotentialTrainingItems,
    loadPotentialTrainingItems,
    loadSelectedTrainingItems,
    loadSynonymsAndExclusions,
    removeTrainingItems,
} from '@/redux/modules/trainingCollection';
import { CATEGORY_TRAINING_COLLECTION_PAGE_SIZE } from './CategoryTrainingCollection.constants';
import { deployment } from '../../../config';
import { formatNumber } from '@/utils/format';
import { getFullCategoryEditUrl } from '@/utils/urls';
import { getTrainingDataSelectorByCategoryName, loadTrainingData } from '@/redux/modules/training';
import { toastWithTemplate } from '@liveauctioneers/caterwaul-components/lib/ToastMessage/ToastMessageTemplate';
import {
    trainingCollectionExclusionSelector,
    trainingCollectionHasErrorSelector,
    trainingCollectionIsLoadingSelector,
    trainingCollectionIsUpdatingSelector,
    trainingCollectionPotentialTrainingItemsSelector,
    trainingCollectionSelectedItemsSelector,
    trainingCollectionSynonymsSelector,
    trainingCollectionTotalItemCountSelector,
} from '@/redux/modules/trainingCollection/trainingCollection.selectors';
import { TrainingCollectionTab } from '@/redux/modules/trainingCollection/trainingCollection.types';
import { TrainingInfo } from '../CategoryTrainingDashboard';
import { TrainingItem } from './CategoryTrainingCollection.types';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from 'react-intl';
import CategoryTrainingCollectionHeader from './Header/CategoryTrainingCollectionHeader';
import CategoryTrainingItemCard from './ItemCard/CategoryTrainingItemCard';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import Pagination from '@liveauctioneers/caterwaul-components/lib/Pagination/Pagination';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Tab from '@liveauctioneers/caterwaul-components/lib/Tab/Tab';
import Tabs from '@liveauctioneers/caterwaul-components/lib/Tab/Tabs';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import Throbber from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';

export type CategoryTrainingCollectionProps = {
    categoryId: number;
    categoryName: string;
    facetId: number;
};

const CategoryTrainingCollection = ({
    categoryId,
    categoryName,
    facetId,
}: CategoryTrainingCollectionProps): ReactElement<CategoryTrainingCollectionProps> => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const isFirstRender = useRef(true);
    const [isAdding, setIsAdding] = useState(false);
    const [pageNumber, setCurrentPage] = useState(1);
    const [houseId, setCurrentHouseId] = useState(0);
    const [searchTerm, setSearchTerm] = useState(categoryName || '');

    const isLoading: boolean = useAppSelector(trainingCollectionIsLoadingSelector);
    const isUpdating: boolean = useAppSelector(trainingCollectionIsUpdatingSelector);
    const hasError: boolean = useAppSelector(trainingCollectionHasErrorSelector);
    const totalItemsCount: number = useAppSelector(trainingCollectionTotalItemCountSelector);
    const synonyms: string[] = useAppSelector(trainingCollectionSynonymsSelector);
    const exclusionKeywords: string[] = useAppSelector(trainingCollectionExclusionSelector);
    const selectedTrainingItems: TrainingItem[] = useAppSelector(trainingCollectionSelectedItemsSelector);
    const potentialTrainingItems: TrainingItem[] = useAppSelector(trainingCollectionPotentialTrainingItemsSelector);
    const [selectedLotIds, setSelectedLotIds] = useState<number[]>([]);

    const trainingCategory: TrainingInfo | undefined = useAppSelector(
        getTrainingDataSelectorByCategoryName(categoryName)
    );
    const trainingItemsCount = trainingCategory?.trainingLotCount || 0;
    const [numberOfItemsChanged, setNumberOfItemsChanged] = useState(0);

    useEffect(() => {
        potentialTrainingItems?.length
            ? setSelectedLotIds(potentialTrainingItems.filter((item) => item.collected).map((item) => item.id))
            : setSelectedLotIds([]);
    }, [potentialTrainingItems]);

    useEffect(() => {
        if (!trainingCategory) {
            dispatch(loadTrainingData(facetId));
        }
        dispatch(loadSynonymsAndExclusions({ categoryId, facetId }));
    }, [categoryId, dispatch, facetId, trainingCategory]);

    useEffect(() => {
        if (isAdding) {
            window.scrollTo(0, 0);
            if (houseId || searchTerm) {
                dispatch(
                    loadPotentialTrainingItems({
                        categoryId,
                        houseId,
                        pageNumber,
                        searchTerm,
                    })
                );
            } else {
                dispatch(clearPotentialTrainingItems());
            }
        }
    }, [categoryId, dispatch, houseId, isAdding, pageNumber, searchTerm]);

    useEffect(() => {
        if (!isAdding) {
            dispatch(loadSelectedTrainingItems(categoryId));
        }
    }, [categoryId, dispatch, isAdding]);

    const updateSelectedTrainingItems = useCallback(() => {
        if (isAdding) {
            dispatch(
                addTrainingItems({
                    categoryId,
                    facetId,
                    houseId,
                    lotIds: selectedLotIds,
                    pageNumber,
                    searchTerm,
                })
            );
        } else {
            dispatch(
                removeTrainingItems({
                    categoryId,
                    facetId,
                    houseId,
                    lotIds: selectedLotIds,
                    pageNumber,
                    searchTerm,
                })
            );
        }
        setNumberOfItemsChanged(selectedLotIds.length);
    }, [categoryId, dispatch, facetId, houseId, isAdding, pageNumber, searchTerm, selectedLotIds]);

    const edited = isAdding ? 'Added' : 'Removed';
    const getSuccessMessage = useCallback(
        (): string =>
            numberOfItemsChanged > 0
                ? `${edited} ${formatNumber(numberOfItemsChanged, intl)} item${numberOfItemsChanged > 1 ? 's' : ''} ${
                      isAdding ? 'to' : 'from'
                  } ${categoryName} training data`
                : '',
        [categoryName, edited, intl, isAdding, numberOfItemsChanged]
    );

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (!isUpdating && !hasError) {
            const success = getSuccessMessage();
            if (Boolean(success)) {
                toastWithTemplate(getSuccessMessage());
            }
            setNumberOfItemsChanged(0);
        }
    }, [isUpdating, hasError, getSuccessMessage]);

    const onPageChange = ({ page: newPageNumber }) => {
        setCurrentPage(newPageNumber);
    };
    const onSearch = (newSearchTerm: string) => {
        setCurrentPage(1);
        setSearchTerm(newSearchTerm);
    };
    const onFilterByHouse = (newHouseId: number) => {
        setCurrentPage(1);
        setCurrentHouseId(newHouseId);
    };

    const trainingItemsToShow: TrainingItem[] = isAdding ? potentialTrainingItems : selectedTrainingItems;

    const renderExclusionsOrSynonyms = (items: string[], title: string) => {
        return (
            <StyledSynonymsAndExclusionsDiv>
                <StyledSynonymsAndExclusionsTitle h3>{title}:</StyledSynonymsAndExclusionsTitle>
                {items.map((item, index) => {
                    // This is necessary in order to not have a trailing comma.
                    if (index === items.length - 1) {
                        return <StyledSynonymsAndExclusionsSpan key={index}>{item}</StyledSynonymsAndExclusionsSpan>;
                    }
                    return <StyledSynonymsAndExclusionsSpan key={index}>{item},</StyledSynonymsAndExclusionsSpan>;
                })}
            </StyledSynonymsAndExclusionsDiv>
        );
    };

    const collectionView = (
        <section>
            <CategoryTrainingCollectionHeader
                isAdding={isAdding}
                lotIdsToEdit={selectedLotIds}
                numberOfCurrentItems={trainingItemsCount}
                numberOfItemsChecked={selectedLotIds.length}
                onFilterByHouse={onFilterByHouse}
                onSearch={onSearch}
                setLotIdsToUpdate={setSelectedLotIds}
                trainingItems={trainingItemsToShow}
                updateSelectedTrainingItems={updateSelectedTrainingItems}
            />
            {isLoading || isUpdating ? (
                <StyledThrobber testid="collection-throbber" />
            ) : (
                <Pagination
                    onChange={onPageChange}
                    pageSizeOptions={[CATEGORY_TRAINING_COLLECTION_PAGE_SIZE]}
                    paginationFilter={{
                        page: pageNumber,
                        pageSize: CATEGORY_TRAINING_COLLECTION_PAGE_SIZE,
                        totalRecords: isAdding ? totalItemsCount : selectedTrainingItems.length,
                    }}
                >
                    <StyledCollectionItems>
                        {trainingItemsToShow?.length &&
                            trainingItemsToShow.map((item) => (
                                <CategoryTrainingItemCard
                                    isAdding={isAdding}
                                    isLotIdSelectedExternally={selectedLotIds.includes(item.id)}
                                    key={`training-item-card-${item.id}`}
                                    onClick={(lotId, isSelected) => {
                                        if (isSelected) {
                                            setSelectedLotIds([...selectedLotIds, lotId]);
                                        } else {
                                            const filteredLotIds = selectedLotIds.filter((id) => id !== lotId);
                                            setSelectedLotIds(filteredLotIds);
                                        }
                                    }}
                                    trainingItem={item}
                                />
                            ))}
                    </StyledCollectionItems>
                </Pagination>
            )}
        </section>
    );

    const onTabChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const newTabName = event.currentTarget.name as TrainingCollectionTab;
        const willBeAdding = newTabName === TrainingCollectionTab.ADD;
        setIsAdding(willBeAdding);

        // It is safer to avoid the user from accidentally removing the entire page of items without intending to do so.
        const newSelectedLotIds =
            potentialTrainingItems?.length && willBeAdding
                ? potentialTrainingItems.filter((item) => item.collected).map((item) => item.id)
                : [];
        setSelectedLotIds(newSelectedLotIds);
    };

    return (
        <StyledCollectionContainer>
            <StyledCollectionHeader>
                <StyledDivToWrapLinkAndTitle>
                    <Text h1>{categoryName}</Text>
                    <StyledEditButton to={getFullCategoryEditUrl(deployment, categoryId)}>Edit</StyledEditButton>
                </StyledDivToWrapLinkAndTitle>
                <StyledContainerDiv>
                    {synonyms && renderExclusionsOrSynonyms(synonyms, 'Synonyms')}
                    {exclusionKeywords && renderExclusionsOrSynonyms(exclusionKeywords, 'Exclusion Keywords')}
                </StyledContainerDiv>
                <Tabs onChange={onTabChange} startingTabName={TrainingCollectionTab.EDIT}>
                    <Tab label="Edit Current Items" name={TrainingCollectionTab.EDIT}>
                        {collectionView}
                    </Tab>
                    <Tab label="Add Items" name={TrainingCollectionTab.ADD}>
                        {collectionView}
                    </Tab>
                </Tabs>
            </StyledCollectionHeader>
        </StyledCollectionContainer>
    );
};

export default CategoryTrainingCollection;

const StyledCollectionContainer = styled.section`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
`;

const StyledCollectionHeader = styled.header`
    ${({ theme }) => css`
        ul {
            background-color: ${theme.colors.white};
            top: 40px;
            position: sticky;
            z-index: 1;
        }
    `}
`;

const StyledCollectionItems = styled.section`
    display: flex;
    flex-wrap: wrap;
`;

const StyledThrobber = styled(Throbber)`
    margin-top: 30px;
`;

const StyledSynonymsAndExclusionsDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 4px 1px;
`;

const StyledSynonymsAndExclusionsTitle = styled(Text)`
    padding-left: 2px;
`;

const StyledSynonymsAndExclusionsSpan = styled.span`
    padding: 4px 2px;
`;

const StyledContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledEditButton = styled(Link)`
    padding-top: 14px;
    padding-left: 7px;

    :hover {
        text-decoration: none;
    }
`;

const StyledDivToWrapLinkAndTitle = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-flow: row;
    top: 0;
    position: sticky;
    z-index: 1;
`;
