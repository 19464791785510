import { ActionWithPayload } from '../../types/redux';
import { Catalog } from '../../types/Catalog';
import { createSelector } from 'reselect';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_CATALOGS_FAIL, LOAD_CATALOGS_REQUEST, LOAD_CATALOGS_SUCCESS } from './actions';
import api from '../api/catalog';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_CATALOGS_FAIL]: (state: State, action: ActionWithPayload<{}, { catalogIds: number[] }>) => ({
            ...state,
            loading: difference(state.loading, action.meta.catalogIds),
        }),
        [LOAD_CATALOGS_REQUEST]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [LOAD_CATALOGS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ catalogs: Catalog[] }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.catalogs) {
                action.payload.catalogs.forEach((item) => {
                    existing[item.catalogId] = { ...item };
                    loaded[item.catalogId] = time;
                    loading = difference(loading, [item.catalogId]);
                });
            }
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.catalog;
const globalStateSelector = (state) => state;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getCatalog = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

const getLoadTimeForCatalog = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isCatalogLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchCatalog = createSelector(
    [getCatalog, getLoadTimeForCatalog, isCatalogLoading],
    (catalog, loaded, loading) => {
        if (catalog) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

export const getCatalogIdsForSeller = createSelector([byIdSelector, idSelector], (byId, id) =>
    Object.keys(byId)
        .map((key) => byId[key])
        .filter((catalog) => catalog.sellerId === id && catalog.catalogStatus)
        .map((catalog) => catalog.catalogId)
);

const whichCatalogsNeeded = createSelector([globalStateSelector, idSelector], (state, catalogIds) =>
    catalogIds.filter((catalogId) => shouldFetchCatalog(state, catalogId))
);

/* ACTION CREATORS */
const loadCatalogs =
    (catalogIds: number[], forceNoCache: boolean = false) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            dispatch({
                payload: catalogIds,
                type: LOAD_CATALOGS_REQUEST,
            });
            const response = await api.fetchCatalogsByIds({ catalogIds, deployment, forceNoCache });
            return dispatch({
                meta: { actionTime: Date.now(), catalogIds },
                payload: response.data,
                type: LOAD_CATALOGS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { catalogIds },
                payload: error,
                type: LOAD_CATALOGS_FAIL,
            });
        }
    };

export const fetchCatalogsIfNeeded = (catalogIds: number[]) => async (dispatch: Function, getState: Function) => {
    const needed = whichCatalogsNeeded(getState(), catalogIds);
    if (needed.length) {
        return dispatch(loadCatalogs(needed));
    }
    return Promise.resolve();
};

export const fetchCatalog = (catalogId: number) => async (dispatch: Function) => {
    return dispatch(loadCatalogs([catalogId], true));
};
