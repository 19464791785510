import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import moment from 'moment';

/* Action Types */
export const CLEAR_CATEGORY_ITEM = 'CLEAR_CATEGORY_ITEM';
export const SELECT_CATEGORY_ITEM = 'SELECT_CATEGORY_ITEM';
export const SUBMIT_CATEGORY_ITEM_START = 'SUBMIT_CATEGORY_ITEM_START';
export const SUBMIT_CATEGORY_ITEM_SUCCESS = 'SUBMIT_CATEGORY_ITEM_SUCCESS';
export const SUBMIT_CATEGORY_ITEM_ERROR = 'SUBMIT_CATEGORY_ITEM_ERROR';

export const DELETE_CATEGORY_ITEM_START = 'DELETE_CATEGORY_ITEM_START';
export const DELETE_CATEGORY_ITEM_SUCCESS = 'DELETE_CATEGORY_ITEM_SUCCESS';
export const DELETE_CATEGORY_ITEM_ERROR = 'DELETE_CATEGORY_ITEM_ERROR';

export const FETCH_CATEGORY_ITEMS_START = 'FETCH_CATEGORY_ITEMS_START';
export const FETCH_CATEGORY_ITEMS_SUCCESS = 'FETCH_CATEGORY_ITEMS_SUCCESS';
export const FETCH_CATEGORY_ITEMS_ERROR = 'FETCH_CATEGORY_ITEMS_ERROR';

/* reducer */
const DEFAULT_STATE = {
    categoryItems: [],
    categoryItemsError: null,
    categoryItemsLoading: false,
    deleteCategoryItemError: null,
    deleteCategoryItemLoading: false,
    selectedCategoryItem: null,
    submitCategoryItemError: null,
    submitCategoryItemLoading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SELECT_CATEGORY_ITEM:
            return {
                ...state,
                selectedCategoryItem: action.payload,
            };
        case CLEAR_CATEGORY_ITEM:
            return {
                ...state,
                selectedCategoryItem: null,
            };
        case SUBMIT_CATEGORY_ITEM_START:
            return {
                ...state,
                submitCategoryItemLoading: true,
            };
        case SUBMIT_CATEGORY_ITEM_ERROR:
            return {
                ...state,
                submitCategoryItemError: action.payload,
                submitCategoryItemLoading: false,
            };
        case SUBMIT_CATEGORY_ITEM_SUCCESS:
            return {
                ...state,
                selectedCategoryItem: null,
                submitCategoryItemLoading: false,
            };
        case DELETE_CATEGORY_ITEM_START:
            return {
                ...state,
                deleteCategoryItemLoading: true,
            };
        case DELETE_CATEGORY_ITEM_ERROR:
            return {
                ...state,
                deleteCategoryItemError: action.payload,
                deleteCategoryItemLoading: false,
            };
        case DELETE_CATEGORY_ITEM_SUCCESS:
            return {
                ...state,
                deleteCategoryItemLoading: false,
                selectedCategoryItem: null,
            };
        case FETCH_CATEGORY_ITEMS_START:
            return {
                ...state,
                categoryItems: [],
                categoryItemsError: null,
                categoryItemsLoading: true,
            };
        case FETCH_CATEGORY_ITEMS_ERROR:
            return {
                ...state,
                categoryItems: [],
                categoryItemsError: action.payload,
                categoryItemsLoading: false,
            };
        case FETCH_CATEGORY_ITEMS_SUCCESS:
            return {
                ...state,
                categoryItems: action.payload || [],
                categoryItemsLoading: false,
            };
        default: {
            return { ...state };
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.category;

export const getSubmitCategoryItemError = createSelector(stateSelector, (state) => state.submitCategoryItemError);

export const getSubmitCategoryLoading = createSelector(stateSelector, (state) => state.submitCategoryItemLoading);

export const getCategoryItems = createSelector(stateSelector, (state) => state.categoryItems);

export const getCategoryItemsErrors = createSelector(stateSelector, (state) => state.categoryItemsError);

export const getCategoryItemsLoading = createSelector(stateSelector, (state) => state.categoryItemsLoading);

export const getSelectedCategoryItem = createSelector(
    stateSelector,
    (state) => state.categoryItems.find((item) => item.id === state.selectedCategoryItem) || {}
);

/* ACTION CREATORS */
export const selectCategoryItem = (id: number) => ({
    payload: id,
    type: SELECT_CATEGORY_ITEM,
});

export const clearSelectedCategoryItem = () => ({
    type: CLEAR_CATEGORY_ITEM,
});

export const fetchCategoryItems = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: FETCH_CATEGORY_ITEMS_START });

        const response = await api.loadCategoryItems({
            authToken,
            deployment,
        });
        dispatch({
            error: false,
            payload: response.payload,
            type: FETCH_CATEGORY_ITEMS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_CATEGORY_ITEMS_ERROR,
        });
    }
};

export const submitCategoryItem = (item: any) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: item,
            type: SUBMIT_CATEGORY_ITEM_START,
        });

        const response = await api.postCategoryItem({
            authToken,
            deployment,
            endDate: moment(item.endDate).format(),
            id: item.id,
            imageUrl: item.imageUrl,
            label: item.label,
            link: item.link,
            sequence: Number(item.sequence),
            startDate: moment(item.startDate).format(),
            title: item.title,
        });
        dispatch({
            error: false,
            payload: { id: response.payload, ...item },
            type: SUBMIT_CATEGORY_ITEM_SUCCESS,
        });
        dispatch(fetchCategoryItems());
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SUBMIT_CATEGORY_ITEM_ERROR,
        });
    }
};

export const deleteCategoryItem = (item: any) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: item,
            type: DELETE_CATEGORY_ITEM_START,
        });

        const response = await api.postDeleteCategoryItem({
            authToken,
            categoryId: Number(item.id),
            deployment,
        });
        dispatch({
            error: false,
            payload: { id: response.payload, ...item },
            type: DELETE_CATEGORY_ITEM_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: DELETE_CATEGORY_ITEM_ERROR,
        });
    }
};
