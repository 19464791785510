export const LOAD_HOUSE_AUTOPAY_BALANCES_FAILURE = 'LOAD_HOUSE_AUTOPAY_BALANCES_FAILURE';
export const LOAD_HOUSE_AUTOPAY_BALANCES_REQUEST = 'LOAD_HOUSE_AUTOPAY_BALANCES_REQUEST';
export const LOAD_HOUSE_AUTOPAY_BALANCES_SUCCESS = 'LOAD_HOUSE_AUTOPAY_BALANCES_SUCCESS';

export const POST_HOUSE_AUTOPAY_NOTES_FAILURE = 'POST_HOUSE_AUTOPAY_NOTES_FAILURE';
export const POST_HOUSE_AUTOPAY_NOTES_REQUEST = 'POST_HOUSE_AUTOPAY_NOTES_REQUEST';
export const POST_HOUSE_AUTOPAY_NOTES_SUCCESS = 'POST_HOUSE_AUTOPAY_NOTES_SUCCESS';

export const HOUSE_AUTOPAY_FILTERS_CHANGE = 'HOUSE_AUTOPAY_FILTERS_CHANGE';
export const HOUSE_AUTOPAY_FILTERS_CHANGE_FAIL = 'HOUSE_AUTOPAY_FILTERS_CHANGE_FAIL';
