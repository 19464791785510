/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
    DELETE_COMBINATION_CATEGORY_SUCCESS,
    FETCH_ALL_COMBINATION_CATEGORIES_FAIL,
    FETCH_ALL_COMBINATION_CATEGORIES_START,
    FETCH_ALL_COMBINATION_CATEGORIES_SUCCESS,
    FETCH_COMBINATION_CATEGORIES_FAIL,
    FETCH_COMBINATION_CATEGORIES_START,
    FETCH_COMBINATION_CATEGORIES_SUCCESS,
    FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_FAIL,
    FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_START,
    FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS,
    FETCH_COMBINATION_CATEGORY_FAIL,
    FETCH_COMBINATION_CATEGORY_START,
    FETCH_COMBINATION_CATEGORY_SUCCESS,
    SAVE_COMBINATION_CATEGORY_FAIL,
    SAVE_COMBINATION_CATEGORY_REQUEST,
    SAVE_COMBINATION_CATEGORY_SUCCESS,
} from './actions';
import { dismissModal } from './modal';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/combinationCategory';

/* reducer */
const DEFAULT_STATE = {
    allCombinationCategories: [],
    combinationCategories: [],
    currentCombinationCateogry: {},
    fetchAllCombinationCategoryError: '',
    fetchAllCombinationCategoryLoading: false,
    fetchCombinationCategoryError: '',
    fetchCombinationCategoryLoading: false,
    fetchCurrCombinationCategoryError: '',
    fetchCurrCombinationCategoryLoading: false,
    saveCombinationCategoryError: '',
    saveCombinationCategoryLoading: false,
    saveCombinationCategorysuccess: false,
    taxonomyChildren: [],
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_COMBINATION_CATEGORY_FAIL:
            return {
                ...state,
                saveCombinationCategoryError: action.payload,
                saveCombinationCategoryLoading: false,
                saveCombinationCategorysuccess: false,
            };
        case SAVE_COMBINATION_CATEGORY_REQUEST:
            return {
                ...state,
                saveCombinationCategoryError: null,
                saveCombinationCategoryLoading: true,
                saveCombinationCategorysuccess: false,
            };
        case SAVE_COMBINATION_CATEGORY_SUCCESS:
            return {
                ...state,
                allCombinationCategories: () => {
                    let newCombo = true;
                    state.allCombinationCategories.map((combo) => {
                        if (combo.combinationCategoryId === action.payload.combinationCategoryId) {
                            newCombo = false;
                            return action.payload;
                        }
                        return combo;
                    });
                    return newCombo
                        ? [action.payload, ...state.allCombinationCategories]
                        : state.allCombinationCategories;
                },
                combinationCategories: action.payload,
                saveCombinationCategoryError: null,
                saveCombinationCategoryLoading: false,
                saveCombinationCategorysuccess: true,
            };
        case FETCH_COMBINATION_CATEGORIES_START:
            return {
                ...state,
                combinationCategories: [],
                fetchCombinationCategoryError: null,
                fetchCombinationCategoryLoading: true,
            };
        case FETCH_COMBINATION_CATEGORIES_FAIL:
            return {
                ...state,
                combinationCategories: [],
                fetchCombinationCategoryError: action.payload,
                fetchCombinationCategoryLoading: false,
            };
        case FETCH_COMBINATION_CATEGORIES_SUCCESS:
            return {
                ...state,
                combinationCategories: action.payload || [],
                fetchCombinationCategoryLoading: false,
            };
        case FETCH_COMBINATION_CATEGORY_START:
            return {
                ...state,
                currentcombinationCategory: [],
                fetchCurrCombinationCategoryError: null,
                fetchCurrCombinationCategoryLoading: true,
            };
        case FETCH_COMBINATION_CATEGORY_FAIL:
            return {
                ...state,
                currentcombinationCategory: [],
                fetchCurrCombinationCategoryError: action.payload,
                fetchCurrCombinationCategoryLoading: false,
            };
        case FETCH_COMBINATION_CATEGORY_SUCCESS:
            return {
                ...state,
                currentCombinationCategory: action.payload || null,
                fetchCurrCombinationCategoryLoading: false,
            };
        case FETCH_ALL_COMBINATION_CATEGORIES_START:
            return {
                ...state,
                allCombinationCategories: [],
                fetchAllCombinationCategoryError: null,
                fetchAllCombinationCategoryLoading: true,
            };
        case FETCH_ALL_COMBINATION_CATEGORIES_FAIL:
            return {
                ...state,
                allCombinationCategories: [],
                fetchAllCombinationCategoryError: action.payload,
                fetchAllCombinationCategoryLoading: false,
            };
        case FETCH_ALL_COMBINATION_CATEGORIES_SUCCESS:
            return {
                ...state,
                allCombinationCategories: action.payload || [],
                fetchAllCombinationCategoryLoading: false,
            };
        case FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_START:
            return {
                ...state,
                fetchTaxonomyChildrenError: null,
                fetchTaxonomyChildrenLoading: true,
                taxonomyChildren: [],
            };
        case FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_FAIL:
            return {
                ...state,
                fetchTaxonomyChildrenError: action.payload,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: [],
            };
        case FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS:
            return {
                ...state,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: action.payload || [],
            };
        case DELETE_COMBINATION_CATEGORY_SUCCESS:
            return {
                ...state,
                allCombinationCategories: state.allCombinationCategories.length
                    ? state.allCombinationCategories.filter((cat) => cat.combinationCategoryId !== action.payload)
                    : [],
                combinationCategories: state.combinationCategories.length
                    ? state.combinationCategories.filter((cat) => cat.combinationCategoryId !== action.payload)
                    : [],
                fetchCombinationCategoryLoading: false,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.combinationCategory;

export const combinationCategoriesSelector = createSelector(stateSelector, (state) => state.combinationCategories);
export const combinationCategoriesTaxonomyChildrenOptionsSelector = createSelector(
    stateSelector,
    (state) => state.taxonomyChildren
);
export const combinationCategoryErrorSelector = createSelector(
    stateSelector,
    (state) => state.saveCombinationCategoryError
);
export const combinationCategoryCaptureLoadingSelector = createSelector(
    stateSelector,
    (state) => state.saveCombinationCategoryLoading
);
export const currentCombinationCategorySelector = createSelector(
    stateSelector,
    (state) => state.currentCombinationCategory
);
export const allCombinationCategoriesSelector = createSelector(
    stateSelector,
    (state) => state.allCombinationCategories
);
export const getSelectedComboConcatedIDs: (state: any) => number = createSelector(
    stateSelector,
    (state) => state.currentCombinationCategory.concatedFacetIDs
);

/* ACTION CREATORS */

export const getAllCombinationCategories = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: '',
            type: FETCH_ALL_COMBINATION_CATEGORIES_START,
        });

        const response = await api.getAllCombinationCategories({
            authToken,
            deployment,
        });

        dispatch({
            payload: response.payload,
            type: FETCH_ALL_COMBINATION_CATEGORIES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_ALL_COMBINATION_CATEGORIES_FAIL,
        });
    }
};

export const getCombinationCategories = (categoryId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: categoryId,
            type: FETCH_COMBINATION_CATEGORIES_START,
        });

        const response = await api.getCombinationCategoriesByCatID({
            authToken,
            categoryId,
            deployment,
        });

        dispatch({
            payload: response.payload,
            type: FETCH_COMBINATION_CATEGORIES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_COMBINATION_CATEGORIES_FAIL,
        });
    }
};

export const getCombinationCategoryByConcatIDs =
    (concatIDs: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: concatIDs,
                type: FETCH_COMBINATION_CATEGORY_START,
            });

            const response = await api.getCombinationCategoryByConcatIDs({
                authToken,
                concatedIds: concatIDs,
                deployment,
            });

            dispatch({
                payload: response.payload,
                type: FETCH_COMBINATION_CATEGORY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_COMBINATION_CATEGORY_FAIL,
            });
        }
    };

export const saveCombinationCategory = (combinationCategory) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: combinationCategory,
            type: SAVE_COMBINATION_CATEGORY_REQUEST,
        });

        if (!combinationCategory.combinationCategoryId) {
            await api.postCombinationCategory({
                authToken,
                combinationCategory,
                deployment,
            });
        } else {
            await api.updateCombinationCategory({
                authToken,
                combinationCategory,
                deployment,
            });
        }

        let combos = state.combinationCategory.combinationCategories;
        if (!combinationCategory.combinationCategoryId) {
            combos = [...combos, combinationCategory];
        } else {
            combos = combos.map((combo) => {
                if (combo.combinationCategoryId === combinationCategory.combinationCategoryId) {
                    return combinationCategory;
                }
                return combo;
            });
        }
        dispatch({
            payload: combos,
            type: SAVE_COMBINATION_CATEGORY_SUCCESS,
        });
        dispatch(dismissModal());
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_COMBINATION_CATEGORY_FAIL,
        });
    }
};

export const deleteCombinationCategory = (combinationCategoryId) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        await api.deleteCombinationCategory({
            authToken,
            deployment,
            idToDelete: combinationCategoryId,
        });

        dispatch({
            payload: combinationCategoryId,
            type: DELETE_COMBINATION_CATEGORY_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_COMBINATION_CATEGORY_FAIL,
        });
    }
};

type TaxonomySearchResult = {
    /** pretty-printed name for category */
    label: string;
    /** categoryId */
    value: number;
};

export const getCategoriesTaxonomyChildren =
    (searchTerm: string) =>
    async (dispatch: Function, getState: Function): Promise<TaxonomySearchResult[]> => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: -1,
                type: FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_START,
            });

            const response = await api.getTaxonomyCategoryChildrenByString({
                authToken,
                deployment,
                searchTerm,
            });

            const taxonomyOptions = response.payload.map((option) => {
                return {
                    label: `${option.categoryId} - ${option.categoryName}`,
                    value: option.categoryId,
                };
            });

            dispatch({
                payload: taxonomyOptions,
                type: FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS,
            });

            return taxonomyOptions;
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_FAIL,
            });
        }
    };
