import { Chargeback } from '../../../types/Chargeback';
import { TypedActionWithPayload } from '../../../types/redux';

export const LOAD_CHARGEBACKS_ADMIN_FAIL = 'LOAD_CHARGEBACKS_ADMIN_FAIL';
export const LOAD_CHARGEBACKS_ADMIN_REQUEST = 'LOAD_CHARGEBACKS_ADMIN_REQUEST';
export const LOAD_CHARGEBACKS_ADMIN_SUCCESS = 'LOAD_CHARGEBACKS_ADMIN_SUCCESS';
export type LOAD_CHARGEBACKS_ADMIN_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_CHARGEBACKS_ADMIN_SUCCESS,
    { chargebacks: Chargeback[]; totalRecords: number }
>;

export const CHARGEBACKS_FILTERS_CHANGE = 'DISBURSEMENTS_FILTERS_CHANGE';
