import { CategorySuggestion } from '../../types/CategorySuggestion';
import { handleResponse, makeGet, makePost } from './helpers';

type GetSuggestedCategoriesByCatIDParams = {
    authToken: string;
    categoryId: number;
    deployment: string;
};

const getSuggestedCategoriesByCatID = ({ authToken, categoryId, deployment }: GetSuggestedCategoriesByCatIDParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getsuggestedcategories',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ categoryId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSuggestedCategoryParams = {
    authToken: string;
    deployment: string;
    suggestion: CategorySuggestion;
};

type DeleteSuggestedCategoryParams = {
    authToken: string;
    deployment: string;
    idToDelete: number;
};

const postSuggestedCategory = ({ authToken, deployment, suggestion }: PostSuggestedCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'addsuggestedcategory', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ ...suggestion });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const updateSuggestedCategory = ({ authToken, deployment, suggestion }: PostSuggestedCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'updatesuggestedcategory', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ ...suggestion });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const deleteSuggestedCategory = ({ authToken, deployment, idToDelete }: DeleteSuggestedCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'deletesuggestedcategory', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ idToDelete });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetTaxonomyCategoryChildrenByStringParams = {
    authToken: string;
    deployment: string;
    searchTerm: string;
};

const getTaxonomyCategoryChildrenByString = ({
    authToken,
    deployment,
    searchTerm,
}: GetTaxonomyCategoryChildrenByStringParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'gettaxonomiesall',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ facetId: -1, searchTerm });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    deleteSuggestedCategory,
    getSuggestedCategoriesByCatID,
    getTaxonomyCategoryChildrenByString,
    postSuggestedCategory,
    updateSuggestedCategory,
};
