import { createSlice } from '@reduxjs/toolkit';
import { defaultGlobalATGIdSlice } from './globalATGId.types';
import { removeHouseGlobalATGId, updateHouseGlobalATGId } from './globalATGId.actions';

const globalATGIdSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(removeHouseGlobalATGId.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(removeHouseGlobalATGId.fulfilled, (slice) => {
            slice.loading = false;
            slice.error = false;
            slice.globalATGId = null;
            slice.submitted = true;
            slice.success = true;
        });
        builder.addCase(removeHouseGlobalATGId.rejected, (slice, { error }) => {
            slice.loading = false;
            slice.error = true;
            slice.message = error.message;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(updateHouseGlobalATGId.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(updateHouseGlobalATGId.fulfilled, (slice, { payload }) => {
            slice.loading = false;
            slice.error = false;
            slice.globalATGId = payload.atgAuctionHouseId;
            slice.submitted = true;
            slice.success = true;
        });
        builder.addCase(updateHouseGlobalATGId.rejected, (slice, { error }) => {
            slice.loading = false;
            slice.error = true;
            slice.message = error.message;
            slice.submitted = true;
            slice.success = false;
        });
    },
    initialState: defaultGlobalATGIdSlice,
    name: 'globalATGIdSlice',
    reducers: {},
});

export const { reducer: globalATGIdReducer } = globalATGIdSlice;
