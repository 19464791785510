import { handleResponse, makeGet } from './helpers';

type GetBidPercentDataParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const getBidPercentData = ({ authToken, catalogId, deployment }: GetBidPercentDataParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidpercent/${catalogId}`,
            authToken,
            deployment,
            path: '<ANALYTICS-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
