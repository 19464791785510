import {
    FetchBidderProfileResponse,
    GetBidderProfileParams,
    PostUpdateBidderProfileParams,
    UpdateBidderProfileResponse,
} from './bidderProfile.types';
import { handleResponseWithNon200Errors, makeGet, makePost } from '../../api/helpers';

/**
 * getBidderProfile - gets a bidder's profile
 * @category User API
 * @function getBidderProfile
 * @param {GetBidderProfileParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/getprofile
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/zmrn74/methods/GET
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnGetBidderProfileV2
 */

export const getBidderProfile = ({ authToken, deployment, params }: GetBidderProfileParams) =>
    new Promise<FetchBidderProfileResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getprofile',
            authToken,
            deployment,
            path: '<USER-API>',
        });

        const { bidderId } = params;
        // backend expects bidderid, cannot change backend and this will break lots of things, so making adjustment pre query.
        const bidderid = bidderId;
        request.query({ bidderid });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });

/**
 * POST to Flynn user API service to update Bidder profile data
 * @function postUpdateBidderProfile
 * @category User API
 * @param {PostUpdateBidderProfileParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/updateprofile
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/5zbu5t/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnUpdateBidderProfileV2
 */

type PostUpdateBidderProfileAPIParams = {
    authToken: string;
    deployment: string;
    params: PostUpdateBidderProfileParams;
};
export const postUpdateBidderProfile = ({ authToken, deployment, params }: PostUpdateBidderProfileAPIParams) =>
    new Promise<UpdateBidderProfileResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updateprofile',
            authToken,
            deployment,
            path: '<USER-API>',
        });

        const { bidderId, profile } = params;
        // TODO: Flynn wants some fields in a different format, we need to get this standardized accross all apps
        const body: any = {
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            mobileNumber: profile.mobile,
            zip: profile.zipcode,
        };
        const bidderid = bidderId;
        request.query({ bidderid });
        request.send(body);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });
