/* eslint-disable import/prefer-default-export */
import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const SET_SALE_TYPE_START = 'SET_SALE_TYPE_START';
export const SET_SALE_TYPE_ERROR = 'SET_SALE_TYPE_ERROR';
export const SET_SALE_TYPE_SUCCESS = 'SET_SALE_TYPE_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    setSaleTimedError: null,
    showWarning: false,
    submitting: false,
    success: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SET_SALE_TYPE_START:
            return {
                ...state,
                submitting: true,
                success: false,
            };
        case SET_SALE_TYPE_SUCCESS:
            return {
                ...state,
                setSaleTimedError: undefined,
                showWarning: action.payload,
                submitting: false,
                success: true,
            };
        case SET_SALE_TYPE_ERROR:
            return {
                ...state,
                setSaleTimedError: action.payload,
                submitting: false,
                success: false,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.changeSaleType;

export const getChangeSaleTypeState = createSelector(stateSelector, (state) => state);
export const getSubmittingChangeSaleType = createSelector(stateSelector, (state) => state.submitting);
export const getSubmitSuccess = createSelector(stateSelector, (state) => state.success);

/* ACTION CREATORS */
type SubmitChangeSaleTypeParams = { catalogId: number; isTimed: boolean; serverId: number };
export const submitChangeSaleType =
    ({ catalogId, isTimed, serverId }: SubmitChangeSaleTypeParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: SET_SALE_TYPE_START,
            });

            const response = await api.postSetCatalogTimed({
                authToken,
                catalogId: Number(catalogId),
                deployment,
                isTimed,
                serverId: Number(serverId),
            });
            dispatch({
                payload: response.payload.includes('No changes needed'),
                type: SET_SALE_TYPE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SET_SALE_TYPE_ERROR,
            });
        }
    };
