import { ApiTrainingInfo } from './training.types';
import { handleResponse, makeGet } from '@/redux/api/helpers';

type GetTrainingDataParams = {
    authToken: string;
    deployment: string;
    facetId: number;
};

export const getTrainingData = ({
    authToken,
    deployment,
    facetId,
}: GetTrainingDataParams): Promise<{
    error: boolean;
    payload: ApiTrainingInfo[];
}> => {
    return new Promise((resolve, reject) => {
        const request = makeGet({
            apiPath: 'training/trainingoverview',
            authToken,
            deployment,
            path: '<CRANNY-CATEGORIZER-API>',
            queryParams: {
                facetId,
            },
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
};
