/* eslint-disable import/prefer-default-export */
import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const RESET_SALE_FAIL = 'RESET_SALE_FAIL';
export const RESET_SALE_REQUEST = 'RESET_SALE_REQUEST';
export const RESET_SALE_SUCCESS = 'RESET_SALE_SUCCESS';

export const MOVE_LIVE_SALE_FAIL = 'MOVE_LIVE_SALE_FAIL';
export const MOVE_LIVE_SALE_REQUEST = 'MOVE_LIVE_SALE_REQUEST';
export const MOVE_LIVE_SALE_SUCCESS = 'MOVE_LIVE_SALE_SUCCESS';

type ResetSaleState = {
    error: string;
    moveLiveSaleError: string;
    moveLiveSaleSubmitted: boolean;
    moveLiveSaleSuccess: boolean;
    submitted: boolean;
    success: boolean;
};

/* reducer */
const DEFAULT_STATE: ResetSaleState = {
    error: '',
    moveLiveSaleError: '',
    moveLiveSaleSubmitted: false,
    moveLiveSaleSuccess: false,
    submitted: false,
    success: false,
};

export default function reducer(state: ResetSaleState = DEFAULT_STATE, action: any = {}): ResetSaleState {
    switch (action.type) {
        case MOVE_LIVE_SALE_FAIL:
            return {
                ...state,
                moveLiveSaleError: action.payload,
                moveLiveSaleSubmitted: false,
                moveLiveSaleSuccess: false,
            };
        case MOVE_LIVE_SALE_REQUEST:
            return {
                ...state,
                moveLiveSaleError: null,
                moveLiveSaleSubmitted: true,
                moveLiveSaleSuccess: false,
            };
        case MOVE_LIVE_SALE_SUCCESS:
            return {
                ...state,
                moveLiveSaleError: null,
                moveLiveSaleSubmitted: false,
                moveLiveSaleSuccess: true,
            };
        case RESET_SALE_FAIL:
            return {
                ...state,
                error: action.payload,
                submitted: false,
                success: false,
            };
        case RESET_SALE_REQUEST:
            return {
                ...state,
                error: null,
                submitted: true,
                success: false,
            };
        case RESET_SALE_SUCCESS:
            return {
                ...state,
                error: null,
                submitted: false,
                success: true,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state): ResetSaleState => state.resetSale;

export const getResetSaleError = createSelector(stateSelector, (state) => state.error);
export const getSubmitResetSaleSuccess = createSelector(stateSelector, (state) => state.success);
export const getSubmittingResetSale = createSelector(stateSelector, (state) => state.submitted);

export const getMoveLiveSaleError = createSelector(stateSelector, (state) => state.moveLiveSaleError);
export const getSubmitMoveLiveSaleSuccess = createSelector(stateSelector, (state) => state.moveLiveSaleSuccess);
export const getSubmittingMoveLiveSale = createSelector(stateSelector, (state) => state.moveLiveSaleSubmitted);

/* ACTION CREATORS */
type SubmitUpdateLotNumbersParams = { catalogId: number; saleStartDate: string };

export const submitResetSale =
    ({ catalogId, saleStartDate }: SubmitUpdateLotNumbersParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: catalogId,
                type: RESET_SALE_REQUEST,
            });

            const response = await api.postResetSale({
                authToken,
                catalogId: Number(catalogId),
                deployment,
                saleStartDate,
            });
            dispatch({
                payload: response.payload,
                type: RESET_SALE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: RESET_SALE_FAIL,
            });
        }
    };

export const submitMoveLiveSaleToFutureDate =
    ({ catalogId, saleStartDate }: SubmitUpdateLotNumbersParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: catalogId,
                type: MOVE_LIVE_SALE_REQUEST,
            });

            const response = await api.postMoveLiveSaleToFutureDate({
                authToken,
                catalogId: Number(catalogId),
                deployment,
                saleStartDate,
            });
            dispatch({
                payload: response.payload,
                type: MOVE_LIVE_SALE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: MOVE_LIVE_SALE_FAIL,
            });
        }
    };
