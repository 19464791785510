export type GlobalATGIdSlice = {
    error: boolean;
    globalATGId: string;
    houseId: number;
    loading: boolean;
    message: string;
    submitted: boolean;
    success: boolean;
};

export const defaultGlobalATGIdSlice: GlobalATGIdSlice = {
    error: false,
    globalATGId: '',
    houseId: 0,
    loading: false,
    message: '',
    submitted: false,
    success: false,
};

export type PostGlobalATGIdParams = {
    atgAuctionHouseId: string;
    houseId: number;
};

export type DeleteGlobalATGIdParams = {
    houseId: number;
};

export type PostGlobalATGIdResponse = {
    error: boolean;
    payload: {
        atgAuctionHouseId: string;
        houseId: number;
    };
};

export type DeleteGlobalATGIdResponse = {
    error: boolean;
    payload: {
        houseId: number;
    };
};

export enum DisplayOptions {
    remove = 'remove',
    save = 'save',
}

export type GlobalATGIdModalData = {
    displayOption: DisplayOptions;
    houseId?: number;
};

export const updateHouseGlobalATGIdFulfilled = 'la/ui/globalATGId/updateHouseGlobalATGId/fulfilled';
export const removeHouseGlobalATGIdFulfilled = 'la/ui/globalATGId/removeHouseGlobalATGId/fulfilled';
