import { createSelector } from 'reselect';
import { emptyShippingLabel, SHIPPING_LABEL_SLICE_CACHE_TIME, ShippingLabelSlice } from './shippingLabel.types';
import { GlobalState } from '@/redux/store';

const getShippingLabelSlice = ({ shippingLabel }: GlobalState): ShippingLabelSlice => shippingLabel;
const getHouseId = (_: GlobalState, houseId: number) => houseId;

const getIsShippingLabelLoading = createSelector(
    [getShippingLabelSlice, getHouseId],
    ({ loading }, houseId) => loading.indexOf(houseId) >= 0
);

const getShippingLabelLoadTime = createSelector(
    [getShippingLabelSlice, getHouseId],
    ({ loaded }, houseId) => loaded[houseId] ?? 0
);

export const getShippingLabelByHouseId = createSelector(
    [getShippingLabelSlice, getHouseId],
    ({ byId }, houseId) => byId[houseId] || emptyShippingLabel
);

export const getShouldFetchShippingLabel = createSelector(
    [getIsShippingLabelLoading, getShippingLabelLoadTime],
    (loading, loaded) => {
        if (loading) {
            return false;
        }
        const timeSinceLastLoad = Date.now() - loaded;
        return !loaded || timeSinceLastLoad > SHIPPING_LABEL_SLICE_CACHE_TIME;
    }
);

export const getSaveShippingLabelSuccess = createSelector(
    [getShippingLabelSlice],
    ({ result }) => result === 'success'
);

export const getSaveShippingLabelError = createSelector([getShippingLabelSlice], ({ result }) => result === 'failure');
