import { createSelector } from 'reselect';
import { GlobalState } from '@/redux/store';
import { HOUSE_BETA_SLICE_CACHE_TIME } from '@/redux/modules/houseBeta/houseBeta.types';

const stateSelector = (state: GlobalState) => state.houseBetas;
const passThroughSelector = (_, passThroughValue) => passThroughValue;

export const getHouseBetaLoaded = createSelector(stateSelector, (state) => state.loaded);
export const getHouseBetaLoading = createSelector(stateSelector, (state) => state.loading);
export const getHouseBetas = createSelector(stateSelector, (state) => state.betas);
export const getHouseBetaHouseId = createSelector(stateSelector, (state) => state.houseId);

export const getShippingSaintHouseId = createSelector(
    stateSelector,
    ({ shippingSaintHouseId }) => shippingSaintHouseId
);

export const getShippingSaintHouseIdSuccessfulSave = createSelector(
    [stateSelector],
    ({ successfulShippingSaintHouseIdSave }) => successfulShippingSaintHouseIdSave
);

export const getShippingSaintHouseIdError = createSelector(
    [stateSelector],
    ({ shippingSaintHouseIdError }) => shippingSaintHouseIdError
);

export const shouldFetchBetas = createSelector(
    getHouseBetaLoaded,
    getHouseBetaLoading,
    getHouseBetaHouseId,
    passThroughSelector,
    (loaded, loading, storedHouseId, houseIdToFetch) => {
        if (loading) {
            return false;
        }
        if (storedHouseId !== houseIdToFetch) {
            return true;
        }
        const time = Date.now();
        const diff = time - (loaded?.valueOf() ?? 0);
        return !loaded || diff > HOUSE_BETA_SLICE_CACHE_TIME;
    }
);
