import { CategorySuggestion } from '@/types/CategorySuggestion';
import { handleResponse, makeGet, makePost } from './helpers';

type GetCombinationSuggestedCategoriesByCatIDParams = {
    authToken: string;
    concatedIDs: string;
    deployment: string;
};

const getCombinationSuggestedCategoriesByConcatIDs = ({
    authToken,
    concatedIDs,
    deployment,
}: GetCombinationSuggestedCategoriesByCatIDParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationsuggestedcategoriesbyid',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ concatedIDs });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostCombinationSuggestedCategoryParams = {
    authToken: string;
    deployment: string;
    suggestion: CategorySuggestion;
};

const postCombinationSuggestedCategory = ({
    authToken,
    deployment,
    suggestion,
}: PostCombinationSuggestedCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'addcombinationsuggestedcategory',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ ...suggestion });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const updateCombinationSuggestedCategory = ({
    authToken,
    deployment,
    suggestion,
}: PostCombinationSuggestedCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updatecombinationsuggestedcategory',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ ...suggestion });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type DeleteCombinationSuggestedCategoryParams = {
    authToken: string;
    deployment: string;
    idToDelete: number;
};

const deleteCombinationSuggestedCategory = ({
    authToken,
    deployment,
    idToDelete,
}: DeleteCombinationSuggestedCategoryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletecombinationsuggestedcategory',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ idToDelete });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetTaxonomyCategoryChildrenByStringParams = {
    authToken: string;
    deployment: string;
    searchTerm: string;
};

const getTaxonomyCategoryChildrenByString = ({
    authToken,
    deployment,
    searchTerm,
}: GetTaxonomyCategoryChildrenByStringParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'gettaxonomiesall',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ facetId: -1, searchTerm });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    deleteCombinationSuggestedCategory,
    getCombinationSuggestedCategoriesByConcatIDs,
    getTaxonomyCategoryChildrenByString,
    postCombinationSuggestedCategory,
    updateCombinationSuggestedCategory,
};
