/* reducer */
const DEFAULT_STATE = {};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        default:
            return state;
    }
}

/* SELECTORS */
export const getLotsSoldCols = () => [
    { name: 'lotID', title: 'Lot Id' },
    { name: 'catID', title: 'Catalog Id' },
    { name: 'sale_price', title: 'Sale Price' },
    { name: 'buyer_prem', title: "Buyer's Premium" },
    { name: 'buyer_name', title: "Buyer's Name" },
    { name: 'buyer_id', title: "Buyer's Id" },
    { name: 'flg_payment_recieved', title: 'Paid' },
];

export const getLotsSoldRows = () => [
    {
        buyer_id: 745195,
        buyer_name: 'John Klein',
        buyer_prem: 100,
        catID: 107997,
        flg_payment_recieved: 'n',
        lotID: 55390077,
        sale_price: 1000,
    },
    {
        buyer_id: 1507591,
        buyer_name: 'Douglass M. Eberhardt',
        buyer_prem: 90,
        catID: 107997,
        flg_payment_recieved: 'n',
        lotID: 55390078,
        sale_price: 900,
    },
    {
        buyer_id: 119652,
        buyer_name: 'Cory Margolis',
        buyer_prem: 4.5,
        catID: 107997,
        flg_payment_recieved: 'y',
        lotID: 55390082,
        sale_price: 45,
    },
    {
        buyer_id: 1525633,
        buyer_name: 'Tracy Landon',
        buyer_prem: 26,
        catID: 108012,
        flg_payment_recieved: 'y',
        lotID: 55405898,
        sale_price: 260,
    },
];
