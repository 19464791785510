import flatten from 'lodash/flatten';

export const extractKeywords = (keywords: string) => {
    return flatten(
        keywords
            .split(',')
            .map((k) => k.trim())
            .map((k) => k.split(' '))
    );
};
