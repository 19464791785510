/* eslint-disable import/prefer-default-export */
import { makeGet } from '../api/helpers';

/* Action Types */
export const ADD_NEW_HOST = 'ADD_NEW_HOST';
export const FETCH_HEALTH_START = 'FETCH_HEALTH_START';
export const FETCH_HEALTH_SUCCESS = 'FETCH_HEALTH_SUCCESS';
export const FETCH_HEALTH_ERROR = 'FETCH_HEALTH_ERROR';

/* reducer */
const DEFAULT_STATE = {};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    let key;

    switch (action.type) {
        case ADD_NEW_HOST:
            key = action.payload.host;
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                },
            };

        case FETCH_HEALTH_START:
            key = action.payload.host;
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                },
            };

        case FETCH_HEALTH_SUCCESS:
            key = action.payload.host;
            return {
                ...state,
                [key]: {
                    ...action.payload,
                    isFetching: false,
                },
            };

        case FETCH_HEALTH_ERROR:
            key = action.meta.host;
            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...action.payload,
                    isFetching: false,
                },
            };

        default:
            return state;
    }
}

/* SELECTORS */
export function getHostHealth(state: any, props: any) {
    const key = props.host;
    const data = state.health[key] || null;
    return data;
}
export function getAllHostsHealth(state: any) {
    const data = state.health || null;
    return data;
}
export function getAllHosts(state: any) {
    const data = state.health || null;

    return Object.keys(data);
}

/* ACTION CREATORS */
type AddNewHostParams = { hostname: string };
export function addNewHost({ hostname }: AddNewHostParams) {
    return {
        payload: {
            host: hostname,
        },
        type: ADD_NEW_HOST,
    };
}

export function fetchServerHealth(host: string) {
    return async (dispatch: Function) => {
        dispatch({
            payload: { host },
            type: FETCH_HEALTH_START,
        });

        try {
            const request = makeGet({
                path: `https://${host}.liveauctioneers.com/healthcheck`,
            });
            // request.set('Authorization', `Bearer ${authToken}`);

            // @ts-ignore
            request.end((err, { body } = {}) => {
                if (err) {
                    dispatch({
                        error: true,
                        meta: { host },
                        payload: body || err,
                        type: FETCH_HEALTH_ERROR,
                    });
                    return false;
                }
                dispatch({
                    payload: {
                        health: body,
                        host,
                    },
                    type: FETCH_HEALTH_SUCCESS,
                });
                return true;
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { host },
                payload: {
                    error,
                },
                type: FETCH_HEALTH_ERROR,
            });
            return false;
        }

        return true;
    };
}
