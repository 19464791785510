import { cachekey } from '../../config';
import isEmpty from 'lodash/isEmpty';
import superagent from 'superagent';
import uuid from 'uuid';

type HandleResponse<ApiModel, ReturnModel> = {
    err?: any;
    reject: Function;
    resolve: Function;
    response: any;
    success404?: boolean;
    transform?: (model: ApiModel) => ReturnModel;
};

type MakeRequest = {
    apiPath?: string;
    authToken?: string;
    deployment?: string;
    forceCredentials?: boolean;
    path: string;
    queryParams?: any;
    useCacheKey?: boolean;
};

type MakeRequestPrivate = MakeRequest & {
    requestFunc: Function;
};

const backupKey = uuid.v4();

export const delay = 10; // in milliseconds

export const handleResponse = <ApiModel, ReturnModel>({
    err,
    reject,
    resolve,
    response = {},
    success404,
    transform,
}: HandleResponse<ApiModel, ReturnModel>) => {
    const handleRequestError = () => {
        // If nothing could handle the response, and it was an error, reject the whole thing
        // Should we make this always be a string?
        if (typeof err === 'string') {
            return reject(err);
        } else {
            return reject('There was an error with the request', err);
        }
    };

    // If there is a server level error, reject the promise
    if (err) {
        if (success404 && err.status === 404) {
            return resolve(response.body || {});
        }
        // If there is not a  response, we can handle the error now. (CORS errors look like this)
        if (isEmpty(response)) {
            return handleRequestError();
        }
    }

    let body = response.body || {};
    if (transform) {
        body = transform(body);
    }

    if (!body) {
        return reject('Unexpected error transforming response');
    }

    if (typeof body !== 'object') {
        return resolve(body);
    }

    // Go ResponseEnvelope
    if ('error' in body && body.error) {
        if ('meta' in body && body.meta && 'currentState' in body.meta) {
            return reject(body, body.payload);
        } else if (typeof body.error === 'string') {
            return reject(body.error);
        } else if (body?.payload) {
            return reject(body.payload);
        } else {
            return reject(body);
        }
    }

    // If the success and message properties are set in body (item-api response)
    // but success is falsy, reject the promise
    if ('success' in body && 'message' in body && !body.success) {
        return reject(body.message);
    }

    // If the success property is set in body but is falsy, reject the promise
    // Might be some sort of lambda (Flynn)
    if ('success' in body && !body.success) {
        if ('payload' in body) {
            if ('error' in body.payload) {
                // Check if the error is from the create account endpoint for the verify email screen
                if (body.payload.error === 'User Already Exists') {
                    return resolve(body);
                }
                return reject(body.payload.error);
            }
            return reject(body.payload);
        }
        return reject(body);
    }

    //if the response has an errorMessage it means it's broken
    if (Boolean(body.errorMessage)) {
        return reject(body.errorMessage);
    }

    if (err) {
        // If we have gotten this far and there was an err object, handle it
        return handleRequestError();
    }

    // else
    return resolve(body);
};

export const handleResponseV2 = <ApiModel, ReturnModel>({
    err,
    reject,
    resolve,
    response = {},
    success404,
    transform,
}: HandleResponse<ApiModel, ReturnModel>) => {
    // Transform the response
    // @ts-ignore
    let body = response.body || {};
    if (transform) {
        try {
            body = transform(body);
        } catch (error) {
            // Failed to transform the response body
        }
    }

    // Handle a 404 explicitly
    if (err && err.status === 404) {
        if (success404) {
            return resolve(body);
        } else {
            if (typeof err === 'string') {
                return reject(err);
            }
            if ('message' in err && typeof err.message === 'string') {
                return reject(err.message);
            }
            return reject('There was an error with the network request');
        }
    }

    // if the response has an errorMessage it means it's broken (from api gateway?)
    if ('errorMessage' in body) {
        return reject(body.errorMessage);
    }

    // handle item-api response (success and message properties)
    if ('success' in body && 'message' in body && !body.success) {
        return reject(body.message);
    }

    // handle old go response (success and payload properties)
    if ('success' in body && 'payload' in body && !body.success) {
        return reject(body.payload);
    }

    // handle new go response (error and payload properties)
    if ('error' in body && body.error) {
        if ('payload' in body) {
            // Proper go response is to have the payload be an error string when the error property is true
            if (typeof body.payload === 'string') {
                return reject(body.payload);
            }
            if (typeof body.payload === 'object') {
                // The createuser endpoint in flynn returns a non standard object when a bidder already exists
                if (body.payload.error === 'User Already Exists') {
                    return reject(body.payload.error);
                }
            }
        }

        // If the response is built correctly, the payload would have been a string and already rejected
        // but to keep the code backward compatible, we will reject with the payload anyway
        return reject(body.payload);
    }

    // if we got this far, and there was an err in the superagent request then reject
    if (err) {
        if (typeof err === 'string') {
            return reject(err);
        }
        if ('message' in err && typeof err.message === 'string') {
            return reject(err.message);
        }
        return reject('There was an error with the network request');
    }

    // In the default case, should we return body, or body.payload?
    return resolve(body);
};

const addCacheKey = (request) => {
    let c = cachekey;
    if (!cachekey) {
        c = backupKey;
    }
    request.query({ c });
    return request;
};

const hosts = [
    {
        dropDeploymentForProd: true,
        path: '<ANALYTICS-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/analytics/APIPATH',
    },
    {
        path: '<APPROVAL-API>',
        url: 'https://approval-DEPLOYMENT.liveauctioneers.com/approval/APIPATH',
    },
    { path: '<AUTHENTICATION-API>', url: 'https://authentic-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<BNAREPORT-API>', url: 'https://api.liveauctioneers.com/APIPATH/DEPLOYMENT' },
    { path: '<CMS-API>', url: 'https://catalog-management-DEPLOYMENT.liveauctioneers.com/cms/APIPATH' },
    { path: '<CROSS-LISTING-API>', url: 'https://catalog-management-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<CLERK-SERVICE>', url: 'https://dante-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<COLLECTION-API>', url: 'https://api.liveauctioneers.com/collections/APIPATH/DEPLOYMENT' },
    { path: '<CRANNY-CATEGORIZER-API>', url: 'https://cranny-categorizer-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<CURRENCY-API>', url: 'https://api.liveauctioneers.com/currency/DEPLOYMENT' },
    { path: '<ESCROW-API>', url: 'https://api.liveauctioneers.com/escrow/APIPATH/DEPLOYMENT' },
    { path: '<HELP-SCOUT>', url: 'https://api.helpscout.net/v2/APIPATH' },
    //lumbergh service path
    { path: '<HOUSEREPORT-API>', url: 'https://lumbergh-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<ITEM-API>', url: 'https://item-api-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<FINANCE-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/invoice/APIPATH' },
    { path: '<FP-API>', url: 'https://api.liveauctioneers.com/fp-DEPLOYMENT/APIPATH' },
    { path: '<MAINHOST-API>', url: 'https://mainhost-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    {
        dropDeploymentForProd: true,
        path: '<MARKETPLACE-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/marketplace/APIPATH',
    },
    { path: '<NOTIFICATIONS-API>', url: 'https://telegram-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    //payment lambda endpoint
    {
        dropDeploymentForProd: true,
        path: '<PAYMENT-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/payment/APIPATH',
    },
    //payment server endpoint
    {
        path: '<PAYMENT-SERVER-API>',
        url: 'https://redstripe-DEPLOYMENT.liveauctioneers.com/payment-api/APIPATH',
    },
    { path: '<PROMOTION-API>', url: 'https://api.liveauctioneers.com/promotion/APIPATH/DEPLOYMENT' },
    { path: '<PROMOTION-API-TWO>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/promotion/APIPATH' },
    { path: '<PUBNUB-API>', url: 'https://ps.pndsn.com/v2/presence/sub-key/' },
    { path: '<PUSH-NOTIFICATION-API>', url: 'https://api.liveauctioneers.com/push/APIPATH/DEPLOYMENT' },
    {
        path: '<RECOMMENDATION-API>',
        url: 'https://api.liveauctioneers.com/recommendation/getrecommendations/DEPLOYMENT',
    },
    { path: '<REVIEW-API>', url: 'https://api.liveauctioneers.com/review/APIPATH/DEPLOYMENT' },
    { path: '<SAVED-SEARCH-API>', url: 'https://api.liveauctioneers.com/savedsearch/APIPATH/DEPLOYMENT' },
    { path: '<SEARCH-API>', url: 'https://search-party-DEPLOYMENT.liveauctioneers.com/search/APIPATH' },
    { path: '<SHIPPING-API>', url: 'https://shipping-api-DEPLOYMENT.liveauctioneers.com/shipping/APIPATH' },
    { path: '<STATS-API>', url: 'https://crispy-pancake-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<STREAM-API>', url: 'https://api.liveauctioneers.com/stream/APIPATH/DEPLOYMENT' },
    { path: '<STREAM-MANAGER>', url: 'https://api.liveauctioneers.com/stream/APIPATH/DEPLOYMENT' },
    { path: '<SUPPORT-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/support/APIPATH' },
    { path: '<WHITE-LABEL-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/white-label/APIPATH' },
    { path: '<USER-API>', url: 'https://flynn-DEPLOYMENT.liveauctioneers.com/user/APIPATH' },
    { path: '<CATEGORY-API>', url: 'https://category-api-DEPLOYMENT.liveauctioneers.com/category-api/APIPATH' },
    { path: '<DEWEY>', url: 'https://dewey-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<HOUSE-FINANCE-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/invoice/APIPATH' },
    { path: '<BUYNOW-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/buynow/APIPATH' },
    // Category Training
    { path: '<CATEGORY-TRAINING>', url: 'https://pigeonhole-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    // Review Service
    {
        path: '<REVIEW-SERVICE>',
        url: 'https://review-service-DEPLOYMENT.liveauctioneers.com/review/admin/APIPATH',
    },
];

export const buildUrl = (path: string = '', deployment: string = '', apiPath: string = '') => {
    let updatedPath = path;
    hosts.forEach((obj) => {
        if (path.includes(obj.path)) {
            let url = '';
            if (deployment === 'prod' && obj.dropDeploymentForProd) {
                if (obj.url.indexOf('-DEPLOYMENT') !== -1) {
                    url = obj.url.replace('-DEPLOYMENT', '');
                }
            } else {
                url = obj.url.replace('DEPLOYMENT', deployment);
            }
            updatedPath = path.replace(obj.path, url);
            updatedPath = updatedPath.replace('APIPATH', apiPath);
        }
    });
    return updatedPath;
};

const buildRequest = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials = false,
    path,
    queryParams,
    requestFunc,
    useCacheKey = true,
}: MakeRequestPrivate) => {
    const url = buildUrl(path, deployment, apiPath);
    const request = requestFunc(url);

    if (forceCredentials) {
        request.withCredentials();
    }
    if (useCacheKey) {
        addCacheKey(request);
    }
    if (queryParams) {
        request.query(queryParams);
    }
    if (authToken) {
        request.set('Authorization', `Bearer ${authToken}`);
    }
    return request;
};

export const makeDelete = ({ apiPath, authToken, deployment, path, queryParams, useCacheKey = true }: MakeRequest) =>
    buildRequest({ apiPath, authToken, deployment, path, queryParams, requestFunc: superagent.delete, useCacheKey });

export const makeGet = ({ apiPath, authToken, deployment, path, queryParams, useCacheKey = true }: MakeRequest) =>
    buildRequest({ apiPath, authToken, deployment, path, queryParams, requestFunc: superagent.get, useCacheKey });

export const makePost = ({ apiPath, authToken, deployment, path, queryParams, useCacheKey = true }: MakeRequest) =>
    buildRequest({ apiPath, authToken, deployment, path, queryParams, requestFunc: superagent.post, useCacheKey });

export const makePut = ({ apiPath, authToken, deployment, path, queryParams, useCacheKey = true }: MakeRequest) =>
    buildRequest({ apiPath, authToken, deployment, path, queryParams, requestFunc: superagent.put, useCacheKey });

export const makePatch = ({ apiPath, authToken, deployment, path, queryParams, useCacheKey = true }: MakeRequest) =>
    buildRequest({ apiPath, authToken, deployment, path, queryParams, requestFunc: superagent.patch, useCacheKey });

type HandleResponseParams = {
    err?: any;
    reject: Function;
    request?: superagent.SuperAgentRequest;
    resolve: Function;
    response: any;
    success404?: boolean;
    transform?: Function;
};

export const handleResponseWithNon200Errors = ({
    err,
    reject,
    resolve,
    response = {},
    success404,
    transform,
    request,
}: HandleResponseParams) => {
    /* eslint-disable-next-line */
    const rejectAndCaptureError = (errorToReturn: any, originalError = null) => {
        // We can capture the error here if we want to send the data to LogRocket
        // captureError(originalError || errorToReturn);
        return reject(errorToReturn);
    };

    const handleRequestError = () => {
        let errorMessage = '';
        try {
            errorMessage =
                'Here is the error with the request: ' +
                JSON.stringify(err) +
                ' when making requests to: ' +
                request?.url;
        } catch (e) {
            errorMessage = 'Failed to stringify the error from the request.';
        }
        return rejectAndCaptureError(errorMessage);
    };

    // If there is a server level error, reject the promise
    if (err) {
        if (success404 && err.status === 404) {
            return resolve(response.body || {});
        }
        // If there is not a  response, we can handle the error now. (CORS errors look like this)
        if (isEmpty(response)) {
            return handleRequestError();
        }
    }

    let body = response.body || {};
    if (transform) {
        body = transform(body);
    }

    // Go ResponseEnvelope
    if ('error' in body && body.error) {
        // Special case with clerk commands where "currentState" is in meta on failure
        if (body?.meta?.currentState) {
            return rejectAndCaptureError(body, body.payload);
        }
        return rejectAndCaptureError(body.payload);
    }

    // If the success and message properties are set in body (item-api response)
    // but success is falsy, reject the promise
    if ('success' in body && 'message' in body && !body.success) {
        return rejectAndCaptureError(body.message);
    }

    // If the success property is set in body but is falsy, reject the promise
    // Might be some sort of lambda (Flynn)
    if ('success' in body && !body.success) {
        if ('payload' in body) {
            if ('error' in body.payload) {
                // Check if the error is from the create account endpoint for the verify email screen
                if (body.payload.error === 'User Already Exists') {
                    return resolve(body);
                }
                return rejectAndCaptureError(body.payload.error);
            }
            return rejectAndCaptureError(body.payload);
        }
        return rejectAndCaptureError(body);
    }

    //if the response has an errorMessage it means it's broken
    if (Boolean(body.errorMessage)) {
        return rejectAndCaptureError(body.errorMessage);
    }

    if (err) {
        // If we have gotten this far and there was an err object, handle it
        return handleRequestError();
    }

    // else
    return resolve(body);
};
