import { ActionWithPayload } from '../../types/redux';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { sendGlobalNotificationApi } from '../api/notification';
import {
    SUBMIT_SEND_GLOBAL_NOTIFICATION_FAILURE,
    SUBMIT_SEND_GLOBAL_NOTIFICATION_REQUEST,
    SUBMIT_SEND_GLOBAL_NOTIFICATION_SUCCESS,
} from './actions';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    submitted: false,
    success: false,
};

type State = typeof DEFAULT_STATE & {
    error: string | null | undefined;
};

export const reducer = handleActions(
    {
        [SUBMIT_SEND_GLOBAL_NOTIFICATION_FAILURE]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            error: action.payload,
            submitted: false,
            success: false,
        }),
        [SUBMIT_SEND_GLOBAL_NOTIFICATION_REQUEST]: (state: State) => ({
            ...state,
            error: null,
            submitted: true,
            success: false,
        }),
        [SUBMIT_SEND_GLOBAL_NOTIFICATION_SUCCESS]: (state: State) => ({
            ...state,
            error: null,
            submitted: false,
            success: true,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.globalNotification;

export const getGlobalNotificationState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
type SubmitSendGlobalNotificationParams = {
    destinations: ('bidder-web' | 'bidder-mobile' | 'seller-web')[];
    forceReload: 'yes' | 'no';
    message: string;
    notificationType: 'reloadNotification' | 'textNotification';
    reloadVersion: string;
    testProd: string;
};

export const xsubmitSendGlobalNotification =
    ({
        destinations,
        forceReload,
        message,
        notificationType,
        reloadVersion,
        testProd,
    }: SubmitSendGlobalNotificationParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: SUBMIT_SEND_GLOBAL_NOTIFICATION_REQUEST,
            });

            const response = await sendGlobalNotificationApi({
                authToken,
                deployment,
                destinations,
                forceReload,
                isProd: testProd === 'production',
                message,
                notificationType,
                reloadVersion,
            });

            dispatch({
                payload: response.payload,
                type: SUBMIT_SEND_GLOBAL_NOTIFICATION_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_SEND_GLOBAL_NOTIFICATION_FAILURE,
            });
        }
    };
