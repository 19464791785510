import { annotateFacetExclusions, getUserExcludedFacets } from './searchExclusions';
import { createSelector } from 'reselect';
import { getDeployment } from './config';
import { getItem } from './item';
import { getRawSearchFacets } from './searchFacets';
import {
    LOAD_SEARCH_RESULTS_FAIL,
    LOAD_SEARCH_RESULTS_REQUEST,
    LOAD_SEARCH_RESULTS_SUCCESS,
} from '../../utils/searchUtils';
import api from '../api/search';
import cloneDeep from 'lodash/cloneDeep';
import facetTypes from '../../utils/search/facetTypes';
import isEmpty from 'lodash/isEmpty';

/* reducer */
const DEFAULT_STATE = {
    error: false,
    facets: [],
    isLoading: true,
    itemIDs: [],
    sort: [],
    submitted: false,
    success: false,
    totalFound: 0,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case LOAD_SEARCH_RESULTS_REQUEST:
            return {
                ...state,
                error: false,
                submitted: true,
                success: false,
            };
        case LOAD_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                facets: action.payload.facets,
                isLoading: false,
                itemIDs: action.payload.itemIDs,
                sort: action.payload.sort.filter((sortOption) => sortOption !== 'publishDate'),
                submitted: false,
                success: true,
                totalBuyNowItems: action.payload.totalBuyNow,
                totalFound: action.payload.totalFound,
                totalLiveItems: action.payload.totalLive,
                totalSoldItems: action.payload.totalSold,
            };
        case LOAD_SEARCH_RESULTS_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.search;
const globalStateSelector = (state) => state;

export const getSearchedItemIds = createSelector(stateSelector, (state) => state.itemIDs || []);

export const getSearchedItems = createSelector([getSearchedItemIds, globalStateSelector], (itemIds, state) =>
    itemIds.map((x) => getItem(state, x)).filter((x) => !isEmpty(x))
);

export const getSearchUiState = createSelector(stateSelector, (state) => {
    return {
        error: state.error,
        isLoading: state.isLoading,
        sort: state.sort,
        submitted: state.submitted,
        totalBuyNowItems: state.totalBuyNowItems,
        totalFound: state.totalFound,
        totalLiveItems: state.totalLiveItems,
        totalSoldItems: state.totalSoldItems,
    };
});

// For each key in the exclusions object, find the raw facet with that key, and add
// it to the search facets array
export const getSearchFacets = (state: any, archivedSearch: boolean) => {
    const search = state.search;
    const facetExclusions = getUserExcludedFacets();
    const rawFacets = getRawSearchFacets(state, archivedSearch) || [];

    const facets = cloneDeep(search.facets) || [];

    // for each exclusion, find the corresponding raw facet option, and add it
    Object.keys(facetExclusions).forEach((complexFacetId) => {
        const index = facets.findIndex((f) => f.id === complexFacetId);
        const complexFacet = rawFacets.find((f) => f.id === complexFacetId);
        if (index === -1 || !complexFacet || !complexFacet.options) {
            return;
        }

        // add excluded options to the options present in the search results
        facets[index].options = facetExclusions[complexFacetId].reduce((options, exclusion) => {
            const includedOptionsIndex = options.findIndex((o) => o.id === exclusion);
            const rawFacetOption = complexFacet.options.find((o) => o.id === exclusion);
            if (!rawFacetOption) {
                return options;
            }
            if (includedOptionsIndex === -1) {
                return [rawFacetOption, ...options];
            }
            options.splice(includedOptionsIndex, 1, rawFacetOption);
            return options;
        }, facets[index].options || []);
    });

    return annotateFacetExclusions(facets, facetExclusions);
};

const getSelectedOptionFacetValues = (state, archivedSearch, id) => {
    const facets = getSearchFacets(state, archivedSearch);
    const facetValues = facets.find((f) => f.id === id);
    const selectedFacetsValues = facetValues && facetValues.options ? facetValues.options.find((f) => f.selected) : {};
    return selectedFacetsValues || {};
};

const getSelectedTreeFacetValues = (state, archivedSearch, id) => {
    const facets = getSearchFacets(state, archivedSearch);
    const categoryFacets = facets.find((f) => f.id === id);
    const category =
        categoryFacets && categoryFacets.categories.facets.length > 0
            ? categoryFacets.categories.facets.find((f) => f.selected)
            : {};
    return category || {};
};

export const getSelectedCreator = (state: any, archivedSearch: boolean) =>
    getSelectedOptionFacetValues(state, archivedSearch, facetTypes.CREATOR_ID);

export const getSelectedMaterials = (state: any, archivedSearch: boolean) =>
    getSelectedOptionFacetValues(state, archivedSearch, facetTypes.MATERIALS_AND_TECHNIQUES_ID);

export const getSelectedStyle = (state: any, archivedSearch: boolean) =>
    getSelectedOptionFacetValues(state, archivedSearch, facetTypes.STYLE_PERIOD_ID);

export const getSelectedOrigin = (state: any, archivedSearch: boolean) =>
    getSelectedTreeFacetValues(state, archivedSearch, facetTypes.ORIGIN_ID);

export const getSelectedCategory = (state: any, archivedSearch: boolean) =>
    getSelectedTreeFacetValues(state, archivedSearch, facetTypes.CATEGORY_ID);

export const getUpcomingAuctions = (state: any, archivedSearch: boolean) => {
    const facets = getSearchFacets(state, archivedSearch);
    const facetValues = facets.find((f) => f.id === facetTypes.AUCTION_HOUSE_ID);
    return facetValues ? facetValues.options.length : 0;
};

export const getCategoryWithGreatestResults = (state: any, archivedSearch: boolean) => {
    const facets = getSearchFacets(state, archivedSearch);
    const categoryFacets = facets.find((f) => f.id === facetTypes.CATEGORY_ID);
    const category =
        categoryFacets && categoryFacets.categories && categoryFacets.categories.facets.length > 0
            ? categoryFacets.categories.facets[0]
            : {};
    return category;
};

/* ACTION CREATORS */
export const submitSearch = (searchQuery: any) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: searchQuery,
            type: LOAD_SEARCH_RESULTS_REQUEST,
        });

        // Annotate facets which have user-defined search exclusions
        const searchExclusions = getUserExcludedFacets();
        const augmentedOptions = !searchExclusions
            ? searchQuery.options
            : Object.keys(searchExclusions).reduce(
                  (options, facetWithExclusions) => ({
                      ...options,
                      [facetWithExclusions]: [
                          {
                              exclude: searchExclusions[facetWithExclusions] || [],
                              include: searchQuery.options[facetWithExclusions] || [],
                          },
                      ],
                  }),
                  { ...searchQuery.options }
              );
        const augmentedSearchQuery = {
            ...searchQuery,
            analyticsTags: ['redesigned-winner'],
            options: augmentedOptions,
        };

        const results = await api.fetchSearchResults({ deployment, searchQuery: augmentedSearchQuery });
        if (results.error) {
            dispatch({
                error: true,
                meta: { searchQuery },
                payload: results.payload,
                type: LOAD_SEARCH_RESULTS_FAIL,
            });
        } else {
            dispatch({
                payload: results.payload,
                type: LOAD_SEARCH_RESULTS_SUCCESS,
            });
        }
    } catch (error) {
        dispatch({
            error: true,
            meta: { searchQuery },
            payload: error,
            type: LOAD_SEARCH_RESULTS_FAIL,
        });
    }
};
