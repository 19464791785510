/* Action Types */
export const FETCH_COMBINATION_CATEGORIES_FAIL = 'FETCH_COMBINATION_CATEGORIES_FAIL';
export const FETCH_COMBINATION_CATEGORIES_START = 'FETCH_COMBINATION_CATEGORIES_START';
export const FETCH_COMBINATION_CATEGORIES_SUCCESS = 'FETCH_COMBINATION_CATEGORIES_SUCCESS';
export const FETCH_COMBINATION_CATEGORY_FAIL = 'FETCH_COMBINATION_CATEGORY_FAIL';
export const FETCH_COMBINATION_CATEGORY_START = 'FETCH_COMBINATION_CATEGORY_START';
export const FETCH_COMBINATION_CATEGORY_SUCCESS = 'FETCH_COMBINATION_CATEGORY_SUCCESS';
export const FETCH_ALL_COMBINATION_CATEGORIES_FAIL = 'FETCH_ALL_COMBINATION_CATEGORIES_FAIL';
export const FETCH_ALL_COMBINATION_CATEGORIES_START = 'FETCH_ALL_COMBINATION_CATEGORIES_START';
export const FETCH_ALL_COMBINATION_CATEGORIES_SUCCESS = 'FETCH_ALL_COMBINATION_CATEGORIES_SUCCESS';
export const FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_FAIL =
    'FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_FAIL';
export const FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_START =
    'FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_START';
export const FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS =
    'FETCH_COMBINATION_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS';
export const DELETE_COMBINATION_CATEGORY_SUCCESS = 'DELETE_COMBINATION_CATEGORY_SUCCESS';
export const SAVE_COMBINATION_CATEGORY_FAIL = 'SAVE_COMBINATION_CATEGORY_FAIL';
export const SAVE_COMBINATION_CATEGORY_REQUEST = 'SAVE_COMBINATION_CATEGORY_REQUEST';
export const SAVE_COMBINATION_CATEGORY_SUCCESS = 'SAVE_COMBINATION_CATEGORY_SUCCESS';
