import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { defaultHouseBetaSlice, HouseBetaSlice } from '@/redux/modules/houseBeta/houseBeta.types';
import {
    fetchHouseBetas,
    fetchShippingSaintHouseId,
    removeShippingSaintHouseId,
    updateHouseBeta,
    updateShippingSaintHouseId,
} from '@/redux/modules/houseBeta/houseBeta.actions';

const errorReducer = (slice: HouseBetaSlice, error: SerializedError) => {
    slice.error = error;
    slice.loading = false;
    slice.successfullyModified = false;
};

const betaSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchHouseBetas.pending, (slice) => {
            slice.loaded = undefined;
            slice.loading = true;
            slice.successfullyModified = false;
        });
        builder.addCase(fetchHouseBetas.fulfilled, (slice, { meta, payload }) => {
            slice.betas = payload;
            slice.error = undefined;
            slice.houseId = meta.arg;
            slice.loaded = new Date();
            slice.loading = false;
        });
        builder.addCase(fetchHouseBetas.rejected, (slice, { error }) => errorReducer(slice, error));

        builder.addCase(updateHouseBeta.pending, (slice) => {
            slice.successfullyModified = false;
        });
        builder.addCase(updateHouseBeta.fulfilled, (slice, { payload }) => {
            slice.betas = slice.betas.map((beta) => (beta.id === payload.id ? payload : beta));
            slice.error = undefined;
            slice.loading = false;
            slice.successfullyModified = true;
        });
        builder.addCase(updateHouseBeta.rejected, (slice, { error }) => errorReducer(slice, error));

        builder.addCase(fetchShippingSaintHouseId.pending, (slice) => {
            slice.shippingSaintHouseId = '';
        });
        builder.addCase(fetchShippingSaintHouseId.fulfilled, (slice, { payload }) => {
            slice.shippingSaintHouseId = payload;
        });

        builder.addCase(updateShippingSaintHouseId.pending, (slice) => {
            slice.successfulShippingSaintHouseIdSave = false;
        });
        builder.addCase(updateShippingSaintHouseId.fulfilled, (slice, { meta }) => {
            slice.shippingSaintHouseId = meta.arg.shippingSaintHouseId;
            slice.successfulShippingSaintHouseIdSave = true;
        });
        builder.addCase(updateShippingSaintHouseId.rejected, (slice, { payload }) => {
            if (payload?.message?.includes('shippingSaintsHouseID already used')) {
                slice.shippingSaintHouseIdError = 'betaChecklist.shippingSaint.errors.duplicate';
            } else {
                slice.shippingSaintHouseIdError = 'betaChecklist.shippingSaint.errors.system';
            }
        });

        builder.addCase(removeShippingSaintHouseId.fulfilled, (slice) => {
            slice.shippingSaintHouseId = '';
        });
    },
    initialState: defaultHouseBetaSlice,
    name: 'houseBetas',
    reducers: {},
});

export const { reducer: houseBetaReducer } = betaSlice;
