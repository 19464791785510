import * as api from '../api/searchSuggestions';
import { createSelector } from 'reselect';
import { getDeployment } from './config';
import cloneDeep from 'lodash/cloneDeep';

/* Action Types */
export const SEARCH_SUGGESTIONS_FAIL = 'la/ui/searchSuggestions/FAIL';
export const SEARCH_SUGGESTIONS_REQUEST = 'la/ui/searchSuggestions/REQUEST';
export const SEARCH_SUGGESTIONS_SUCCESS = 'la/ui/searchSuggestions/SUCCESS';

// reducer
const DEFAULT_STATE = {
    error: false,
    submitted: false,
    success: false,
    suggestions: [],
};

export type State = typeof DEFAULT_STATE;

export function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SEARCH_SUGGESTIONS_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SEARCH_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
                suggestions: cloneDeep(action.payload),
            };
        case SEARCH_SUGGESTIONS_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.searchSuggestions;
export const searchSuggestionsSelector = createSelector(stateSelector, (state) => state.suggestions);

export const getSearchSuggestions = createSelector(searchSuggestionsSelector, (suggestions) => {
    if (suggestions?.length > 0) {
        return suggestions
            .filter((s) => s.value.match(/^[a-zA-Z0-9]+/) !== null && s.houseId !== 0) // removes test values / non alphanumerics
            .map((s) => {
                return { label: s.value, value: s.houseId };
            })
            .sort(function compare(a, b) {
                if (a.label[0] > b.label[0]) {
                    return 1;
                } else {
                    return -1;
                }
            });
    } else {
        return [];
    }
});

/* ACTION CREATORS */
export const fetchSearchSuggestions =
    (term: string, secondary: boolean = false) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            dispatch({
                payload: term,
                type: SEARCH_SUGGESTIONS_REQUEST,
            });
            const response = await api.fetchSuggestions({ deployment, term });
            dispatch({
                meta: { secondary },
                payload: response.payload,
                type: SEARCH_SUGGESTIONS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { term },
                payload: error,
                type: SEARCH_SUGGESTIONS_FAIL,
            });
        }
    };
