import config from '../config';

const getCookieValue = (a) => {
    const b = typeof document !== 'undefined' && document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
    return b ? b.pop() : '';
};

export const getInitialState = (req: any = { cookies: [], headers: [], hostname: '', url: '' }) => {
    const token = req.cookies['auctioneer-auth'] || getCookieValue('auctioneer-auth') || '';

    return {
        config,
        health: {
            'bidder-catalog-api-barako': {},
            'bidder-catalog-api-preprod': {},
            'bidder-catalog-api-prod': {},
            'bidder-catalog-api-stage': {},
            'bidder-home-api-barako': {},
            'bidder-home-api-preprod': {},
            'bidder-home-api-prod': {},
            'bidder-home-api-stage': {},
            'bidder-log-api-barako': {},
            'bidder-log-api-preprod': {},
            'bidder-log-api-prod': {},
            'bidder-log-api-stage': {},
            'bidder-react-barako': {},
            'bidder-react-preprod': {},
            'bidder-react-preprod-barako': {},
            'bidder-react-prod': {},
            'bidder-react-stage': {},
            'crispy-pancake-barako': {},
            'crispy-pancake-preprod': {},
            'crispy-pancake-prod': {},
            'crispy-pancake-stage': {},
            'item-api-barako': {},
            'item-api-preprod': {},
            'item-api-prod': {},
            'item-api-stage': {},
            'redesigned-winner-barako': {},
            'redesigned-winner-preprod': {},
            'redesigned-winner-prod': {},
            'redesigned-winner-stage': {},
        },
        user: {
            token,
            userData: {},
        },
    };
};
