import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

type Props = {
    className?: string;
};

const Navigation = ({ className }: Props) => {
    const [path, setPath] = useState<string>(window.location.pathname);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setPath(location.pathname);
        });
    }, [history]);

    return (
        <StyledNavigation className={className} role="navigation">
            <StyledNavigationContainer>
                <StyledNavigationLink $currentRoute={path.startsWith('/health')} to="/health">
                    Health
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/j52')} to="/j52">
                    J52
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/status')} to="/status">
                    Status
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/bidder')} to="/bidder">
                    Bidder
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/catalog')} to="/catalog">
                    Catalog
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/marketing')} to="/marketing">
                    Marketing
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/bin')} to="/bin">
                    BIN
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/categorization')} to="/categorization">
                    Categorization
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/finance-dashboard')} to="/finance-dashboard">
                    Finance
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/house-settings')} to="/house-settings">
                    Auction House Settings
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/white-label')} to="/white-label">
                    White Label
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/reviews')} to="/reviews">
                    Reviews
                </StyledNavigationLink>
                <StyledNavigationLink $currentRoute={path.startsWith('/atg-ship')} to="/atg-ship">
                    atgShip
                </StyledNavigationLink>
            </StyledNavigationContainer>
        </StyledNavigation>
    );
};

export default Navigation;

const StyledNavigation = styled.div`
    background: ${({ theme }) => theme.colors.grey200};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 5px 0;
`;

const StyledNavigationContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
`;

const StyledNavigationLink = styled(Link)<{ $currentRoute: boolean }>`
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    font-size: 16px;
    margin: 0 10px;
    font-family: ${({ theme }) => theme.typography.fontSecondary};

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.blue200};
    }

    ${({ $currentRoute }) => {
        if ($currentRoute) {
            return css`
                &:visited {
                    color: ${({ theme }) => theme.colors.black};
                }
            `;
        }
    }}
`;
