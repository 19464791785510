import { ActionWithPayload } from '@/types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
    DELETE_FEATURED_AUCTIONEERS_FAILURE,
    DELETE_FEATURED_AUCTIONEERS_REQUEST,
    DELETE_FEATURED_AUCTIONEERS_SUCCESS,
    EDIT_FEATURED_AUCTIONEERS_FAILURE,
    EDIT_FEATURED_AUCTIONEERS_REQUEST,
    EDIT_FEATURED_AUCTIONEERS_SUCCESS,
    LOAD_FEATURED_AUCTIONEERS_FAILURE,
    LOAD_FEATURED_AUCTIONEERS_REQUEST,
    LOAD_FEATURED_AUCTIONEERS_SUCCESS,
    POST_FEATURED_AUCTIONEERS_FAILURE,
    POST_FEATURED_AUCTIONEERS_REQUEST,
    POST_FEATURED_AUCTIONEERS_SUCCESS,
} from './actions';
import { FeaturedAuctioneer } from '@/types/FeaturedAuctioneer';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/featuredAuctioneers';

/* REDUCER */

const DEFAULT_STATE: State = {
    error: false,
    featuredAuctioneers: [],
    isLoading: false,
};

export type State = {
    error: boolean;
    featuredAuctioneers: FeaturedAuctioneer[];
    isLoading: boolean;
};

export const reducer = handleActions(
    {
        [combineActions(
            DELETE_FEATURED_AUCTIONEERS_FAILURE,
            EDIT_FEATURED_AUCTIONEERS_FAILURE,
            LOAD_FEATURED_AUCTIONEERS_FAILURE,
            POST_FEATURED_AUCTIONEERS_FAILURE
        )]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [combineActions(
            DELETE_FEATURED_AUCTIONEERS_REQUEST,
            EDIT_FEATURED_AUCTIONEERS_REQUEST,
            LOAD_FEATURED_AUCTIONEERS_REQUEST,
            POST_FEATURED_AUCTIONEERS_REQUEST
        )]: (state: State): State => ({
            ...state,
            error: false,
            isLoading: true,
        }),
        [LOAD_FEATURED_AUCTIONEERS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ featuredAuctioneers: FeaturedAuctioneer[] }>
        ): State => {
            return {
                ...state,
                featuredAuctioneers: action.payload.featuredAuctioneers,
                isLoading: false,
            };
        },
        [combineActions(
            DELETE_FEATURED_AUCTIONEERS_SUCCESS,
            EDIT_FEATURED_AUCTIONEERS_SUCCESS,
            POST_FEATURED_AUCTIONEERS_SUCCESS
        )]: (state: State): State => ({
            ...state,
            error: false,
            isLoading: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */

const stateSelector = (state): State => state.featuredAuctioneers;
export const loadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const featuredAuctioneersSelector = createSelector(stateSelector, (state) => state.featuredAuctioneers);

/* ACTION CREATORS */

export const deleteFeaturedAuctioner =
    (featuredAuctioneerID: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: DELETE_FEATURED_AUCTIONEERS_REQUEST,
            });

            const response = await api.deleteFeaturedAuctioner({ authToken, deployment, featuredAuctioneerID });
            dispatch({
                payload: response.payload,
                type: DELETE_FEATURED_AUCTIONEERS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                type: DELETE_FEATURED_AUCTIONEERS_FAILURE,
            });
        }
    };

export const fetchFeaturedAuctioneers = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_FEATURED_AUCTIONEERS_REQUEST,
        });

        const response = await api.fetchFeaturedAuctioneers({ authToken, deployment });
        return dispatch({
            payload: { featuredAuctioneers: response.payload },
            type: LOAD_FEATURED_AUCTIONEERS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_FEATURED_AUCTIONEERS_FAILURE,
        });
    }
};

export const postFeaturedAuctioneers =
    (
        destinationURL: string,
        endTime: string,
        houseId: number,
        imageURL: string,
        positionColumn: number,
        startTime: string
    ) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: POST_FEATURED_AUCTIONEERS_REQUEST,
            });

            const response = await api.postFeaturedAuctioneers({
                authToken,
                deployment,
                destinationURL,
                endTime,
                houseId,
                imageURL,
                positionColumn,
                startTime,
            });
            dispatch({
                payload: response.payload,
                type: POST_FEATURED_AUCTIONEERS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                type: POST_FEATURED_AUCTIONEERS_FAILURE,
            });
        }
    };

export const editFeaturedAuctioneers =
    (
        destinationURL: string,
        endTime: string,
        featuredAuctioneerId: number,
        houseId: number,
        imageURL: string,
        positionColumn: number,
        startTime: string
    ) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: EDIT_FEATURED_AUCTIONEERS_REQUEST,
            });

            const response = await api.editFeaturedAuctioneers({
                authToken,
                deployment,
                destinationURL,
                endTime,
                featuredAuctioneerId,
                houseId,
                imageURL,
                positionColumn,
                startTime,
            });
            dispatch({
                payload: response.payload,
                type: EDIT_FEATURED_AUCTIONEERS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                type: EDIT_FEATURED_AUCTIONEERS_FAILURE,
            });
        }
    };
