/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { makePost } from '../api/helpers';

/* Action Types */
export const UPLOAD_CATALOG_REQUEST = 'UPLOAD_CATALOG_REQUEST';
export const UPLOAD_CATALOG_FAIL = 'UPLOAD_CATALOG_FAIL';
export const UPLOAD_CATALOG_SUCCESS = 'UPLOAD_CATALOG_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    errors: null,
    submitted: false,
    success: null,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case UPLOAD_CATALOG_FAIL:
            return {
                ...state,
                error: action.payload,
                errors: action.meta.errors,
                submitted: false,
                success: null,
            };
        case UPLOAD_CATALOG_REQUEST:
            return {
                ...state,
                error: null,
                errors: null,
                submitted: true,
                success: null,
            };
        case UPLOAD_CATALOG_SUCCESS:
            return {
                ...state,
                error: null,
                errors: null,
                submitted: false,
                success: action.payload,
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.uploadCatalog;

export const getUploadCatalogState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
const submitCatalogFail = (errorMessage, errors) => {
    let meta = {};
    if (errors && errors.errors) {
        meta = { errors: errors.errors };
    }
    return {
        error: true,
        meta,
        payload: errorMessage,
        type: UPLOAD_CATALOG_FAIL,
    };
};

export const submitUploadCatalog = (catalog: any) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: UPLOAD_CATALOG_REQUEST,
        });

        const request = makePost({
            apiPath: 'create/catalog',
            authToken: authToken,
            deployment,
            path: '<ITEM-API>',
        });
        request
            .send(catalog)
            // @ts-ignore
            .end((err, { body } = {}) => {
                if (err) {
                    dispatch(submitCatalogFail(err.message, err.error));
                } else if (body && body.errorMessage) {
                    dispatch(submitCatalogFail(body.errorMessage, body.error));
                } else if (body && 'success' in body && !body.success) {
                    dispatch(submitCatalogFail(body.message, body.error));
                } else {
                    dispatch({
                        payload: body.data,
                        type: UPLOAD_CATALOG_SUCCESS,
                    });
                }
            });
    } catch (error) {
        // @ts-ignore
        dispatch(submitCatalogFail(error.message));
    }
};
