import { handleResponse, makePost } from '@/redux/api/helpers';
import { PostDeleteBidderCardsParams, PostDeleteBidderCardsResponse } from './bidderCards.types';

type PostDeleteBidderCardsAPIParams = {
    authToken: string;
    deployment: string;
    params: PostDeleteBidderCardsParams;
};

export const postDeleteBidderCards = ({ authToken, deployment, params }: PostDeleteBidderCardsAPIParams) =>
    new Promise<PostDeleteBidderCardsResponse>((resolve, reject) => {
        const { bidderId, cardIds } = params;
        const request = makePost({
            apiPath: `support/bidder/${bidderId}/cards/delete`,
            authToken,
            deployment,
            path: '<PAYMENT-SERVER-API>',
        });

        request.send({ cardIds });
        request.query({ bidderId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
