import { ActionWithPayload } from '@/types/redux';
import {
    ADD_CATEGORY_FAIL,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    EDIT_CATEGORY_FAIL,
    EDIT_CATEGORY_REQUEST,
    EDIT_CATEGORY_SUCCESS,
    LOAD_CATEGORY_FAIL,
    LOAD_CATEGORY_REQUEST,
    LOAD_CATEGORY_SUCCESS,
    LOAD_PARENT_FAIL,
    LOAD_PARENT_REQUEST,
    LOAD_PARENT_SUCCESS,
    MERGE_CATEGORY_FAIL,
    MERGE_CATEGORY_REQUEST,
    MERGE_CATEGORY_SUCCESS,
    RESET_CATEGORY_ERROR_STATE,
    SELECT_CATEGORY,
    SELECT_FACET,
} from './actions';
import { Category } from '@/types/Category';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api, { AddCategoryProps, FetchCategoryByIdParams, PutEditCategoryParams } from '../api/categoryTaxonomy';
import cloneDeep from 'lodash/cloneDeep';

/* reducer */
const DEFAULT_STATE: State = {
    byId: {},
    currentFacetId: 1,
    error: undefined,
    loading: false,
    selectedCategoryId: undefined,
    submitted: false,
    success: false,
};

export type State = {
    byId: {
        [id: number]: Category; // Truly a Partial<Category>
    };
    currentFacetId: number;
    error: any;
    loading: boolean;
    selectedCategoryId?: number;
    submitted: boolean;
    success: boolean;
};

export const reducer = handleActions(
    {
        [combineActions(
            ADD_CATEGORY_FAIL,
            LOAD_CATEGORY_FAIL,
            LOAD_PARENT_FAIL,
            DELETE_CATEGORY_FAIL,
            EDIT_CATEGORY_FAIL,
            MERGE_CATEGORY_FAIL
        )]: (state: State, action: ActionWithPayload<any>): State => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [combineActions(
            ADD_CATEGORY_REQUEST,
            LOAD_CATEGORY_REQUEST,
            LOAD_PARENT_REQUEST,
            DELETE_CATEGORY_REQUEST,
            EDIT_CATEGORY_REQUEST,
            MERGE_CATEGORY_REQUEST
        )]: (state: State): State => ({
            ...state,
            submitted: true,
        }),
        [combineActions(ADD_CATEGORY_SUCCESS, EDIT_CATEGORY_SUCCESS, MERGE_CATEGORY_SUCCESS, DELETE_CATEGORY_SUCCESS)]:
            (state: State): State => ({
                ...state,
                success: true,
            }),
        [LOAD_CATEGORY_SUCCESS]: (state: State, action: ActionWithPayload<Category>): State => {
            const existing = cloneDeep(state.byId);

            let facetId;

            if (action.payload) {
                facetId = action.payload.facetId;
                if (action.payload.subCategories) {
                    // @ts-ignore
                    action.payload.subCategories = action.payload.subCategories.map((c: Category) => {
                        if (c.categoryId === state.selectedCategoryId) {
                            c.subCategories = existing[c.categoryId]?.subCategories;
                        }
                        existing[c.categoryId] = { ...c };
                        return c.categoryId;
                    });
                } else {
                    action.payload.subCategories = [];
                }

                if (action.payload.parentCategoryId === null && action.payload.categoryId !== 0) {
                    action.payload.parentCategoryId = 0;
                }

                existing[action.payload.categoryId] = { ...action.payload };
            }

            return {
                ...state,
                byId: existing,
                currentFacetId: facetId || state.currentFacetId,
                loading: false,
            };
        },
        [LOAD_PARENT_SUCCESS]: (state: State, action: ActionWithPayload<Partial<Category>[]>): State => {
            const existing = cloneDeep(state.byId);

            if (action.payload) {
                action.payload.map(
                    (c: { categoryId: number; name: string }) =>
                        // @ts-ignore allows for lack of partial in the state definition.
                        (existing[c.categoryId] = { ...existing[c.categoryId], ...c })
                );
            }

            return {
                ...state,
                byId: existing,
                loading: false,
            };
        },
        [RESET_CATEGORY_ERROR_STATE]: (state: State): State => ({
            ...state,
            error: undefined,
        }),
        [SELECT_CATEGORY]: (state: State, action: ActionWithPayload<number>): State => ({
            ...state,
            selectedCategoryId: action.payload,
        }),
        [SELECT_FACET]: (state: State, action: ActionWithPayload<number>): State => ({
            ...state,
            currentFacetId: action.payload,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (globalState: any): State => globalState.categoryTaxonomy;

const idSelector = (state: any, id: number) => id;
const byIdSelector: (state: any) => {
    [id: number]: Category;
} = createSelector(stateSelector, (state) => state.byId);

export const getCategoryById: (state: any, id: number) => Category = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id]
);
export const getAllCategories = byIdSelector;
export const getSelectedCategoryId: (state: any) => number = createSelector(
    stateSelector,
    (state) => state.selectedCategoryId
);
export const getSelectedFacetId: (state: any) => number = createSelector(
    stateSelector,
    (state) => state.currentFacetId
);
export const getSelectedCategory = createSelector([byIdSelector, getSelectedCategoryId], (byId, id) => byId[id]);
export const isCategorySelected = createSelector(getSelectedCategoryId, (id) => id !== 0);
export const isSelectedCategoryLoaded = createSelector(
    [byIdSelector, getSelectedCategoryId],
    (byId, id) => byId[id] !== undefined
);
export const isCategoryLoaded = (state: any, categoryId: number): boolean =>
    getCategoryById(state, categoryId) !== undefined;

export const isError = createSelector(stateSelector, (state) => state.error !== undefined);

/* ACTION CREATORS */
export const fetchCategoryById =
    ({ categoryId, facetId }: FetchCategoryByIdParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            if (isNaN(categoryId) || categoryId === null) {
                return;
            }

            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            if (!Boolean(facetId)) {
                facetId = getSelectedFacetId(state);
            }

            dispatch({
                type: LOAD_CATEGORY_REQUEST,
            });

            const response = await api.fetchCategoryById({ authToken, categoryId, deployment, facetId });
            return dispatch({
                payload: response.payload,
                type: LOAD_CATEGORY_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_CATEGORY_FAIL,
            });
        }
    };

export const fetchCategoryByIdIfNeeded =
    ({ categoryId }: FetchCategoryByIdParams) =>
    async (dispatch: Function, getState: Function) => {
        if (Boolean(categoryId) && !isCategoryLoaded(getState(), categoryId)) {
            return dispatch(fetchCategoryById({ categoryId }));
        }
        return Promise.resolve();
    };

export const fetchParentCategories =
    ({ categoryId }: FetchCategoryByIdParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_CATEGORY_REQUEST,
            });

            const response = await api.fetchParentCategories({ authToken, categoryId, deployment });

            return dispatch({
                payload: response.payload,
                type: LOAD_PARENT_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_CATEGORY_FAIL,
            });
        }
    };

export const addCategory = (categoryDetails: AddCategoryProps) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const facetId = getSelectedFacetId(state);

        dispatch({
            type: ADD_CATEGORY_REQUEST,
        });

        const response = await api.postAddCategory({ authToken, deployment, ...categoryDetails, facetId });
        api.postUpdateDewey({ authToken, deployment });

        return dispatch({
            payload: response.data,
            type: ADD_CATEGORY_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: ADD_CATEGORY_FAIL,
        });
    }
};

export const editCategory =
    (categoryDetails: PutEditCategoryParams) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: EDIT_CATEGORY_REQUEST,
            });

            const response = await api.putEditCategory({ authToken, deployment, ...categoryDetails });
            api.postUpdateDewey({ authToken, deployment });

            return dispatch({
                payload: response.data,
                type: EDIT_CATEGORY_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: EDIT_CATEGORY_FAIL,
            });
        }
    };

export const deleteCategory =
    (category: Category, preventRecategorization: boolean) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: DELETE_CATEGORY_REQUEST,
            });

            const response = await api.postMergeCategory({
                authToken,
                deployment,
                DstCategoryID: category.parentCategoryId,
                preventRecategorization,
                SrcCategoryID: category.categoryId,
            });
            api.postUpdateDewey({ authToken, deployment });

            return dispatch({
                payload: response.data,
                type: DELETE_CATEGORY_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: DELETE_CATEGORY_FAIL,
            });
        }
    };

export const mergeCategory =
    (srcId: number, dstId: number, preventRecategorization: boolean) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: MERGE_CATEGORY_REQUEST,
            });

            const response = await api.postMergeCategory({
                authToken,
                deployment,
                DstCategoryID: dstId,
                preventRecategorization,
                SrcCategoryID: srcId,
            });
            api.postUpdateDewey({ authToken, deployment });

            return dispatch({
                payload: response.data,
                type: MERGE_CATEGORY_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: MERGE_CATEGORY_FAIL,
            });
        }
    };

export const selectCategory =
    ({ categoryId }: FetchCategoryByIdParams) =>
    async (dispatch: Function) => {
        return Promise.all([
            dispatch(fetchCategoryById({ categoryId })),
            dispatch(fetchParentCategories({ categoryId })),
            dispatch({
                payload: categoryId,
                type: SELECT_CATEGORY,
            }),
        ]);
    };

export const resetErrorState = () => async (dispatch: Function) => {
    return dispatch({
        type: RESET_CATEGORY_ERROR_STATE,
    });
};

export const selectFacet = (facetId: number) => async (dispatch: Function) => {
    return dispatch({
        payload: facetId,
        type: SELECT_FACET,
    });
};
