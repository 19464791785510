import { CategorySuggestion } from '../../types/CategorySuggestion';
import { handleResponse, makeGet, makePost } from './helpers';

type GetRelatedSearchesByCatIDParams = {
    authToken: string;
    categoryId: number;
    deployment: string;
};

const getRelatedSearchesByCatID = ({ authToken, categoryId, deployment }: GetRelatedSearchesByCatIDParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getrelatedsearchesbyid',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ categoryId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRelatedSearchParams = {
    authToken: string;
    deployment: string;
    suggestion: CategorySuggestion;
};

type DeleteRelatedSearchParams = {
    authToken: string;
    deployment: string;
    idToDelete: number;
};

const postRelatedSearch = ({ authToken, deployment, suggestion }: PostRelatedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'addrelatedsearch', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ ...suggestion });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const updateRelatedSearch = ({ authToken, deployment, suggestion }: PostRelatedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'updaterelatedsearch', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ ...suggestion });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

const deleteRelatedSearch = ({ authToken, deployment, idToDelete }: DeleteRelatedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'deleterelatedsearch', authToken, deployment, path: '<CATEGORY-API>' });
        request.send({ idToDelete });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    deleteRelatedSearch,
    getRelatedSearchesByCatID,
    postRelatedSearch,
    updateRelatedSearch,
};
