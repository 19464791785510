import { Refund } from '../../../types/Payment';
import { TypedActionWithPayload } from '../../../types/redux';

export const LOAD_BIDDER_REFUNDS_ADMIN_FAIL = 'LOAD_BIDDER_REFUNDS_ADMIN_FAIL';
export const LOAD_BIDDER_REFUNDS_ADMIN_REQUEST = 'LOAD_BIDDER_REFUNDS_ADMIN_REQUEST';
export const LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS = 'LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS';
export type LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS,
    { data: Refund[]; totalRecords: number }
>;

export const REFUNDS_FILTERS_CHANGE = 'REFUNDS_FILTERS_CHANGE';
export const REFUNDS_FILTERS_CHANGE_FAIL = 'REFUNDS_FILTERS_CHANGE_FAIL';
