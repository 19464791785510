import { handleResponse, makeDelete, makeGet, makePut } from './helpers';

export type ReviewItem = {
    ReviewSubmittedTS: string;
    accuracy: number;
    bidderCity: string;
    bidderCountry: string;
    bidderFirstName: string;
    bidderId: number;
    bidderLastName: string;
    bidderState: string;
    catalogId: number;
    catalogName: string;
    comments: Comment[];
    note?: string;
    overall: number;
    payment: number;
    responsiveness: number;
    review: string;
    reviewHeadline: string;
    reviewId: number;
    reviewSubmitted: string;
    reviewSubmittedTS: string;
    sellerId: number;
    shipping: number;
    wonItems: WonItem[];
};

type Comment = {
    bidderId: number;
    comment: string;
    commentId: number;
    commentSubmitted: string;
    commentSubmittedTS: string;
    houseId: number;
    privacyStatus: string;
    reviewId: number;
    userDisplayName: string;
    userId: number;
    userType: string;
};

type WonItem = {
    bidderId: number;
    catalogId: number;
    itemId: number;
    title: string;
};

type FetchReviewDataParams = {
    authToken: string;
    bidderId?: number;
    deployment: string;
    houseId?: number;
    page?: number;
    pageSize?: number;
};

type FetchReviewDataResponse = {
    error: boolean;
    payload: null | ReviewItem[];
};

type UpdateReviewDataParams = {
    authToken: string;
    deployment: string;
    review: ReviewItem;
};

type UpdateReviewDataResponse = {
    error: boolean;
    payload: unknown; // todo: make this a review item type array
};

type DeleteReviewDataParams = {
    authToken: string;
    deployment: string;
    reviewId: number;
};

type DeleteReviewDataResponse = {
    error: boolean;
    payload: unknown; // todo: figure out what this returns
};

type DeleteCommentDataParams = {
    authToken: string;
    commentId: number;
    deployment: string;
};

type DeleteCommentDataResponse = {
    error: boolean;
    payload: unknown; // todo: figure out what this returns
};

// get reviews
export const getReviews = ({ authToken, bidderId, deployment, houseId, page, pageSize }: FetchReviewDataParams) =>
    new Promise<FetchReviewDataResponse>((resolve, reject) => {
        let params: any = {};
        if (bidderId) {
            params.bidderId = bidderId;
        }
        if (!isNaN(houseId)) {
            params.houseId = houseId;
        }
        if (page) {
            params.page = page;
        }
        if (pageSize) {
            params.pageSize = pageSize;
        }
        const queryParameters = new URLSearchParams(params).toString();

        const request = makeGet({
            apiPath: `reviews?${queryParameters}`,
            authToken,
            deployment,
            path: '<REVIEW-SERVICE>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

// update review
export const updateReview = ({ authToken, deployment, review }: UpdateReviewDataParams) =>
    new Promise<UpdateReviewDataResponse>((resolve, reject) => {
        const reviewId = review.reviewId;
        const request = makePut({
            apiPath: `review/${reviewId}`,
            authToken,
            deployment,
            path: '<REVIEW-SERVICE>',
        });
        request.send(review);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

// delete review(s)
export const deleteReview = ({ authToken, deployment, reviewId }: DeleteReviewDataParams) =>
    new Promise<DeleteReviewDataResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `review/${reviewId}/delete`,
            authToken,
            deployment,
            path: '<REVIEW-SERVICE>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const deleteComment = ({ authToken, commentId, deployment }: DeleteCommentDataParams) =>
    new Promise<DeleteCommentDataResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `comment/${commentId}`,
            authToken,
            deployment,
            path: '<REVIEW-SERVICE>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
