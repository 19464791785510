import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { fetchSellersByIds } from '@/redux/api/seller';
import { fetchSuggestions } from '@/redux/api/searchSuggestions';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import AsyncSelect from '@liveauctioneers/caterwaul-components/lib/Select/Async';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

type Props = {
    submitHouseId: (houseId: number) => void;
};

type SelectOptions = { label: string; value: number }[];

const HouseFinder = ({ submitHouseId }: Props) => {
    const { formatMessage } = useIntl();

    const authToken = useSelector(getAuthToken);
    const deployment = useSelector(getDeployment);

    const [houseIdInput, setHouseIdInput] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const loadOptions = (searchInput: string, callback: (selectOptions: SelectOptions) => void) => {
        let selectOptions: SelectOptions = [];

        const fuzzySearchHouse = async () => {
            // If search is an integer, try to look up house by id
            const searchIsInteger = Number.isInteger(Number(searchInput)) && Number(searchInput) > 0;

            if (searchIsInteger) {
                const findHouseByIdPayload = await fetchSellersByIds({
                    authToken,
                    deployment,
                    sellerIds: [Number(searchInput)],
                });

                if (findHouseByIdPayload.data.sellers.length) {
                    selectOptions = [
                        {
                            label: findHouseByIdPayload.data.sellers[0].name,
                            value: findHouseByIdPayload.data.sellers[0].sellerId,
                        },
                    ];
                }
            } else {
                // if searching by auction house name, retrieve search suggestions
                const suggestions = await fetchSuggestions({ deployment, term: searchInput });
                selectOptions = suggestions?.payload?.map(({ houseId, label }) => ({ label, value: houseId })) || [];
            }
        };

        fuzzySearchHouse().then(() => {
            callback(selectOptions);
        });
    };

    const submitSearch = useCallback(() => {
        if (!houseIdInput) {
            return;
        }
        setSubmitted(true);

        submitHouseId(houseIdInput);

        setSubmitted(false);
    }, [houseIdInput, submitHouseId]);

    // Debounce calling search api
    const debouncedLoadOptions = useDebouncedCallback(loadOptions, 300);

    return (
        <StyledContainer>
            <AsyncSelect
                autoFocus
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                hideSpacer
                id="inputHouseId"
                isSearchable
                label={formatMessage({ id: 'houseFinder.label' })}
                loadOptions={debouncedLoadOptions}
                noOptionsMessage={() =>
                    formatMessage({
                        id: 'houseFinder.noOptions',
                    })
                }
                onChange={({ value }) => setHouseIdInput(value)}
                placeholder={formatMessage({
                    id: 'houseFinder.placeholder',
                })}
                testid="house-search-select"
                width={282}
            />
            <StyledButton loading={submitted} onClick={submitSearch}>
                {formatMessage({
                    id: 'houseFinder.search',
                })}
            </StyledButton>
        </StyledContainer>
    );
};

export default HouseFinder;

const StyledContainer = styled.section`
    display: flex;
    flex-direction: row;
`;

const StyledButton = styled(Button)`
    margin-left: 16px;
    margin-top: 18px;
    height: 42px;
`;
