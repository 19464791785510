import { AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { Dispatch } from 'redux';
import { GlobalState } from './store';
import { createAsyncThunk as toolkitCreateThunk } from '@reduxjs/toolkit';

// NOTE: This is copied from redux-toolkit, because it is not exported.
// We shouldn't need to touch this often, but this will serve as a reference point, just in case
type AsyncThunkConfig = {
    dispatch?: Dispatch;
    extra?: unknown;
    fulfilledMeta?: unknown;
    pendingMeta?: unknown;
    rejectValue?: unknown;
    rejectedMeta?: unknown;
    serializedErrorType?: unknown;
    state?: unknown;
};

export type AsyncThunkBaseConfig = AsyncThunkConfig & {
    state: GlobalState;
};

export type AsyncThunkConfigWithRejectValue = AsyncThunkBaseConfig & {
    rejectValue: Error;
};

/**
 * Wrapper function for redux-toolkit's createAsyncThunk, but typed to improve ease of use when working with rejectWithValue
 *
 * @param typePrefix Name of the action, will be appended with `.pending`, `.fulfilled`, or `.rejected` when dispatched and completed
 * @param payloadCreator The thunk function that will be run when the action is dispatched
 * @param options Additional configuration, including error-based `rejectValue` configuration
 *
 * @typeParam Returned - What the thunk will return when completed successfully
 * @typeParam Input - The arguments of the thunk that will need to be provided on dispatch
 * @typeParam Config - Additional configuration, including error-based `rejectValue` configuration
 */
export function createAsyncThunk<Returned, Input = void, Config extends AsyncThunkConfig = { state: GlobalState }>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, Input, Config>,
    options?: AsyncThunkOptions<Input, Config>
): AsyncThunk<Returned, Input, Config> {
    return toolkitCreateThunk<Returned, Input, Config>(typePrefix, payloadCreator, options);
}
