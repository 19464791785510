import { createSlice } from '@reduxjs/toolkit';
import { defaultShippingLabelSlice } from './shippingLabel.types';
import { fetchShippingLabel, saveShippingLabel } from './shippingLabel.actions';

const shippingLabelSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchShippingLabel.pending, (slice, { meta }) => {
            if (slice.loading.indexOf(meta.arg) < 0) {
                slice.loading = [...slice.loading, meta.arg];
            }
        });
        builder.addCase(fetchShippingLabel.fulfilled, (slice, { meta, payload }) => {
            const loadingIndex = slice.loading.indexOf(meta.arg);
            if (loadingIndex >= 0) {
                slice.loading = slice.loading.splice(loadingIndex, 1);
            }
            slice.loaded[meta.arg] = Date.now();
            slice.byId[meta.arg] = payload;
        });
        builder.addCase(fetchShippingLabel.rejected, (slice, { meta }) => {
            const loadingIndex = slice.loading.indexOf(meta.arg);
            if (loadingIndex >= 0) {
                slice.loading = slice.loading.splice(loadingIndex, 1);
            }
        });

        builder.addCase(saveShippingLabel.pending, (slice) => {
            slice.result = undefined;
        });
        builder.addCase(saveShippingLabel.fulfilled, (slice) => {
            slice.result = 'success';
        });
        builder.addCase(saveShippingLabel.rejected, (slice) => {
            slice.result = 'failure';
        });
    },
    initialState: defaultShippingLabelSlice,
    name: 'shippingLabel',
    reducers: {},
});

export const { reducer: shippingLabelReducer } = shippingLabelSlice;
