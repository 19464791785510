import { createSlice } from '@reduxjs/toolkit';
import { defaultShippingQuoteSlice, ShippingQuoteError } from './shippingQuote.types';
import { fetchShippingQuote } from './shippingQuote.actions';

const shipingQuoteSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchShippingQuote.pending, (slice, { meta }) => {
            if (slice.loading.indexOf(meta.arg.itemId) < 0) {
                slice.loading = [...slice.loading, meta.arg.itemId];
            }
            slice.error = undefined;
            slice.result = undefined;
        });
        builder.addCase(fetchShippingQuote.fulfilled, (slice, { meta, payload }) => {
            const loadingIndex = slice.loading.indexOf(meta.arg.itemId);
            if (loadingIndex >= 0) {
                slice.loading = slice.loading.splice(loadingIndex, 1);
            }
            slice.loaded[meta.arg.itemId] = Date.now();
            slice.byId[meta.arg.itemId] = payload;
            slice.byId[meta.arg.itemId].country = meta.arg.countryCode;
            slice.byId[meta.arg.itemId].postalCode = meta.arg.postalCode;
            slice.result = 'success';
        });
        builder.addCase(fetchShippingQuote.rejected, (slice, { meta, payload }) => {
            const loadingIndex = slice.loading.indexOf(meta.arg.itemId);
            if (loadingIndex >= 0) {
                slice.loading = slice.loading.splice(loadingIndex, 1);
            }
            slice.error = payload as ShippingQuoteError;
            slice.result = 'failure';
        });
    },
    initialState: defaultShippingQuoteSlice,
    name: 'shippingQuote',
    reducers: {},
});

export const { reducer: shippingQuoteReducer } = shipingQuoteSlice;
