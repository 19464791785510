import {
    addBetaById,
    createGlobalBidLimitCriteria,
    deleteBetaById,
    deleteShippingSaintHouseId,
    fetchBetasByHouseId,
    getShippingSaintHouseId,
    postShippingSaintHouseId,
} from '@/redux/modules/houseBeta/houseBeta.api';
import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import {
    HouseBeta,
    HouseBetas,
    UpdateHouseBetaParams,
    UpdateShippingSaintHouseIdParams,
} from '@/redux/modules/houseBeta/houseBeta.types';
import { shouldFetchBetas } from '@/redux/modules/houseBeta/houseBeta.selectors';

/**
 * @deprecated Use `fetchHouseBetas.fulfilled` when converted to toolkit
 */
export const MODIFY_HOUSE_BETA_SUCCESS = 'MODIFY_HOUSE_BETA_SUCCESS';

export const fetchHouseBetas = createAsyncThunk<HouseBeta[], number>(
    'la/ui/houseBetas/fetchHouseBetas',
    async (houseId, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await fetchBetasByHouseId({
            authToken,
            deployment,
            houseId,
        });
        dispatch({ type: MODIFY_HOUSE_BETA_SUCCESS });
        return payload;
    }
);

export const fetchHouseBetasIfNeeded = createAsyncThunk<void, number>(
    'la/ui/houseBetas/fetchHouseBetasIfNeeded',
    async (houseId, { dispatch, getState }) => {
        if (!Boolean(houseId) || isNaN(houseId)) {
            return;
        }

        const state = getState();

        if (shouldFetchBetas(state, houseId)) {
            await dispatch(fetchHouseBetas(houseId));
        }
    }
);

export const updateHouseBeta = createAsyncThunk<HouseBeta, UpdateHouseBetaParams>(
    'la/ui/houseBetas/updateHouseBeta',
    async ({ beta, houseId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        if (beta.id === HouseBetas.GlobalMinimumBidLimit) {
            await createGlobalBidLimitCriteria({
                active: beta.enrolled,
                authToken,
                deployment,
                houseId,
            });
        }
        if (beta.enrolled) {
            await addBetaById({
                authToken,
                betaId: beta.id,
                deployment,
                houseId,
            });
        } else {
            await deleteBetaById({
                authToken,
                betaId: beta.id,
                deployment,
                houseId,
            });
        }
        return beta;
    }
);

export const fetchShippingSaintHouseId = createAsyncThunk<string, number>(
    'la/ui/houseBeta/fetchShippingSaintHouseId',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const response = await getShippingSaintHouseId({
            authToken,
            deployment,
            houseId,
        });
        return response.payload.shippingSaintsHouseId;
    }
);

export const fetchShippingSaintHouseIdIfNeeded = createAsyncThunk<void, number>(
    'la/ui/houseBetas/fetchShippingSaintsIfNeeded',
    async (houseId, { dispatch }) => {
        if (!Boolean(houseId) || isNaN(houseId)) {
            return;
        }

        await dispatch(fetchShippingSaintHouseId(houseId));
    }
);

export const updateShippingSaintHouseId = createAsyncThunk<
    void,
    UpdateShippingSaintHouseIdParams,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/houseBeta/updateShippingSaintHouseId',
    async ({ houseId, shippingSaintHouseId }, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        try {
            await postShippingSaintHouseId({
                authToken,
                deployment,
                houseId,
                shippingSaintHouseId,
            });
        } catch (error) {
            return rejectWithValue(new Error(error));
        }
    }
);

export const removeShippingSaintHouseId = createAsyncThunk<void, number>(
    'la/ui/houseBeta/deleteShippingSaintHouseId',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        await deleteShippingSaintHouseId({
            authToken,
            deployment,
            houseId,
        });
    }
);
