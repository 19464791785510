import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import {
    getShippingIncentivesSettings,
    postShippingIncentivesSettings,
} from '@/redux/modules/shippingIncentives/shippingIncentives.api';
import { getShouldFetchShippingIncentiveSettings } from '@/redux/modules/shippingIncentives/shippingIncentives.selectors';
import { ShippingMethodIncentivesSettings } from '@/redux/modules/shippingIncentives/shippingIncentives.types';

export const fetchShippingIncentivesSettings = createAsyncThunk<ShippingMethodIncentivesSettings, number>(
    'la/ui/shippingIncentives/fetchShippingIncentivesSettings',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await getShippingIncentivesSettings({
            authToken,
            deployment,
            houseId,
        });
        return payload;
    }
);

export const fetchShippingIncentivesSettingsIfNeeded = createAsyncThunk<void, number>(
    'la/ui/shippingIncentives/fetchShippingIncentivesSettingsIfNeeded',
    async (houseId, { dispatch, getState }) => {
        const state = getState();
        const shouldFetch = getShouldFetchShippingIncentiveSettings(state, houseId);
        if (shouldFetch) {
            await dispatch(fetchShippingIncentivesSettings(houseId));
        }
    }
);

type SaveParams = {
    body: ShippingMethodIncentivesSettings;
    houseId: number;
};

export const saveShippingIncentivesSettings = createAsyncThunk<void, SaveParams>(
    'la/ui/shippingIncentives/saveShippingIncentivesSettings',
    async ({ body, houseId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        await postShippingIncentivesSettings({
            authToken,
            body,
            deployment,
            houseId,
        });
    }
);
