import { fetchHouseHouseStylesIfNeeded } from '@/redux/modules/whitelabel';
import { getWhiteLabelUrlWithHouseId } from '@/utils/urls';
import { useAppDispatch } from '@/redux/hooks';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import HouseFinder from '../../components/HouseFinder/HouseFinder';

type Props = {
    setShowHouseStyles: Function;
};
const WhiteLabelHouseFinder = ({ setShowHouseStyles }: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const submitHouseId = useCallback(
        (houseId) => {
            history.push({
                pathname: getWhiteLabelUrlWithHouseId(houseId),
            });
            dispatch(fetchHouseHouseStylesIfNeeded(houseId));
            setShowHouseStyles(true);
        },
        [dispatch, history, setShowHouseStyles]
    );

    return <HouseFinder submitHouseId={submitHouseId} />;
};

export default WhiteLabelHouseFinder;
