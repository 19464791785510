import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/sellerInvoice';

/* Action Types */
export const SEND_SELLER_INVOICE_FAIL = 'SEND_SELLER_INVOICE_FAIL';
export const SEND_SELLER_INVOICE_REQUEST = 'SEND_SELLER_INVOICE_REQUEST';
export const SEND_SELLER_INVOICE_SUCCESS = 'SEND_SELLER_INVOICE_SUCCESS';

/* reducer */
const DEFAULT_STATE = {
    sendError: null,
    sendStatus: null,
    sendSubmitted: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SEND_SELLER_INVOICE_FAIL:
            return {
                ...state,
                sendError: action.payload,
                sendSubmitted: false,
            };
        case SEND_SELLER_INVOICE_REQUEST:
            return {
                ...state,
                sendError: null,
                sendStatus: null,
                sendSubmitted: true,
            };
        case SEND_SELLER_INVOICE_SUCCESS:
            return {
                ...state,
                sendError: null,
                sendStatus: action.payload,
                sendSubmitted: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.sellerInvoice;

export const getSellerInvoicesSendError = createSelector(stateSelector, (state) => state.sendError);
export const getSellerInvoicesSendStatus = createSelector(stateSelector, (state) => state.sendStatus);
export const getSellerInvoicesSendSubmitted = createSelector(stateSelector, (state) => state.sendSubmitted);

/* ACTION CREATORS */
export const sumbitSendSellerInvoices =
    (limit: number = 0) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: { limit },
                type: SEND_SELLER_INVOICE_REQUEST,
            });

            const response = await api.postSendSellerInvoices({ authToken, deployment, limit });

            dispatch({
                meta: { actionTime: Date.now(), limit },
                payload: response.payload,
                type: SEND_SELLER_INVOICE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { limit },
                payload: error.message,
                type: SEND_SELLER_INVOICE_FAIL,
            });
        }
    };
