import { ActionWithPayload } from '../../types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getBidderRefundsAdmin } from '../api/refund';
import { getDeployment } from './config';
import {
    LOAD_BIDDER_REFUNDS_ADMIN_FAIL,
    LOAD_BIDDER_REFUNDS_ADMIN_REQUEST,
    LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS,
    LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS_ACTION,
    REFUNDS_FILTERS_CHANGE,
    REFUNDS_FILTERS_CHANGE_FAIL,
} from './actions';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import { Refund } from '../../types/Payment';
import moment from 'moment';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

type State = {
    paymentRefundLoading: boolean;
    refunds: Refund[];
    refundsFilters: PaginationFilter;
    refundsLoaded?: Date;
    refundsLoading: boolean;
};

const DEFAULT_STATE: State = {
    paymentRefundLoading: false,
    refunds: [],
    refundsFilters: {
        from: moment().subtract(90, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: moment().toDate(),
        totalRecords: 0,
    },
    refundsLoaded: undefined,
    refundsLoading: false,
};

/* reducer */
export default handleActions(
    {
        [combineActions(REFUNDS_FILTERS_CHANGE_FAIL, LOAD_BIDDER_REFUNDS_ADMIN_FAIL)]: (
            state: State,
            action: ActionWithPayload<any>
        ) => ({
            ...state,
            error: action.payload,
            refundsLoading: false,
        }),
        [LOAD_BIDDER_REFUNDS_ADMIN_REQUEST]: (state: State) => ({
            ...state,
            refundsLoading: true,
        }),
        [LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS]: (state: State, action: LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS_ACTION) => ({
            ...state,
            error: undefined,
            refunds: action.payload.data,
            refundsFilters: {
                ...state.refundsFilters,
                totalRecords: action.payload.totalRecords,
            },
            refundsLoaded: new Date(),
            refundsLoading: false,
        }),
        [REFUNDS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                refundsFilters: {
                    ...state.refundsFilters,
                    ...action.payload,
                },
                refundsLoaded: undefined,
            };
            return newState;
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.refund;

export const refundsFiltersSelector = createSelector(stateSelector, (state) => state.refundsFilters);

export const refundsSelector = createSelector(stateSelector, (state) => state.refunds);

export const refundsLoadedSelector = createSelector(stateSelector, (state) => state.refundsLoaded);

export const refundsLoadingSelector = createSelector(stateSelector, (state) => state.refundsLoading);

export const shouldFetchRefunds = createSelector(
    refundsLoadedSelector,
    refundsLoadingSelector,
    (refundsLoaded, refundsLoading) => {
        if (Boolean(refundsLoading)) {
            return false;
        }
        const time = Date.now();
        const diff = time - refundsLoaded;
        return !refundsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

/* ACTION CREATORS */
export const onRefundsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: REFUNDS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: REFUNDS_FILTERS_CHANGE_FAIL,
        });
    }
};

export const fetchAdminBidderRefundsIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchRefunds(state)) {
            try {
                dispatch({
                    type: LOAD_BIDDER_REFUNDS_ADMIN_REQUEST,
                });

                const state = getState();
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                const response = await getBidderRefundsAdmin({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });
                return dispatch({
                    payload: {
                        data: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_BIDDER_REFUNDS_ADMIN_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    meta: { error },
                    payload: 'Error loading refunds',
                    type: LOAD_BIDDER_REFUNDS_ADMIN_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };
