import ms from 'ms';

export const SHIPPING_INCENTIVES_SLICE_CACHE_TIME = ms('30m');

export type ShippingMethodIncentivesSettings = {
    maxPercent: number;
    maxPrice: number;
    minDistance: number;
    shouldDefaultToLiveShipping: boolean;
};

export type ShippingIncentivesSlice = {
    byId: {
        [houseId: number]: ShippingMethodIncentivesSettings;
    };
    loaded: {
        [houseId: number]: number;
    };
    loading: number[];
    result: 'success' | 'failure';
};

export const defaultShippingIncentivesSlice: ShippingIncentivesSlice = {
    byId: {},
    loaded: {},
    loading: [],
    result: undefined,
};
