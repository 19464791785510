import { createSelector } from 'reselect';

/* reducer */
const DEFAULT_STATE = {};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        default:
            return state;
    }
}

/* SELECTORS */
const configSelector = (state) => state.config;

export const getBuildNumber = createSelector(configSelector, (config) => config.buildNumber);

export const getDeployment = createSelector(configSelector, (config) => config.deployment);
