import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { loadBidderPaymentStatusData } from '../api/caseStatusData';

/* Action Types */
export const FETCH_CASE_STATUS_START = 'FETCH_CASE_STATUS_START';
export const FETCH_CASE_STATUS_SUCCESS = 'FETCH_CASE_STATUS_SUCCESS';
export const FETCH_CASE_STATUS_ERROR = 'FETCH_CASE_STATUS_ERROR';

/* reducer */
const DEFAULT_STATE = {
    byId: {},
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case FETCH_CASE_STATUS_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CASE_STATUS_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.bidderId]: action.payload,
                },
                loading: false,
            };
        case FETCH_CASE_STATUS_ERROR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.bidderId]: [],
                },
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.bidderTransCaseStatus;
const idSelector = (state, id) => id;
const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getTransactionCaseStatuses = createSelector([byIdSelector, idSelector], (byId, id) => {
    if (byId === undefined) {
        return [];
    }
    return byId[id] === undefined ? [] : byId[id];
});

export const getTransactionCaseStatusError = createSelector(stateSelector, (state) => state.error);

export const getTransactionCaseStatusLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
type FetchCaseStatusParams = {
    bidderId: string;
    transactionIds: string[];
};
export const fetchBidderTransactionCaseStatus =
    ({ bidderId, transactionIds }: FetchCaseStatusParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                meta: { bidderId },
                type: FETCH_CASE_STATUS_START,
            });

            const response = await loadBidderPaymentStatusData({
                authToken,
                deployment,
                transactionIds,
            });

            dispatch({
                meta: { bidderId },
                payload: response.payload,
                type: FETCH_CASE_STATUS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { bidderId },
                payload: error,
                type: FETCH_CASE_STATUS_ERROR,
            });
        }
    };
