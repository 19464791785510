import { BlockBidderIconSmall } from '@liveauctioneers/caterwaul-components/lib/Icons';
import { BodyPrimary, H3, H4 } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { deleteComment, deleteReview, updateReview } from '@/redux/modules/reviews';
import { useAppDispatch } from '@/redux/hooks';
import { useState } from 'react';
import Input from '@liveauctioneers/caterwaul-components/lib/Input/Input';
import StarPicker from '@liveauctioneers/caterwaul-components/lib/StarRating/StarPicker';
import styled from 'styled-components';

const Review = ({ bidderId, data, houseId }) => {
    const dispatch = useAppDispatch();

    // static data
    const bidderName = data.bidderFirstName + ' ' + data.bidderLastName;

    // review text
    const [headline, setHeadline] = useState(data.reviewHeadline);
    const [review, setReview] = useState(data.review);
    const [comments, setComments] = useState(data.comments);
    const [note, setNotes] = useState(data.note);

    // review scores
    const [overall, setOverall] = useState(data.overall);
    const [accuracy, setAccuracy] = useState(data.accuracy);
    const [payment, setPayment] = useState(data.payment);
    const [shipping, setShipping] = useState(data.shipping);
    const [responsiveness, setResponsiveness] = useState(data.responsiveness);

    const [isChanged, setIsChanged] = useState(false);

    const commentItems = comments.map((item) => {
        const handleRemoveComment = () => {
            setIsChanged(true);
            setComments((prevState) => {
                return prevState.filter((prevValue) => {
                    return prevValue.commentId !== item.commentId;
                });
            });
            dispatch(deleteComment({ bidderId, commentId: item.commentId, houseId }));
        };

        return (
            <StyledComment key={item.commentId}>
                <H4>Comment by: {item.userDisplayName}</H4>
                <Input
                    aria-label="Comment"
                    hideSpacer
                    onChange={(e) => {
                        item.comment = e.target.value;
                        setComments((prevState) => {
                            return prevState.map((prevValue) => {
                                if (item.commentId === prevValue.commentId) {
                                    prevValue.comment = e.target.value;
                                }
                                return prevValue;
                            });
                        });
                        setIsChanged(true);
                    }}
                    type="textarea"
                    value={item.comment}
                />
                <StyledCommentButton onClick={handleRemoveComment} small variant="primaryAlt">
                    Remove Comment
                </StyledCommentButton>
            </StyledComment>
        );
    });

    /* eslint-disable sort-keys/sort-keys-fix */
    const scores = {
        overall: [overall, setOverall],
        accuracy: [accuracy, setAccuracy],
        payment: [payment, setPayment],
        shipping: [shipping, setShipping],
        responsiveness: [responsiveness, setResponsiveness],
    };
    /* eslint-enable sort-keys/sort-keys-fix */
    const StarPickers = Object.keys(scores).map((scoreKey) => {
        const [score, setScore] = scores[scoreKey];
        const scoreLabel = scoreKey.charAt(0).toUpperCase() + scoreKey.substring(1);
        const isResponsiveness = scoreKey === 'responsiveness';

        return (
            <StyledStarPickerWrapper key={data.reviewId + scoreKey}>
                <BodyPrimary>{scoreLabel}: </BodyPrimary>
                <StyledScoreLabel>
                    {isResponsiveness && (
                        <StyledBlockBidderIconSmall
                            onClick={() => {
                                setScore(-1);
                                setIsChanged(true);
                            }}
                            selected={score === -1}
                        />
                    )}
                    <StarPicker
                        initialRating={score === -1 ? 0 : score}
                        onClick={(stars) => {
                            setScore(stars);
                            setIsChanged(true);
                        }}
                        stars={5}
                    />
                </StyledScoreLabel>
            </StyledStarPickerWrapper>
        );
    });

    const handleUpdateReview = () => {
        const reviewItem = {
            ...data,
            accuracy: accuracy,
            comments: comments,
            note: note,
            overall: overall,
            payment: payment,
            responsiveness: responsiveness,
            review: review,
            reviewHeadline: headline,
            shipping: shipping,
        };
        dispatch(
            updateReview({
                bidderId,
                houseId,
                review: reviewItem,
            })
        );
    };

    const handleDeleteReview = () => {
        dispatch(
            deleteReview({
                bidderId,
                houseId,
                reviewId: data.reviewId,
            })
        );
    };

    return (
        <ReviewContainer>
            <StaticReviewData>
                <div>
                    <H3>{bidderName}</H3>
                    <br />
                    <BodyPrimary>review id: {data.reviewId}</BodyPrimary>
                    <br />
                    <BodyPrimary>seller id: {data.sellerId}</BodyPrimary>
                    <br />
                    <BodyPrimary>bidder id: {data.bidderId}</BodyPrimary>
                </div>
                <MutateReviewOptions>
                    <div>
                        <StyledButton disabled={!isChanged} onClick={handleUpdateReview} small>
                            Update
                        </StyledButton>
                    </div>
                    <StyledButton onClick={handleDeleteReview} small variant="primaryAlt">
                        Delete
                    </StyledButton>
                </MutateReviewOptions>
            </StaticReviewData>

            <MutableReviewText>
                <BodyPrimary>
                    Headline:
                    <Input
                        aria-label="headline"
                        onChange={(e) => {
                            setIsChanged(true);
                            setHeadline(e.target.value);
                        }}
                        value={headline}
                    />
                </BodyPrimary>
                <div>
                    <BodyPrimary>
                        Review:
                        <Input
                            aria-label="review"
                            onChange={(e) => {
                                setIsChanged(true);
                                setReview(e.target.value);
                            }}
                            value={review}
                        />
                    </BodyPrimary>
                </div>

                {note && (
                    <div>
                        <BodyPrimary>Private Feedback:</BodyPrimary>
                        <Input
                            aria-label="private feedback"
                            onChange={(e) => {
                                setIsChanged(true);
                                setNotes(e.target.value);
                            }}
                            value={note}
                        />
                    </div>
                )}

                <BodyPrimary>Comments:</BodyPrimary>
                <div>{commentItems}</div>
            </MutableReviewText>

            <MutableReviewScores>{StarPickers}</MutableReviewScores>
        </ReviewContainer>
    );
};

export default Review;

const ReviewContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    padding-bottom: 45px;
    margin-bottom: 45px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StaticReviewData = styled.div`
    flex-shrink: 1;
`;

const MutableReviewText = styled.div`
    flex-grow: 1;
    padding: 0 30px;
`;
const MutableReviewScores = styled.div`
    flex-shrink: 1;
    padding: 0 30px;
`;

const MutateReviewOptions = styled.div`
    margin-top: 30px;
`;

const StyledStarPickerWrapper = styled.div`
    margin-bottom: 15px;
`;

const StyledScoreLabel = styled.div`
    position: relative;
`;

const StyledBlockBidderIconSmall = styled(BlockBidderIconSmall)`
    position: absolute;
    top: 2px;
    left: -24px;
`;

const StyledButton = styled(Button)`
    margin-bottom: 15px;
`;

const StyledComment = styled.div`
    margin-top: 30px;
`;

const StyledCommentButton = styled(Button)`
    margin-top: 15px;
`;
