import { handleResponse, makeGet, makePost, makePut } from './helpers';

type StaticParams = {
    authToken: string;
    deployment: string;
};

export type FetchCategoryByIdParams = {
    categoryId: number;
    facetId?: number;
};

export type AddCategoryProps = {
    description: string;
    facetId: number;
    label: string;
    metaDescription: string;
    name: string;
    pageTitle: string;
    parentCategoryId: number;
};

export type PutEditCategoryParams = {
    categoryId: number;
    description: string;
    exclusionKeywords: string;
    label: string;
    metaDescription: string;
    name: string;
    pageTitle: string;
    parentCategoryId: number;
    synonyms: string;
};

export default {
    fetchCategoryById: ({ authToken, categoryId, deployment, facetId }: FetchCategoryByIdParams & StaticParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({ apiPath: 'category', authToken, deployment, path: '<CATEGORY-API>' });
            request.query({ categoryId, facetId });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    fetchParentCategories: ({ authToken, categoryId, deployment }: FetchCategoryByIdParams & StaticParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({ apiPath: 'parents', authToken, deployment, path: '<CATEGORY-API>' });
            request.query({ categoryId, facetId: 1 });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postAddCategory: ({
        authToken,
        deployment,
        description,
        facetId,
        label,
        metaDescription,
        name,
        pageTitle,
        parentCategoryId = 0,
    }: AddCategoryProps & StaticParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                apiPath: 'category',
                authToken,
                deployment,
                path: '<CATEGORY-API>',
            });
            request.send({
                description,
                facetId,
                label,
                metaDescription,
                name,
                pageTitle,
                parentCategoryId,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postMergeCategory: ({
        authToken,
        deployment,
        DstCategoryID,
        preventRecategorization,
        SrcCategoryID,
    }: {
        DstCategoryID: number;
        SrcCategoryID: number;
        preventRecategorization: boolean;
    } & StaticParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                apiPath: 'mergecategory',
                authToken,
                deployment,
                path: '<CATEGORY-API>',
            });
            request.send({
                DstCategoryID,
                preventRecategorization,
                SrcCategoryID,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postUpdateDewey: ({ authToken, deployment }: StaticParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                apiPath: 'index/update/all',
                authToken,
                deployment,
                path: '<DEWEY>',
            });
            request.send({
                available: true,
                ended: false,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    putEditCategory: ({
        authToken,
        categoryId,
        deployment,
        description,
        exclusionKeywords,
        label,
        metaDescription,
        name,
        pageTitle,
        parentCategoryId = 0,
        synonyms,
    }: PutEditCategoryParams & StaticParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePut({
                apiPath: 'category',
                authToken,
                deployment,
                path: '<CATEGORY-API>',
            });
            request.send({
                categoryId,
                description,
                exclusionKeywords,
                label,
                metaDescription,
                name,
                pageTitle,
                parentCategoryId,
                synonyms,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
