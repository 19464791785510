import { BodyPrimary } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { dismissModal, openErrorModal } from '@/redux/modules/modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { getSellerIncentiveError } from '@/redux/modules/sellerIncentive/sellerIncentive.selectors';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '@liveauctioneers/caterwaul-components/lib/SimpleModal/SimpleModalHeader';
import React, { useCallback } from 'react';
import SimpleModal from '@liveauctioneers/caterwaul-components/lib/SimpleModal/SimpleModal';
import styled from 'styled-components';

const SellerIncentiveModal = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const error = useSelector(getSellerIncentiveError);

    const closeModal = useCallback(() => {
        dispatch(dismissModal());
    }, [dispatch]);

    const afterClose = useCallback(() => {
        if (error) {
            dispatch(openErrorModal());
        }
    }, [dispatch, error]);

    return (
        <SimpleModal
            body={
                <StyledContainer>
                    <StyledBodyText>
                        <FormattedMessage id="sellerIncentive.modal.saveBody" />
                    </StyledBodyText>
                    <ButtonContainer>
                        <StyledButton aria-label="" onClick={closeModal}>
                            <FormattedMessage id="close" />
                        </StyledButton>
                    </ButtonContainer>
                </StyledContainer>
            }
            header={
                <ModalHeader onClose={closeModal} title={formatMessage({ id: 'sellerIncentive.modal.saveTitle' })} />
            }
            onAfterClose={afterClose}
            show
        />
    );
};

export default SellerIncentiveModal;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StyledBodyText = styled(BodyPrimary)`
    display: block;
    text-align: center;
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    flex-direction: column;
`;

const StyledButton = styled(Button)`
    width: 286px;
`;
