import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuthToken } from '../user';
import { getDeployment } from '../config';
import { getTrainingData } from './training.api';
import { TrainingInfo } from '@/pages/Categorization/CategoryTrainingDashboard';
import { TrainingState } from './training.types';

const defaultState: TrainingState = {
    error: undefined,
    existingTrainingData: [],
};

export const loadTrainingData = createAsyncThunk(
    'la/domain/training/loadTrainingData',
    async (facetId: number, { getState }): Promise<TrainingInfo[]> => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const { payload: apiTrainingItems } = await getTrainingData({
            authToken,
            deployment,
            facetId,
        });
        return apiTrainingItems.map((apiItem) => ({
            accuracy: apiItem.accuracy,
            id: apiItem.categoryId,
            lastModified: apiItem.categoryLastModified,
            name: {
                category: apiItem.name.category,
                taxonomy: apiItem.name.taxonomy,
            },
            trainingLotCount: apiItem.trainingLotCount,
        }));
    }
);

const trainingSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(loadTrainingData.fulfilled, (state, { payload }) => ({
                ...state,
                error: undefined,
                existingTrainingData: payload,
            }))
            .addCase(loadTrainingData.pending, (state) => ({
                ...state,
                error: undefined,
            }))
            .addCase(loadTrainingData.rejected, (state, action) => ({
                ...state,
                error: action.error.message,
                existingTrainingData: [],
            }));
    },
    initialState: defaultState,
    name: 'training',
    reducers: {},
});

export const { reducer: trainingReducer } = trainingSlice;
