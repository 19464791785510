export type BidderAddress = {
    accountPurpose?: string;
    address1: string;
    address2: string;
    address3?: string;
    addressId: number;
    avalaraVerified?: boolean;
    bidderId: number;
    city: string;
    countryCode: string;
    enabled?: boolean;
    firstName: string;
    isDefault?: boolean;
    lastName: string;
    organizationName: string;
    partialAddress?: string;
    phoneNumber: string;
    postalCode: string;
    shippingOptionType?: string;
    singleLineAddress?: string;
    state: string;
    suggestion?: string;
    updatedDate?: string;
    validatorID?: number;
    validatorProvider?: AddressValidatorProvider;
    verifiedStatus?: number;
    zip?: string;
};

export enum AddressValidatorProvider {
    Suggested = 'suggested',
    User = 'user',
}

export enum AcceptedTermsOfService {
    accepted = 'accepted',
    unknown = 'unknown',
    withdrawn = 'withdrawn',
}

export type BidderProfile = {
    acceptedTermsOfService: AcceptedTermsOfService;
    address: string;
    address2: string;
    addressId: number;
    addresses: BidderAddress[];
    approvalId: number;
    bidderId: number;
    city: string;
    country: string;
    countryCode: string;
    createdDate: string;
    creditCardValidated: boolean;
    email: string;
    firstName: string;
    hasEverRegisteredForAuction: boolean;
    lastName: string;
    latitude: string | null;
    levelOneScore: number;
    longitude: string | null;
    mobileCarrier: number;
    mobileNumber: string;
    mobileVerified: boolean;
    phoneNumber: string;
    phoneNumber2: string;
    premiumBidder: boolean;
    profileComplete: boolean;
    resellerId: string;
    signupClient: string;
    signupClientVersion: string;
    standing: string;
    state: string;
    username: string;
    zip: string;
};

export type BidderDetails = {
    ANMS: number;
    preferredCurrency: string;
    // premiumBidder is not used, used to be for ACH, but now it is unused - blake 2021-06-28
    upcomingBids?: number;
} & BidderProfile;

export const EmptyBidderDetails: BidderDetails = {
    acceptedTermsOfService: AcceptedTermsOfService.unknown,
    address: '',
    address2: '',
    addresses: [],
    addressId: 0,
    ANMS: 0,
    approvalId: 0,
    bidderId: -1,
    city: '',
    country: '',
    countryCode: '',
    createdDate: '',
    creditCardValidated: false,
    email: '',
    firstName: '',
    hasEverRegisteredForAuction: false,
    lastName: '',
    latitude: null,
    levelOneScore: 0,
    longitude: null,
    mobileCarrier: 0,
    mobileNumber: '',
    mobileVerified: false,
    phoneNumber: '',
    phoneNumber2: '',
    preferredCurrency: 'USD',
    premiumBidder: false,
    profileComplete: false,
    resellerId: '',
    signupClient: '',
    signupClientVersion: '',
    standing: '',
    state: '',
    upcomingBids: 0,
    username: '',
    zip: '',
};

export type BidderDetailsSlice = {
    bidderDetails: BidderDetails;
    error: boolean;
    errorMessage: string | null | undefined;
    loading: boolean;
    submitted: boolean;
    success: boolean;
};

export const emptyBidderDetailsSlice = {
    bidderDetails: EmptyBidderDetails,
    error: false,
    errorMessage: '',
    loading: false,
    submitted: false,
    success: false,
};

export const defaultBidderDetailsSlice: BidderDetailsSlice = {
    bidderDetails: EmptyBidderDetails,
    error: false,
    errorMessage: null,
    loading: false,
    submitted: false,
    success: false,
};

export type FetchBidderProfileResponse = {
    error: boolean;
    payload: BidderDetails;
};

export type FetchBidderProfileParams = {
    bidderId: number;
};

export type GetBidderProfileParams = {
    authToken: string;
    deployment: string;
    params: {
        bidderId: number;
    };
};

export type UpdateBidderProfileRequest = {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    phoneNumber?: string;
    zipcode?: string;
};

export type UpdateProfileWithAddressRequest = {
    address: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    organizationName: string;
    phoneNumber?: string;
    state: string;
    suggested: boolean;
    userVerified: boolean;
    zipcode: string;
};

export type UpdateBidderProfileResponse = {
    error: boolean;
    payload: UpdateProfileWithAddressRequest & {
        suggestedAddress: {
            address1: string;
            address2: string;
            city: string;
            country: string;
            countryCode: string;
            email: string;
            firstName: string;
            lastName: string;
            mobile: string;
            phoneNumber?: string;
            state: string;
            zipcode: string;
        };
    };
};

export type PostUpdateBidderProfileParams = {
    bidderId: number;
    profile: UpdateBidderProfileRequest;
};
