import { BodyPrimary, BodySecondary, H1, H3 } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { fetchReviews } from '@/redux/modules/reviews';
import { useAppDispatch } from '@/redux/hooks';
import Input from '@liveauctioneers/caterwaul-components/lib/Input/Input';
import React, { ChangeEvent, useEffect, useState } from 'react';
import ReviewList from './ReviewList';
import styled from 'styled-components';
import TextCollapse from '@liveauctioneers/caterwaul-components/lib/TextCollapse/TextCollapse';

const ReviewsPage = () => {
    const dispatch = useAppDispatch();

    const [valid, setValid] = useState(false);
    const [validBidderId, setValidBidderId] = useState(true);
    const [validHouseId, setValidHouseId] = useState(true);

    // search params
    const [bidderId, setBidderId] = useState<number | undefined>(undefined);
    const [houseId, setHouseId] = useState<number | undefined>(undefined);

    // simple input validation
    useEffect(() => {
        if (validBidderId && validHouseId) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [validBidderId, validHouseId]);

    const handleBidderIdChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(value))) {
            setValidBidderId(false);
        } else {
            setBidderId(Number(value));
            setValidBidderId(true);
        }
    };

    const handleHouseIdChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(value))) {
            setValidHouseId(false);
        } else {
            setHouseId(Number(value));
            setValidHouseId(true);
        }
    };

    // get da reviews!
    const handleReviewFetch = () => {
        dispatch(
            fetchReviews({
                bidderId,
                houseId,
                pageSize: 50,
            })
        );
    };

    return (
        <StyledPageContainer>
            <ReviewPageHeader>
                <H1>Reviews Page</H1>
                <StyledBodyPrimary>Please provide a house id and bidder id</StyledBodyPrimary>
            </ReviewPageHeader>

            <StyledWrapper>
                <Input
                    aria-label="Bidder Id"
                    errorMessage={!validBidderId && 'Must enter an all numbers Bidder ID'}
                    label="Bidder ID"
                    onChange={handleBidderIdChange}
                    valid={validBidderId}
                    // value={bidderId}
                />
                <Input
                    aria-label="House Id"
                    errorMessage={!validHouseId && 'Must enter an all numbers House ID'}
                    label="House ID"
                    onChange={handleHouseIdChange}
                    valid={validHouseId}
                    // value={houseId}
                />
                <Button disabled={!valid} onClick={handleReviewFetch}>
                    Submit
                </Button>
                {/* <Button onClick={onClick}>Submit</Button> */}
            </StyledWrapper>

            <ReviewPageUsage>
                <TextCollapse
                    className=""
                    collapseText="Show Less"
                    content={
                        <BodySecondary>
                            <H3>How to use this page.</H3> <br />
                            <b>1) Red buttons are immediate and undoable!</b>
                            <br />
                            2) Will show up to 50 reviews, if you don't see the review you are looking for you might
                            have to use both House ID and Bidder ID.
                            <br />
                            3) To edit the review or comment, change the text in the input field or the star rating and
                            then click the UPDATE button.
                            <br />
                            4) Removing a comment only requires you to click the 'REMOVE COMMENT' button and does not
                            require you to click update.
                            <br />
                            5) Known bug: Clicking the 'BlockBidder' icon (cicular icon with a slash through it) will
                            set the responsiveness score to "n/a" (-1), the icon will turn red, but the star rating will
                            still be present. You can click update and the rating will be updated. You can refresh the
                            page to confirm the change has taken affect.
                        </BodySecondary>
                    }
                    expandText="Show More"
                    minHeightPx={40}
                    testid="test"
                />
            </ReviewPageUsage>

            <ReviewList bidderId={bidderId} houseId={houseId} />
        </StyledPageContainer>
    );
};

export default ReviewsPage;

const StyledPageContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px 0;
`;

const StyledBodyPrimary = styled(BodyPrimary)`
    margin-bottom: 20px;
`;

const StyledWrapper = styled.div`
    max-width: 500px;
    margin-bottom: 30px;
`;

const ReviewPageHeader = styled.div`
    margin-bottom: 30px;
`;

const ReviewPageUsage = styled.div`
    margin-bottom: 30px;
`;
