/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
    DELETE_SUGGESTED_CATEGORY_SUCCESS,
    FETCH_SUGGESTED_CATEGORIES_FAIL,
    FETCH_SUGGESTED_CATEGORIES_START,
    FETCH_SUGGESTED_CATEGORIES_SUCCESS,
    FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL,
    FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START,
    FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS,
    SAVE_SUGGESTED_CATEGORY_FAIL,
    SAVE_SUGGESTED_CATEGORY_REQUEST,
    SAVE_SUGGESTED_CATEGORY_SUCCESS,
} from './actions';
import { dismissModal } from './modal';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import api from '../api/suggestedCategory';

/* reducer */
const DEFAULT_STATE = {
    fetchSuggestedCategoryError: '',
    fetchSuggestedCategoryLoading: false,
    saveSuggestedCategoryError: '',
    saveSuggestedCategoryLoading: false,
    saveSuggestedCategorysuccess: false,
    suggestedCategories: [],
    taxonomyChildren: [],
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case SAVE_SUGGESTED_CATEGORY_FAIL:
            return {
                ...state,
                saveSuggestedCategoryError: action.payload,
                saveSuggestedCategoryLoading: false,
                saveSuggestedCategorysuccess: false,
            };
        case SAVE_SUGGESTED_CATEGORY_REQUEST:
            return {
                ...state,
                saveSuggestedCategoryError: null,
                saveSuggestedCategoryLoading: true,
                saveSuggestedCategorysuccess: false,
                suggestedCategories: [...state.suggestedCategories, action.payload].sort((a, b) => {
                    return a.position - b.position;
                }),
            };
        case SAVE_SUGGESTED_CATEGORY_SUCCESS:
            return {
                ...state,
                saveSuggestedCategoryError: null,
                saveSuggestedCategoryLoading: false,
                saveSuggestedCategorysuccess: true,
                suggestedCategories: action.payload || [],
            };
        case FETCH_SUGGESTED_CATEGORIES_START:
            return {
                ...state,
                fetchSuggestedCategoryError: null,
                fetchSuggestedCategoryLoading: true,
                suggestedCategories: [],
            };
        case FETCH_SUGGESTED_CATEGORIES_FAIL:
            return {
                ...state,
                fetchSuggestedCategoryError: action.payload,
                fetchSuggestedCategoryLoading: false,
                suggestedCategories: [],
            };
        case FETCH_SUGGESTED_CATEGORIES_SUCCESS:
            return {
                ...state,
                fetchSuggestedCategoryLoading: false,
                suggestedCategories: action.payload || [],
            };
        case FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START:
            return {
                ...state,
                fetchTaxonomyChildrenError: null,
                fetchTaxonomyChildrenLoading: true,
                taxonomyChildren: [],
            };
        case FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL:
            return {
                ...state,
                fetchTaxonomyChildrenError: action.payload,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: [],
            };
        case FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS:
            return {
                ...state,
                fetchTaxonomyChildrenLoading: false,
                taxonomyChildren: action.payload || [],
            };
        case DELETE_SUGGESTED_CATEGORY_SUCCESS:
            return {
                ...state,
                fetchSuggestedCategoryLoading: false,
                suggestedCategories: state.suggestedCategories.length
                    ? state.suggestedCategories.filter((cat) => cat.suggestedCategoryId !== action.payload)
                    : [],
            };
        default: {
            return state;
        }
    }
}

/* SELECTORS */
const stateSelector = (state) => state.suggestedCategory;

export const suggestedCategoriesSelector = createSelector(stateSelector, (state) => state.suggestedCategories);
export const suggestedCategoriesTaxonomyChildrenOptionsSelector = createSelector(
    stateSelector,
    (state) => state.taxonomyChildren
);
export const suggestedCategoryErrorSelector = createSelector(
    stateSelector,
    (state) => state.saveSuggestedCategoryError
);
export const suggestedCategoryCaptureLoadingSelector = createSelector(
    stateSelector,
    (state) => state.saveSuggestedCategoryLoading
);

/* ACTION CREATORS */

export const getSuggestedCategories = (categoryId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: categoryId,
            type: FETCH_SUGGESTED_CATEGORIES_START,
        });

        const response = await api.getSuggestedCategoriesByCatID({
            authToken,
            categoryId,
            deployment,
        });

        const suggestedCatsInPositionOrder = response.payload.sort((a, b) => {
            return a.position - b.position;
        });

        dispatch({
            payload: suggestedCatsInPositionOrder,
            type: FETCH_SUGGESTED_CATEGORIES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_SUGGESTED_CATEGORIES_FAIL,
        });
    }
};

export const saveSuggestedCategory = (suggestedCategory) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: suggestedCategory,
            type: SAVE_SUGGESTED_CATEGORY_REQUEST,
        });

        let response;
        if (!suggestedCategory.suggestedCategoryId) {
            response = await api.postSuggestedCategory({
                authToken,
                deployment,
                suggestion: suggestedCategory,
            });
        } else {
            response = await api.updateSuggestedCategory({
                authToken,
                deployment,
                suggestion: suggestedCategory,
            });
        }

        const suggestedCatsInPositionOrder = response.payload.sort((a, b) => {
            return a.position - b.position;
        });

        dispatch({
            payload: suggestedCatsInPositionOrder,
            type: SAVE_SUGGESTED_CATEGORY_SUCCESS,
        });
        dispatch(dismissModal());
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_SUGGESTED_CATEGORY_FAIL,
        });
    }
};

export const deleteSuggestedCategory = (suggestedCategoryId) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        await api.deleteSuggestedCategory({
            authToken,
            deployment,
            idToDelete: suggestedCategoryId,
        });

        dispatch({
            payload: suggestedCategoryId,
            type: DELETE_SUGGESTED_CATEGORY_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SAVE_SUGGESTED_CATEGORY_FAIL,
        });
    }
};

export const getCategoriesTaxonomyChildren = (searchTerm: string) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: -1,
            type: FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_START,
        });

        const response = await api.getTaxonomyCategoryChildrenByString({
            authToken,
            deployment,
            searchTerm,
        });

        const taxonomyOptions = response.payload.map((option) => {
            return {
                label: `${option.categoryId} - ${option.categoryName}`,
                value: option.categoryId,
            };
        });

        dispatch({
            payload: taxonomyOptions,
            type: FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_SUCCESS,
        });
        return taxonomyOptions;
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_SUGGESTED_CATEGORIES_TAXONOMY_CHILDREN_FAIL,
        });
    }
};
