import { Disbursement, Payment } from '../../../types/Payment';
import { TypedActionWithPayload } from '../../../types/redux';

export const LOAD_BIDDER_PAYMENTS_ADMIN_FAIL = 'LOAD_BIDDER_PAYMENTS_ADMIN_FAIL';
export const LOAD_BIDDER_PAYMENTS_ADMIN_REQUEST = 'LOAD_BIDDER_PAYMENTS_ADMIN_REQUEST';
export const LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS = 'LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS';
export type LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_BIDDER_PAYMENTS_ADMIN_SUCCESS,
    { payments: Payment[]; totalRecords: number }
>;
export const LOAD_DISBURSEMENTS_ADMIN_FAIL = 'LOAD_DISBURSEMENTS_ADMIN_FAIL';
export const LOAD_DISBURSEMENTS_ADMIN_REQUEST = 'LOAD_DISBURSEMENTS_ADMIN_REQUEST';
export const LOAD_DISBURSEMENTS_ADMIN_SUCCESS = 'LOAD_DISBURSEMENTS_ADMIN_SUCCESS';
export type LOAD_DISBURSEMENTS_ADMIN_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_DISBURSEMENTS_ADMIN_SUCCESS,
    { disbursements: Disbursement[]; totalRecords: number }
>;

export const POST_PAYMENT_CANCEL_FAIL = 'POST_PAYMENT_CANCEL_FAIL';
export const POST_PAYMENT_CANCEL_REQUEST = 'POST_PAYMENT_CANCEL_REQUEST';
export const POST_PAYMENT_CANCEL_SUCCESS = 'POST_PAYMENT_CANCEL_SUCCESS';

export const POST_PAYMENT_CAPTURE_FAIL = 'POST_PAYMENT_CAPTURE_FAIL';
export const POST_PAYMENT_CAPTURE_REQUEST = 'POST_PAYMENT_CAPTURE_REQUEST';
export const POST_PAYMENT_CAPTURE_SUCCESS = 'POST_PAYMENT_CAPTURE_SUCCESS';

export const POST_PAYMENT_REFUND_FAIL = 'POST_PAYMENT_REFUND_FAIL';
export const POST_PAYMENT_REFUND_REQUEST = 'POST_PAYMENT_REFUND_REQUEST';
export const POST_PAYMENT_REFUND_SUCCESS = 'POST_PAYMENT_REFUND_SUCCESS';

export const POST_PAYMENT_SETUP_RESET_FAIL = 'POST_PAYMENT_SETUP_RESET_FAIL';
export const POST_PAYMENT_SETUP_RESET_REQUEST = 'POST_PAYMENT_SETUP_RESET_REQUEST';
export const POST_PAYMENT_SETUP_RESET_SUCCESS = 'POST_PAYMENT_SETUP_RESET_SUCCESS';

export const GET_DISBURSEMENT_AUDIT_FAIL = 'GET_DISBURSEMENT_AUDIT_FAIL';
export const GET_DISBURSEMENT_AUDIT_REQUEST = 'GET_DISBURSEMENT_AUDIT_REQUEST';
export const GET_DISBURSEMENT_AUDIT_SUCCESS = 'GET_DISBURSEMENT_AUDIT_SUCCESS';

export const SUBMIT_PAYSAFE_ONBOARD_FAIL = 'SUBMIT_PAYSAFE_ONBOARD_FAIL';
export const SUBMIT_PAYSAFE_ONBOARD_REQUEST = 'SUBMIT_PAYSAFE_ONBOARD_REQUEST';
export const SUBMIT_PAYSAFE_ONBOARD_SUCCESS = 'SUBMIT_PAYSAFE_ONBOARD_SUCCESS';

export const DISBURSEMENTS_FILTERS_CHANGE = 'DISBURSEMENTS_FILTERS_CHANGE';
export const PAYMENTS_FILTERS_CHANGE = 'PAYMENTS_FILTERS_CHANGE';
export const REPORTS_FILTERS_CHANGE = 'REPORTS_FILTERS_CHANGE';
export const FILTERS_CHANGE_FAIL = 'FILTERS_CHANGE_FAIL';
