import { ActionWithPayload } from '../../types/redux';
import {
    BILLING_FILTERS_CHANGE,
    BILLING_FILTERS_CHANGE_FAIL,
    LOAD_HOUSE_BILLING_HISTORY_FAIL,
    LOAD_HOUSE_BILLING_HISTORY_REQUEST,
    LOAD_HOUSE_BILLING_HISTORY_SUCCESS,
    POST_HOUSE_PAYMENT_REFUND_FAIL,
    POST_HOUSE_PAYMENT_REFUND_REQUEST,
    POST_HOUSE_PAYMENT_REFUND_SUCCESS,
} from './actions';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import { RefundForm } from '../../types/Payment';
import api from '../api/houseBilling';
import moment from 'moment';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

const DEFAULT_STATE = {
    billingFilters: {
        from: moment().subtract(90, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: moment().toDate(),
        totalRecords: 0,
    },
    billingHistory: [],
    error: undefined,
    loaded: undefined,
    loading: false,
    refundError: '',
    refundLoading: false,
    refundSuccess: false,
};

type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [combineActions(BILLING_FILTERS_CHANGE_FAIL, LOAD_HOUSE_BILLING_HISTORY_FAIL)]: (
            state: State,
            action: ActionWithPayload<{ error: string }>
        ) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [BILLING_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>) => {
            let newState = {
                ...state,
                billingFilters: {
                    ...state.billingFilters,
                    ...action.payload,
                },
                loaded: undefined,
            };
            return newState;
        },
        [LOAD_HOUSE_BILLING_HISTORY_REQUEST]: (state: State) => ({
            ...state,
            loading: true,
        }),
        [LOAD_HOUSE_BILLING_HISTORY_SUCCESS]: (state: State, action: ActionWithPayload<any>) => ({
            ...state,
            billingFilters: {
                ...state.billingFilters,
                totalRecords: action.payload.totalRecords,
            },
            billingHistory: [...action.payload?.billingHistory],
            loaded: new Date(),
            loading: false,
        }),
        [POST_HOUSE_PAYMENT_REFUND_FAIL]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            refundError: action.payload,
            refundLoading: false,
        }),
        [POST_HOUSE_PAYMENT_REFUND_REQUEST]: (state: State) => ({
            ...state,
            refundLoading: true,
        }),
        [POST_HOUSE_PAYMENT_REFUND_SUCCESS]: (state: State) => ({
            ...state,
            loaded: undefined,
            refundError: undefined,
            refundLoading: false,
            refundSuccess: true,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state) => state.houseBilling;

export const getHouseBillingHistory = createSelector(stateSelector, (state) => state.billingHistory);

export const getHouseBillingHistoryLoading = createSelector(stateSelector, (state) => state.loading);

export const getHouseBillingHistoryLoaded = createSelector(stateSelector, (state) => state.loaded);

export const getHouseRefundLoading = createSelector(stateSelector, (state) => state.refundLoading);

export const getHouseRefundError = createSelector(stateSelector, (state) => state.refundError);

export const getHouseRefundSuccess = createSelector(stateSelector, (state) => state.refundSuccess);

export const getHouseBillingFilters = createSelector(stateSelector, (state) => state.billingFilters);

export const shouldFetchBillingItems = createSelector(
    getHouseBillingHistoryLoaded,
    getHouseBillingHistoryLoading,
    (paymentsLoaded, paymentsLoading) => {
        if (paymentsLoading) {
            return false;
        }
        const time = Date.now();
        const diff = time - paymentsLoaded;
        return !paymentsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const fetchHouseBillingHistoryIfNeeded =
    (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchBillingItems(state)) {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                dispatch({ type: LOAD_HOUSE_BILLING_HISTORY_REQUEST });

                const response = await api.getHouseBillingHistory({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    keyword: filters.keyword || '',
                    page: filters.page,
                    pageSize: filters.pageSize,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                dispatch({
                    payload: {
                        billingHistory: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_HOUSE_BILLING_HISTORY_SUCCESS,
                });
            } catch (error) {
                dispatch({ error: true, payload: error, type: LOAD_HOUSE_BILLING_HISTORY_FAIL });
            }
        }
    };

export const onBillingFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: BILLING_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { error },
            payload: 'Error changing filter',
            type: BILLING_FILTERS_CHANGE_FAIL,
        });
    }
};

export const getHouseBillingRecords = (filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await api.getHouseBillingCsv({
            authToken,
            deployment,
            from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
            // @ts-ignore
            page: filters.page,
            pageSize: filters.pageSize,
            to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const postHouseRefund =
    (transactionId: string, refundForm: RefundForm) => async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: POST_HOUSE_PAYMENT_REFUND_REQUEST,
            });

            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await api.postHousePaymentRefund({
                authToken,
                deployment,
                refundForm,
                transactionId,
            });

            return dispatch({
                payload: response.payload,
                type: POST_HOUSE_PAYMENT_REFUND_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error.payload || 'Error posting refund',
                type: POST_HOUSE_PAYMENT_REFUND_FAIL,
            });
        }
    };
