import { handleResponse, makeDelete, makeGet, makePost } from '../../api/helpers';
import { HouseBeta, UpdateShippingSaintHouseIdParams } from '@/redux/modules/houseBeta/houseBeta.types';

type FetchBetasByHouseIdParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};
export const fetchBetasByHouseId = ({ authToken, deployment, houseId }: FetchBetasByHouseIdParams) =>
    new Promise<{ payload: HouseBeta[] }>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/beta`,
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type DeleteBetaByIdParams = {
    authToken: string;
    betaId: number;
    deployment: string;
    houseId: number;
};
export const deleteBetaById = ({ authToken, betaId, deployment, houseId }: DeleteBetaByIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `house/${houseId}/beta/${betaId}`,
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type AddBetaByIdParams = {
    authToken: string;
    betaId: number;
    deployment: string;
    houseId: number;
};
export const addBetaById = ({ authToken, betaId, deployment, houseId }: AddBetaByIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `house/${houseId}/beta/${betaId}`,
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type CriteriaParams = {
    active: boolean;
    authToken: string;
    deployment: string;
    houseId: number;
};
type ApprovalCriteria = {
    active: boolean;
    bidLimit: number;
    criteriaId: number;
    dependent: boolean;
    limited: boolean;
};
type ApprovalCriteriaBody = {
    criteria: ApprovalCriteria[];
};
type ApprovalCriteriaResponse = {
    error: boolean;
};
export const createGlobalBidLimitCriteria = ({ active, authToken, deployment, houseId }: CriteriaParams) =>
    new Promise<ApprovalCriteriaResponse>((resolve, reject) => {
        const body: ApprovalCriteriaBody = {
            criteria: [
                {
                    active,
                    bidLimit: 2000,
                    criteriaId: 12,
                    dependent: false,
                    limited: true,
                },
            ],
        };
        const request = makePost({
            apiPath: `house/${houseId}/criteria/create`,
            authToken,
            deployment,
            path: '<APPROVAL-API>',
        });
        request.send(body);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetShippingSaintHouseIdParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type GetShippingSaintHouseIdResponse = {
    error: boolean;
    payload: {
        houseId: number;
        shippingSaintsHouseId: string;
    };
};

export const getShippingSaintHouseId = ({ authToken, deployment, houseId }: GetShippingSaintHouseIdParams) =>
    new Promise<GetShippingSaintHouseIdResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getshippingsaintshouseid',
            authToken,
            deployment,
            path: '<SUPPORT-API>',
            queryParams: {
                houseId,
            },
        });
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type PostShippingSaintHouseIdParams = UpdateShippingSaintHouseIdParams & {
    authToken: string;
    deployment: string;
};

type PostShippingSaintHouseIdResponse =
    | {
          error: true;
          payload: string;
      }
    | {
          error: false;
          payload: {
              houseId: number;
              shippingSaintsHouseId: string;
          };
      };

export const postShippingSaintHouseId = ({
    authToken,
    deployment,
    houseId,
    shippingSaintHouseId,
}: PostShippingSaintHouseIdParams) =>
    new Promise<PostShippingSaintHouseIdResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'mapshippingsaintshouseid',
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.send({
            houseId,
            shippingSaintsHouseId: shippingSaintHouseId,
        });
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

export type DeleteShippingSaintHouseIdParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export const deleteShippingSaintHouseId = ({ authToken, deployment, houseId }: DeleteShippingSaintHouseIdParams) =>
    new Promise((resolve, reject) => {
        const request = makeDelete({
            apiPath: 'deleteshippingsaintshouseid',
            authToken,
            deployment,
            path: '<SUPPORT-API>',
        });
        request.send({
            houseId,
        });
        request.end((err, response) => {
            handleResponse({
                err,
                reject,
                resolve,
                response,
            });
        });
    });
