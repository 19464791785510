import { handleResponse, makePost } from './helpers';
import { TransactionStatus } from '@/types/BidderPayment';

type LoadBidderPaymentStatusDataParams = {
    authToken: string;
    deployment: string;
    transactionIds: string[];
};

type LoadBidderPaymentStatusDataResponse = {
    error: boolean;
    payload: TransactionStatus[];
};

export const loadBidderPaymentStatusData = ({
    authToken,
    deployment,
    transactionIds,
}: LoadBidderPaymentStatusDataParams) =>
    new Promise<LoadBidderPaymentStatusDataResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: '/admin/payments/status',
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send({
            transactionIds,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
