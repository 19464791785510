import { handleResponse, makePost } from './helpers';

type PostSendSellerInvoicesParams = {
    authToken: string;
    deployment: string;
    limit: number;
};
const postSendSellerInvoices = ({ authToken, deployment, limit }: PostSendSellerInvoicesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ authToken, deployment, path: '<FINANCE-API>houses/send' });
        if (typeof limit === 'number' && limit === 0) {
            request.send({ sendAll: true });
        }
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    postSendSellerInvoices,
};
