import { handleResponse, makeGet, makePost } from './helpers';

type FetchSellersParams = {
    deployment: string;
};

type FetchSellersByIdsParams = {
    authToken: string;
    deployment: string;
    sellerIds: number[];
    useCacheKey?: boolean;
};

export const fetchSellers = ({ deployment }: FetchSellersParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ deployment, path: '<ITEM-API>spa/small/sellers' });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const fetchSellersByIds = ({ authToken, deployment, sellerIds, useCacheKey }: FetchSellersByIdsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ authToken, deployment, path: '<ITEM-API>spa/small/sellers', useCacheKey });
        request.send({ ids: sellerIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
