import { createSelector } from 'reselect';
import { TrainingCollectionState } from './trainingCollection.types';

// TODO: Type state param as global state type when that type is created
const stateSelector = (state): TrainingCollectionState => state.trainingCollection;

export const trainingCollectionIsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const trainingCollectionIsUpdatingSelector = createSelector(stateSelector, (state) => state.isUpdating);
export const trainingCollectionHasErrorSelector = createSelector(stateSelector, (state) => state.error);
export const trainingCollectionSynonymsSelector = createSelector(stateSelector, (state) => state.synonyms);
export const trainingCollectionExclusionSelector = createSelector(stateSelector, (state) => state.exclusions);

export const trainingCollectionSelectedItemsSelector = createSelector(
    stateSelector,
    (state) => state.selectedTrainingItems
);

export const trainingCollectionTotalItemCountSelector = createSelector(stateSelector, (state) => state.totalItemCount);

export const trainingCollectionPotentialTrainingItemsSelector = createSelector(
    stateSelector,
    (state) => state.potentialTrainingItems
);
