import { createSelector } from 'reselect';
import { GlobalState } from '@/redux/store';
import { SELLER_INCENTIVE_SLICE_CACHE_TIME, SellerIncentiveSlice } from './sellerIncentive.types';

const stateSelector = (state: GlobalState): SellerIncentiveSlice => state.sellerIncentive;
const passThroughSelector = (_, passThroughValue) => passThroughValue;

export const getSellerIncentiveSlice = stateSelector;

export const getSellerIncentiveError = createSelector(stateSelector, ({ error }) => error);

export const getSellerIncentiveLoading = createSelector(stateSelector, ({ loading }) => loading);

export const getSellerIncentiveLoaded = createSelector(stateSelector, ({ loaded }) => loaded);

export const getSellerIncentiveHouseId = createSelector(stateSelector, ({ houseId }) => houseId);

export const shouldFetchSellerIncentive = createSelector(
    getSellerIncentiveLoaded,
    getSellerIncentiveLoading,
    getSellerIncentiveHouseId,
    passThroughSelector,
    (loaded, loading, storedHouseId, houseIdToFetch) => {
        if (loading) {
            return false;
        }
        if (storedHouseId !== houseIdToFetch) {
            return true;
        }

        const time = Date.now();
        const diff = time - (loaded?.valueOf() ?? 0);
        return !loaded || diff > SELLER_INCENTIVE_SLICE_CACHE_TIME;
    }
);
