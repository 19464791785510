import { deployment } from '../../../../config';
import { getItemUrl, urlSafeTitle } from '@/utils/urls';
import { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TrainingItem } from '../CategoryTrainingCollection.types';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled, { css } from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';

export type CategoryTrainingItemCardProps = {
    isAdding: boolean;
    isLotIdSelectedExternally: boolean;
    onClick: (lotId: number, isSelected: boolean) => void;
    trainingItem: TrainingItem;
};

const CategoryTrainingItemCard = ({
    isAdding,
    isLotIdSelectedExternally,
    onClick,
    trainingItem,
}: CategoryTrainingItemCardProps): ReactElement<CategoryTrainingItemCardProps> => {
    const { collected, id, imageUrl, name } = trainingItem;
    const [isSelected, setIsSelected] = useState(collected);
    const [failedImageLoad, setFailedImageLoad] = useState(false);
    const isFirstRender = useRef(true);

    useLayoutEffect(() => {
        // we don't want to override item's initial selected state
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setIsSelected(isLotIdSelectedExternally);
    }, [isAdding, isFirstRender, isLotIdSelectedExternally]);

    // We want this to only run once, when the component is first mounted, rather than listening to "isAdding"
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!isAdding) {
            setIsSelected(false);
        }
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <StyledItemCard
            $isSelected={isSelected}
            onClick={() => {
                onClick(id, !isSelected);
                setIsSelected(!isSelected);
            }}
        >
            <StyledImageHolder
                id={`training-item-${id}-is-selected-checkbox`}
                onClick={() => {
                    onClick(id, !isSelected);
                    setIsSelected(!isSelected);
                }}
            >
                {failedImageLoad ? (
                    <StyledNoImage id="no-image" />
                ) : (
                    <StyledItemImage
                        alt={name}
                        onError={() => {
                            setFailedImageLoad(true);
                        }}
                        src={imageUrl}
                    />
                )}
            </StyledImageHolder>
            <StyledCardTitle $isSelected={isSelected} h4>
                <Link primary target="_blank" to={getItemUrl(deployment, id, urlSafeTitle(name))}>
                    {id}: {name}
                </Link>
            </StyledCardTitle>
        </StyledItemCard>
    );
};

export default CategoryTrainingItemCard;

const selectedBorderWidth = 7;
const IMAGE_HEIGHT = 240;
const IMAGE_WIDTH = 240;
const baseVerticalMargin = 10;
const baseHorizontalMargin = 20;
const StyledItemCard = styled.article<{ $isSelected: boolean }>`
    :hover {
        cursor: pointer;
    }

    ${({ $isSelected, theme }) => css`
        border: ${$isSelected ? `${theme.colors.blue100} solid ${selectedBorderWidth}px` : ''};
        margin: ${$isSelected ? baseVerticalMargin - selectedBorderWidth : baseVerticalMargin}px
            ${$isSelected ? baseHorizontalMargin - selectedBorderWidth : baseHorizontalMargin}px;
        width: ${IMAGE_WIDTH + 2 * ($isSelected ? selectedBorderWidth : 0)}px;
    `}
`;

const StyledImageHolder = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
    `}
`;

const StyledItemImage = styled.img`
    object-fit: contain;
    height: ${IMAGE_HEIGHT}px;
    width: ${IMAGE_WIDTH}px;
`;

const StyledNoImage = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.beige100};
        height: ${IMAGE_HEIGHT}px;
        width: ${IMAGE_WIDTH}px;
    `}
`;

const StyledCardTitle = styled(Text)<{ $isSelected: boolean }>`
    ${({ $isSelected }) =>
        $isSelected
            ? css`
                  padding-left: 5px;
                  box-decoration-break: clone;
              `
            : css``};
`;
