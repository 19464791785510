import { handleResponseV2, makeGet, makePost } from '@/redux/api/helpers';
import { LabelSettings, ShippingLabel, ShippingLabelOption, ShippingLabelOwner } from './shippingLabel.types';

type ShippingLabelParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

const generateRequest = ({
    authToken,
    deployment,
    houseId,
}: ShippingLabelParams): Parameters<typeof makeGet>[0] | Parameters<typeof makePost>[0] => ({
    apiPath: `house/${houseId}/elabel`,
    authToken,
    deployment,
    path: '<SHIPPING-API>',
});

type ShippingLabelApiModel = {
    fedexSettings: LabelSettings;
    isDefault: ShippingLabelOwner;
    upsSettings: LabelSettings;
    uspsSettings: LabelSettings;
};

type GetShippingLabelResponse = {
    error: boolean;
    payload: ShippingLabel;
};

export const getShippingLabel = (params: ShippingLabelParams) =>
    new Promise<GetShippingLabelResponse>((resolve, reject) => {
        const request = makeGet(generateRequest(params));

        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
                transform: (model: { error: boolean; payload: ShippingLabelApiModel }) => ({
                    ...model,
                    payload: {
                        isDefault: model.payload.isDefault,
                        [ShippingLabelOption.UnitedParcelService]: model.payload.upsSettings,
                        [ShippingLabelOption.Fedex]: model.payload.fedexSettings,
                        [ShippingLabelOption.UnitedStatesPostalService]: model.payload.uspsSettings,
                    },
                }),
            })
        );
    });

type PostShippingLabelParams = ShippingLabelParams & {
    body: ShippingLabel;
};

type PostShippingLabelResponse = {};

export const postShippingLabel = ({ authToken, body, deployment, houseId }: PostShippingLabelParams) =>
    new Promise<PostShippingLabelResponse>((resolve, reject) => {
        const mappedParams: ShippingLabelParams = {
            authToken,
            deployment,
            houseId,
        };
        const request = makePost(generateRequest(mappedParams));

        request.send({
            fedexSettings: body[ShippingLabelOption.Fedex],
            isDefault: body.isDefault,
            upsSettings: body[ShippingLabelOption.UnitedParcelService],
            uspsSettings: body[ShippingLabelOption.UnitedStatesPostalService],
        });

        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
