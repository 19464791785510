import { handleResponse, makePost } from './helpers';

type PostSpaAuctioneerLoginParams = {
    deployment: string;
    password: string;
    username: string;
};

export const postSpaAuctioneerLogin = ({ deployment, password, username }: PostSpaAuctioneerLoginParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ deployment, path: '<ITEM-API>auth/spaauctioneerlogin' });
        request.send({
            password,
            username,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
