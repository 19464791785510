import { createSlice } from '@reduxjs/toolkit';
import { defaultShippingIncentivesSlice } from '@/redux/modules/shippingIncentives/shippingIncentives.types';
import {
    fetchShippingIncentivesSettings,
    saveShippingIncentivesSettings,
} from '@/redux/modules/shippingIncentives/shippingIncentives.actions';

const shippingIncentivesSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchShippingIncentivesSettings.pending, (slice, { meta }) => {
            if (slice.loading.indexOf(meta.arg) < 0) {
                slice.loading = [...slice.loading, meta.arg];
            }
        });
        builder.addCase(fetchShippingIncentivesSettings.fulfilled, (slice, { meta, payload }) => {
            const loadingIndex = slice.loading.indexOf(meta.arg);
            if (loadingIndex >= 0) {
                slice.loading = slice.loading.splice(loadingIndex, 1);
            }
            if (payload.maxPercent || payload.maxPrice || payload.minDistance) {
                slice.loaded[meta.arg] = Date.now();
                slice.byId[meta.arg] = payload;
            }
        });
        builder.addCase(fetchShippingIncentivesSettings.rejected, (slice, { meta }) => {
            const loadingIndex = slice.loading.indexOf(meta.arg);
            if (loadingIndex >= 0) {
                slice.loading = slice.loading.splice(loadingIndex, 1);
            }
        });

        builder.addCase(saveShippingIncentivesSettings.pending, (slice) => {
            slice.result = undefined;
        });
        builder.addCase(saveShippingIncentivesSettings.fulfilled, (slice) => {
            slice.result = 'success';
        });
        builder.addCase(saveShippingIncentivesSettings.rejected, (slice) => {
            slice.result = 'failure';
        });
    },
    initialState: defaultShippingIncentivesSlice,
    name: 'shippingIncentives',
    reducers: {},
});

export const { reducer: shippingIncentivesReducer } = shippingIncentivesSlice;
