import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const REMOVE_LOTS_FROM_BIN = 'REMOVE_LOTS_FROM_BIN';
export const REMOVE_LOTS_FROM_BIN_SUCCESS = 'REMOVE_LOTS_FROM_BIN_SUCCESS';
export const REMOVE_LOTS_FROM_BIN_ERROR = 'REMOVE_LOTS_FROM_BIN_ERROR';
export const ADD_LOTS_TO_BIN = 'ADD_LOTS_TO_BIN';
export const ADD_LOTS_TO_BIN_SUCCESS = 'ADD_LOTS_TO_BIN_SUCCESS';
export const ADD_LOTS_TO_BIN_ERROR = 'ADD_LOTS_TO_BIN_ERROR';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case ADD_LOTS_TO_BIN:
        case REMOVE_LOTS_FROM_BIN:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case ADD_LOTS_TO_BIN_SUCCESS:
        case REMOVE_LOTS_FROM_BIN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case ADD_LOTS_TO_BIN_ERROR:
        case REMOVE_LOTS_FROM_BIN_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.removeBINLots;

export const getBINLotsError = createSelector(stateSelector, (state) => state.error);

export const getBINLotsLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export const removeBINLots = (itemIds: number[]) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: itemIds,
            type: REMOVE_LOTS_FROM_BIN,
        });

        const response = await api.postRemoveBINItems({
            authToken,
            deployment,
            itemIds,
        });
        dispatch({
            meta: { itemIds },
            payload: response.payload,
            type: REMOVE_LOTS_FROM_BIN_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: REMOVE_LOTS_FROM_BIN_ERROR,
        });
    }
};

export const addBINLots = (itemIds: number[]) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: itemIds,
            type: ADD_LOTS_TO_BIN,
        });

        const response = await api.postAddBINItems({
            authToken,
            deployment,
            itemIds,
        });
        dispatch({
            meta: { itemIds },
            payload: response.payload,
            type: ADD_LOTS_TO_BIN_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: ADD_LOTS_TO_BIN_ERROR,
        });
    }
};
