import * as api from '../api/support';
import { createSelector } from 'reselect';
import { getAuthToken } from './user';
import { getDeployment } from './config';

/* Action Types */
export const MARK_AS_PAID_START = 'MARK_AS_PAID_START';
export const MARK_AS_PAID_SUCCESS = 'MARK_AS_PAID_SUCCESS';
export const MARK_AS_PAID_ERROR = 'MARK_AS_PAID_ERROR';

/* reducer */
const DEFAULT_STATE = {
    error: null,
    loading: false,
};

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case MARK_AS_PAID_START:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case MARK_AS_PAID_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case MARK_AS_PAID_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.markAsPaid;

export const getMarkAsPaidError = createSelector(stateSelector, (state) => state.error);

export const getMarkAsPaidLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
type MarkAsPaidParams = { itemId: number };
export const markAsPaid =
    ({ itemId }: MarkAsPaidParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: itemId,
                type: MARK_AS_PAID_START,
            });

            const response = await api.postMarkJ52ItemPaid({
                authToken,
                deployment,
                itemId,
            });
            dispatch({
                meta: { itemId },
                payload: response.payload,
                type: MARK_AS_PAID_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: MARK_AS_PAID_ERROR,
            });
        }
    };
