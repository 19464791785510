import { handleResponseV2, makeGet, makePost } from '@/redux/api/helpers';
import { ShippingMethodIncentivesSettings } from '@/redux/modules/shippingIncentives/shippingIncentives.types';

type ShippingIncentivesParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

const generateRequest = ({
    authToken,
    deployment,
    houseId,
}: ShippingIncentivesParams): Parameters<typeof makeGet>[0] | Parameters<typeof makePost>[0] => ({
    apiPath: `house/${houseId}/invoice-generation-settings`,
    authToken,
    deployment,
    path: '<SHIPPING-API>',
});

type ShippingIncentiveSettingsApiModel = {
    distanceLowerLimit: number;
    houseId: number;
    quotePercentage: number;
    quoteUpperLimit: number;
    setDefaultLS: number;
};

type GetShippingIncentivesSettingsResponse = {
    error: boolean;
    payload: ShippingMethodIncentivesSettings;
};

export const getShippingIncentivesSettings = (params: ShippingIncentivesParams) =>
    new Promise<GetShippingIncentivesSettingsResponse>((resolve, reject) => {
        const request = makeGet(generateRequest(params));
        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
                transform: (model: {
                    error: boolean;
                    payload: ShippingIncentiveSettingsApiModel;
                }): GetShippingIncentivesSettingsResponse => ({
                    ...model,
                    payload: {
                        maxPercent: model.payload.quotePercentage,
                        maxPrice: model.payload.quoteUpperLimit,
                        minDistance: model.payload.distanceLowerLimit,
                        shouldDefaultToLiveShipping: Boolean(model.payload.setDefaultLS),
                    },
                }),
            })
        );
    });

type PostShippingIncentivesSettingsParams = ShippingIncentivesParams & {
    body: ShippingMethodIncentivesSettings;
};

type PostShippingIncentivesSettingsResponse = {};

export const postShippingIncentivesSettings = ({
    authToken,
    body,
    deployment,
    houseId,
}: PostShippingIncentivesSettingsParams) =>
    new Promise<PostShippingIncentivesSettingsResponse>((resolve, reject) => {
        const mappedParams: ShippingIncentivesParams = {
            authToken,
            deployment,
            houseId,
        };
        const request = makePost(generateRequest(mappedParams));
        request.send({
            distanceLowerLimit: body.minDistance,
            houseId,
            quotePercentage: body.maxPercent,
            quoteUpperLimit: body.maxPrice,
            setDefaultLS: Number(body.shouldDefaultToLiveShipping),
        });
        request.end((err, response) =>
            handleResponseV2({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
