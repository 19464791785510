import {
    GetSellerIncentiveParams,
    GetSellerIncentiveResponse,
    PostSellerIncentiveParams,
    PostSellerIncentiveResponse,
} from './sellerIncentive.types';
import { handleResponseWithNon200Errors, makeGet, makePost } from '../../api/helpers';

type PostSellerIncentiveAPIParams = {
    authToken: string;
    deployment: string;
    params: PostSellerIncentiveParams;
};

export const postSellerIncentive = ({
    authToken,
    deployment,
    params: { active, amount, houseId, marketplaceCode, percentage, type },
}: PostSellerIncentiveAPIParams) =>
    new Promise<PostSellerIncentiveResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `marketplace/LA/house/${houseId}/incentives`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.send({ active, amount, houseId, marketplaceCode, percentage, type });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });

type GetSellerIncentiveAPIParams = {
    authToken: string;
    deployment: string;
    params: GetSellerIncentiveParams;
};

export const getSellerIncentive = ({ authToken, deployment, params: { houseId } }: GetSellerIncentiveAPIParams) =>
    new Promise<GetSellerIncentiveResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `marketplace/LA/house/${houseId}/incentives`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
