/* Action Types */
export const FETCH_RELATED_SEARCHES_FAIL = 'FETCH_RELATED_SEARCHES_FAIL';
export const FETCH_RELATED_SEARCHES_START = 'FETCH_RELATED_SEARCHES_START';
export const FETCH_RELATED_SEARCHES_SUCCESS = 'FETCH_RELATED_SEARCHES_SUCCESS';
export const FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL = 'FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL';
export const FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_START = 'FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_START';
export const FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS = 'FETCH_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS';
export const DELETE_RELATED_SEARCH_SUCCESS = 'DELETE_RELATED_SEARCH_SUCCESS';
export const SAVE_RELATED_SEARCH_FAIL = 'SAVE_RELATED_SEARCH_FAIL';
export const SAVE_RELATED_SEARCH_REQUEST = 'SAVE_RELATED_SEARCH_REQUEST';
export const SAVE_RELATED_SEARCH_SUCCESS = 'SAVE_RELATED_SEARCH_SUCCESS';

export const FETCH_COMBINATION_RELATED_SEARCHES_FAIL = 'FETCH_COMBINATION_RELATED_SEARCHES_FAIL';
export const FETCH_COMBINATION_RELATED_SEARCHES_START = 'FETCH_COMBINATION_RELATED_SEARCHES_START';
export const FETCH_COMBINATION_RELATED_SEARCHES_SUCCESS = 'FETCH_COMBINATION_RELATED_SEARCHES_SUCCESS';
export const FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL =
    'FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_FAIL';
export const FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_START =
    'FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_START';
export const FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS =
    'FETCH_COMBINATION_RELATED_SEARCHES_TAXONOMY_CHILDREN_SUCCESS';
export const DELETE_COMBINATION_RELATED_SEARCH_SUCCESS = 'DELETE_COMBINATION_RELATED_SEARCH_SUCCESS';
export const SAVE_COMBINATION_RELATED_SEARCH_FAIL = 'SAVE_COMBINATION_RELATED_SEARCH_FAIL';
export const SAVE_COMBINATION_RELATED_SEARCH_REQUEST = 'SAVE_COMBINATION_RELATED_SEARCH_REQUEST';
export const SAVE_COMBINATION_RELATED_SEARCH_SUCCESS = 'SAVE_COMBINATION_RELATED_SEARCH_SUCCESS';
