import { handleResponse, makeGet, makePost } from './helpers';
import { RefundForm } from '../../types/Payment';

type GetHouseBillingHistoryParams = {
    authToken: string;
    deployment: string;
    from: string;
    keyword: string;
    page: number;
    pageSize: number | 'all';
    to: string;
};

type GetHouseBillingRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    to: string;
};

type PostHousePaymentRefundParams = {
    authToken: string;
    deployment: string;
    refundForm: RefundForm;
    transactionId: string;
};

export default {
    getHouseBillingCsv: ({ authToken, deployment, from, to }: GetHouseBillingRecordsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'admin/housepaymentscsv',
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.query({ from, to });
            request.responseType('blob');
            request.end((err, response) => {
                if (err) {
                    return reject(err);
                }
                return resolve(response);
            });
        }),
    getHouseBillingHistory: ({
        authToken,
        deployment,
        from,
        keyword,
        page,
        pageSize,
        to,
    }: GetHouseBillingHistoryParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'admin/housepayments',
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.query({ from, keyword, page, pageSize, to });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postHousePaymentRefund: ({ authToken, deployment, refundForm, transactionId }: PostHousePaymentRefundParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                apiPath: `housepayment/${transactionId}/refund`,
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.send(refundForm);
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
