import * as api from '../api/support';
import { ActionWithPayload } from '../../types/redux';
import { createSelector } from 'reselect';
import { FETCH_SERVER_STATUS_FAILURE, FETCH_SERVER_STATUS_REQUEST, FETCH_SERVER_STATUS_SUCCESS } from './actions';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { ServerStatusRow } from '../../types/ServerStatusRow';

/* reducer */
const DEFAULT_STATE = {
    data: [],
    error: null,
    loading: false,
};

type State = typeof DEFAULT_STATE & {
    data: ServerStatusRow[];
    error: string | null | undefined;
};

export const reducer = handleActions(
    {
        [FETCH_SERVER_STATUS_FAILURE]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            data: [],
            error: action.payload,
            loading: false,
        }),
        [FETCH_SERVER_STATUS_REQUEST]: (state: State) => ({
            ...state,
            data: [],
            loading: true,
        }),
        [FETCH_SERVER_STATUS_SUCCESS]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            data: action.payload,
            loading: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.serverStatus;

export const getServerStatusData = createSelector(stateSelector, (state) => state.data);

export const getServerStatusError = createSelector(stateSelector, (state) => state.error);

export const getServerStatusLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export const fetchServerStatusData = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: FETCH_SERVER_STATUS_REQUEST,
        });

        const response = await api.loadAuctionServerStatus({
            authToken,
            deployment,
        });
        dispatch({
            payload: response.payload,
            type: FETCH_SERVER_STATUS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_SERVER_STATUS_FAILURE,
        });
    }
};
